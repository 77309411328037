import React, { useState } from 'react';
import TagEdit from './TagEdit';

export default initialProps => {
    const [tags, setTags] = useState(initialProps.alleSortedByFirstLetter);

    return (
        <div className="row">
            {Object.keys(tags)
                .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()))
                .map(letter => (
                    <div className="col-12 mt-2" key={letter}>
                        <div className="card">
                            <div className="card-body d-flex flex-column">
                                <h3 className="text-primary">{letter}</h3>
                                <p>
                                    {tags[letter].map(tag => (
                                        <TagEdit tag={tag} key={tag.id} updateTags={setTags} />
                                    ))}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};
