import React, { Component } from 'react';
import PortalButtonBar from '../PortalButtonBar';
import UrlaubsantragWocheTable from './Edit/UrlaubsantragWocheTable';
import UrlaubsantragProjekte from './Edit/UrlaubsantragProjekte';
import UrlaubsantragTagRow from './Edit/UrlaubsantragTagRow';
import GenehmigtUrlaubsantragKommentare from './Edit/GenehmigtUrlaubsantragKommentare';
import UrlaubsantragUebersicht from './Edit/UrlaubsantragUebersicht';
import UrlaubsantragGenehmigungsVerantwortliche from './Edit/UrlaubsantragGenehmigungsVerantwortliche';
import 'react-dates/initialize';
import moment from 'moment';
import * as api from '../api';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { URLAUBSANTRAG_STATUS } from './constants';
import toast from '../toastify';
import uuid4 from 'uuid/v4';
import Select from 'react-select';

/**
 * Type for representing the Urlaubsantrag. It is designed to facilitate easy serialization/deserialization vs the backend and is thus structured according to
 * what the backend expects and delivers. Any changes to properties not marked for internal use on the frontend must be coordinated with the backend.
 *
 * @typedef {Object} Urlaubsantrag
 * @property {UrlaubsantragId} urlaubsantragId
 * @property {string} von - in YYYY-MM-DD format
 * @property {string} bis - in YYYY-MM-DD format
 * @property {string} status
 * @property {string} kommentar
 * @property {UrlaubsantragTagKonditionen[]} konditionen
 * @property {UrlaubsantragProjektVertretungsDaten[]} projektVertretungsDaten
 * @property {UrlaubsantragGenehmigungsDaten[]} genehmigungsDaten
 */

/**
 * @typedef {Object} UrlaubsantragId
 * @property {string} id - uuid of the Urlaubsantrag
 */

/**
 * @typedef {Object} UrlaubsantragTagKonditionen
 * @property {Moment} date - Moment representation of the tag property; required for use here on the frontend
 * @property {string} tag - YYYY-MM-DD
 * @property {float} sollstunden
 * @property {float} urlaubstunden
 * @property {float} ueberstunden
 */

/**
 * @typedef {Object} UrlaubsantragProjektVertretungsDaten
 * @property {int} projektId
 * @property {int} vertretungId
 * @property {int} value - Projekt id, for internal use with React-Select
 * @property {string} label - Projekt name, for internal use with React-Select
 * @property {boolean} selected - is the Projekt currently included in the state.urlaubsantrag.projektVertretungsDaten, for internal use with React-Select
 * @property {string} vertretungName - name of the Vertreter for this Projekt
 */

/**
 * @typedef {Object} UrlaubsantragGenehmigungsDaten
 * @property {int} verantwortlicherId - id of the Genehmigungsverantwortlicher Mitarbeiter
 * @property {int} value - id of the Genehmigungsverantwortlicher Mitarbeiter, for internal use with React-Select
 * @property {string} label - name of the Genehmigungsverantwortlicher Mitarbeiter, for internal use with React-Select
 */

export default class GenehmigtUrlaubsantragErfassung extends Component {
    constructor(props) {
        super(props);
        this.urlaubsAntragEditor = React.createRef();
        this.urlaubsantragProjekteEditor = React.createRef();
        this.urlaubsantragGenehmigungsVerantwortlicheEditor = React.createRef();

        /**
         * @type {{
         *  urlaubsantrag: Urlaubsantrag,
         *  projektsForSelect: UrlaubsantragProjektVertretungsDaten[],
         *  mitarbeitersForSelect: UrlaubsantragGenehmigungsDaten[],
         *  urlaubsantragIsDirty: boolean,
         *  saveInProgress: boolean,
         *  beantragenInProgress: boolean,
         *  verwerfenInProgress: boolean
         * }}
         */
        this.state = {
            mitarbeitersForSelect: [],
            projektsForSelect: [],
            resizeDatePicker: false,
            startDate: null,
            endDate: null,
            focusedInput: null,
            showUrlaubsAntragEditor: false,
            urlaubsantrag: {
                urlaubsantragId: { id: null },
                von: null,
                bis: null,
                status: null,
                kommentar: '',
                konditionen: [],
                projektVertretungsDaten: [],
                genehmigungsDaten: [],
            },
            zeiterfassungStats: {},
            fetching: true,
            urlaubsantragIsDirty: false,
            saveInProgress: false,
            beantragenInProgress: false,
            verwerfenInProgress: false,
            stundenPristine: true,
            selectedMitarbeiter: null,
            fetchingMitarbeiterData: false,
        };
    }

    componentDidMount() {
        this.updateDatePickerView();
        window.addEventListener('resize', this.updateDatePickerView);

        this.loadMitarbeiters();
    }

    loadMitarbeiters = async () => {
        const json = await api.getJson(`/urlaub/urlaubsantrag/new-data/ich`);
        this.setState({ mitarbeitersForSelect: json.mitarbeiters, fetching: false });
    };

    clear = () => {
        this.setState({
            resizeDatePicker: false,
            startDate: null,
            endDate: null,
            focusedInput: null,
            showUrlaubsAntragEditor: false,
            urlaubsantrag: {
                urlaubsantragId: { id: null },
                von: null,
                bis: null,
                status: null,
                kommentar: '',
                konditionen: [],
                projektVertretungsDaten: this.state.projektsForSelect
                    .filter(projekt => projekt.current === true)
                    .map(projekt => this.getNewProjektVertretungsDaten(projekt)),
                genehmigungsDaten: [],
            },
            zeiterfassungStats: {},
            urlaubsantragIsDirty: false,
            saveInProgress: false,
            beantragenInProgress: false,
            verwerfenInProgress: false,
            stundenPristine: true,
            fetchingMitarbeiterData: false,
        });
    };

    onMitarbeiterChange = value => {
        this.setState({ fetchingMitarbeiterData: true, selectedMitarbeiter: value, startDate: null, endDate: null });

        api.getJson(`/urlaub/urlaubsantrag/new-data/${value.value}`).then(json => {
            const urlaubsantrag = {
                urlaubsantragId: { id: null },
                von: null,
                bis: null,
                status: null,
                kommentar: '',
                konditionen: [],
                projektVertretungsDaten: [],
                genehmigungsDaten: [],
            };
            urlaubsantrag.projektVertretungsDaten = json.projekts
                .filter(projekt => projekt.current === true)
                .map(projekt => this.getNewProjektVertretungsDaten(projekt));

            this.setState({
                projektsForSelect: json.projekts,
                resizeDatePicker: false,
                startDate: null,
                endDate: null,
                focusedInput: null,
                showUrlaubsAntragEditor: false,
                urlaubsantrag,
                zeiterfassungStats: {},
                urlaubsantragIsDirty: false,
                saveInProgress: false,
                beantragenInProgress: false,
                verwerfenInProgress: false,
                stundenPristine: true,
                selectedMitarbeiter: value,
                fetchingMitarbeiterData: false,
            });
        });
    };

    getNewProjektVertretungsDaten = projekt => {
        return {
            projektId: projekt.value,
            vertretungId: null,
            value: projekt.value,
            label: projekt.label,
            vertretungName: '',
            frontendUuid: uuid4(),
        };
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDatePickerView);
    }

    updateDatePickerView = () => {
        this.setState({
            resizeDatePicker: window.innerWidth < 1200,
        });
    };

    addProjektVertretung = () => {
        let projektToAdd = this.state.projektsForSelect.find(projekt => projekt.current === true);
        if (projektToAdd === undefined) {
            projektToAdd = this.state.projektsForSelect.find(projekt => true);
        }
        const urlaubsantrag = this.state.urlaubsantrag;
        urlaubsantrag.projektVertretungsDaten.push(this.getNewProjektVertretungsDaten(projektToAdd));
        this.setState({ urlaubsantrag, urlaubsantragIsDirty: true });
    };

    removeProjektVertretung = projektVertretungToRemove => {
        const indexOfProjektToRemove = this.state.urlaubsantrag.projektVertretungsDaten.findIndex(
            projektVertretung => projektVertretung.frontendUuid === projektVertretungToRemove.frontendUuid
        );
        const urlaubsantrag = this.state.urlaubsantrag;
        urlaubsantrag.projektVertretungsDaten.splice(indexOfProjektToRemove, 1);
        this.setState({ urlaubsantrag, urlaubsantragIsDirty: true });
    };

    onProjektVertretungProjectChange = (projektVertretung, newProjekt) => {
        projektVertretung.projektId = newProjekt.value;
        projektVertretung.value = newProjekt.value;
        projektVertretung.label = newProjekt.label;

        // urlaubsantrag has to be set in state because it contains projektVertretung
        this.setState({ urlaubsantrag: this.state.urlaubsantrag, urlaubsantragIsDirty: true });
    };

    onProjektVertretungVertretungChange = (projektVertretung, newVertretung) => {
        projektVertretung.vertretungId = newVertretung.value;
        projektVertretung.vertretungName = newVertretung.label;
        // urlaubsantrag has to be set in state because it contains projektVertretung
        this.setState({ urlaubsantrag: this.state.urlaubsantrag, urlaubsantragIsDirty: true });
    };

    areProjektVertretungenValid = () => {
        return this.state.urlaubsantrag.projektVertretungsDaten.every(projektVertretung => projektVertretung.vertretungId !== null);
    };

    toggleUrlaubsAntragEditor = () => {
        const urlaubsAntragEditor = this.urlaubsAntragEditor.current;
        if (this.state.showUrlaubsAntragEditor === false) {
            this.setState({ showUrlaubsAntragEditor: true });
        } else {
            this.setState({ showUrlaubsAntragEditor: false });
        }
        setTimeout(() => urlaubsAntragEditor.scrollIntoView({ block: 'end', behavior: 'smooth' }), 50);
    };

    onDatesChange = ({ startDate, endDate }) => {
        const updateState = (startDate, endDate, data = null) => {
            let { urlaubsantrag, zeiterfassungStats } = this.state;
            let urlaubsantragIsDirty = false;
            if (data !== null) {
                urlaubsantrag.von = startDate.format('YYYY-MM-DD');
                urlaubsantrag.bis = endDate.format('YYYY-MM-DD');
                urlaubsantrag.konditionen = data.zeitraumDaten.tagStunden.map(data => {
                    return {
                        date: moment(data.tag),
                        tag: moment(data.tag).format('YYYY-MM-DD'),
                        sollstunden: data.sollstunden,
                        urlaubstunden: data.sollstunden,
                        ueberstunden: 0,
                    };
                });
                zeiterfassungStats = data.zeiterfassungStats;
                urlaubsantragIsDirty = true;
            }

            this.setState({ startDate, endDate, urlaubsantrag, zeiterfassungStats, urlaubsantragIsDirty });
            this.calculate();
        };

        if (startDate !== null && endDate !== null) {
            this.getZeitraumData(startDate, endDate)
                .then(data => {
                    if (data.zeitraumDaten) {
                        updateState(startDate, endDate, data);
                    } else {
                        let errorMessage = 'Ein Fehler ist aufgetreten.';
                        if (data.error !== undefined) {
                            errorMessage = data.error;
                        }
                        toast.error(errorMessage + ' Der Urlaubsantrag kann nicht bearbeitet werden.');
                    }
                })
                .catch(data => {
                    toast.error('Ein Fehler ist aufgetreten. Der Urlaubsantrag kann nicht bearbeitet werden.');
                });
        } else {
            updateState(startDate, endDate);
        }
    };

    getZeitraumData = async (startDate, endDate) => {
        const start = startDate.format('YYYY-MM-DD');
        const end = endDate.format('YYYY-MM-DD');

        return await api.getJson(`/urlaub/urlaubsantrag/zeitraum-daten/${this.state.selectedMitarbeiter.value}/${start}/${end}`);
    };

    calculate = () => {
        const { urlaubsantrag, zeiterfassungStats } = this.state;
        const urlaubStdSumme =
            urlaubsantrag.konditionen.length > 0 ? urlaubsantrag.konditionen.reduce((carry, urlaubStdSumme) => carry + urlaubStdSumme.urlaubstunden, 0) : 0;
        const ueberStdSumme =
            urlaubsantrag.konditionen.length > 0 ? urlaubsantrag.konditionen.reduce((carry, ueberStdSumme) => carry + ueberStdSumme.ueberstunden, 0) : 0;

        return {
            verfuegbarUrlaubStd: zeiterfassungStats.urlaubAvailableStunden,
            verfuegbarUeberStd: zeiterfassungStats.ueberStunden,
            aktuellerAntragUrlaubStd: urlaubStdSumme,
            aktuellerAntragUeberStd: ueberStdSumme,
            restUrlaubStd: zeiterfassungStats.urlaubAvailableStunden - urlaubStdSumme,
            restUeberStd: zeiterfassungStats.ueberStunden - ueberStdSumme,
        };
    };

    setUrlaubsantragTagRow = (tag, { urlaubStd, ueberStd }) => {
        this.state.urlaubsantrag.konditionen.find(element => element.tag === tag).urlaubstunden = urlaubStd;
        this.state.urlaubsantrag.konditionen.find(element => element.tag === tag).ueberstunden = ueberStd;
        this.state.urlaubsantragIsDirty = true;
        this.setState(this.state);
    };

    setKommentar = kommentar => {
        const urlaubsantrag = this.state.urlaubsantrag;
        urlaubsantrag.kommentar = kommentar;
        this.setState({ urlaubsantrag, urlaubsantragIsDirty: true });
    };

    areKonditionenValid = () => {
        return this.state.urlaubsantrag.konditionen.length > 0;
    };

    beantragen = async () => {
        if (!this.isUrlaubsantragValid()) {
            this.markAllFieldsNotPristine();
            return;
        }

        this.setState({ beantragenInProgress: true });
        api.postJson(`/urlaub/urlaubsantrag/genehmigt-erfassen/${this.state.selectedMitarbeiter.value}`, this.state.urlaubsantrag)
            .then(json => {
                if (json.success) {
                    toast.success('Urlaubsantrag erfolgreich erfasst! Ein neuer Antrag ist bereit für Erfassung.');
                    this.clear();
                } else {
                    let errorMessage = 'Ein Fehler ist aufgetreten.';
                    if (json.error !== undefined) {
                        errorMessage = json.error;
                    }
                    toast.error('Der Urlaubsantrag konnte nicht erfasst werden. ' + errorMessage);
                    this.setState({ beantragenInProgress: false });
                }
            })
            .catch(json => {
                toast.error('Der Urlaubsantrag konnte nicht erfasst werden. Ein Fehler ist aufgetreten.');
                this.setState({ beantragenInProgress: false });
            });
    };

    verwerfen = () => {
        toast.warn('Urlaubsantrag wurde verworfen. Ein neuer Antrag ist bereit für Erfassung.');
        this.clear();
    };

    onGenehmigungsverantwortlicherChange = values => {
        const urlaubsantrag = this.state.urlaubsantrag;
        urlaubsantrag.genehmigungsDaten = values;
        this.setState({ urlaubsantrag, urlaubsantragIsDirty: true });
    };

    isZeitraumSelected = () => this.state.urlaubsantrag.von && this.state.urlaubsantrag.bis;

    areGenehmigungsValid = () => this.state.urlaubsantrag.genehmigungsDaten.length > 0;

    isUrlaubsantragValid = () => this.areProjektVertretungenValid() && this.areGenehmigungsValid() && this.areKonditionenValid();

    beantragterUrlaubWeeks = () => {
        const beantragterUrlaubWeeks = [[]];

        let i = 0;
        this.state.urlaubsantrag.konditionen.forEach((tag, j) => {
            if (0 !== j && 0 === tag.date.weekday()) {
                i++;
                beantragterUrlaubWeeks[i] = [];
            }
            beantragterUrlaubWeeks[i].push(tag);
        });

        return beantragterUrlaubWeeks;
    };

    canUrlaubsantragBeSaved = () => {
        return (
            (this.state.urlaubsantrag.status === URLAUBSANTRAG_STATUS.ENTWURF || this.state.urlaubsantrag.urlaubsantragId.id === null) &&
            !this.isRequestInProgress()
        );
    };

    isRequestInProgress = () => {
        return this.state.saveInProgress || this.state.beantragenInProgress || this.state.verwerfenInProgress;
    };

    canUrlaubsantragBeBeantragt = () => {
        return this.canUrlaubsantragBeSaved();
    };

    canUrlaubsantragBeVerworfen = () => {
        return (
            (([URLAUBSANTRAG_STATUS.ENTWURF, URLAUBSANTRAG_STATUS.BEANTRAGT].includes(this.state.urlaubsantrag.status) &&
                this.state.urlaubsantrag.urlaubsantragId.id !== null) ||
                this.state.urlaubsantrag.urlaubsantragId.id === null) &&
            !this.isRequestInProgress()
        );
    };

    markStundenNotPristine = () => {
        this.setState({ stundenPristine: false });
    };

    markAllFieldsNotPristine = () => {
        this.urlaubsantragProjekteEditor.current.markAllNotPristine();
        this.urlaubsantragGenehmigungsVerantwortlicheEditor.current.markNotPristine();
        this.markStundenNotPristine();
    };

    render() {
        const stundenResult = this.calculate();
        const showUrlaubsAntragEditor = this.state.showUrlaubsAntragEditor;
        const resizeDatePicker = this.state.resizeDatePicker;
        const beantragterUrlaubWeeks = this.beantragterUrlaubWeeks();

        if (this.state.fetching) {
            return (
                <div>
                    <div>
                        <i className="ml-2 fas fa-2x fa-spinner fa-pulse" />
                    </div>
                    <span>Loading ...</span>
                </div>
            );
        }

        return (
            <div>
                <PortalButtonBar>
                    <div className="form-group row align-items-center mb-3">
                        <span className="col-sm-12 col-md-2">Bitte gewünschten Mitarbeiter auswählen</span>
                        <div className="col-md-3 col-sm-12">
                            <Select
                                isDisabled={this.state.fetchingMitarbeiterData}
                                options={this.state.mitarbeitersForSelect}
                                value={this.state.selectedMitarbeiter}
                                onChange={value => this.onMitarbeiterChange(value)}
                                isMulti={false}
                            />
                        </div>
                    </div>
                    <div className="form-group row align-items-center mb-0">
                        <span className="col-sm-12 col-md-2">Bitte gewünschten Urlaubszeitraum auswählen</span>
                        <div className="col-md-6 col-sm-12">
                            <DateRangePicker
                                disabled={this.state.fetchingMitarbeiterData || this.state.selectedMitarbeiter === null}
                                startDate={this.state.startDate}
                                startDateId="your_unique_start_date_id"
                                endDate={this.state.endDate}
                                endDateId="your_unique_end_date_id"
                                onDatesChange={this.onDatesChange}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                noBorder={false}
                                hideKeyboardShortcutsPanel={true}
                                startDatePlaceholderText="Von"
                                endDatePlaceholderText="Bis"
                                showClearDates={true}
                                minimumNights={0}
                                orientation={resizeDatePicker ? 'vertical' : 'horizontal'}
                                isOutsideRange={date => date.isBefore('2019-04-01')}
                            />
                        </div>
                    </div>
                </PortalButtonBar>
                {this.isZeitraumSelected() && !this.state.fetchingMitarbeiterData && (
                    <React.Fragment>
                        <div className="alert alert-info mb-3">
                            Hinweis: abgesendete Urlaubsanträge werden sofort im System als "Genehmigt" angelegt. Nachträgliche änderungen sind nicht möglich.
                        </div>
                        <UrlaubsantragUebersicht
                            {...stundenResult}
                            isUrlaubsDateSet={this.state.startDate && this.state.endDate}
                            toggleUrlaubsAntrag={this.toggleUrlaubsAntragEditor}
                        />
                        {this.state.startDate && this.state.endDate ? (
                            <div ref={this.urlaubsAntragEditor} className={`card table-responsive-xl mt-3 ${showUrlaubsAntragEditor ? `d-block` : `d-none`}`}>
                                <div className="card-body">
                                    <div className="row">
                                        {beantragterUrlaubWeeks.map((week, i) => (
                                            <div className="col-xxl" key={i}>
                                                <UrlaubsantragWocheTable>
                                                    <div style={i === 0 && beantragterUrlaubWeeks.length > 1 ? { marginTop: 63 * (7 - week.length) } : {}} />
                                                    {week.map(date => (
                                                        <React.Fragment key={date.date}>
                                                            <UrlaubsantragTagRow
                                                                value={date}
                                                                setValue={row => this.setUrlaubsantragTagRow(date.tag, row)}
                                                                sollStd={date.sollstunden}
                                                                date={date.date}
                                                                onBlur={this.markStundenNotPristine}
                                                            />
                                                        </React.Fragment>
                                                    ))}
                                                </UrlaubsantragWocheTable>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <UrlaubsantragProjekte
                            ref={this.urlaubsantragProjekteEditor}
                            values={this.state.urlaubsantrag.projektVertretungsDaten}
                            availableProjects={this.state.projektsForSelect}
                            availableVertretungs={this.state.mitarbeitersForSelect}
                            addNewProject={this.addProjektVertretung}
                            removeProject={this.removeProjektVertretung}
                            onProjectChange={this.onProjektVertretungProjectChange}
                            onVertretungChange={this.onProjektVertretungVertretungChange}
                        />
                        <UrlaubsantragGenehmigungsVerantwortliche
                            ref={this.urlaubsantragGenehmigungsVerantwortlicheEditor}
                            options={this.state.mitarbeitersForSelect}
                            values={this.state.urlaubsantrag.genehmigungsDaten}
                            onChange={this.onGenehmigungsverantwortlicherChange}
                        />
                        <GenehmigtUrlaubsantragKommentare
                            kommentar={this.state.urlaubsantrag.kommentar}
                            onChangeKommentar={this.setKommentar}
                            onBeantragen={this.beantragen}
                            enableBeantragen={this.canUrlaubsantragBeBeantragt()}
                            onVerwerfen={this.verwerfen}
                            enableVerwerfen={this.canUrlaubsantragBeVerworfen()}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }
}
