import { Component } from 'react';
import { LPHS, PROJEKT_FUNKTIONEN } from '../../constants';
import * as api from '../../api';
import React from 'react';
import Select from 'react-select';

export default class ProjektEdit extends Component {
    state = {
        projects: [],
        lphs: 0,
        lph1: false,
        lph2: false,
        lph4: false,
        lph8: false,
        projektId: null,
        fkt: '',
        aktiv: false,
        user: '',
    };

    toggleLph(lph) {
        this.state['lph' + lph] = !this.state['lph' + lph];
        // loop all LPHs and check if flag is set. with badass reduce! BAAAAM!
        this.state.lphs = Object.keys(LPHS).reduce((last, lph) => last + (this.state['lph' + lph] ? parseInt(lph) : 0), 0);
        this.setState(this.state);
    }

    setProjektFkt(option) {
        this.setState({
            fkt: option.value,
            aktiv: !!option.value,
        });
    }

    componentWillReceiveProps(nextProps) {
        // projekt can be set
        if (nextProps.projektId !== this.state.projektId) {
            this.changeProject(nextProps.projektId);
        }
        if (nextProps.user != this.state.user) {
            this.loadProjects(nextProps.user);
        }
    }

    componentDidMount() {
        this.loadProjects(this.props.user);
    }

    async loadProjects(user) {
        const projects = await api.getJson(`/zeiterfassung/projekt-list/${user}/`);
        this.setState({
            projects,
            user,
        });
    }

    async changeProject(projektId) {
        if (null === projektId || 0 === projektId) {
            this.setState({ projektId });
            if (null === projektId) {
                this.props.onUpdate();
            }
            return;
        }

        const data = await api.getJson(`/zeiterfassung/mitarbeiter-projekt-data/${this.props.user}/${projektId}/`);
        this.setState({
            ...data,
            projektId,
        });
    }

    async submit(e) {
        e.preventDefault();

        const { lphs, fkt, aktiv, projektId } = this.state;
        await api.postJson(`/zeiterfassung/mitarbeiter-projekt-data/${this.props.user}/${projektId}/`, {
            lphs,
            fkt,
            aktiv,
        });
        this.props.onUpdate();
    }

    getProjects() {
        const { projects } = this.state;

        const isMyProjekt = projekt => projekt.fkt.length > 0 || projekt.aktiv;
        const isInternalProjekt = projekt => projekt.typ !== 'Bauprojekt';

        return [
            {
                label: 'Meine Projekte',
                options: projects.filter(projekt => isMyProjekt(projekt) && !isInternalProjekt(projekt)),
            },
            {
                label: 'Interne Projekte',
                options: projects.filter(projekt => isInternalProjekt(projekt)),
            },
            {
                label: 'Alle Projekte',
                options: projects.filter(projekt => !isMyProjekt(projekt) && !isInternalProjekt(projekt)),
            },
        ];
    }

    render() {
        const { projektId } = this.state;

        if (null === projektId) {
            return (
                <button className="btn btn-link" onClick={() => this.changeProject(0)}>
                    + Projekt hinzufügen
                </button>
            );
        }

        const formatGroupLabel = data => (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                <span>{data.label}</span>
            </div>
        );

        const funktionen = [{ value: '', label: 'Nur für Zeiterfassung' }, ...PROJEKT_FUNKTIONEN.map(funktion => ({ value: funktion, label: funktion }))];

        const projektSelected = projektId !== 0;
        const isInternalProjekt = this.state.internal;

        return (
            <form onSubmit={this.submit.bind(this)}>
                <div className="row align-items-center">
                    <div className="col">
                        <Select
                            options={this.getProjects()}
                            value={this.state.projects.filter(p => p.value === this.state.projektId)[0]}
                            onChange={option => this.changeProject(option.value)}
                            formatGroupLabel={formatGroupLabel}
                            placeholder="Bitte wähle Dein Projekt"
                        />
                    </div>
                    {projektSelected && (
                        <React.Fragment>
                            {!isInternalProjekt && (
                                <React.Fragment>
                                    <div className="col-auto">
                                        <div className="btn-group" role="group">
                                            {Object.keys(LPHS).map(lph => {
                                                const active = this.state.lphs & lph;
                                                return (
                                                    <ButtonWithIcon key={lph} active={active} onClick={() => this.toggleLph(lph)}>
                                                        {' '}
                                                        {LPHS[lph]}
                                                    </ButtonWithIcon>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <Select
                                            options={funktionen}
                                            value={funktionen.filter(p => p.value === this.state.fkt)[0]}
                                            onChange={option => this.setProjektFkt(option)}
                                            placeholder="Bitte wähle Deine Rolle im Projekt"
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <ButtonWithIcon active={this.state.aktiv} onClick={() => this.setState({ aktiv: !this.state.aktiv })}>
                                            Aktiv
                                        </ButtonWithIcon>
                                    </div>
                                </React.Fragment>
                            )}
                            {isInternalProjekt && (
                                <div className="col-auto">
                                    <ButtonWithIcon active={this.state.lph1} onClick={() => this.toggleLph(1)}>
                                        Aktiv
                                    </ButtonWithIcon>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                    <div className="col-auto">
                        <div className="btn-group">
                            <button className="btn btn-secondary" onClick={() => this.changeProject(null)}>
                                <i className="fas fa-times" />
                            </button>{' '}
                            {projektSelected && (
                                <button type="submit" className="btn btn-primary">
                                    <i className="fas fa-save" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const ButtonWithIcon = ({ active, onClick, children }) => (
    <button type="button" className={`btn ${active ? 'btn-secondary' : 'btn-outline-secondary'}`} onClick={onClick}>
        <span className={active ? '' : 'd-none'}>
            <i className="fas fa-fw fa-check text-success" />
        </span>
        <span className={active ? 'd-none' : ''}>
            <i className="fas fa-fw fa-times text-danger" />
        </span>
        {children}
    </button>
);
