export const PROJEKT_FUNKTION__PROJEKTLEITER = 'Projektleiter';
export const PROJEKT_FUNKTION__PROJEKTBETREUUNG = 'Projektbetreuung';
export const PROJEKT_FUNKTION__PROJEKTMITARBEITER = 'Projektmitarbeiter';
export const PROJEKT_FUNKTION__STUDENT = 'Student';
export const PROJEKT_FUNKTION__SEKRETARIAT = 'Sekretariat';
export const PROJEKT_FUNKTION__SIGEKO = 'SiGeKo';

export const PROJEKT_FUNKTIONEN = [
    PROJEKT_FUNKTION__PROJEKTLEITER,
    PROJEKT_FUNKTION__PROJEKTBETREUUNG,
    PROJEKT_FUNKTION__PROJEKTMITARBEITER,
    PROJEKT_FUNKTION__STUDENT,
    PROJEKT_FUNKTION__SEKRETARIAT,
    PROJEKT_FUNKTION__SIGEKO,
];

export const LPH_14 = 1;
export const LPH_57 = 2;
export const LPH_89 = 4;
export const LPH_PS = 8;

export const LPHS = {
    [LPH_14]: 'LPH 1-4',
    [LPH_57]: 'LPH 5-7',
    [LPH_89]: 'LPH 8-9',
    [LPH_PS]: 'PS',
};
