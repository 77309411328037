import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { STATUS_ENTWURF, STATUS_ERLEDIGT, STATUS_GELOESCHT, STATUS_HINFAELLIG, STATUS_IN_ARBEIT, STATUS_OFFEN } from '../AufgabenConstants';
import { arrayUnique, FIELD_BEARBEITER, FIELD_DRINGLICHKEIT, FIELD_PROJEKT, FIELD_STATUS } from '../Lists';

const ASC = 'asc';
const DESC = 'desc';

export default ({
    className = '',
    label,
    canBeSorted,
    sortOrder,
    setSortField,
    canBeFiltered,
    openAuswahlFilter,
    auswahlFilterOptions,
    setOpenAuswahlFilter,
    field,
    setActiveFilter,
    activeElements,
    resetFilter,
}) => (
    <li className={`${className} aufgaben-liste-filter-item p-2 `}>
        <div className="d-flex align-items-center justify-content-between h-100 w-100">
            <div
                className={`aufgaben-liste-filter-item-label ${canBeSorted ? '' : 'element-disabled element-disabled--full-opacity'}`}
                onClick={() => canBeSorted && setSortField()}>
                {label}
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ zIndex: 50 }}>
                <div onClick={() => canBeSorted && setSortField()}>
                    <FontAwesomeIcon
                        className={`ml-1 pointer filter-icon ${sortOrder === ASC || sortOrder === DESC ? 'd-none' : ''}`}
                        icon={'sort'}
                        size="1x"
                    />
                    {sortOrder === ASC && <FontAwesomeIcon className="ml-1 pointer sort-icon" icon="sort-up" size="1x" />}
                    {sortOrder === DESC && <FontAwesomeIcon className="ml-1 pointer sort-icon" icon="sort-down" size="1x" />}
                </div>
                {canBeFiltered && <FontAwesomeIcon className="ml-1 filter-icon" icon="filter" size="1x" onClick={() => setOpenAuswahlFilter()} />}
            </div>
        </div>
        {openAuswahlFilter && (
            <FilterPopUp
                resetFilter={resetFilter}
                activeElements={activeElements}
                setActiveFilter={options => setActiveFilter(options)}
                activeField={field}
                options={auswahlFilterOptions}
            />
        )}
    </li>
);

class FilterPopUp extends React.Component {
    state = {
        activeElements: this.props.activeElements && this.props.activeElements.length !== 0 ? this.props.activeElements : [],
    };

    setOption = option => {
        const { setActiveFilter } = this.props;
        this.setState(
            state => ({
                activeElements: state.activeElements.includes(option)
                    ? [...state.activeElements.filter(el => el !== option)]
                    : [...state.activeElements, option],
            }),
            () => setActiveFilter(this.state.activeElements)
        );
    };

    resetFilter = () => {
        this.setState({ activeElements: [] });
        this.props.setActiveFilter([]);
    };

    getOptions = () => {
        const { options } = this.props;

        if (this.isActiveField(FIELD_STATUS)) {
            return [STATUS_ENTWURF, STATUS_OFFEN, STATUS_IN_ARBEIT, STATUS_ERLEDIGT, STATUS_HINFAELLIG, STATUS_GELOESCHT];
        }
        if (this.isActiveField(FIELD_BEARBEITER)) {
            let nestedOptions = [];
            options.forEach(option => option.forEach(nestedOption => nestedOptions.push(nestedOption.name)));
            return arrayUnique(nestedOptions);
        }

        if (this.isActiveField(FIELD_PROJEKT)) {
            const convertOptions = arrayUnique(options.map(option => (option !== null && option.name.length !== 0 ? option.name : 'Persönliche Aufgabe')));
            const filteredOptions = convertOptions.filter(item => item !== 'Persönliche Aufgabe');
            filteredOptions.unshift('Persönliche Aufgabe');
            return filteredOptions;
        }

        return arrayUnique(options);
    };

    isActiveField = activeField => {
        return this.props.activeField === activeField;
    };

    render() {
        const { activeElements } = this.props;
        return (
            <span className="popup popup--filter d-flex justify-content-center flex-column">
                {this.getOptions().map(option => (
                    <span key={option} className="form-group d-flex align-items-center filter-popup-item" onClick={() => this.setOption(option)}>
                        {this.isActiveField(FIELD_DRINGLICHKEIT) && <span className={`dringlichkeit-filter-color bg-${getDringlichkeitColor(option)}`} />}
                        <input
                            readOnly={true}
                            className="pointer ml-3"
                            type="checkbox"
                            checked={activeElements && activeElements.includes(option) ? false : true}
                        />
                        <span className="ml-3 pointer">{this.isActiveField(FIELD_DRINGLICHKEIT) ? getDringlichkeitFilterLabels(option) : option}</span>
                    </span>
                ))}
                <span onClick={() => this.resetFilter()} className="ml-auto mr-auto filter-popup-item pr-1 pl-1">
                    Filter löschen
                </span>
            </span>
        );
    }
}

const getDringlichkeitColor = dringlichkeit => {
    switch (dringlichkeit) {
        case 1:
            return 'danger';
        case 2:
            return 'primary';
        case 3:
            return 'success';
        default:
            return 'dark';
    }
};

const getDringlichkeitFilterLabels = dringlichkeit => {
    switch (dringlichkeit) {
        case 1:
            return 'sehr dringend';
        case 2:
            return 'dringend';
        case 3:
            return 'nicht dringend';
        default:
            return 'not defined dringlichkeit';
    }
};
