import React from 'react';
import { handleFormSubmitResult } from '../../formHelpers';
import { postJson } from '../../api';
import DokumentForm from './DokumentForm';

export default ({ knoten, toggleModal, addNewDokumentToKnoten, alleTags }) => {
    const onSubmit = async values => {
        const result = await postJson('/wissen/dokument/erzeugen', {
            knoten: knoten,
            ...values,
        });

        if (handleFormSubmitResult(result, 'Dokument erfolgreich angelegt.')) {
            addNewDokumentToKnoten(result.dokument);
        }
    };

    return <DokumentForm toggleModal={toggleModal} onSubmit={values => onSubmit(values)} knoten={knoten} alleTags={alleTags} />;
};
