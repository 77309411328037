import React from 'react';
import { DateRangePicker } from 'react-dates';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import * as api from '../../api';
import toast from '../../toastify';
import { getNextView, getPrevView, convertBearbeiter } from './getAufgabeView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { STATUS_HINFAELLIG, STATUS_ERLEDIGT, STATUS_IN_ARBEIT, STATUS_OFFEN, STATUS_ENTWURF, STATUS_GELOESCHT } from '../AufgabenConstants';
import { KEY_LEFT, KEY_RIGHT } from '../../Modal/KeyBindings';

export default class AufgabeEdit extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        this.state = {
            aufgabe: this.props.aufgabe,
            startDate: this.props.aufgabe.start,
            endDate: this.props.aufgabe.ende,
            dataLoaded: false,
            dirty: false,
            tempAufgabe: {},
            bearbeiter: [],
            initialStatus: this.props.aufgabe.status,
            changedStatus: null,
            backToDetails: false,
        };
    }

    async componentDidMount() {
        const json = await api.getJson('/aufgaben/bearbeiten-daten');
        const newState = {
            bearbeiter: json.bearbeiter.map(bearbeiter => ({
                label: bearbeiter.name,
                // hier value wegen react-select
                value: bearbeiter.id,
            })),
            dataLoaded: true,
        };
        this.setState(newState);
        window.addEventListener('keyup', this.handleArrowKeys, false);
    }

    componentWillUnmount = () => window.removeEventListener('keyup', this.handleArrowKeys, false);

    // handle form submit to backend
    onSubmit = async values => {
        const aufgabe = this.state.aufgabe;
        const params = {
            ...aufgabe,
            projekt:
                values.projekt === undefined || values.projekt === null
                    ? null
                    : {
                          id: aufgabe.projekt.id,
                          name: aufgabe.projekt.name,
                          url: `/projekt/${aufgabe.projekt.id}/detail`,
                      },
            bearbeiter: values.bearbeiter === undefined ? [] : values.bearbeiter,
            titel: values.titel,
            beschreibung: values.beschreibung === undefined ? '' : values.beschreibung,
            start: this.state.startDate,
            ende: this.state.endDate,
            status: this.state.changedStatus !== null ? this.state.changedStatus : this.props.aufgabe.status,
        };
        try {
            const value = await api.postJson(`/aufgaben/speichern/ich/${aufgabe.key}`, params);
            if (value.error) {
                toast.error(value.error);
            } else {
                this.props.updateEditedAufgabe(value.aufgabe);
                this.setState({ changedStatus: null });
                toast.success('Aufgabe erfolgreich gespeichert');
            }
        } catch (e) {
            toast.error('Es ist ein unerwarteter Fehler aufgetreten');
        }
    };

    // handle right / left key press
    handleArrowKeys = e => {
        e.preventDefault();
        // get form ref
        const { form } = this.formRef.current;

        // check if a field is active so users can navigate trough letters in the form
        if (form.getState().active !== undefined) {
            return;
        }

        // otherwhise handle navigation with the current state of the form
        const keys = {
            [KEY_RIGHT]: () => this.handleNavigation(e, form.getState().dirty, 'next', form.getState().values),
            [KEY_LEFT]: () => this.handleNavigation(e, form.getState().dirty, 'prev', form.getState().values),
        };

        if (keys[e.keyCode]) {
            keys[e.keyCode]();
        }
    };

    // message if a field is required and no value is given
    required = value => (value ? undefined : 'Feld muss ausgefüllt werden!');

    // handle navigation with validation
    handleNavigation = (e, dirty, direction, values = {}) => {
        const aufgaben = this.props.aufgaben;
        const aufgabe = this.state.aufgabe;
        const changedStatus = this.state.changedStatus;

        e.preventDefault();

        // if final forms detects a input then set dirty to true and save the temp input values in state
        if (dirty) {
            this.setState({ dirty, tempAufgabe: values, dirtyFromNavigation: true });
            return;
        }

        // check if the status where changed
        if (changedStatus === null) {
            dirty = false;
        }

        // handle if the status has changed
        if (changedStatus !== null && changedStatus.toLowerCase() !== aufgabe.status.toLowerCase()) {
            this.setState({ dirty: true, tempAufgabe: values });
            return;
        }

        // no changes made, go to prev aufgabe
        if (direction === 'prev' && dirty === false) {
            this.setState({
                aufgabe: getPrevView(aufgaben, aufgabe),
                changedStatus: null,
                initialStatus: getPrevView(aufgaben, aufgabe).status,
            });
        }
        // no changes made, go to next aufgabe
        if (direction === 'next' && dirty === false) {
            this.setState({
                aufgabe: getNextView(aufgaben, aufgabe),
                changedStatus: null,
                initialStatus: getNextView(aufgaben, aufgabe).status,
            });
        }
    };

    // handler when the form is not saved and next aufgabe or prev aufgabe should be displayed
    discardOrSaveThenProceed = willSave => {
        // first check direction where to navigate
        this.state.direction === 'prev'
            ? this.setState({
                  aufgabe: getPrevView(this.props.aufgaben, this.state.aufgabe, true),
                  dirty: false,
                  initialStatus: this.state.aufgabe.status,
                  changedStatus: null,
              })
            : this.setState({
                  aufgabe: getNextView(this.props.aufgaben, this.state.aufgabe, true),
                  dirty: false,
                  initialStatus: this.state.aufgabe.status,
                  changedStatus: null,
              });

        const { form } = this.formRef.current;
        // handle close / discard changes and where to navigate either detail view, close modal per X or to next aufgabe
        if (this.props.modalWillCloseDirty && willSave) {
            this.onSubmit({ ...form.getState().values });
            this.props.modalWillCloseClean();
            return;
        } else if (this.state.backToDetails && willSave) {
            this.onSubmit({ ...form.getState().values });
            this.props.toggleDetailView(form.getState().values);
        } else if (this.state.backToDetails && !willSave) {
            this.props.toggleDetailView();
        } else if (willSave) {
            this.onSubmit({ ...this.state.tempAufgabe });
        } else if (this.state.dirtyFromNavigation) {
            return;
        } else {
            this.props.modalWillCloseClean();
        }
    };

    // set new status of aufgabe to edit
    setNewStatus = (status, e) => {
        e.preventDefault();
        this.setState({ changedStatus: status, initialStatus: null });
    };

    // navigate back to detail view
    backToDetails = (e, dirty) => {
        e.preventDefault();
        if (dirty) {
            this.setState({ dirty, backToDetails: true });
        } else {
            this.props.toggleDetailView();
        }
    };

    render() {
        const { aufgabe, bearbeiter, dataLoaded, dirty, initialStatus, changedStatus, backToDetails } = this.state;
        const { modalWillCloseDirty } = this.props;
        return (
            <React.Fragment>
                {(dirty || modalWillCloseDirty) && (
                    <NotSavedError
                        cancel={() => {
                            this.props.cancel();
                            this.setState({ dirty: false });
                        }}
                        modalWillCloseDirty={modalWillCloseDirty}
                        backToDetails={backToDetails}
                        discardOrSaveThenProceed={willSave => this.discardOrSaveThenProceed(willSave)}
                    />
                )}
                <Form
                    initialValues={aufgabe}
                    onSubmit={this.onSubmit}
                    ref={this.formRef}
                    render={({ handleSubmit, form, values, submitting, dirty }) => {
                        return (
                            <form
                                onSubmit={handleSubmit}
                                onChange={() => {
                                    const { form } = this.formRef.current;
                                    this.props.checkIfDirty(form.getState().dirty);
                                }}>
                                <div className={`container-fluid ${(this.state.dirty || modalWillCloseDirty) && 'element-disabled'}`}>
                                    <div className="row">
                                        <InputField label="Schlüssel">
                                            <input className="form-control" placeholder={aufgabe.key} disabled />
                                        </InputField>
                                        <InputField label="Titel">
                                            <Field name="titel" className="form-control" validate={this.required}>
                                                {props => (
                                                    <input
                                                        autoFocus
                                                        placeholder={props.meta.error && props.meta.touched ? props.meta.error : 'Titel'}
                                                        {...props.input}
                                                        className={`form-control ${props.meta.error &&
                                                            props.meta.touched &&
                                                            'font-weight-bold border-danger text-danger'}`}
                                                    />
                                                )}
                                            </Field>
                                        </InputField>
                                        <InputField label="Start / Ende">
                                            <DateRangePicker
                                                startDate={this.state.startDate}
                                                startDateId="cursor-"
                                                endDate={this.state.endDate}
                                                endDateId="your_unique_end_date_id"
                                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                                focusedInput={this.state.focusedInput}
                                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                hideKeyboardShortcutsPanel={true}
                                                startDatePlaceholderText="Start"
                                                endDatePlaceholderText="Ende"
                                                minimumNights={0}
                                                small
                                                showClearDates
                                                monthFormat="DD.MM.YYYY"
                                                isOutsideRange={() => {}}
                                            />
                                        </InputField>
                                        <InputField label="Projekt">
                                            <Select placeholder={aufgabe.projekt !== null ? aufgabe.projekt.name : 'Persönliche Aufgabe'} isDisabled={true} />
                                        </InputField>
                                        <InputField label="Beschreibung">
                                            <Field name="beschreibung" rows={3} component="textarea" placeholder="Beschreibung" className="form-control" />
                                        </InputField>
                                        <InputField label="Bearbeiter">
                                            {dataLoaded ? (
                                                <Field
                                                    name="bearbeiter"
                                                    component={ReactSelectAdapter}
                                                    options={bearbeiter}
                                                    placeholder="Bitte wählen"
                                                    isMulti={true}
                                                    isClearable={true}
                                                />
                                            ) : (
                                                <Select isDisabled={true} placeholder="Loading .." />
                                            )}
                                        </InputField>
                                        <InputField label="Status">
                                            <div className="btn-group-toggle">
                                                <button
                                                    className={`btn btn-outline-info ${(initialStatus === STATUS_ENTWURF && 'active') ||
                                                        (changedStatus === STATUS_ENTWURF && 'active')}`}
                                                    onClick={e => this.setNewStatus(STATUS_ENTWURF, e)}>
                                                    Entwurf
                                                </button>
                                                <button
                                                    className={`btn btn-outline-danger ${(initialStatus === STATUS_OFFEN && 'active') ||
                                                        (changedStatus === STATUS_OFFEN && 'active')}`}
                                                    onClick={e => this.setNewStatus(STATUS_OFFEN, e)}>
                                                    Offen
                                                </button>
                                                <button
                                                    className={`btn btn-outline-primary ${(initialStatus === STATUS_IN_ARBEIT && 'active') ||
                                                        (changedStatus === STATUS_IN_ARBEIT && 'active')}`}
                                                    onClick={e => this.setNewStatus(STATUS_IN_ARBEIT, e)}>
                                                    in Arbeit
                                                </button>
                                                <button
                                                    className={`btn btn-outline-success ${(initialStatus === STATUS_ERLEDIGT && 'active') ||
                                                        (changedStatus === STATUS_ERLEDIGT && 'active')}`}
                                                    onClick={e => this.setNewStatus(STATUS_ERLEDIGT, e)}>
                                                    Erledigt
                                                </button>
                                                <button
                                                    className={`btn btn-outline-dark ${(initialStatus === STATUS_HINFAELLIG && 'active') ||
                                                        (changedStatus === STATUS_HINFAELLIG && 'active')}`}
                                                    onClick={e => this.setNewStatus(STATUS_HINFAELLIG, e)}>
                                                    Hinfällig
                                                </button>
                                                <button
                                                    className={`btn btn-outline-warning ${(initialStatus === STATUS_GELOESCHT && 'active') ||
                                                        (changedStatus === STATUS_GELOESCHT && 'active')}`}
                                                    onClick={e => this.setNewStatus(STATUS_GELOESCHT, e)}>
                                                    {STATUS_GELOESCHT}
                                                </button>
                                            </div>
                                        </InputField>
                                        <hr className="w-100" />
                                        <div className="modal-footer justify-content-between p-0 w-100 border-0">
                                            <div className="btn-group">
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    disabled={submitting}
                                                    onClick={e => this.handleNavigation(e, dirty, 'prev', values)}>
                                                    <FontAwesomeIcon className="mr-2" icon="chevron-left" />
                                                    Vorherige Aufgabe
                                                </button>
                                                <button
                                                    disabled={submitting}
                                                    className="btn btn-outline-secondary"
                                                    onClick={e => this.handleNavigation(e, dirty, 'next', values)}>
                                                    Nächste Aufgabe
                                                    <FontAwesomeIcon className="ml-2" icon="chevron-right" />
                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={e => this.backToDetails(e, dirty)} className="btn btn-primary">
                                                    <FontAwesomeIcon icon="file-alt" />
                                                </button>
                                                <button type="submit" className="btn btn-primary ml-2" disabled={submitting}>
                                                    Speichern
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary ml-2"
                                                    disabled={submitting}
                                                    onClick={e => handleSubmit(e).then(this.props.modalWillCloseClean())}>
                                                    Speichern und Schliessen
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                />
            </React.Fragment>
        );
    }
}

const InputField = ({ label, className = '', children }) => (
    <div className={`${className} col-12`}>
        <label>{label}</label>
        <div className="form-group">{children}</div>
    </div>
);

const ReactSelectAdapter = ({ input, ...rest }) => {
    input = {
        ...input,
        value: convertBearbeiter(input.value),
    };
    return <Select {...input} {...rest} searchable />;
};

const NotSavedError = ({ cancel, discardOrSaveThenProceed, modalWillCloseDirty }) => (
    <div className="aufgabe-edit-not-saved d-flex align-items-center justify-content-center">
        <div className="container-fluid d-flex align-items-center justify-content-center">
            <div className="row flex-column">
                <div className="col-12">
                    <h3 className="mb-2 text-center">Achtung! Änderungen sind nicht gespeichert!</h3>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <div className="btn-group d-flex flex-column flex-lg-row mt-3">
                        <button className="btn-sm btn-danger pointer" onClick={() => discardOrSaveThenProceed(false)}>
                            {`${modalWillCloseDirty ? 'Trotzdem Schliessen' : 'verwerfen und weiter'}`}
                        </button>
                        <button className="btn-sm btn-primary ml-0 mr-0 ml-lg-3 mr-lg-3 mt-3 mt-lg-0 pointer" onClick={() => discardOrSaveThenProceed(true)}>
                            {`${modalWillCloseDirty ? 'Speichern und Schliessen' : 'speichern und weiter'}`}
                        </button>
                        <button className="btn-sm btn-primary mt-3 mt-lg-0 pointer" onClick={() => cancel()}>
                            Abbrechen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
