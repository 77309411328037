import numeral from 'numeral';

numeral.locale('de');

export const formatMinsAsHrs = mins => {
    if (mins === 0 || mins === '0' || mins === null || mins === undefined) {
        return '';
    }
    return numeral(mins)
        .divide(60)
        .format('0.00');
};

/**
 * @typedef {Object} MonthInterface
 * @property {int} month
 * @property {int} year
 *
 * @param {int} month
 * @param {int} year
 * @param {MonthInterface[]} period
 * @return {boolean}
 */
export const isMonthInSelectedPeriod = (month, year, period) => {
    return period.find(date => date.year === year && date.month === month) !== undefined;
};
