import React, { Component } from 'react';
import * as api from '../api';
import { gql } from 'apollo-boost';
import { Query, Mutation } from 'react-apollo';
import Header from './KalenderWidget/Header';
import MonthSwitch from './KalenderWidget/MonthSwitch';

class KalenderWidget extends Component {
    state = {
        fetching: true,
        data: null,
        user: null,
        month: null,
    };

    componentDidMount() {
        this.loadMonth(this.props.user, this.props.month);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.month !== this.state.month || nextProps.user !== this.state.user || nextProps.updater !== this.state.updater) {
            this.loadMonth(nextProps.user, nextProps.month);
        }
    }

    async loadMonth(user, month) {
        this.setState({
            fetching: true,
        });

        const json = await api.getJson(`/kalender/month/${user}/${month}/`);
        this.setState({
            data: json,
            fetching: false,
            user,
            month,
        });
    }

    render() {
        const { fetching, data } = this.state;

        if (fetching && null === data) {
            return <div>Loading</div>;
        }

        return (
            <div className="calendar--widget card">
                <div className="card-body pb-2">
                    <Header data={data} fetching={fetching} />
                    <MonthSwitch data={data} />
                </div>

                <div className="calendar card-body pt-2">
                    <div className="row no-gutters align-items-center">
                        <div className="col-auto small" style={{ width: 50 }} />
                        <div className="col text-center">Mo</div>
                        <div className="col text-center">Di</div>
                        <div className="col text-center">Mi</div>
                        <div className="col text-center">Do</div>
                        <div className="col text-center">Fr</div>
                        <div className="col text-center">Sa</div>
                        <div className="col text-center">So</div>
                    </div>
                    {data.weeks.map(week => (
                        <div key={week.id} className="row no-gutters align-items-center">
                            <Mutation mutation={SET_WEEK}>
                                {setWeek => (
                                    <div
                                        className={`col-auto week ${week.canNav ? 'clickable' : ''}`}
                                        onClick={() => week.canNav && setWeek({ variables: { week: week.id } })}>
                                        {week.human}
                                    </div>
                                )}
                            </Mutation>
                            {week.days.map(day => (
                                <div key={day.day} className={`col calendar--day`}>
                                    <span
                                        className={`calendar--day--inner ${day.color} ${day.fill ? `fill-${day.fill}` : ''} ${day.today ? 'today' : ''} ${
                                            day.inMonth ? '' : 'not-in-month'
                                        }`}>
                                        <span className="day">{day.day}</span>
                                        {day.arbeitszeit && day.vollstaendig ? (
                                            <span className="calendar--day--inner--underline" />
                                        ) : (
                                            !day.vollstaendig && <span className="calendar--day--inner--underline incomplete-day" />
                                        )}
                                    </span>
                                </div>
                            ))}
                        </div>
                    ))}
                    <div className="row mt-4">
                        <div className="col-6 p-4k-0 d-flex justify-content-center flex-column flex-4k-row">
                            <div className="d-flex align-items-center p-1">
                                <span className="calendar--legend--item today" />
                                <div className="small">Heute</div>
                            </div>
                            <div className="d-flex align-items-center p-1">
                                <span className="calendar--legend--item blue" />
                                <div className="text-center small">Beantragter Urlaub</div>
                            </div>
                            <div className="d-flex align-items-center p-1">
                                <span className="calendar--legend--item underline" />
                                <div className="small">Zeit vollständig erfasst</div>
                            </div>
                            <div className="d-flex align-items-center p-1">
                                <span className="calendar--legend--item gray" />
                                <div className="text-center small">Krankheit</div>
                            </div>
                        </div>
                        <div className="col-6 p-4k-0 d-flex justify-content-start flex-column flex-4k-row">
                            <div className="d-flex align-items-center p-1">
                                <span className="calendar--legend--item fill-green" />
                                <div className="text-center small">Feiertag</div>
                            </div>
                            <div className="d-flex align-items-center p-1">
                                <span className="calendar--legend--item green" />
                                <div className="text-center small">Genehmigter Urlaub</div>
                            </div>

                            <div className="d-flex align-items-center p-1">
                                <span className="calendar--legend--item incomplete-day" />
                                <div className="small">Zeit unvollständig erfasst</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default () => (
    <Query query={GET_MONTH}>
        {({ loading, error, data }) => {
            if (loading) return <div>Loading...</div>;
            if (error) return <div>Error :(</div>;

            return <KalenderWidget month={data.kalenderWidget.month} user={data.user} updater={data.updater} />;
        }}
    </Query>
);

const GET_MONTH = gql`
    query {
        user @client
        updater @client
        kalenderWidget @client {
            month
        }
    }
`;

const SET_WEEK = gql`
    mutation setWeek($week: String!) {
        setZeiterfassungWidgetWeek(week: $week) @client
    }
`;
