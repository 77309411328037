import React from 'react';
import { InputField, ReactSelectAdapter, transformAlleKnotenForReactSelect } from '../../formHelpers';
import { Field } from 'react-final-form';

export default ({ aktuellerKnoten, alleKnoten }) => {
    return (
        <InputField label="Element in anderen Knoten verschieben">
            <Field
                name="neuerKnoten"
                component={ReactSelectAdapter}
                options={transformAlleKnotenForReactSelect(alleKnoten)}
                placeholder="Bitte Wählen"
                isClearable={true}
                defaultValue={{ label: aktuellerKnoten.name, value: aktuellerKnoten.id }}
            />
        </InputField>
    );
};
