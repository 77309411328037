// selectors
const mobileNav = document.getElementById('mobile-nav');

if (mobileNav) {
    const openNavBtn = document.getElementById('mobile-nav-open-btn');
    const backToMainNavBtn = document.getElementById('back-to-main-nav-btn');
    const navItems = mobileNav.getElementsByClassName('mobile-nav-item');

    const itemArray = Array.from(navItems);

    openNavBtn.addEventListener('click', () => {
        mobileNav.classList.toggle('show-mobile-nav');

        document.body.classList.toggle('body-fixed');

        openNavBtn.classList.toggle('mobile-nav-close-btn');

        if (mobileNav.className.includes('show-mobile-nav')) {
            openNavBtn.innerHTML = '<i class="fas fa-times fa-2x text-white"></i>';
        } else {
            openNavBtn.innerHTML = '<i class="fas fa-bars fa-2x text-muted"></i>';
        }
        itemArray.forEach(item => {
            item.classList.remove('sub-list-inactive');
            item.classList.remove('active');
        });
    });

    for (let i = 0; i < navItems.length; i++) {
        navItems[i].addEventListener('click', function() {
            itemArray.forEach(item => item.classList.add('sub-list-inactive'));

            let current = document.getElementsByClassName('active');

            if (current.length > 0) {
                current[0].className = current[0].className.replace(' active', '');
            }

            this.className += ' active';
        });
    }

    backToMainNavBtn.addEventListener('click', function() {
        itemArray.forEach(item => {
            if (item.classList.contains('active')) {
                item.classList.remove('active');
            }
            item.classList.remove('sub-list-inactive');
        });
    });
}
