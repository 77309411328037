export const URLAUBSANTRAG_STATUS = {
    ENTWURF: 'entwurf',
    BEANTRAGT: 'beantragt',
    GENEHMIGT: 'genehmigt',
    ABGELEHNT: 'abgelehnt',
    VERWORFEN: 'verworfen',
    STORNIERT: 'storniert',
};

export const URLAUBSANTRAG_GENEHMIGUNG_STATUS = {
    OFFEN: 'offen',
    GENEHMIGT: 'genehmigt',
    ABGELEHNT: 'abgelehnt',
};
