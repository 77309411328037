import React from 'react';
import moment from 'moment';
import numeral from 'numeral';

const formatHours = value => `${numeral(value).format('0.00')} h`;

export default class UrlaubsantragViewWochen extends React.Component {
    beantragterUrlaubWeeks = () => {
        const beantragterUrlaubWeeks = [[]];

        let i = 0;
        this.props.urlaubsantrag.konditionen.forEach((tag, j) => {
            if (0 !== j && 0 === moment(tag.tag).weekday()) {
                i++;
                beantragterUrlaubWeeks[i] = [];
            }
            beantragterUrlaubWeeks[i].push(tag);
        });

        return beantragterUrlaubWeeks;
    };

    calculate = () => {
        const urlaubStdSumme =
            this.props.urlaubsantrag.konditionen.length > 0
                ? this.props.urlaubsantrag.konditionen.reduce((carry, urlaubStdSumme) => carry + urlaubStdSumme.urlaubstunden, 0)
                : 0;
        const ueberStdSumme =
            this.props.urlaubsantrag.konditionen.length > 0
                ? this.props.urlaubsantrag.konditionen.reduce((carry, ueberStdSumme) => carry + ueberStdSumme.ueberstunden, 0)
                : 0;

        return {
            urlaubStdSumme,
            ueberStdSumme,
        };
    };

    render() {
        const beantragterUrlaubWeeks = this.beantragterUrlaubWeeks();
        const values = this.calculate();

        return (
            <div className="card table-responsive-xl mt-3">
                <div className="card-body">
                    <div className="container-fluid">
                        <div className="row">
                            {beantragterUrlaubWeeks.map((week, i) => (
                                <div key={`beantragterUrlaubWeeks${i}`} className="col-xxl">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td className="small">Datum</td>
                                                <td className="small text-center">Sollstd</td>
                                                <td className="small text-center">Urlaub / Überstunden</td>
                                            </tr>
                                        </thead>
                                        <tbody className="urlaubsantrag-table">
                                            <div style={i === 0 && beantragterUrlaubWeeks.length > 1 ? { marginTop: 49 * (7 - week.length) } : {}} />
                                            {week.map(day => (
                                                <tr className={day.sollstunden <= 0 ? `element-disabled` : ``} key={day.tag}>
                                                    <td className="urlaubsantrag-datum-tag">{moment(day.tag).format('dd D.M.YY')}</td>
                                                    <td className="text-center urlaubsantrag-sollstd">{day.sollstunden}</td>
                                                    <td className="text-center">
                                                        <span className="mr-3">{day.urlaubstunden}</span>
                                                        <span className="ml-3">{day.ueberstunden}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </div>

                        <div className="text-right">
                            Urlaub: {formatHours(values.urlaubStdSumme)}, Überstunden: {formatHours(values.ueberStdSumme)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
