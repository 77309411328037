export const getNextView = (viewOptions, currentView) => {
    const currentViewIndex = viewOptions.findIndex(item => item.id === currentView.id);
    if (currentViewIndex + 1 >= viewOptions.length) {
        return viewOptions[0];
    } else {
        return viewOptions[currentViewIndex + 1];
    }
};

export const getPrevView = (viewOptions, currentView) => {
    const currentViewIndex = viewOptions.findIndex(item => item.id === currentView.id);
    if (currentViewIndex === 0) {
        return viewOptions[viewOptions.length - 1];
    } else {
        return viewOptions[currentViewIndex - 1];
    }
};

export const convertForReactSelect = item => {
    return {
        ...item,
        bearbeiter: item.bearbeiter.map(bearbeiter => ({
            ...bearbeiter,
            label: bearbeiter.name,
            value: bearbeiter.id,
        })),
    };
};

export const convertProjekt = aufgabe => {
    if (aufgabe.projekt === null) {
        return;
    }
    return {
        label: aufgabe.projekt.name,
        value: aufgabe.projekt.id,
    };
};

export const convertProjekte = projekte => projekte.map(projekt => ({ value: projekt.id, label: projekt.name }));

export const convertBearbeiter = bearbeiter =>
    bearbeiter.map(bearbeiter => {
        if (bearbeiter.label) {
            return bearbeiter;
        }
        return {
            ...bearbeiter,
            label: bearbeiter.name,
            value: bearbeiter.id,
        };
    });
