import React from 'react';
import { gql } from 'apollo-boost';
import { Query, Mutation } from 'react-apollo';
import * as api from '../api';
import Select from 'react-select';

export default class AdminWidget extends React.Component {
    state = {
        dataFetched: false,
        mitarbeiters: [],
        ich: '',
    };

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const json = await api.getJson(`/zeiterfassung/admin-data/`);

        this.setState({
            ...json,
            dataFetched: true,
        });
    }

    render() {
        return (
            <Query
                query={gql`
                    query {
                        user @client
                    }
                `}>
                {({ loading, error, data }) => {
                    if (error) return <div>Error :(</div>;
                    if (loading || !this.state.dataFetched) return <div>Loading...</div>;

                    const value = data.user === 'ich' ? this.state.ich : data.user;
                    return (
                        <Mutation
                            mutation={gql`
                                mutation setUser($user: String!) {
                                    setUser(user: $user) @client
                                }
                            `}>
                            {setUser => {
                                return (
                                    <div className="card">
                                        <div className="card-body pb-2">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <span className="fa fa-lg fa-user" />
                                                </div>
                                                <div className="col">
                                                    <h2>Mitarbeiter</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pt-2">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Select
                                                        options={this.state.mitarbeiters}
                                                        value={this.state.mitarbeiters.find(mitarbeiter => mitarbeiter.value == value)}
                                                        onChange={selectedMitarbeiter =>
                                                            setUser({
                                                                variables: {
                                                                    user: selectedMitarbeiter.value,
                                                                },
                                                            })
                                                        }
                                                        isMulti={false}
                                                    />
                                                </div>
                                                <div className="col-12 mt-2 ml-1">
                                                    <a href={`/mitarbeiter/${value}/detail/`}>zum Profil</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }}
                        </Mutation>
                    );
                }}
            </Query>
        );
    }
}
