import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WikiEditAction from './WikiEditAction';

export default ({ wiki, updateWiki, alleKnoten, alleTags }) => {
    const [modalOpen, toggleModal] = useState(false);

    return (
        <React.Fragment>
            <button className="btn btn-sm btn-secondary" title="Wiki bearbeiten" onClick={() => toggleModal(!modalOpen)}>
                <FontAwesomeIcon icon="edit" />
            </button>
            {modalOpen && (
                <Modal onClose={() => toggleModal(!modalOpen)} size="md" title={`Wiki ${wiki.bezeichnung} bearbeiten`} canClose={true}>
                    <WikiEditAction wiki={wiki} updateWiki={updateWiki} toggleModal={toggleModal} alleKnoten={alleKnoten} alleTags={alleTags} />
                </Modal>
            )}
        </React.Fragment>
    );
};
