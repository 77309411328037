import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fixBase64 from './fixBase64';

export default ({ dateien, setDateien }) => {
    const handleFileChange = (event, editFile = null) => {
        const reader = new FileReader();
        const file = event.target.files[0];
        reader.readAsDataURL(file);

        reader.onload = async () => {
            if (editFile !== null) {
                setDateien(
                    dateien.map(datei => {
                        if (datei.id === editFile.id) {
                            return {
                                id: null,
                                inhalt: fixBase64(reader.result),
                                name: file.name,
                            };
                        }
                        return datei;
                    })
                );
            } else {
                setDateien([
                    ...dateien,
                    {
                        id: null,
                        inhalt: fixBase64(reader.result),
                        name: file.name,
                    },
                ]);
            }
        };
    };
    return (
        <React.Fragment>
            <div className="upload-button mt-2">
                <input type="file" onChange={e => handleFileChange(e)} />
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 my-3">
                        <ul className="list-group list-unstyled">
                            {dateien.map((file, i) => (
                                <li className="d-flex justify-content-between align-items-center" key={`file-${i}`}>
                                    <span>{file.name}</span>
                                    <div className="position-relative mr-0 mr-lg-2">
                                        <div className="upload-button edit-file-button">
                                            <input type="file" onChange={e => handleFileChange(e, file)} />
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-danger remove-file-btn"
                                            onClick={() => setDateien(dateien.filter(fileToBeRemoved => fileToBeRemoved.name !== file.name))}>
                                            <FontAwesomeIcon icon="times" />
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
