import * as api from '../api';
import { getInhaltsTypOfBackend } from './getInhaltsTyp';

export const postJson = (url, params) => api.postJson(url, params);

export const deleteAction = (url, params) => api.deleteAction(url, params);

export const getJson = (url, params) => api.getJson(url, params);

const createApiBody = (method, body) => ({
    method: method,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
});

export const deleteInhaltsElement = async (type, element) => {
    const rawResponse = await fetch(`/wissen/${type}/loeschen`, createApiBody('DELETE', element));
    return await rawResponse.json();
};

export const wissenFrageBeantworten = async antwort => {
    const rawResponse = await fetch(`/wissen/frage/beantworten`, createApiBody('POST', antwort));
    return await rawResponse.json();
};

export const wissenKnotenBearbeiten = async knoten => {
    const rawResponse = await fetch('/wissen/knoten/aktualisieren', createApiBody('POST', knoten));
    return await rawResponse.json();
};

export const wissenFrageAktualisieren = async updatedAntwort => {
    const rawResponse = await fetch('/wissen/frage/antwort/aktualisieren', createApiBody('POST', updatedAntwort));
    return await rawResponse.json();
};

const angepinnteInhaltselementVerwalten = async (inhaltselement, typ, baseUrl) => {
    const knotenId = inhaltselement.knotenId || inhaltselement.knoten.id;
    const inhaltstyp = getInhaltsTypOfBackend(typ);
    const elementIdToPass = inhaltselement.id;
    const rawResponse = await fetch(`${baseUrl}/${knotenId}/${inhaltstyp}/${elementIdToPass}`, createApiBody('POST', {}));
    return await rawResponse.json();
};

const alsFokusthemaGesetztesInhaltselementVerwalten = async (elementId, typ, baseUrl, istFokusthema) => {
    const inhaltstyp = getInhaltsTypOfBackend(typ);
    const rawResponse = await fetch(`${baseUrl}/${inhaltstyp}/${elementId}/${istFokusthema}`, createApiBody('POST', {}));
    return await rawResponse.json();
};

export const wissenInhaltselementAnpinnen = async (inhaltselement, typ) => {
    return angepinnteInhaltselementVerwalten(inhaltselement, typ, '/wissen/knoten/inhaltselement-anpinnen');
};

export const wissenInhaltselementEntpinnen = async (inhaltselement, typ) => {
    return angepinnteInhaltselementVerwalten(inhaltselement, typ, '/wissen/knoten/inhaltselement-entpinnen');
};

export const wissenKommentarErzeugen = async kommentar => {
    const rawResponse = await fetch('/wissen/kommentar/erzeugen', createApiBody('POST', kommentar));
    return await rawResponse.json();
};

export const wissenKommentarLoeschen = async kommentar => {
    const rawResponse = await fetch('/wissen/kommentar/loeschen', createApiBody('DELETE', kommentar));
    return await rawResponse.json();
};

export const wissenKommentarAktualisieren = async kommentar => {
    const rawResponse = await fetch('/wissen/kommentar/aktualisieren', createApiBody('POST', kommentar));
    return await rawResponse.json();
};

export const wissenAntwortAlsRichtigMarkieren = async (antwort, istRichtig) => {
    const rawResponse = await fetch(`/wissen/frage/antwort/als-richtig-kennzeichnen/${antwort.id}/${istRichtig}`, createApiBody('POST', {}));
    return await rawResponse.json();
};

export const wissenInhaltselementBewerten = async (inhaltselement, inhaltstyp, istBewertet) => {
    const rawResponse = await fetch(`/wissen/inhaltselement-bewerten/${inhaltstyp}/${inhaltselement.id}/${istBewertet}`, createApiBody('POST', {}));
    return await rawResponse.json();
};

export const wissenInhaltselementAlsFokusthema = async (inhaltselement, inhaltstyp, istFokusthema) => {
    return alsFokusthemaGesetztesInhaltselementVerwalten(inhaltselement, inhaltstyp, '/wissen/fokusthema-setzen', istFokusthema);
};

export const tagAktualisieren = async tag => {
    const rawResponse = await fetch('/wissen/tags/aktualisieren', createApiBody('POST', tag));
    return await rawResponse.json();
};

export const tagLoeschen = async tag => {
    const rawResponse = await fetch('/wissen/tags/loeschen', createApiBody('DELETE', tag));
    return await rawResponse.json();
};
