import React, { Component } from 'react';

export default class extends Component {
    static Item = ({ headline, children, doOpen, open = false }) => (
        <div className="card" onClick={doOpen}>
            <div className="card-header">
                <h5 className="mb-0">
                    <button className={`btn btn-link ${!open && 'collapsed'}`}>{headline}</button>
                </h5>
            </div>

            <div className={`collapse ${open && 'show'}`}>
                <div className="card-body">{children}</div>
            </div>
        </div>
    );

    state = {
        open: 0,
    };

    doOpen = i => this.setState({ open: i });

    componentWillReceiveProps() {
        this.doOpen(0);
    }

    render() {
        const children = React.Children.map(this.props.children, (childElement, i) => {
            return React.cloneElement(childElement, {
                open: this.state.open === i,
                doOpen: () => this.doOpen(i),
            });
        });

        return <div className={this.props.className}>{children}</div>;
    }
}
