import React, { useState } from 'react';
import Avatar from '../../Aufgaben/Aufgabe/Avatar';
import PlaceholderPic from '../../../../../../application/public/images/dashboard/Profilbild_geschlechtsneutral.svg';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InhaltsElementBewerten from '../InhaltsElemente/InhaltsElementBewerten';
import KommentarAnlegen from '../Kommentar/KommentarAnlegen';
import AntwortDelete from './AntwortDelete';
import { handleFormSubmitResult } from '../../formHelpers';
import AntwortEditor from './AntwortEditor';
import { getInhaltsTypOfBackend } from '../getInhaltsTyp';
import { WISSEN_INHALTSTYP_ANTWORT } from '../WissenConstants';
import Kommentar from '../Kommentar/Kommentar';
import { deleteKommentarInInhaltselement, updateKommentarInInhaltselement } from '../Kommentar/updateKommentarInInhaltselement';
import moment from 'moment';
import { wissenAntwortAlsRichtigMarkieren } from '../wissenApi';

export default ({
    initialValues,
    richtigeAntwort,
    antwortAlsRichtigMarkierenOderEntziehen,
    submitAntwort,
    updateFrageAfterAntwortDelete,
    benutzerIstModerator,
}) => {
    const [editorOpen, toggleEditor] = useState(false);
    const [antwort, setAntwort] = useState(initialValues);

    const handleAntwortAlsRichtigMarkierenOderEntziehen = async () => {
        const result = await wissenAntwortAlsRichtigMarkieren(antwort, richtigeAntwort.id !== antwort.id ? 1 : 0);

        if (handleFormSubmitResult(result, '')) {
            antwortAlsRichtigMarkierenOderEntziehen(richtigeAntwort.id !== antwort.id ? antwort : false);
        }
    };

    return (
        <React.Fragment>
            <div className="d-flex align-items-center justify-content-between mt-3">
                <div className="d-flex align-items-center">
                    {antwort.ersteller.aktiv && (
                        <Avatar name={antwort.ersteller.name} avatar={PlaceholderPic} url={`/mitarbeiter/${antwort.ersteller.internId}/detail`} />
                    )}
                    {!antwort.ersteller.aktiv && <span className="text-muted">{antwort.ersteller.name}</span>}
                    <span className="ml-1 text-muted">am {moment(antwort.erstelltAm).format('DD.MM.YY, hh:mm')}</span>
                </div>
                {richtigeAntwort.id === antwort.id && <FontAwesomeIcon icon="check" color="green" size="lg" title="richtige Antwort" />}
            </div>
            <div className="text-muted mt-3">
                <ReactMarkdown escapeHtml={false} source={initialValues.inhalt} />
            </div>
            {antwort.dateien.length !== 0 && (
                <div className="mt-3">
                    <span>Angehängte Dateien</span>
                    {antwort.dateien.map(datei => (
                        <a key={datei.id} href={`/wissen/datei/herunterladen/${datei.id}`} className="font-italic ml-2">
                            {datei.name}
                        </a>
                    ))}
                </div>
            )}
            <div className="d-flex justify-content-end">
                <div className="btn-group">
                    {benutzerIstModerator && (
                        <button
                            title="Antwort als richtig markieren"
                            className={`btn btn-sm ${richtigeAntwort.id === antwort.id ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => handleAntwortAlsRichtigMarkierenOderEntziehen()}>
                            <FontAwesomeIcon icon="star" />
                        </button>
                    )}
                    <InhaltsElementBewerten
                        inhaltselement={antwort}
                        typ={WISSEN_INHALTSTYP_ANTWORT}
                        istBewertetDurchAktuelleBenutzer={antwort.istBewertetDurchAktuelleBenutzer}
                    />
                    <KommentarAnlegen
                        btnColor="outline-secondary"
                        inhaltselement={antwort}
                        inhaltselementTyp={getInhaltsTypOfBackend(WISSEN_INHALTSTYP_ANTWORT)}
                        addKommentarToInhaltselement={kommentar => setAntwort({ ...antwort, kommentare: [...antwort.kommentare, kommentar] })}
                    />
                    {antwort.istAktuellerBenutzerErstellerVonAntwort && (
                        <React.Fragment>
                            <button className="btn btn-sm btn-outline-secondary" title="Bearbeiten" onClick={() => toggleEditor(true)}>
                                <FontAwesomeIcon icon="edit" />
                            </button>
                            <AntwortDelete antwort={antwort} updateFrageAfterAntwortDelete={updateFrageAfterAntwortDelete} />
                        </React.Fragment>
                    )}
                </div>
            </div>
            {antwort.kommentare.length !== 0 && (
                <div className="col-12 mt-3">
                    <h4>Kommentare</h4>
                    {antwort.kommentare.map(kommentar => (
                        <Kommentar
                            kommentar={kommentar}
                            updateKommentarInInhaltselement={updatedKommentar => updateKommentarInInhaltselement(updatedKommentar, antwort, setAntwort)}
                            key={kommentar.id}
                            deleteKommentarInInhaltselement={deletedKommentar => deleteKommentarInInhaltselement(deletedKommentar, antwort, setAntwort)}
                        />
                    ))}
                </div>
            )}
            <div className="col-12 mt-3">
                {editorOpen && (
                    <AntwortEditor
                        antwort={antwort}
                        toggleEditor={toggleEditor}
                        submitAntwort={antwort => {
                            submitAntwort(antwort);
                            toggleEditor(false);
                        }}
                    />
                )}
            </div>
            <hr className="w-100" />
        </React.Fragment>
    );
};
