import React, { Component } from 'react';
import moment from 'moment';
import { formatMinsAsHrs, isMonthInSelectedPeriod } from './utils';
import Collapser from './Collapser';

export default class ProjektUebersichtMonthGroup extends Component {
    calculateDataModel = () => {
        const props = this.props;

        const sumTotalMinutes = { 'LPH 1-4': 0, 'LPH 5-7': 0, 'LPH 8-9': 0, PS: 0, sum: 0 };

        const projektMitarbeiters = props.projekt.mitarbeiters;

        if (props.sortBy === props.sortOptions.NAME) {
            projektMitarbeiters.sort((a, b) => {
                const fullNameA = props.mitarbeiters[a.mId].nachname + ' ' + props.mitarbeiters[a.mId].name;
                const fullNameB = props.mitarbeiters[b.mId].nachname + ' ' + props.mitarbeiters[b.mId].name;
                return fullNameA.localeCompare(fullNameB, 'de');
            });
        } else {
            projektMitarbeiters.sort((a, b) => {
                const functionComparison = props.mitarbeiters[a.mId].funktion.localeCompare(props.mitarbeiters[b.mId].funktion, 'de');

                if (functionComparison !== 0) {
                    return functionComparison;
                }

                const fullNameA = props.mitarbeiters[a.mId].nachname + ' ' + props.mitarbeiters[a.mId].name;
                const fullNameB = props.mitarbeiters[b.mId].nachname + ' ' + props.mitarbeiters[b.mId].name;
                return fullNameA.localeCompare(fullNameB, 'de');
            });
        }

        const dataGroupedByMonth = [];

        projektMitarbeiters.forEach((mitarbeiter, mIdx) => {
            const mitarbeiterInfo = props.mitarbeiters[mitarbeiter.mId];

            mitarbeiter.individualMonths.forEach(iteratedMonth => {
                // if the currently iterated month is not within the selected date range, skip it
                if (!isMonthInSelectedPeriod(iteratedMonth.month, iteratedMonth.year, props.selectedDates)) {
                    return;
                }

                let month = dataGroupedByMonth.find(month => month.year === iteratedMonth.year && month.month === iteratedMonth.month);
                if (month === undefined) {
                    month = {
                        year: iteratedMonth.year,
                        month: iteratedMonth.month,
                        totalMinutes: { 'LPH 1-4': 0, 'LPH 5-7': 0, 'LPH 8-9': 0, PS: 0, sum: 0 },
                        mitarbeiters: [],
                    };
                    dataGroupedByMonth.push(month);
                }

                iteratedMonth.lphs.forEach(lph => {
                    month.totalMinutes[lph.lph] += lph.totalMinutes;
                    month.totalMinutes.sum += lph.totalMinutes;
                    sumTotalMinutes[lph.lph] += lph.totalMinutes;
                    sumTotalMinutes.sum += lph.totalMinutes;
                });

                month.mitarbeiters.push({
                    mId: mitarbeiter.mId,
                    name: `${mitarbeiterInfo.nachname} ${mitarbeiterInfo.name}`,
                    funktion: mitarbeiterInfo.funktion,
                    lphs: iteratedMonth.lphs.concat([
                        {
                            lph: 'sum',
                            totalMinutes: iteratedMonth.lphs.reduce((carry, lph) => carry + lph.totalMinutes, 0),
                        },
                    ]),
                });
            });
        });

        return { sumTotalMinutes, dataGroupedByMonth };
    };

    render() {
        if (this.props.projekt === undefined) {
            return <tbody />;
        }

        const { sumTotalMinutes, dataGroupedByMonth } = this.calculateDataModel();

        return (
            <tbody>
                {dataGroupedByMonth.map((month, i) => (
                    <tr key={`month${i}`}>
                        <td>
                            <Collapser target={`.collapse-${i}`} classNames="pr-2" />
                            {moment(`${month.year}-${month.month}-01`, 'YYYY-M-DD').format('MMM YYYY')}
                            {month.mitarbeiters.map((mitarbeiter, j) => (
                                <React.Fragment key={`month${i}mitarbeiter${j}`}>
                                    <div className={`pl-3 collapse collapse-${i}`}>{mitarbeiter.name}</div>
                                    <div className={`text-muted small pl-3 collapse collapse-${i}`}>{mitarbeiter.funktion}</div>
                                </React.Fragment>
                            ))}
                        </td>
                        {Object.keys(sumTotalMinutes).map((lphName, j) => (
                            <td key={`month${i}lph${j}`} className="text-right">
                                {formatMinsAsHrs(month.totalMinutes[lphName]) || '\u00A0'}
                                {month.mitarbeiters.map((mitarbeiter, k) => (
                                    <React.Fragment key={`month${i}lph${j}mitarbeiter${k}`}>
                                        <div className={`text-right text-muted collapse collapse-${i}`}>
                                            {formatMinsAsHrs(mitarbeiter.lphs.find(lph => lph.lph === lphName).totalMinutes) || '\u00A0'}
                                        </div>
                                        <div className={`text-muted small collapse collapse-${i}`}>&nbsp;</div>
                                    </React.Fragment>
                                ))}
                            </td>
                        ))}
                    </tr>
                ))}
                <tr>
                    <td className="text-right font-weight-bold">Σ</td>
                    {Object.keys(sumTotalMinutes).map((lphName, i) => (
                        <td key={`sumlph${i}`} className="text-right font-weight-bold">
                            {formatMinsAsHrs(sumTotalMinutes[lphName])}
                        </td>
                    ))}
                </tr>
            </tbody>
        );
    }
}
