import React, { Component } from 'react';
import * as api from '../api';
import { gql } from 'apollo-boost';
import { Query, Mutation } from 'react-apollo';
import numeral from '../numeral';

class MonatsuebersichtWidget extends Component {
    state = {
        fetching: true,
        data: null,
        user: null,
        month: null,
    };

    componentDidMount() {
        this.loadMonth(this.props.user, this.props.month);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.month !== this.state.month || nextProps.user !== this.state.user || nextProps.updater !== this.state.updater) {
            this.loadMonth(nextProps.user, nextProps.month);
        }
    }

    async loadMonth(user, month) {
        this.setState({
            fetching: true,
        });

        const json = await api.getJson(`/zeiterfassung/monatsuebersicht/${user}/${month}/`);
        this.setState({
            data: json,
            fetching: false,
            user,
            month,
        });
    }

    render() {
        const { fetching, data } = this.state;

        if (fetching && null === data) {
            return <div>Loading</div>;
        }

        return (
            <div className="monatsuebersicht--widget card">
                <div className="card-body">
                    <Header fetching={fetching} data={data} />
                    <MonthSwitch data={data} />

                    <HourRow name="Sollstunden Monat" value={data.sollstunden} />
                    <SmallOpticalBorder />
                    <HourRow name="Überstunden aus Vormonat" value={data.ueberstunden_vormonat} />
                    <HourRow name="Geleistete Stunden" value={data.stunden_geleistet} force={true} />
                    <HourRow name="Urlaub" value={data.urlaub} />
                    <HourRow name="Krankheit" value={data.krankheit} />
                    {data.anpassungen
                        .filter(anpassung => anpassung.arbeitszeit != 0)
                        .map(anpassung => (
                            <HourRow key={anpassung.name} name={anpassung.name} value={numeral(anpassung.arbeitszeit).format('0.00')} />
                        ))}
                    <SmallOpticalBorder />
                    <HourRow name="Überstunden in Folgemonat" value={data.ueberstunden_folgemonat} />

                    <div className="text-muted mt-1">
                        <HourRow name="Urlaubsstunden beantragt" value={data.urlaub_beantragt} force={true} />
                        <HourRow name="Urlaubsstunden offen" value={data.urlaub_offen} force={true} />
                    </div>
                </div>
            </div>
        );
    }
}

const SmallOpticalBorder = () => (
    <div className="my-1">
        <div className="border-bottom" />
    </div>
);

const HourRow = ({ name, value, force = false }) => {
    if (!value && !force) {
        return null;
    }

    return (
        <div className="row">
            <div className="col">{name}</div>
            <div className="col-auto text-right">{numeral(value).format('0.00')}</div>
        </div>
    );
};
const HourRowUnset = ({ name }) => {
    return (
        <div className="row">
            <div className="col">{name}</div>
            <div className="col-auto text-right">-,--</div>
        </div>
    );
};

const Header = ({ data, fetching }) => (
    <div className="row">
        <div className="col-auto">
            <span className="fa fa-lg fa-list" />
        </div>
        <div className="col">
            <h2>Monatsübersicht</h2>
        </div>
        <div className="col-auto text-right">
            <div className={`${!fetching && 'd-none'}`}>
                <i className="fas fa-2x fa-spinner fa-pulse" />
            </div>
        </div>
    </div>
);

const MonthSwitch = ({ data }) => (
    <div className="text-center">
        <Mutation mutation={SET_MONTH}>
            {setMonth => (
                <button
                    className={`btn btn-link text-dark ${data.previous ? '' : 'disabled'}`}
                    onClick={() => data.previous && setMonth({ variables: { month: data.previous } })}>
                    <i className="fas fa-chevron-left" />
                </button>
            )}
        </Mutation>

        {data.name}

        <Mutation mutation={SET_MONTH}>
            {setMonth => (
                <button
                    className={`btn btn-link text-dark ${data.next ? '' : 'disabled'}`}
                    onClick={() => data.next && setMonth({ variables: { month: data.next } })}>
                    <i className="fas fa-chevron-right" />
                </button>
            )}
        </Mutation>
    </div>
);

const SET_MONTH = gql`
    mutation setMonth($month: String!) {
        setMonatsuebersichtWidgetMonth(month: $month) @client
    }
`;

export default () => (
    <Query query={GET_MONTH}>
        {({ loading, error, data }) => {
            if (loading) return <div>Loading...</div>;
            if (error) return <div>Error :(</div>;

            return <MonatsuebersichtWidget month={data.monatsuebersichtWidget.month} user={data.user} updater={data.updater} />;
        }}
    </Query>
);

const GET_MONTH = gql`
    query {
        user @client
        updater @client
        monatsuebersichtWidget @client {
            month
        }
    }
`;
