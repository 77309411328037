import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleFormSubmitResult } from '../../formHelpers';
import { deleteInhaltsElement } from '../wissenApi';

export default ({ inhaltsElement, type }) => {
    const [modalOpen, toggleModal] = useState(false);

    const handleElementDelete = async () => {
        const result = await deleteInhaltsElement(type, inhaltsElement);
        handleFormSubmitResult(result, `${inhaltsElement.bezeichnung} erfolgreich gelöscht!`);
        toggleModal(!modalOpen);
    };

    return (
        <React.Fragment>
            <button className="btn btn-sm btn-danger" onClick={() => toggleModal(!modalOpen)}>
                <FontAwesomeIcon icon="trash" />
            </button>
            {modalOpen && (
                <Modal
                    onClose={() => toggleModal(!modalOpen)}
                    size="sm"
                    title="Inhaltselement Löschen"
                    canClose={true}
                    footer={
                        <div className="btn-group">
                            <a onClick={() => handleElementDelete()} className="btn btn-outline-danger">
                                Ja
                            </a>
                            <button className="btn btn-outline-primary" onClick={() => toggleModal(!modalOpen)}>
                                Abbrechen
                            </button>
                        </div>
                    }>
                    <div className="d-flex align-items-center justify-content-center">
                        <h3>Inhaltselement {inhaltsElement.bezeichnung} wirklich Löschen ?</h3>
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};
