import React from 'react';

export default ({ tags }) => {
    return (
        <li className="list-group-item d-flex align-items-center">
            <span className="knoten-detail-type font-weight-bold">Tags:</span>
            <div className="container-fluid">
                <div className="row">
                    {tags.map(tag => (
                        <div className="col-auto" key={tag.id}>
                            <span className="badge badge-info font-weight-normal w-100">{tag.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </li>
    );
};
