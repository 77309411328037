import React, { useState } from 'react';
import WikiEdit from './WikiEdit';
import { CardTemplate } from '../ViewTemplates';
import Kommentar from '../Kommentar/Kommentar';
import moment from 'moment';
import Avatar from '../../Aufgaben/Aufgabe/Avatar';
import PlaceholderPic from '../../../../../../application/public/images/dashboard/Profilbild_geschlechtsneutral.svg';
import KommentarAnlegen from '../Kommentar/KommentarAnlegen';
import InhaltsElementDelete from '../InhaltsElemente/InhaltsElementDelete';
import InhaltsElementAlsFokusthema from '../InhaltsElemente/InhaltsElementAlsFokusthema';
import InhaltsElementAnpinnen, { pinInhaltselementOnDetailPage, unPinInhaltselementOnDetailPage } from '../InhaltsElemente/InhaltsElementAnpinnen';
import { WISSEN_INHALTSTYP_WIKI } from '../WissenConstants';
import { WISSEN_INHALTSTYP_WIKI_URL } from '../WissenConstants';
import { getInhaltsTypOfBackend } from '../getInhaltsTyp';
import { deleteKommentarInInhaltselement, updateKommentarInInhaltselement } from '../Kommentar/updateKommentarInInhaltselement';
import { istElementAngepinnt } from '../InhaltsElemente/InhaltsTypenListItem';
import InhaltselementDetailFiles from '../InhaltsElemente/InhaltselementDetailPageFiles';
import { deleteAllowed, editAllowed, pinAndFokusthemaSetzenAllowed } from '../InhaltselementActionPermissions';
import InhaltsElementBewerten from '../InhaltsElemente/InhaltsElementBewerten';
import TagDetails from '../Tags/TagDetails';
import ReactMarkdown from 'react-markdown';

export default initialProps => {
    const [wiki, setWiki] = useState(initialProps.wiki);
    const [fokusthemen, setFokusthemen] = useState([initialProps.wiki]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body d-flex justify-content-end">
                            <div className="btn-group">
                                {editAllowed(initialProps.benutzerIstAdmin, initialProps.benutzerIstErstellerVonInhaltsAggregat) && (
                                    <WikiEdit
                                        wiki={wiki}
                                        updateWiki={wiki => setWiki(wiki)}
                                        alleKnoten={initialProps.alleKnoten}
                                        alleTags={initialProps.alleTags}
                                    />
                                )}
                                <KommentarAnlegen
                                    inhaltselement={wiki}
                                    inhaltselementTyp={getInhaltsTypOfBackend(WISSEN_INHALTSTYP_WIKI)}
                                    addKommentarToInhaltselement={kommentar => setWiki({ ...wiki, kommentare: [...wiki.kommentare, kommentar] })}
                                />
                                {pinAndFokusthemaSetzenAllowed(initialProps.benutzerIstAdmin, initialProps.benutzerIstModerator) && (
                                    <React.Fragment>
                                        <InhaltsElementAlsFokusthema
                                            inhaltsElement={wiki}
                                            fokusthemen={fokusthemen}
                                            setFokusthemen={setFokusthemen}
                                            typ={WISSEN_INHALTSTYP_WIKI}
                                        />
                                        <InhaltsElementAnpinnen
                                            typ={WISSEN_INHALTSTYP_WIKI}
                                            inhaltsElement={{ ...wiki, knotenId: wiki.knoten.id }}
                                            pinInhaltselement={element => pinInhaltselementOnDetailPage(element, setWiki)}
                                            unpinInhaltselement={element => unPinInhaltselementOnDetailPage(element, setWiki)}
                                            istElementAngepinnt={istElementAngepinnt(wiki, wiki.knoten.angepinnteInhaltselemente)}
                                        />
                                    </React.Fragment>
                                )}
                                <InhaltsElementBewerten
                                    inhaltselement={wiki}
                                    typ={WISSEN_INHALTSTYP_WIKI}
                                    istBewertetDurchAktuelleBenutzer={wiki.istBewertetDurchAktuelleBenutzer}
                                />
                                {deleteAllowed(
                                    initialProps.benutzerIstAdmin,
                                    initialProps.benutzerIstModerator,
                                    initialProps.benutzerIstErstellerVonInhaltsAggregat
                                ) && <InhaltsElementDelete inhaltsElement={wiki} type={WISSEN_INHALTSTYP_WIKI_URL} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-6 col-md-12">
                    <CardTemplate headingClass="bg-light border-bottom border-primary" heading={wiki.bezeichnung}>
                        <ul className="list-group list-unstyled list-group-flush">
                            <li className="list-group-item d-flex align-items-center">
                                <span className="knoten-detail-type font-weight-bold">Aktualisiert am:</span>
                                <span> {moment(wiki.aktualisiertAm).format('DD.MM.YYYY, hh:mm')}</span>
                            </li>
                            <li className="list-group-item d-flex align-items-center">
                                <span className="knoten-detail-type font-weight-bold">Ersteller:</span>
                                {wiki.ersteller.aktiv && (
                                    <Avatar name={wiki.ersteller.name} url={`/mitarbeiter/${wiki.ersteller.internId}/detail`} avatar={PlaceholderPic} />
                                )}
                                {!wiki.ersteller.aktiv && <span className="text-muted">{wiki.ersteller.name}</span>}
                            </li>
                            <li className="list-group-item d-flex align-items-center">
                                <span className="knoten-detail-type font-weight-bold">Knoten:</span>
                                <span> {wiki.knoten.name}</span>
                            </li>
                            <TagDetails tags={wiki.tags} />
                            <li className="list-group-item d-flex flex-column">
                                <span className="font-weight-bold">Inhalt:</span>
                                <ReactMarkdown escapeHtml={false} source={wiki.inhalt} />
                            </li>
                        </ul>
                    </CardTemplate>
                </div>
                <div className="col-lg-6 col-md-12">
                    <CardTemplate
                        headingClass="bg-light border-bottom border-primary"
                        classNames={`${wiki.kommentare.length !== 0 ? 'mb-3' : ''}`}
                        heading="Angefügte Dateien">
                        <InhaltselementDetailFiles inhaltselement={wiki} />
                    </CardTemplate>
                    {wiki.kommentare.length !== 0 && (
                        <CardTemplate headingClass="border-bottom border-primary" heading="Kommentare">
                            <ul className="list-group list-group-flush list-unstyled">
                                {wiki.kommentare.map(kommentar => (
                                    <Kommentar
                                        key={kommentar.id}
                                        kommentar={kommentar}
                                        updateKommentarInInhaltselement={updatedKommentar => updateKommentarInInhaltselement(updatedKommentar, wiki, setWiki)}
                                        deleteKommentarInInhaltselement={deletedKommentar => deleteKommentarInInhaltselement(deletedKommentar, wiki, setWiki)}
                                    />
                                ))}
                            </ul>
                        </CardTemplate>
                    )}
                </div>
            </div>
        </div>
    );
};
