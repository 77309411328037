import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FrageAnlegenAction from './FrageAnlegenAction';

export default ({ knoten, addNewFrageToKnoten, alleTags }) => {
    const [modalOpen, toggleModal] = useState(false);

    return (
        <React.Fragment>
            <button
                className="inhaltselement-anlegen-btn btn btn-sm btn-primary d-flex align-items-center"
                title="Frage Anlegen"
                onClick={() => toggleModal(!modalOpen)}>
                <FontAwesomeIcon icon="plus" />
            </button>
            {modalOpen && (
                <Modal onClose={() => toggleModal(!modalOpen)} size="md" title={`Frage in ${knoten.name} stellen`} canClose={true}>
                    <FrageAnlegenAction addNewFrageToKnoten={addNewFrageToKnoten} toggleModal={toggleModal} knoten={knoten} alleTags={alleTags} />
                </Modal>
            )}
        </React.Fragment>
    );
};
