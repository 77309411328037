import $ from 'jquery';

(function($) {
    $.fn.dynamicFormByPrototype = function(options) {
        var self = $(this);
        var settings = $.extend(
            {
                prototypeName: '__name__',
                buttonAdd: null,
                counterNode: null,
                onRemove: function() {},
            },
            options
        );

        var parentLevel =
            self
                .parent()
                .closest('.e2-form-prototype')
                .data('e2-form-prototype') || 0;
        self.data('e2-form-prototype', parentLevel + 1);
        self.addClass('e2-form-prototype-l' + (parentLevel + 1));
        if (parentLevel > 0) {
            settings.prototypeName = '__name' + parentLevel + '__';
        }

        var $lengthElement = self.find('.e2-form-prototype-length:first');
        var collectionHolder = self.find('[data-prototype]:first');

        var rowsPerElement = 1;
        if (collectionHolder.hasClass('table-striped2') || collectionHolder.closest('table.table-striped2').length > 0) {
            rowsPerElement = 2;
        }

        var elementCount = function() {
            return collectionHolder.find('> *').length / rowsPerElement;
        };

        collectionHolder.data('index', elementCount());

        var isTable = collectionHolder[0].tagName.toLowerCase() === 'tbody';

        function addTagForm(collectionHolder) {
            // Get the data-prototype explained earlier
            var prototype = collectionHolder.data('prototype');
            // get the new index
            var index = collectionHolder.data('index');
            // Replace '__name__' in the prototype's HTML to
            // instead be a number based on how many items we have
            var regExp = new RegExp(settings.prototypeName, 'g');
            var $newForm = prototype.replace(regExp, index);
            // increase the index with one for the next item
            collectionHolder.data('index', index + 1);
            // save the amount of elements in the collection

            // we had issues with multiple tr elements coming within one add(), so first we store
            // the count of all elements...
            var oldLength = collectionHolder.find('> *').length;
            collectionHolder.append($newForm);

            onDomUpdate();

            // ...to finally recognize the new parts
            // trigger html rendering for newly inserted html elements
            var $form = collectionHolder.find('> *').slice(oldLength);
            self.trigger('e2-dom-init', [$form]);
        }

        var onDomUpdate = function() {
            var cnt = elementCount();
            if ($lengthElement.length > 0) {
                $lengthElement.val(cnt);
            }

            self.find('.e2-form-prototype-info-empty').toggle(cnt === 0);

            // enumerate
            collectionHolder
                .find('.e2-form-prototype-enumerator')
                .not('.e2-form-prototype-l' + (parentLevel + 2) + ' [data-prototype] .e2-form-prototype-enumerator')
                .each(function(i) {
                    $(this).text(i + 1);
                });

            if (!isTable || self.data('no-hide')) {
                return false;
            }
            collectionHolder.closest('table').toggle(cnt > 0);
        };

        onDomUpdate();

        self.on('click', '.e2-form-prototype-add', function(e) {
            // match no elements from subclass
            if ($(this).is('.e2-form-prototype-l' + (parentLevel + 2) + ' .e2-form-prototype-add')) {
                return;
            }
            // add a new tag form (see next code block)
            addTagForm(collectionHolder);

            self.trigger('element_add');
            self.trigger('element_change', [elementCount()]);

            e.preventDefault();
        });

        // add remove handler to element
        self.on('click', '.e2-form-prototype-remove', function(e) {
            if ($(this).is('.e2-form-prototype-l' + (parentLevel + 2))) {
                return;
            }

            if (typeof $(this).data('target') !== 'undefined') {
                $($(this).data('target')).remove();
            } else {
                var container = $(this).closest('tr, li, .row');
                if (
                    parseInt(
                        $(this)
                            .closest('td')
                            .attr('rowspan')
                    ) > 0
                ) {
                    container.next().remove();
                }
                container.remove();
            }

            onDomUpdate();

            self.trigger('element_remove');
            self.trigger('element_change', [elementCount()]);
            settings.onRemove();

            e.preventDefault();
        });

        self.on('set_count', function(e, count) {
            e.stopPropagation();

            collectionHolder.html('');
            collectionHolder.data('index', 0);
            for (var i = 1; i <= count; i++) {
                addTagForm(collectionHolder);
            }
            onDomUpdate();
        });

        self.on('add', function(e) {
            e.stopPropagation();

            addTagForm(collectionHolder);

            self.trigger('element_add');
            self.trigger('element_change', [elementCount()]);

            onDomUpdate();
        });

        self.trigger('prototype_initialized', [elementCount()]);
    };
})($);

/**
 * Listens on '.e2-form-prototype' and '.e2-form-prototype-add' and '[data-prototype]' of collection
 *
 * Fetches the buttons first, looks up in dom tree for corresponding context-container
 *
 * @param $context
 */
$(document).on('e2-dom-init', function(e, $context) {
    $context.find('.e2-form-prototype').each(function(i, e) {
        $(e).dynamicFormByPrototype();
    });
});
