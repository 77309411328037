import React from 'react';
import { FIELD_BEARBEITER, FIELD_DRINGLICHKEIT, FIELD_ENDE, FIELD_ERSTELLER, FIELD_START, FIELD_STATUS, FIELD_TITEL, List } from './Lists';

export default ({ userid, username, aufgaben }) => {
    const config = {
        headline: 'Projektaufgaben',
        url: `/aufgaben/projekt/${aufgaben.projekt.id}`,
        fields: {
            [FIELD_DRINGLICHKEIT]: {
                label: '',
                sort: true,
                filter: true,
            },
            [FIELD_TITEL]: {
                sort: true,
            },
            [FIELD_BEARBEITER]: {
                sort: true,
                filter: true,
            },
            [FIELD_ERSTELLER]: {
                sort: true,
            },
            [FIELD_START]: {
                sort: true,
            },
            [FIELD_ENDE]: {
                sort: true,
            },
            [FIELD_STATUS]: {
                label: 'Status',
                // can field be sorted
                sort: true,
                // will there be a auswahlfilter on the list
                filter: true,
                // will the values of this column be applied to search
                search: true,
            },
        },
        elementsPerPage: 10,
        toCompleteListBtn: false,
    };
    return <List userid={userid} username={username} className="projekt-aufgabenliste" {...config} />;
};
