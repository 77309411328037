import { WISSEN_INHALTSTYP_DOKUMENT, WISSEN_INHALTSTYP_WIKI, WISSEN_INHALTSTYP_FRAGE, WISSEN_INHALTSTYP_ANTWORT } from './WissenConstants';

export const convertInhaltsTypen = typen =>
    typen.map(typ => ({
        inhaltstyp: getInhaltsTypOfBackend(typ.value),
    }));

export const convertInhaltsTypenForSelect = typen =>
    typen.map(typ => ({
        value: getInhaltsTypForFrontend(typ.inhaltstyp),
        label: getInhaltsTypForFrontend(typ.inhaltstyp),
    }));

export const getInhaltsTypOfBackend = typ => {
    switch (typ) {
        case WISSEN_INHALTSTYP_WIKI:
            return 'WIKI';
        case WISSEN_INHALTSTYP_DOKUMENT:
            return 'DOKUMENT';
        case WISSEN_INHALTSTYP_FRAGE:
            return 'FRAGE_ANTWORT';
        case WISSEN_INHALTSTYP_ANTWORT:
            return 'ANTWORT';
    }
};

export const getInhaltsTypForFrontend = typ => {
    switch (typ) {
        case 'DOKUMENT':
            return WISSEN_INHALTSTYP_DOKUMENT;
        case 'WIKI':
            return WISSEN_INHALTSTYP_WIKI;
        case 'FRAGE_ANTWORT':
            return WISSEN_INHALTSTYP_FRAGE;
    }
};
