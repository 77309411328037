import React from 'react';
import Modal from '../../Modal/Modal';
import AufgabeErstellenForm from './AufgabeErstellenForm';
import ButtonImg from '../../../../../../application/public/images/icons/Aufgaben.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class AufgabeErstellen extends React.Component {
    state = {
        modalOpen: false,
        expand: false,
    };

    toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen, expand: false });

    expand = () => this.setState({ expand: true });

    render() {
        const aufgabenProps = this.props.aufgaben || {};
        const defaultBearbeiter = [{ value: this.props.userid, label: this.props.username }];
        const { modalOpen, expand } = this.state;
        const { icon } = this.props;
        return (
            <React.Fragment>
                <button className="btn btn-primary" onClick={() => this.toggleModal()}>
                    {icon ? <FontAwesomeIcon icon={icon} /> : <img src={ButtonImg} className="create-aufgabe-btn" />}
                </button>
                {modalOpen && (
                    <Modal onClose={() => this.toggleModal()} size={`${expand ? 'lg' : 'md'}`} title="Aufgabe erstellen" canClose={true}>
                        <AufgabeErstellenForm toggleModal={this.toggleModal} {...aufgabenProps} defaultBearbeiter={defaultBearbeiter} expand={this.expand} />
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
