import React from 'react';
import { URLAUBSANTRAG_STATUS } from '../constants';

export default ({ urlaubsantragStatus }) => {
    let badgeColor;
    switch (urlaubsantragStatus) {
        case URLAUBSANTRAG_STATUS.ENTWURF:
            badgeColor = 'bg-secondary';
            break;
        case URLAUBSANTRAG_STATUS.BEANTRAGT:
            badgeColor = 'bg-primary';
            break;
        case URLAUBSANTRAG_STATUS.VERWORFEN:
            badgeColor = 'bg-secondary';
            break;
        case URLAUBSANTRAG_STATUS.GENEHMIGT:
            badgeColor = 'bg-success';
            break;
        case URLAUBSANTRAG_STATUS.ABGELEHNT:
        case URLAUBSANTRAG_STATUS.STORNIERT:
            badgeColor = 'bg-danger';
            break;
        default:
            badgeColor = 'bg-dark';
            break;
    }

    return <span className={`antrag-status badge p-1 ${badgeColor}`}>{urlaubsantragStatus}</span>;
};
