import React, { useState } from 'react';
import { Creatable } from 'react-select';
import { transformItemsWithIdAndNameForReactSelect } from '../../formHelpers';

export default ({ tags, setTags, alleTags }) => {
    const [options, setOptions] = useState(transformItemsWithIdAndNameForReactSelect(alleTags));

    const handleCreateOption = option => {
        const newOption = { value: null, label: option };

        setOptions([...options, newOption]);
        setTags([...tags, newOption]);
    };

    return (
        <Creatable
            className="creatable"
            isMulti
            isClearable={true}
            isSearchable={true}
            options={options}
            value={tags}
            onChange={setTags}
            onCreateOption={option => handleCreateOption(option)}
            placeholder="Bitte wählen"
            createOptionPosition="first"
        />
    );
};
