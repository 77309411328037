import React from 'react';
import PortalButtonBar from '../PortalButtonBar';
import UrlaubsantragViewWochen from './Widget/UrlaubsantragViewWochen';
import moment from 'moment';
import * as api from '../api';
import toast from '../toastify';
import { URLAUBSANTRAG_STATUS } from './constants';
import Modal from '../Modal';

export default class UrlaubsantragView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            urlaubsantrag: {
                urlaubsantragId: { id: null },
                von: null,
                bis: null,
                status: null,
                kommentar: '',
                konditionen: [],
                projektVertretungsDaten: [],
                genehmigungsDaten: [],
            },
            initialLoading: true,
            isVertretung: false,
            darfStornieren: false,
            canDecide: false,
            decideInProgress: false,
            stornoInProgress: false,
        };
    }

    async getUrlaubsantragData(uuid) {
        await api
            .getJson(`/urlaub/urlaubsantrag/existing-data/${uuid}`)
            .then(json => {
                if (json.urlaubsantrag !== undefined) {
                    this.setState({
                        urlaubsantrag: json.urlaubsantrag,
                        isVertretung: json.isVertretung,
                        darfStornieren: json.darfStornieren,
                        canDecide: json.canDecide,
                        initialLoading: false,
                    });
                } else {
                    let errorMessage = 'Ein Fehler ist beim Laden des Urlaubsantrags aufgetreten';
                    if (json.error !== undefined) {
                        errorMessage = json.error;
                    }
                    toast.error(errorMessage);
                    this.setState({ initialLoading: false });
                }
            })
            .catch(json => {
                toast.error('Ein Fehler ist beim Laden des Urlaubsantrags aufgetreten');
                this.setState({ initialLoading: false });
            });
    }

    componentDidMount() {
        const pathFragments = location.pathname.split('/');
        if (pathFragments.length === 4 && pathFragments[3].length === 36) {
            this.getUrlaubsantragData(pathFragments[3]);
        } else {
            return;
        }
    }

    genehmigen = () => {
        this.setState({ decideInProgress: true });
        api.getJson(`/urlaub/urlaubsantrag/genehmigen/${this.state.urlaubsantrag.urlaubsantragId.id}`)
            .then(json => {
                if (json.success !== undefined && json.success === true) {
                    toast.success('Urlaubsantrag genehmigt');
                    this.getUrlaubsantragData(this.state.urlaubsantrag.urlaubsantragId.id).finally(result => {
                        this.setState({ decideInProgress: false });
                    });
                } else {
                    let errorMessage = 'Ein Fehler ist aufgetreten';
                    if (json.error !== undefined) {
                        errorMessage = json.error;
                    }
                    toast.error(errorMessage);
                    this.setState({ decideInProgress: false });
                }
            })
            .catch(json => {
                toast.error('Ein Fehler ist aufgetreten');
                this.setState({ decideInProgress: false });
            });
    };

    ablehnen = () => {
        this.setState({ decideInProgress: true });
        api.getJson(`/urlaub/urlaubsantrag/ablehnen/${this.state.urlaubsantrag.urlaubsantragId.id}`)
            .then(json => {
                if (json.success !== undefined && json.success === true) {
                    toast.success('Urlaubsantrag abgelehnt');
                    this.getUrlaubsantragData(this.state.urlaubsantrag.urlaubsantragId.id).finally(result => {
                        this.setState({ decideInProgress: false });
                    });
                } else {
                    let errorMessage = 'Ein Fehler ist aufgetreten';
                    if (json.error !== undefined) {
                        errorMessage = json.error;
                    }
                    toast.error(errorMessage);
                    this.setState({ decideInProgress: false });
                }
            })
            .catch(json => {
                toast.error('Ein Fehler ist aufgetreten');
                this.setState({ decideInProgress: false });
            });
    };

    stornieren = () => {
        this.setState({ stornoInProgress: true });
        api.getJson(`/urlaub/urlaubsantrag/stornieren/${this.state.urlaubsantrag.urlaubsantragId.id}`)
            .then(json => {
                if (json.urlaubsantrag !== undefined) {
                    toast.success('Urlaubsantrag storniert');
                    this.getUrlaubsantragData(this.state.urlaubsantrag.urlaubsantragId.id).finally(result => {
                        this.setState({ stornoInProgress: false });
                    });
                } else {
                    let errorMessage = 'Ein Fehler ist aufgetreten';
                    if (json.error !== undefined) {
                        errorMessage = json.error;
                    }
                    toast.error(errorMessage);
                    this.setState({ stornoInProgress: false });
                }
            })
            .catch(json => {
                toast.error('Ein Fehler ist aufgetreten');
                this.setState({ stornoInProgress: false });
            });
    };

    render() {
        const { initialLoading, urlaubsantrag, darfStornieren } = this.state;

        if (initialLoading) {
            return (
                <div>
                    <div>
                        <i className="ml-2 fas fa-2x fa-spinner fa-pulse" />
                    </div>
                    <span>Loading ...</span>
                </div>
            );
        }

        return (
            <div className={`${!initialLoading ? '' : 'd-none'}`}>
                <PortalButtonBar>
                    <div className="row align-items-center mb-0">
                        <div className="col-sm-12 col-md">
                            <span>Gewünschter Urlaubszeitraum</span>
                        </div>
                        <div className="col-md">
                            <span className="font-weight-bold">von: {moment(this.state.urlaubsantrag.von).format('DD.MM.YYYY')}</span>
                            <span className="font-weight-bold ml-3">bis: {moment(this.state.urlaubsantrag.bis).format('DD.MM.YYYY')}</span>
                        </div>
                        <div className="col-md-auto">
                            <span>Urlaubsantrag von:</span>
                            <span className="ml-3">
                                <a href={this.state.urlaubsantrag.mitarbeiterProfileUrl}>{this.state.urlaubsantrag.mitarbeiterName}</a>
                            </span>
                        </div>
                    </div>
                </PortalButtonBar>
                <UrlaubsantragViewWochen urlaubsantrag={this.state.urlaubsantrag} />
                <div className="card mt-3">
                    <div className="card-body">
                        <h2>Urlaubsvertretung</h2>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <td className="lead">Projektname</td>
                                    <td className="lead">Vertretung durch</td>
                                </tr>
                            </thead>
                            <tbody className="urlaubsantrag-table">
                                {this.state.urlaubsantrag.projektVertretungsDaten.map(projektVertretungsDaten => (
                                    <tr key={projektVertretungsDaten.id}>
                                        <td>{projektVertretungsDaten.label}</td>
                                        <td>
                                            <div className="row">
                                                <div className="col-10">{projektVertretungsDaten.vertretungName}</div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12  mb-3">
                                    <span className="lead">Beantragung bei</span>
                                </div>
                            </div>
                            <table className="table table-striped">
                                <tbody className="urlaubsantrag-table">
                                    {this.state.urlaubsantrag.genehmigungsDaten.map(genehmigungsDaten => (
                                        <tr key={genehmigungsDaten.value}>
                                            <td>{genehmigungsDaten.label}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-body">
                        <h2>Kommentare</h2>
                        <p>{this.state.urlaubsantrag.kommentar || 'Keine Kommentare vorhanden'}</p>
                        {this.state.isVertretung && this.state.urlaubsantrag.status === URLAUBSANTRAG_STATUS.BEANTRAGT && (
                            <div className="row">
                                <div className="col text-right">
                                    <button
                                        className="mr-2 btn btn-success"
                                        onClick={this.genehmigen}
                                        disabled={!this.state.canDecide || this.state.decideInProgress}>
                                        Genehmigen
                                    </button>
                                    <button className="btn btn-danger" onClick={this.ablehnen} disabled={!this.state.canDecide || this.state.decideInProgress}>
                                        Ablehnen
                                    </button>
                                </div>
                            </div>
                        )}
                        {urlaubsantrag.status === URLAUBSANTRAG_STATUS.GENEHMIGT && darfStornieren && (
                            <div className="row">
                                <div className="col text-right">
                                    <Modal
                                        onConfirm={this.stornieren}
                                        triggerBtnClasses={'btn btn-danger'}
                                        triggerBtnDisabled={this.state.stornoInProgress}
                                        triggerBtnText={'Stornieren'}
                                        confirmBtnClasses={'btn btn-danger'}
                                        confirmBtnText={'Stornieren'}
                                        cancelBtnText={'Abbrechen'}
                                        contents={'Willst du wirklich den Urlaubsantrag stornieren? Der Vorgang kann nicht rückgängig gemacht werden.'}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
