import React, { Component } from 'react';
import * as api from '../api';
import { gql } from 'apollo-boost';
import { Query, Mutation } from 'react-apollo';
import Header from './ProjektstundenWidget/Header';
import MonthSwitch from './ProjektstundenWidget/MonthSwitch';
import ProjektList from './ProjektstundenWidget/ProjektList';

class ProjektstundenWidget extends Component {
    state = {
        fetching: true,
        data: null,
        user: null,
        month: null,
    };

    componentDidMount() {
        this.loadMonth(this.props.user, this.props.month);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.month !== this.state.month || nextProps.user !== this.state.user || nextProps.updater !== this.state.updater) {
            this.loadMonth(nextProps.user, nextProps.month);
        }
    }

    async loadMonth(user, month) {
        this.setState({
            fetching: true,
        });

        const json = await api.getJson(`/zeiterfassung/projektstunden/${user}/${month}/`);
        this.setState({
            data: json,
            fetching: false,
            user,
            month,
        });
    }

    render() {
        const { fetching, data } = this.state;

        if (fetching && null === data) {
            return <div>Loading</div>;
        }

        return (
            <div className="calendar--widget card">
                <div className="card-body pb-2">
                    <Header data={data} fetching={fetching} />
                    <MonthSwitch data={data} />
                </div>
                <ProjektList projekte={data.projekte} />
            </div>
        );
    }
}

export default () => (
    <Query query={GET_MONTH}>
        {({ loading, error, data }) => {
            if (loading) return <div>Loading...</div>;
            if (error) return <div>Error :(</div>;

            return <ProjektstundenWidget month={data.projektstundenWidget.month} user={data.user} updater={data.updater} />;
        }}
    </Query>
);

const GET_MONTH = gql`
    query {
        user @client
        updater @client
        projektstundenWidget @client {
            month
        }
    }
`;
