import React from 'react';

export default ({
    setActiveStandortId,
    activeStandortId,
    standorte,
    doSearch,
    searchTerm,
    reset,
}) => (
    <div className="row align-items-center mb-2">
        <div className="col-auto">Standort</div>
        <div className="col">
            <select
                className="custom-select"
                onChange={e => setActiveStandortId(parseInt(e.target.value))}
                value={activeStandortId}>
                <option value={0}>Bitte wählen Sie einen Standort</option>
                {standorte.map(standort => (
                    <option key={standort.id} value={standort.id}>
                        {standort.name}
                    </option>
                ))}
            </select>
        </div>
        <div className="col">
            <input
                className="form-control"
                placeholder="Suchen Sie direkt nach einem Standort/Baubüro"
                onChange={e => doSearch(e.target.value)}
                value={searchTerm}
            />
        </div>
        <div className="col">
            <button onClick={() => reset()} className="btn btn-outline-secondary">
                Auswahl zurücksetzen
            </button>
        </div>
    </div>
);
