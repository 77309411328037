import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { wissenInhaltselementBewerten } from '../wissenApi';
import { handleFormSubmitResult } from '../../formHelpers';
import { getInhaltsTypOfBackend } from '../getInhaltsTyp';

export default ({ inhaltselement, typ, istBewertetDurchAktuelleBenutzer, classNames = '' }) => {
    const [rated, setRated] = useState(istBewertetDurchAktuelleBenutzer ? 1 : 0);

    const handleRating = async inhaltselement => {
        const result = await wissenInhaltselementBewerten(inhaltselement, getInhaltsTypOfBackend(typ), rated ? 0 : 1);
        if (handleFormSubmitResult(result)) {
            setRated(rated ? 0 : 1);
        }
    };

    return (
        <button
            title="Bewerten"
            onClick={() => handleRating(inhaltselement)}
            className={`btn btn-sm ${rated ? 'btn-primary' : 'btn-outline-primary'} ${classNames}`}>
            <FontAwesomeIcon icon="thumbs-up" />
        </button>
    );
};
