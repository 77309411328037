import React, { Component } from 'react';
import moment from 'moment';
import { formatMinsAsHrs, isMonthInSelectedPeriod } from './utils';
import Collapser from './Collapser';

export default class ProjektUebersichtMitarbeiterGroup extends Component {
    render() {
        if (this.props.projekt === undefined) {
            return <tbody />;
        }

        const props = this.props;
        const projektMitarbeiters = props.projekt.mitarbeiters;

        if (props.sortBy === props.sortOptions.NAME) {
            projektMitarbeiters.sort((a, b) => {
                const fullNameA = props.mitarbeiters[a.mId].nachname + ' ' + props.mitarbeiters[a.mId].name;
                const fullNameB = props.mitarbeiters[b.mId].nachname + ' ' + props.mitarbeiters[b.mId].name;
                return fullNameA.localeCompare(fullNameB, 'de');
            });
        } else {
            projektMitarbeiters.sort((a, b) => {
                const functionComparison = props.mitarbeiters[a.mId].funktion.localeCompare(props.mitarbeiters[b.mId].funktion, 'de');

                if (functionComparison !== 0) {
                    return functionComparison;
                }

                const fullNameA = props.mitarbeiters[a.mId].nachname + ' ' + props.mitarbeiters[a.mId].name;
                const fullNameB = props.mitarbeiters[b.mId].nachname + ' ' + props.mitarbeiters[b.mId].name;
                return fullNameA.localeCompare(fullNameB, 'de');
            });
        }

        const mitarbeiterLphTotalMinutes = {};
        const projektLphTotalMinutes = {};
        let totalProjektMinutes = 0;
        this.props.projekt.mitarbeiters.forEach(mitarbeiter => {
            const lphTotalMinutes = { sum: 0 };
            mitarbeiter.individualMonths.forEach(month => {
                if (!isMonthInSelectedPeriod(month.month, month.year, this.props.selectedDates)) {
                    return;
                }

                month.lphs.forEach(lph => {
                    if (!Object.keys(lphTotalMinutes).includes(lph.lph)) {
                        lphTotalMinutes[lph.lph] = 0;
                    }
                    if (!Object.keys(projektLphTotalMinutes).includes(lph.lph)) {
                        projektLphTotalMinutes[lph.lph] = 0;
                    }
                    lphTotalMinutes[lph.lph] += lph.totalMinutes;
                    lphTotalMinutes.sum += lph.totalMinutes;
                    projektLphTotalMinutes[lph.lph] += lph.totalMinutes;
                    totalProjektMinutes += lph.totalMinutes;
                });
            });

            mitarbeiterLphTotalMinutes[mitarbeiter.mId] = lphTotalMinutes;
        });

        return (
            <tbody>
                {projektMitarbeiters.map((mitarbeiter, i) =>
                    mitarbeiterLphTotalMinutes[mitarbeiter.mId].sum ? (
                        <tr key={`m${i}`}>
                            <td>
                                {!this.props.singleMitarbeiter && (
                                    <React.Fragment>
                                        <Collapser target={`.collapse-${i}`} classNames="pr-2" />
                                    </React.Fragment>
                                )}
                                {`${this.props.mitarbeiters[mitarbeiter.mId].nachname} ${this.props.mitarbeiters[mitarbeiter.mId].name}`}
                                <div className="small text-muted pl-3">{this.props.mitarbeiters[mitarbeiter.mId].funktion}</div>
                                {mitarbeiter.individualMonths
                                    .filter(month => isMonthInSelectedPeriod(month.month, month.year, this.props.selectedDates))
                                    .map((month, j) => (
                                        <div key={`m${i}month${j}`} className={`text-muted pl-3 collapse collapse-${i}`}>
                                            {moment(`${month.year}-${month.month}-01`, 'YYYY-M-DD').format('MMM YYYY')}
                                        </div>
                                    ))}
                            </td>
                            {mitarbeiter.allMonths.map((lph, j) => (
                                <td className="text-right" key={`m${i}lph${j}`}>
                                    {formatMinsAsHrs(mitarbeiterLphTotalMinutes[mitarbeiter.mId][lph.lph]) || '\u00A0'}
                                    <div className="small text-muted">&nbsp;</div>
                                    {mitarbeiter.individualMonths
                                        .filter(month => isMonthInSelectedPeriod(month.month, month.year, this.props.selectedDates))
                                        .map((month, k) => (
                                            <div key={`m${i}lph${j}month${k}`} className={`text-muted collapse collapse-${i}`}>
                                                {formatMinsAsHrs(month.lphs.find(monthLph => monthLph.lph === lph.lph).totalMinutes) || '\u00A0'}
                                            </div>
                                        ))}
                                </td>
                            ))}
                            <td className="text-right">
                                {formatMinsAsHrs(mitarbeiterLphTotalMinutes[mitarbeiter.mId].sum) || '\u00A0'}
                                <div className="small text-muted">&nbsp;</div>
                                {mitarbeiter.allLphs.months
                                    .filter(month => isMonthInSelectedPeriod(month.month, month.year, this.props.selectedDates))
                                    .map((month, j) => (
                                        <div key={`m${i}summonth${j}`} className={`text-muted collapse collapse-${i}`}>
                                            {formatMinsAsHrs(month.totalMinutes) || '\u00A0'}
                                        </div>
                                    ))}
                            </td>
                        </tr>
                    ) : null
                )}
                <tr>
                    <td className="text-right font-weight-bold">Σ</td>
                    {Object.keys(projektLphTotalMinutes).map((lphName, i) => (
                        <td key={`lphsums${i}`} className="text-right font-weight-bold">
                            {formatMinsAsHrs(projektLphTotalMinutes[lphName])}
                        </td>
                    ))}
                    <td className="text-right font-weight-bold">{formatMinsAsHrs(totalProjektMinutes)}</td>
                </tr>
            </tbody>
        );
    }
}
