import React from 'react';
import { Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';

export default ({ data }) => (
    <div className="text-center">
        <Mutation mutation={SET_MONTH}>
            {setMonth => (
                <button className="btn btn-link text-dark" onClick={() => setMonth({ variables: { month: data.previous } })}>
                    <i className="fas fa-chevron-left" />
                </button>
            )}
        </Mutation>

        {data.name}

        <Mutation mutation={SET_MONTH}>
            {setMonth => (
                <button className="btn btn-link text-dark" onClick={() => setMonth({ variables: { month: data.next } })}>
                    <i className="fas fa-chevron-right" />
                </button>
            )}
        </Mutation>
    </div>
);

const SET_MONTH = gql`
    mutation setMonth($month: String!) {
        setKalenderWidgetMonth(month: $month) @client
    }
`;
