import * as Sentry from '@sentry/browser';
import React from 'react';

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        if (this.state.error) {
            //render fallback UI
            return (
                <div className="alert alert-danger">
                    <h2>Es ist ein Fehler aufgetreten</h2>
                    <p>
                        ...weshalb die Komponente nicht angezeigt werden kann. Der Fehler wurde bereits gemeldet, du kannst uns aber unterstützen, indem du
                        beschreibst was genau passiert ist.
                    </p>
                    <button
                        className="btn btn-primary"
                        onClick={() =>
                            Sentry.showReportDialog({
                                lang: 'de',
                                user: { name: ActiveUser.name, email: ActiveUser.email },
                                title: 'Da haben wir wohl ein kleines Problem',
                            })
                        }>
                        Ja ich will helfen :-)
                    </button>
                </div>
            );
        } else {
            //when there's not an error, render children untouched
            return this.props.children;
        }
    }
}
