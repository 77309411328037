import React from 'react';

import {
    FIELD_AENDERUNGSDATUM,
    FIELD_BEARBEITER,
    FIELD_DRINGLICHKEIT,
    FIELD_ENDE,
    FIELD_ERSTELLDATUM,
    FIELD_ERSTELLER,
    FIELD_PROJEKT,
    FIELD_START,
    FIELD_STATUS,
    FIELD_TITEL,
    List,
} from './Lists';

const allgemeineAufgabenListConfig = {
    url: `/aufgaben/meine/ich`,
    fields: {
        [FIELD_DRINGLICHKEIT]: {
            label: '',
            sort: true,
            filter: true,
        },
        [FIELD_TITEL]: {
            sort: true,
            search: true,
        },
        [FIELD_PROJEKT]: {
            sort: true,
            search: true,
        },
        [FIELD_BEARBEITER]: {
            sort: true,
        },
        [FIELD_ERSTELLER]: {
            sort: true,
            search: true,
        },
        [FIELD_START]: {
            sort: true,
        },
        [FIELD_ENDE]: {
            sort: true,
        },
        [FIELD_ERSTELLDATUM]: {
            sort: true,
        },
        [FIELD_AENDERUNGSDATUM]: {
            sort: true,
        },
        [FIELD_STATUS]: {
            // can field be sorted
            sort: true,
            // will there be a auswahlfilter on the list
            filter: true,
            // will the values of this column be applied to search
            search: true,
        },
    },
    elementsPerPage: 50,
    toCompleteListBtn: false,
};

export default () => <List className="allgemeine-aufgabenliste" {...allgemeineAufgabenListConfig} />;
