import React from 'react';
import { handleFormSubmitResult } from '../../formHelpers';
import DokumentForm from './DokumentForm';
import { postJson } from '../../api';

export default ({ dokument, toggleModal, updateDokument, alleKnoten, alleTags }) => {
    const onSubmit = async values => {
        const result = await postJson('/wissen/dokument/aktualisieren', values);

        if (handleFormSubmitResult(result, 'Dokument erfolgreich bearbeitet.')) {
            updateDokument(result.dokument);
        }
    };

    return (
        <DokumentForm initialValues={dokument} onSubmit={values => onSubmit(values)} toggleModal={toggleModal} alleKnoten={alleKnoten} alleTags={alleTags} />
    );
};
