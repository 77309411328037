import React, { useState } from 'react';
import * as InhaltsTyp from './InhaltsTypenListItem';
import { WISSEN_INHALTSTYP_DOKUMENT, WISSEN_INHALTSTYP_FRAGE, WISSEN_INHALTSTYP_WIKI } from '../WissenConstants';
import { ASC, DESC, FIELD_BESCHREIBUNG } from '../../Aufgaben/Lists';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paginate from '../../Paginate';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

const FIELD_BEZEICHNUNG = 'bezeichnung';
const FIELD_AKTUALISIERT_AM = 'aktualisiertAm';
const FIELD_ERSTELLER = 'ersteller';
const FIELD_INHALT = 'inhalt';
const FIELD_ANTWORTEN = 'antworten';

const DOKUMENTE_FIELDS = {
    [FIELD_BEZEICHNUNG]: {
        label: 'Titel',
        sort: true,
    },
    // [FIELD_BESCHREIBUNG]: {
    //     label: 'Beschreibung',
    //     sort: false,
    // },
    // [FIELD_ERSTELLER]: {
    //     label: 'Ersteller',
    //     sort: true,
    // },
    [FIELD_AKTUALISIERT_AM]: {
        label: 'Änderungsdatum',
        sort: true,
    },
};

const FRAGE_ANTWORT_FIELDS = {
    [FIELD_BEZEICHNUNG]: {
        label: 'Titel',
        sort: true,
    },
    // [FIELD_INHALT]: {
    //     label: 'Inhalt',
    //     sort: false,
    // },
    // [FIELD_ERSTELLER]: {
    //     label: 'Ersteller',
    //     sort: true,
    // },
    [FIELD_AKTUALISIERT_AM]: {
        label: 'Änderungsdatum',
        sort: true,
    },
};

const WIKI_FIELDS = {
    [FIELD_BEZEICHNUNG]: {
        label: 'Titel',
        sort: true,
    },
    // [FIELD_INHALT]: {
    //     label: 'Inhalt',
    //     sort: false,
    // },
    // [FIELD_ERSTELLER]: {
    //     label: 'Ersteller',
    //     sort: true,
    // },
    [FIELD_AKTUALISIERT_AM]: {
        label: 'Änderungsdatum',
        sort: true,
    },
};

export default ({
    inhaltsTyp,
    inhaltsElemente,
    showFilter,
    pinInhaltselement,
    angepinnteInhaltselemente,
    unpinInhaltselement,
    isModerator,
    setFokusthemen,
    fokusthemen,
}) => {
    const [pagingData, setPagingData] = useState({ elementsPerPage: 5, activePage: 0 });
    const [activeFilter, setActiveFilter] = useState('');
    const [textFilter, setTextFilter] = useState('');
    const [filterDates, setFilterDates] = useState({ startDate: null, endDate: null });
    const [focusedInput, setFocusedInput] = useState(null);
    const [sortData, setSortData] = useState({ field: FIELD_AKTUALISIERT_AM, direction: DESC });

    const needsPaging = inhaltsElemente.length > pagingData.elementsPerPage;

    const isFilterActive = textFilter.length !== 0 || activeFilter.length !== 0 || filterDates.startDate !== null || filterDates.endDate !== null;

    const getInhaltsTypFilterItems = () => {
        switch (inhaltsTyp) {
            case WISSEN_INHALTSTYP_DOKUMENT:
                return (
                    <InhaltsElementeFilterItem
                        filter={{ set: setActiveFilter, get: activeFilter }}
                        fields={DOKUMENTE_FIELDS}
                        sortData={sortData}
                        setSortData={(field, direction) => setSortData({ field: field, direction: direction })}
                    />
                );
            case WISSEN_INHALTSTYP_FRAGE:
                return (
                    <InhaltsElementeFilterItem
                        filter={{ set: setActiveFilter, get: activeFilter }}
                        fields={FRAGE_ANTWORT_FIELDS}
                        sortData={sortData}
                        setSortData={(field, direction) => setSortData({ field: field, direction: direction })}
                    />
                );
            case WISSEN_INHALTSTYP_WIKI:
                return (
                    <InhaltsElementeFilterItem
                        filter={{ set: setActiveFilter, get: activeFilter }}
                        fields={WIKI_FIELDS}
                        sortData={sortData}
                        setSortData={(field, direction) => setSortData({ field: field, direction: direction })}
                    />
                );
        }
    };

    return (
        <React.Fragment>
            <ul className="list-unstyled list-group">
                {needsPaging && (
                    <div className="paging-container">
                        <Paginate
                            breakClassName="d-none"
                            pageRangeDisplayed={0}
                            marginPagesDisplayed={0}
                            initialPage={0}
                            forcePage={pagingData.activePage}
                            pageCount={Math.ceil(inhaltsElemente.length / pagingData.elementsPerPage)}
                            onPageChange={activePage => setPagingData({ ...pagingData, activePage: activePage.selected })}
                        />
                    </div>
                )}
                {showFilter && (
                    <li className="list-group-item bg-light">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-7">
                                    <input
                                        type="text"
                                        placeholder="Suche .."
                                        className="form-control"
                                        onChange={e => {
                                            setTextFilter(e.target.value.toLowerCase());
                                            setPagingData({ ...pagingData, activePage: 0 });
                                        }}
                                    />
                                </div>
                                <div className="col-5 d-flex justify-content-end">
                                    <DateRangePicker
                                        startDate={filterDates.startDate}
                                        endDate={filterDates.endDate}
                                        startDateId="cursor-"
                                        endDateId="your_unique_end_date_id"
                                        onDatesChange={({ startDate, endDate }) => setFilterDates({ startDate: startDate, endDate: endDate })}
                                        focusedInput={focusedInput}
                                        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                                        hideKeyboardShortcutsPanel={true}
                                        startDatePlaceholderText="Von"
                                        endDatePlaceholderText="Bis"
                                        minimumNights={0}
                                        small
                                        showClearDates
                                        monthFormat="DD.MM.YYYY"
                                        isOutsideRange={() => {}}
                                    />
                                </div>
                            </div>
                        </div>
                    </li>
                )}
            </ul>
            <div className="px-2">
                <table className="table table-responsive-xl mb-0" style={{ tableLayout: 'fixed' }}>
                    {inhaltsElemente.length !== 0 && (
                        <thead>
                            <tr>{getInhaltsTypFilterItems()}</tr>
                        </thead>
                    )}
                    <tbody>
                        {getInhaltsTypListItem(
                            inhaltsTyp,
                            inhaltsElemente,
                            textFilter,
                            filterDates,
                            sortData,
                            pagingData,
                            needsPaging,
                            isFilterActive,
                            pinInhaltselement,
                            angepinnteInhaltselemente,
                            unpinInhaltselement,
                            isModerator,
                            setFokusthemen,
                            fokusthemen
                        )}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

const sortation = (field, sortOrder) => {
    return (a, b) => {
        let result = 0;

        if (field === FIELD_ANTWORTEN) {
            result = a[field].length < b[field].length ? -1 : a[field].length > b[field].length ? 1 : 0;
            return sortOrder === ASC ? result : result * -1;
        }

        if (field === FIELD_ERSTELLER) {
            result = a[field].name.toLowerCase().localeCompare(b[field].name.toLowerCase());
            return sortOrder === ASC ? result : result * -1;
        }

        if (a[field] && b[field]) {
            result = a[field].toLowerCase().localeCompare(b[field].toLowerCase());
        }

        return sortOrder === ASC ? result : result * -1;
    };
};

const getInhaltsTypListItem = (
    inhaltsTyp,
    inhaltsElemente,
    textFilter,
    filterDates,
    sortData,
    pagingData,
    needsPaging,
    isFilterActive,
    pinInhaltselement,
    angepinnteInhaltselemente,
    unpinInhaltselement,
    isModerator,
    setFokusthemen,
    fokusthemen
) => {
    switch (inhaltsTyp) {
        case WISSEN_INHALTSTYP_DOKUMENT:
            return (
                <InhaltsTyp.Dokument
                    elemente={getInhaltsElemente(inhaltsElemente, textFilter, filterDates, sortData, pagingData, needsPaging)}
                    isFilterActive={isFilterActive}
                    pinInhaltselement={pinInhaltselement}
                    angepinnteInhaltselemente={angepinnteInhaltselemente}
                    isModerator={isModerator}
                    unpinInhaltselement={unpinInhaltselement}
                    setFokusthemen={setFokusthemen}
                    fokusthemen={fokusthemen}
                />
            );
        case WISSEN_INHALTSTYP_FRAGE:
            return (
                <InhaltsTyp.FrageAntwort
                    elemente={getInhaltsElemente(inhaltsElemente, textFilter, filterDates, sortData, pagingData, needsPaging)}
                    isFilterActive={isFilterActive}
                    pinInhaltselement={pinInhaltselement}
                    angepinnteInhaltselemente={angepinnteInhaltselemente}
                    isModerator={isModerator}
                    unpinInhaltselement={unpinInhaltselement}
                    setFokusthemen={setFokusthemen}
                    fokusthemen={fokusthemen}
                />
            );
        case WISSEN_INHALTSTYP_WIKI:
            return (
                <InhaltsTyp.Wiki
                    elemente={getInhaltsElemente(inhaltsElemente, textFilter, filterDates, sortData, pagingData, needsPaging)}
                    isFilterActive={isFilterActive}
                    pinInhaltselement={pinInhaltselement}
                    angepinnteInhaltselemente={angepinnteInhaltselemente}
                    isModerator={isModerator}
                    unpinInhaltselement={unpinInhaltselement}
                    setFokusthemen={setFokusthemen}
                    fokusthemen={fokusthemen}
                />
            );
        default:
            return <li>Inhaltstyp UKNOWN!</li>;
    }
};

const getInhaltsElemente = (inhaltsElemente, textFilter, filterDates, sortData, pagingData, needsPaging) => {
    inhaltsElemente = inhaltsElemente
        .filter(element => {
            if (textFilter.length === 0) return true;

            let bezeichnung;
            let beschreibung;
            let inhalt;

            if (element[FIELD_BESCHREIBUNG]) beschreibung = element[FIELD_BESCHREIBUNG].toLowerCase().indexOf(textFilter) >= 0;
            if (element[FIELD_INHALT]) inhalt = element[FIELD_INHALT].toLowerCase().indexOf(textFilter) >= 0;
            if (element[FIELD_BEZEICHNUNG]) bezeichnung = element[FIELD_BEZEICHNUNG].toLowerCase().indexOf(textFilter) >= 0;

            return bezeichnung || beschreibung || inhalt;
        })
        .filter(element => {
            const aktualisiertAm = moment(element.aktualisiertAm);

            if (filterDates.startDate === null && filterDates.endDate === null) {
                return true;
            } else if (filterDates.startDate !== null && filterDates.endDate !== null) {
                return aktualisiertAm.isBetween(filterDates.startDate, filterDates.endDate, 'days', '[]');
            }
            return false;
        })
        .sort(sortation(sortData.field, sortData.direction));

    // paging
    if (needsPaging) {
        return inhaltsElemente.slice(pagingData.elementsPerPage * pagingData.activePage, pagingData.elementsPerPage * (pagingData.activePage + 1));
    } else {
        return inhaltsElemente;
    }
};

const InhaltsElementeFilterItem = ({ fields, filter, setSortData, sortData }) => {
    const getCellWidth = field => {
        switch (field) {
            case FIELD_BEZEICHNUNG:
                return 'auto';
            case FIELD_BESCHREIBUNG:
                return 250;
            case FIELD_INHALT:
                return 250;
            case FIELD_ERSTELLER:
                return 150;
            case FIELD_AKTUALISIERT_AM:
                return 200;
            default:
                return '';
        }
    };
    return (
        <React.Fragment>
            {Object.keys(fields).map(field => (
                <td
                    className={`border-top-0 ${field === FIELD_AKTUALISIERT_AM ? 'text-right' : ''}`}
                    key={field}
                    style={{
                        width: getCellWidth(field),
                    }}>
                    {!fields[field].sort && <span>{fields[field].label}</span>}
                    {fields[field].sort && (
                        <React.Fragment>
                            <span className="pointer" onClick={() => setSortData(field, sortData.field === field && sortData.direction === ASC ? DESC : ASC)}>
                                {fields[field].label}
                            </span>
                            {sortData.field !== field ? (
                                <FontAwesomeIcon className="ml-1 pointer filter-icon" icon="sort" size="1x" />
                            ) : (
                                <FontAwesomeIcon
                                    className="ml-1 sort-icon"
                                    icon={sortData.field === field && sortData.direction === DESC ? 'sort-down' : 'sort-up'}
                                    size="1x"
                                />
                            )}
                        </React.Fragment>
                    )}
                    {fields[field].filter && <FontAwesomeIcon className="ml-1 filter-icon" icon="filter" size="1x" onClick={() => filter.set(field)} />}
                    {filter.get === field && <FilterPopUp field={field} />}
                </td>
            ))}
        </React.Fragment>
    );
};

const FilterPopUp = ({ field }) => {
    return (
        <div className="popup popup--filter d-flex justify-content-center flex-column">
            <span>{field}</span>
        </div>
    );
};
