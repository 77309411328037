import React from 'react';
import Select from 'react-select';

export default class UrlaubsantragProjekte extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldValidation: props.values.map(vertretungsDatum => {
                return {
                    uuid: vertretungsDatum.frontendUuid,
                    pristine: true,
                };
            }),
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const fieldValidation = this.state.fieldValidation.filter(element => {
            return nextProps.values.find(vertretungsDatum => vertretungsDatum.frontendUuid === element.uuid) !== undefined;
        });
        nextProps.values.forEach(vertretungsDatum => {
            if (fieldValidation.find(element => vertretungsDatum.frontendUuid === element.uuid) === undefined) {
                fieldValidation.push({ uuid: vertretungsDatum.frontendUuid, pristine: true });
            }
        });
        this.setState({ fieldValidation });
    }

    markNotPristine = frontendUuid => {
        this.state.fieldValidation.find(element => element.uuid === frontendUuid).pristine = false;
        this.setState({ fieldValidation: this.state.fieldValidation });
    };

    markAllNotPristine = () => {
        this.state.fieldValidation.forEach(element => {
            element.pristine = false;
        });
        this.setState({ fieldValidation: this.state.fieldValidation });
    };

    render() {
        const { values, availableProjects, availableVertretungs, addNewProject, removeProject, onProjectChange, onVertretungChange } = this.props;

        const ownProjekts = { label: 'Eigene Projekte', options: [] };
        const otherProjekts = { label: 'Andere Projekte', options: [] };
        availableProjects.forEach(projekt => {
            if (projekt.current) {
                ownProjekts.options.push(projekt);
            } else {
                otherProjekts.options.push(projekt);
            }
        });
        const filteredProjects = [ownProjekts, otherProjekts];

        return (
            <React.Fragment>
                <div className="card mt-3">
                    <div className="card-body">
                        <h2>Urlaubsvertretung</h2>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <td className="lead">
                                        <div className="row">
                                            <div className="col-6">Projektname</div>
                                            <div className="col-6">Vertretung durch</div>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody className="urlaubsantrag-table">
                                {values.map(vertretungsDatum => (
                                    <tr key={vertretungsDatum.frontendUuid}>
                                        <td>
                                            <div className="row align-items-justify">
                                                <div className="col-6">
                                                    <Select
                                                        options={filteredProjects}
                                                        value={vertretungsDatum}
                                                        onChange={value => onProjectChange(vertretungsDatum, value)}
                                                        isMulti={false}
                                                        isDisabled={this.props.disabled}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Select
                                                        options={availableVertretungs}
                                                        value={
                                                            vertretungsDatum.vertretungId
                                                                ? { value: vertretungsDatum.vertretungId, label: vertretungsDatum.vertretungName }
                                                                : null
                                                        }
                                                        onChange={value => onVertretungChange(vertretungsDatum, value)}
                                                        isMulti={false}
                                                        onBlur={() => this.markNotPristine(vertretungsDatum.frontendUuid)}
                                                        isDisabled={this.props.disabled}
                                                    />
                                                    {this.state.fieldValidation.find(element => element.uuid === vertretungsDatum.frontendUuid).pristine ||
                                                    vertretungsDatum.vertretungId ? null : (
                                                        <div className="alert-danger pl-2 mt-1">Bitte wähle deine Vertretung für dieses Projekt aus</div>
                                                    )}
                                                </div>
                                                <div className="col-auto">
                                                    {!this.props.disabled && (
                                                        <a href="#" onClick={() => removeProject(vertretungsDatum)}>
                                                            <span className="fas fa-times" />
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="text-right">
                            <button onClick={addNewProject} className="btn btn-secondary" disabled={this.props.disabled}>
                                <span className="fas fa-plus" /> Projekt hinzufugen
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
