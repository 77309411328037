import React from 'react';
import Select from 'react-select';

export default class UrlaubsantragGenehmigungsVerantwortliche extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pristine: true,
        };
    }

    markNotPristine = () => {
        this.setState({ pristine: false });
    };

    render() {
        const valid = this.state.pristine || this.props.values.length > 0;

        return (
            <div className="card mt-3">
                <div className="card-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <span className="lead">Beantragung bei</span>
                            </div>
                            <div className="col-12 mt-2">
                                <Select
                                    options={this.props.options}
                                    value={this.props.values}
                                    onChange={this.props.onChange}
                                    isMulti={true}
                                    onBlur={() => this.markNotPristine()}
                                    isDisabled={this.props.disabled}
                                />
                            </div>
                        </div>
                        {valid ? null : (
                            <div className="row">
                                <div className="col-12">
                                    <div className="alert-danger pl-2 mt-1">Der Urlaubsantrag muss bei mindestens einem Mitarbeiter beantragt werden</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
