import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KnotenEditForm from './KnotenEditForm';

export default ({ knoten, updateKnoten, benutzer, inhaltsTypen, berechtigungsgruppen }) => {
    const [modalOpen, toggleModal] = useState(false);

    return (
        <React.Fragment>
            <button className="btn btn-sm btn-outline-secondary" onClick={() => toggleModal(!modalOpen)}>
                <FontAwesomeIcon icon="edit" />
            </button>
            {modalOpen && (
                <Modal onClose={() => toggleModal(!modalOpen)} size="md" title={knoten.name} canClose={true}>
                    <KnotenEditForm
                        toggleModal={toggleModal}
                        knoten={knoten}
                        updateKnoten={updateKnoten}
                        inhaltsTypen={inhaltsTypen}
                        benutzer={benutzer}
                        berechtigungsgruppen={berechtigungsgruppen}
                    />
                </Modal>
            )}
        </React.Fragment>
    );
};
