import React from 'react';
import { postJson } from '../../api';
import WikiForm from './WikiForm';
import { handleFormSubmitResult } from '../../formHelpers';

export default ({ updateWiki, wiki, toggleModal, alleKnoten, alleTags }) => {
    const onSubmit = async values => {
        const result = await postJson('/wissen/wiki/aktualisieren', values);
        if (handleFormSubmitResult(result, 'Wiki erfolgreich bearbeitet.')) {
            updateWiki(result.wiki);
        }
    };

    return <WikiForm initialValues={wiki} onSubmit={values => onSubmit(values)} toggleModal={toggleModal} alleKnoten={alleKnoten} alleTags={alleTags} />;
};
