import React, { Component } from 'react';

import Accordeon from '../Accordeon';
import * as Filters from './filters';
import SidebarView from './SidebarView';
import StandortResultRowView from './StandortResultRowView';
import GoogleMapView from './GoogleMapView';
import FilterView from './FilterView';

export default class extends Component {
    state = {
        // state
        activeStandortId: 0, // the selected standort id
        searchTerm: '', // the currently performed search
        // data
        standorte: {},
        mitarbeiters: {},
        projekte: {},
    };

    async componentDidMount() {
        const response = await fetch(process.env.STANDORTE_MAP_WIDGET_DATA_URL);
        const json = await response.json();

        this.setState(state => ({
            ...state,
            ...json,
        }));
    }

    getStandort(id = this.state.activeStandortId) {
        if (!id) return null;
        return this.state.standorte[id];
    }

    getMitarbeiter(id) {
        if (!id) return null;
        return {
            standorte: [],
            ...this.state.mitarbeiters[id],
        };
    }

    getProjekt(id) {
        if (!id) return null;
        return {
            standorte: [],
            ...this.state.projekte[id],
        };
    }

    getStandorte = (filter = standort => true) =>
        Object.keys(this.state.standorte)
            .map(id => this.getStandort(id))
            .filter(filter);

    getMitarbeiters = (filter = () => true) =>
        Object.keys(this.state.mitarbeiters)
            .map(id => this.getMitarbeiter(id))
            .filter(filter);

    getProjekte = (filter = () => true) =>
        Object.keys(this.state.projekte)
            .map(id => this.getProjekt(id))
            .filter(filter);

    setActiveStandort = standort =>
        this.setState({ activeStandortId: standort !== null ? standort.id : 0 });

    setActiveBaubuero = standort =>
        this.setState({ activeStandortId: standort.parent, searchTerm: standort.name });

    setSearch = searchTerm => this.setState({ searchTerm: searchTerm || '' });

    getActiveStandorte = () => this.getStandorte(Filters.STANDORTE_ALL_ACTIVE(this.state));

    reset = () => this.setState({ activeStandortId: 0, activeBaubueroId: 0, searchTerm: '' });

    isSelection = () =>
        this.state.activeStandortId > 0 ||
        this.state.activeBaubueroId > 0 ||
        this.state.searchTerm.length > 0;

    render() {
        // skip when no standorte are available
        if (0 === this.getStandorte().length) {
            return null;
        }

        return (
            <div className="standort--widget">
                <FilterView
                    setActiveStandortId={id => this.setActiveStandort(this.getStandort(id))}
                    activeStandortId={this.state.activeStandortId}
                    standorte={this.getStandorte(Filters.STANDORTE_ALL_PARENTS)}
                    doSearch={searchTerm => this.setSearch(searchTerm)}
                    searchTerm={this.state.searchTerm}
                    reset={() => this.reset()}
                />
                <div className="row">
                    <div className="col-md-10">
                        <GoogleMapView
                            standorte={this.getActiveStandorte()}
                            isSelection={this.isSelection()}
                            clickStandortMarker={standort => this.setActiveStandort(standort)}
                            clickBaubueroMarker={standort => this.setActiveBaubuero(standort)}
                        />
                    </div>
                    <div className="col-md-2">
                        <SidebarView
                            isSelection={this.isSelection()}
                            activeStandort={this.getStandort()}
                        />
                    </div>
                </div>

                {this.isSelection() && (
                    <Accordeon className="mt-2">
                        {this.getActiveStandorte().map(standort => (
                            <Accordeon.Item key={standort.id} headline={standort.name}>
                                <StandortResultRowView
                                    standort={standort}
                                    projekte={this.getProjekte(
                                        Filters.PROJEKTE_ALL_FOR_STANDORT(standort)
                                    )}
                                    mitarbeiters={this.getMitarbeiters(
                                        Filters.MITARBEITERS_ALL_FOR_STANDORT(standort)
                                    )}
                                />
                            </Accordeon.Item>
                        ))}
                    </Accordeon>
                )}
            </div>
        );
    }
}
