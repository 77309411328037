import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({ inhaltselement }) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <ul className="list-unstyled list-group list-group-flush">
                        {inhaltselement.dateien.length === 0 && (
                            <li className="list-group-item">
                                <span className="lead"> Noch keine Dateien angefügt!</span>
                            </li>
                        )}
                        {inhaltselement.dateien.map(datei => (
                            <li key={datei.id} className="list-group-item d-flex align-items-center justify-content-between">
                                <span>{datei.name}</span>
                                <a className="btn btn-primary" href={`/wissen/datei/herunterladen/${datei.id}`}>
                                    Herunterladen
                                    <FontAwesomeIcon className="ml-2" icon="file" />
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
