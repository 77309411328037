// function used by .filter() to fetch all main entries from standorte
export const STANDORTE_ALL_PARENTS = standort => ['Hauptbüro', 'Standort'].includes(standort.type);

// function used by .filter() to fetch all baubüros from standorte
export const STANDORTE_ALL_CHILDS = standort => null !== standort.parent;

// function used by .filter() to fetch all standorte matching search
export const STANDORTE_ALL_ACTIVE = ({ activeStandortId, searchTerm }) => standort => {
    if (
        searchTerm.length > 0 &&
        -1 === standort.name.toLowerCase().search(searchTerm.toLowerCase())
    ) {
        return false;
    }
    if (
        activeStandortId !== 0 &&
        !(standort.id === activeStandortId || standort.parent === activeStandortId)
    ) {
        return false;
    }
    return true;
};

// function used by .filter() to fetch all projekte belonging to a standort
export const PROJEKTE_ALL_FOR_STANDORT = standort => projekt =>
    projekt.standorte.includes(standort.id);

// function used by .filter() to fetch all mitarbeiters belonging to a standort
export const MITARBEITERS_ALL_FOR_STANDORT = standort => mitarbeiter =>
    mitarbeiter.standorte.includes(standort.id);
