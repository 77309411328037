import React from 'react';
import ReactOnRails from 'react-on-rails';
import KnotenDetails from './Wissen/Knoten/KnotenDetails';
import KnotenBaum from './Wissen/Baum/KnotenBaum';
import DokumentDetails from './Wissen/Dokument/DokumentDetails';
import FrageDetails from './Wissen/FrageAntwort/FrageDetails';
import WikiDetails from './Wissen/Wiki/WikiDetails';
import TagListe from './Wissen/Tags/TagListe';

ReactOnRails.register({ KnotenDetails, KnotenBaum, DokumentDetails, WikiDetails, FrageDetails, TagListe });
