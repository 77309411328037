import numeral from '../../numeral';
import React from 'react';
import { LPH_14, LPH_57, LPH_89, LPH_PS } from '../../constants';

export default ({ projekte }) => {
    if (0 === projekte.length) {
        return (
            <div className="card-body">
                <p>Es sind noch keine Zeiten erfasst.</p>
            </div>
        );
    }

    return (
        <table className="table table-striped mb-0">
            <thead>
                <tr>
                    <th className="text-center w-25">LPH 1-4</th>
                    <th className="text-center w-25">LPH 5-7</th>
                    <th className="text-center w-25">LPH 8-9</th>
                    <th className="text-center w-25">PS</th>
                </tr>
            </thead>
            <tbody>
                {projekte.map(projekt => {
                    const minutes = [projekt.zeiten[LPH_14], projekt.zeiten[LPH_57], projekt.zeiten[LPH_89], projekt.zeiten[LPH_PS]];
                    return (
                        <tr key={projekt.id}>
                            <td colSpan={4}>
                                <small>{projekt.name}</small>
                                <div className="row">
                                    {minutes.map((hour, i) => (
                                        <div key={i} className="col text-center">
                                            {hour > 0 ? <strong>{numeral(hour / 60).format('0.00')}</strong> : <span className="text-muted">0</span>}
                                        </div>
                                    ))}
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
