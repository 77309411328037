import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import { tagAktualisieren } from '../wissenApi';
import { handleFormSubmitResult } from '../../formHelpers';
import TagDelete from './TagDelete';

export default ({ tag, updateTags }) => {
    const [modalOpen, toggleModal] = useState(false);
    const [value, setValue] = useState(tag.name);

    const handleTagUpdate = async () => {
        const result = await tagAktualisieren({ ...tag, name: value });
        if (handleFormSubmitResult(result, 'Tag erfolgreich aktualisiert')) {
            updateTags(result.alleSortedByFirstLetter);
            toggleModal(false);
        }
    };

    return (
        <React.Fragment>
            <div className="tag pointer badge badge-info p-1 m-1" onClick={() => toggleModal(!modalOpen)}>
                <span className="lead">{tag.name}</span>
            </div>
            {modalOpen && (
                <Modal
                    onClose={() => toggleModal(!modalOpen)}
                    size="md"
                    title="Tag bearbeiten"
                    canClose={true}
                    footer={
                        <div className="btn-group">
                            <div className="btn btn-outline-secondary" onClick={() => toggleModal(false)}>
                                Abbrechen
                            </div>
                            <TagDelete updateTags={updateTags} tag={tag} toggleModal={toggleModal} />
                            <div className="btn btn-primary" onClick={() => handleTagUpdate()}>
                                Speichern und Schliessen
                            </div>
                        </div>
                    }>
                    <input autoFocus={true} type="text" value={value} onChange={e => setValue(e.target.value)} className="form-control" />
                </Modal>
            )}
        </React.Fragment>
    );
};
