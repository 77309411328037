import 'isomorphic-fetch';

import './components/index';
import './components/index-serverside';

import './vendor/index';
import './jquery.dynamic-form-prototype';
import './magic';
import './mobile-navigation';
import 'react-toastify/dist/ReactToastify.min.css';
import Sortable from 'sortablejs';
global.Sortable = Sortable;
