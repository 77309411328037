import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteAction } from '../../api';
import { handleFormSubmitResult } from '../../formHelpers';

export default ({ antwort, updateFrageAfterAntwortDelete, btnColor = 'outline-secondary' }) => {
    const [modalOpen, toggleModal] = useState(false);

    const handleDelete = async () => {
        const result = deleteAction('/wissen/frage/antwort/loeschen', antwort);
        if (handleFormSubmitResult(result)) {
            updateFrageAfterAntwortDelete(antwort);
        }
    };

    return (
        <React.Fragment>
            <button className={`btn btn-sm btn-${btnColor}`} title="Antwort löschen" onClick={() => toggleModal(!modalOpen)}>
                <FontAwesomeIcon icon="times" />
            </button>
            {modalOpen && (
                <Modal
                    onClose={() => toggleModal(!modalOpen)}
                    size="sm"
                    title="Antwort löschen"
                    canClose={true}
                    footer={
                        <div className="btn-group">
                            <button
                                className="btn btn-danger"
                                onClick={() => {
                                    handleDelete();
                                    toggleModal(!modalOpen);
                                }}>
                                OK
                            </button>
                            <button className="btn btn-secondary" onClick={() => toggleModal(!modalOpen)}>
                                Abbrechen
                            </button>
                        </div>
                    }>
                    <h3>Antwort wirklich löschen ?</h3>
                </Modal>
            )}
        </React.Fragment>
    );
};
