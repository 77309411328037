import React from 'react';
import numeral from 'numeral';

export default ({
    verfuegbarUrlaubStd,
    verfuegbarUeberStd,
    aktuellerAntragUrlaubStd,
    aktuellerAntragUeberStd,
    restUrlaubStd,
    restUeberStd,
    toggleUrlaubsAntrag,
    isUrlaubsDateSet,
}) => (
    <div className="card table-responsive-xl">
        <div className="card-body">
            <h2>Antragsübersicht</h2>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <td />
                        <td className="text-right">Urlaub</td>
                        <td className="text-right">Überstunden</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Verfügbare Stunden</td>
                        <td className="text-right">{numeral(verfuegbarUrlaubStd).format('0.00')}</td>
                        <td className="text-right">{numeral(verfuegbarUeberStd).format('0.00')}</td>
                    </tr>
                    <tr>
                        <td>Aktueller Urlaubsantrag</td>
                        <td className="text-right">{numeral(aktuellerAntragUrlaubStd).format('0.00')}</td>
                        <td className="text-right">{numeral(aktuellerAntragUeberStd).format('0.00')}</td>
                    </tr>
                    <tr className="resturlaub-row">
                        <td className="font-weight-bold">Resturlaub</td>
                        <td className="text-right">{numeral(restUrlaubStd).format('0.00')}</td>
                        <td className="text-right">{numeral(restUeberStd).format('0.00')}</td>
                    </tr>
                </tbody>
            </table>
            <div className="text-right">
                <button onClick={toggleUrlaubsAntrag} className={`btn btn-secondary ${!isUrlaubsDateSet && `element-disabled`}`}>
                    Urlaubsantrag bearbeiten
                </button>
            </div>
        </div>
    </div>
);
