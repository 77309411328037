import React, { Component } from 'react';
import MonthPicker from './MonthPicker';
import 'react-month-picker/css/month-picker.css';
import Select from 'react-select';
import { PdfButton, XlsxButton } from './Buttons';

export default class ProjektUebersichtFilter extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row align-items-center">
                    <div className="col-xl-2">
                        <div className="align-items-center row">
                            <label className="col-xl-3 col-form-label">Start</label>
                            <div className="col-xl-7">
                                <MonthPicker
                                    value={this.props.startDate}
                                    onChange={this.props.onStartDateChange}
                                    minValue={this.props.minDate}
                                    maxValue={this.props.maxDate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2">
                        <div className="align-items-center row">
                            <label className="col-xl-3 col-form-label">Ende</label>
                            <div className="col-xl-7">
                                <MonthPicker
                                    value={this.props.endDate}
                                    onChange={this.props.onEndDateChange}
                                    minValue={this.props.minDate}
                                    maxValue={this.props.maxDate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5">
                        <div className="form-group mb-0 row">
                            <label className="col-xl-2 text-xl-center text-lg-left col-form-label">Projekt</label>
                            <div className="col-xl-10">
                                <Select
                                    options={this.props.projekts}
                                    value={this.props.projekts.find(projekt => projekt.value === this.props.selectedProjektId)}
                                    onChange={this.props.onProjektChange}
                                    isMulti={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end col-xl-3 text-right ml-auto mt-3 mt-lg-3 mt-xl-0">
                        <div className="pr-1">
                            <PdfButton onClick={this.props.onPdfButtonClick} />
                            <XlsxButton onClick={this.props.onXlsxButtonClick} />
                        </div>
                        {!this.props.singleMitarbeiter && (
                            <React.Fragment>
                                <div className="pr-1">
                                    <button
                                        title="nach Mitarbeiter gruppieren"
                                        className={`btn btn-secondary ${this.props.groupBy === this.props.groupOptions.MITARBEITER ? 'active' : ''}`}
                                        onClick={() => this.props.onGroupByChange(this.props.groupOptions.MITARBEITER)}
                                        type="button">
                                        <span className="fa fa-lg fa-id-badge" />
                                    </button>
                                    <button
                                        title="nach Monat gruppieren"
                                        className={`btn btn-secondary ${this.props.groupBy === this.props.groupOptions.MONTH ? 'active' : ''}`}
                                        onClick={() => this.props.onGroupByChange(this.props.groupOptions.MONTH)}
                                        type="button">
                                        <span className="fa fa-lg fa-calendar" />
                                    </button>
                                </div>
                                <div className="pr-1">
                                    <button
                                        title="nach Mitarbeitername sortieren"
                                        className={`btn btn-secondary ${this.props.sortBy === this.props.sortOptions.NAME ? 'active' : ''}`}
                                        onClick={() => this.props.onSortByChange(this.props.sortOptions.NAME)}
                                        type="button">
                                        <span className="fa fa-lg fa-sort-alpha-down" />
                                    </button>
                                    <button
                                        title="nach Mitarbeiterfunktion sortieren"
                                        className={`btn btn-secondary ${this.props.sortBy === this.props.sortOptions.FUNCTION ? 'active' : ''}`}
                                        onClick={() => this.props.onSortByChange(this.props.sortOptions.FUNCTION)}
                                        type="button">
                                        <span className="fa fa-lg fa-tag" />
                                    </button>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
