import React, { Component } from 'react';
import * as api from '../api';
import moment from 'moment';
import Filter from './StundenUebersichtWidget/MonatUebersichtFilter';
import SheetContainer from './StundenUebersichtWidget/MonatUebersichtSheetContainer';
import PortalButtonBar from '../PortalButtonBar';

moment.locale('de');

/**
 * Monthpicker date object
 * @typedef {Object} MonthpickerDate
 * @property {number} year
 * @property {number} month
 */

/**
 * Mitarbeiter dropdown object
 * @typedef {Object} Mitarbeiter
 * @property {number} value
 * @property {string} label
 */

export default class MonatStundenUebersichtWidget extends Component {
    state = {
        dataLoaded: false,
        uebersicht: {},
        /**
         * @type {MonthpickerDate}
         */
        selectedStartDate: {
            year: parseInt(moment().format('YYYY'), 10),
            month: parseInt(moment().format('M'), 10),
        },
        /**
         * @type {MonthpickerDate}
         */
        selectedEndDate: {
            year: parseInt(moment().format('YYYY'), 10),
            month: parseInt(moment().format('M'), 10),
        },
        /**
         * @type {Mitarbeiter[]}
         */
        selectedMitarbeiters: [],
        /**
         * @type {Mitarbeiter[]}
         */
        mitarbeiters: [],
    };

    async componentDidMount() {
        const json = await api.getJson(`/zeiterfassung/stundenuebersicht/monatstunden/init`);

        const newState = {
            ...json,
            dataLoaded: true,
        };

        this.setState(newState);
    }

    /**
     *
     * @param {MonthpickerDate} startDate
     * @param {MonthpickerDate} endDate
     * @param {Mitarbeiter[]} selectedMitarbeiters
     * @return {Promise<void>}
     */
    updateData = async (startDate, endDate, selectedMitarbeiters) => {
        const start = moment(`${startDate.year}-${startDate.month}-01`, 'YYYY-M-DD').format('YYYY-MM-DD');
        const end = moment(`${endDate.year}-${endDate.month}-01`, 'YYYY-M-DD')
            .endOf('month')
            .format('YYYY-MM-DD');

        const json = await api.getJson(`/zeiterfassung/stundenuebersicht/monatstunden/data/${start}/${end}`, {
            mId: selectedMitarbeiters.map(mitarbeiter => mitarbeiter.value),
        });

        const newState = {
            ...json,
            selectedStartDate: startDate,
            selectedEndDate: endDate,
        };

        this.setState(newState);
    };

    /**
     * @param {number} year
     * @param {number} month
     */
    onStartDateChange = (year, month) => {
        if (
            moment(`${year}-${month}-01`, 'YYYY-M-DD').isAfter(moment(`${this.state.selectedEndDate.year}-${this.state.selectedEndDate.month}-01`, 'YYYY-M-DD'))
        ) {
            return;
        }

        // we need to update state before requesting a data update otherwise the monthpicker will close only after the request resolves
        const selectedStartDate = { year, month };
        this.setState({ selectedStartDate });

        this.updateData(selectedStartDate, this.state.selectedEndDate, this.state.selectedMitarbeiters);
    };

    /**
     * @param {number} year
     * @param {number} month
     */
    onEndDateChange = (year, month) => {
        if (
            moment(`${year}-${month}-01`, 'YYYY-M-DD').isBefore(
                moment(`${this.state.selectedStartDate.year}-${this.state.selectedStartDate.month}-01`, 'YYYY-M-DD')
            )
        ) {
            return;
        }

        // we need to update state before requesting a data update otherwise the monthpicker will close only after the request resolves
        const selectedEndDate = { year, month };
        this.setState({ selectedEndDate });

        this.updateData(this.state.selectedStartDate, selectedEndDate, this.state.selectedMitarbeiters);
    };

    onMitarbeiterChange = selectedMitarbeiters => {
        this.setState({ selectedMitarbeiters });
        this.updateData(this.state.selectedStartDate, this.state.selectedEndDate, selectedMitarbeiters);
    };

    makePdf = () => {
        this.makeFile('pdf');
    };

    makeFile = format => {
        const start = moment(`${this.state.selectedStartDate.year}-${this.state.selectedStartDate.month}-01`, 'YYYY-M-DD').format('YYYY-MM-DD');
        const end = moment(`${this.state.selectedEndDate.year}-${this.state.selectedEndDate.month}-01`, 'YYYY-M-DD')
            .endOf('month')
            .format('YYYY-MM-DD');
        const mIds = this.state.selectedMitarbeiters.map((mitarbeiter, index) => `mId[${index}]=${mitarbeiter.value}`).join('&');

        const url = `/zeiterfassung/stundenuebersicht/monatstunden/file/${start}/${end}.${format}?${mIds}`;
        if (format === 'pdf') {
            window.open(url, '_blank');
        } else {
            location = url;
        }
    };

    makeXlsx = () => {
        this.makeFile('xlsx');
    };

    render() {
        if (!this.state.dataLoaded) {
            return <div>Loading</div>;
        }

        return (
            <React.Fragment>
                <PortalButtonBar>
                    <Filter
                        mitarbeiters={this.state.mitarbeiters}
                        selectedMitarbeiters={this.state.selectedMitarbeiters}
                        onMitarbeiterChange={this.onMitarbeiterChange}
                        startDate={this.state.selectedStartDate}
                        endDate={this.state.selectedEndDate}
                        onStartDateChange={this.onStartDateChange}
                        onEndDateChange={this.onEndDateChange}
                        onPdfButtonClick={this.makePdf}
                        onXlsxButtonClick={this.makeXlsx}
                    />
                </PortalButtonBar>
                <SheetContainer sheets={this.state.monthSheets} />
            </React.Fragment>
        );
    }
}
