import React, { Component } from 'react';
import _ from 'lodash';
import Sheet from './MonatUebersichtSheet';

export default class MonatUebersichtSheetContainer extends Component {
    state = {
        selectedMid: null,
        selectedMonth: null,
    };

    componentDidMount() {
        this.onPropsChange(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.onPropsChange(nextProps);
    }

    onPropsChange = props => {
        if (props.sheets.length > 0) {
            this.setState({
                selectedMid: props.sheets[0].mitarbeiter.id,
                selectedMonth: { month: props.sheets[0].month, year: props.sheets[0].year },
            });
        }
    };

    onMitarbeiterChange = mId => {
        this.setState({ selectedMid: mId, selectedMonth: this.getMonthsForMitarbeiter(mId)[0] });
    };

    onMonthChange = month => {
        this.setState({
            selectedMonth: { month: month.month, year: month.year },
        });
    };

    getMitarbeiters = () => {
        return _.uniqBy(
            this.props.sheets
                .map(sheet => ({
                    id: sheet.mitarbeiter.id,
                    name: sheet.mitarbeiter.name,
                }))
                .sort((a, b) => a.name.localeCompare(b.name, 'de')),
            mitarbeiter => mitarbeiter.id
        );
    };

    getMonthsForMitarbeiter = mId => {
        if (mId === null) {
            return [];
        }

        return this.props.sheets
            .filter(sheet => sheet.mitarbeiter.id === mId)
            .map(sheet => ({
                name: sheet.monthName,
                month: sheet.month,
                year: sheet.year,
            }));
    };

    getActiveSheet = () => {
        return this.props.sheets.filter(
            sheet =>
                sheet.mitarbeiter.id === this.state.selectedMid &&
                sheet.month === this.state.selectedMonth.month &&
                sheet.year === this.state.selectedMonth.year
        )[0];
    };

    render() {
        if (this.props.sheets.length === 0) {
            return null;
        }

        const mitarbeiters = this.getMitarbeiters();
        const months = this.getMonthsForMitarbeiter(this.state.selectedMid);
        const activeSheet = this.getActiveSheet();
        const state = this.state;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-auto pr-0">
                        <ul className="nav nav-tabs nav-tabs-uebersicht">
                            {mitarbeiters.map(mitarbeiter => (
                                <li className="nav-item" key={mitarbeiter.id}>
                                    <a
                                        className={`nav-link ${state.selectedMid === mitarbeiter.id ? 'active' : ''}`}
                                        href="#"
                                        onClick={() => this.onMitarbeiterChange(mitarbeiter.id)}>
                                        {mitarbeiter.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col" style={{ 'border-bottom': '1px solid #dee2e6' }} />
                    <div className="col-auto pl-0">
                        <ul className="nav nav-tabs nav-tabs-uebersicht">
                            {months.map(month => (
                                <li className="nav-item" key={`${month.month}-${month.year}`}>
                                    <a
                                        className={`nav-link ${
                                            state.selectedMonth.month === month.month && state.selectedMonth.year === month.year ? 'active' : ''
                                        }`}
                                        href="#"
                                        onClick={() => this.onMonthChange(month)}>
                                        {`${month.name} ${month.year}`}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <Sheet sheet={activeSheet} />
            </React.Fragment>
        );
    }
}
