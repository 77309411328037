import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InhaltsElementAnpinnen from './InhaltsElementAnpinnen';
import InhaltsElementAlsFokusthema from './InhaltsElementAlsFokusthema';
import { WISSEN_INHALTSTYP_DOKUMENT, WISSEN_INHALTSTYP_FRAGE, WISSEN_INHALTSTYP_WIKI } from '../WissenConstants';
import ReactMarkdown from 'react-markdown';

export const Dokument = ({
    elemente,
    isFilterActive,
    pinInhaltselement,
    angepinnteInhaltselemente,
    isModerator,
    unpinInhaltselement,
    fokusthemen,
    setFokusthemen,
}) => {
    return (
        <React.Fragment>
            {elemente.length === 0 && <NoInhaltsElementFound isFilterActive={isFilterActive} />}
            {elemente.map(element => (
                <tr className="inhalts-element-link-li" key={element.id}>
                    <td>
                        <a href={`/wissen/${element.id}/details`} className="inhalts-element-link">
                            <span className="inhalts-element-inhalt">{element.bezeichnung}</span>
                        </a>
                    </td>
                    {/*<td>*/}
                    {/*    <span className="inhalts-element-inhalt">*/}
                    {/*        <ReactMarkdown source={element.beschreibung} className="markdown" />*/}
                    {/*    </span>*/}
                    {/*</td>*/}
                    {/*<td>*/}
                    {/*    <span className={`inhalts-element-inhalt ${element.ersteller.aktiv ? '' : 'text-muted'}`}>{element.ersteller.name}</span>*/}
                    {/*</td>*/}
                    <td>
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="btn-group">
                                {isModerator && (
                                    <React.Fragment>
                                        <InhaltsElementAnpinnen
                                            typ={WISSEN_INHALTSTYP_DOKUMENT}
                                            inhaltsElement={element}
                                            unpinInhaltselement={unpinInhaltselement}
                                            pinInhaltselement={pinInhaltselement}
                                            istElementAngepinnt={istElementAngepinnt(element, angepinnteInhaltselemente)}
                                        />
                                        <InhaltsElementAlsFokusthema
                                            typ={WISSEN_INHALTSTYP_DOKUMENT}
                                            inhaltsElement={element}
                                            fokusthemen={fokusthemen}
                                            setFokusthemen={setFokusthemen}
                                        />
                                    </React.Fragment>
                                )}
                                <a className="btn btn-sm btn-outline-primary mr-2" href={`/wissen/datei/herunterladen/${element.datei.id}`}>
                                    <FontAwesomeIcon icon="file" />
                                </a>
                            </div>
                            <span className="text-right">{moment(element.aktualisiertAm).format('DD.MM.YYYY, HH:mm')}</span>
                        </div>
                    </td>
                </tr>
            ))}
        </React.Fragment>
    );
};

export const FrageAntwort = ({
    elemente,
    isFilterActive,
    pinInhaltselement,
    angepinnteInhaltselemente,
    isModerator,
    unpinInhaltselement,
    fokusthemen,
    setFokusthemen,
}) => (
    <React.Fragment>
        {elemente.length === 0 && <NoInhaltsElementFound isFilterActive={isFilterActive} />}
        {elemente.map(element => (
            <tr className="inhalts-element-link-li" key={element.id}>
                <td>
                    <a href={`/wissen/${element.id}/details`} className="inhalts-element-link">
                        <span className="d-flex">
                            <span className="inhalts-element-inhalt">{element.bezeichnung}</span>
                            <span className="badge badge-pill badge-success ml-2 d-flex align-items-center justify-content-center">
                                <span>{element.antworten.length}</span>
                            </span>
                            {istFrageRichtigBeantwortet(element.antworten) && (
                                <FontAwesomeIcon className="ml-2" icon="check" color="green" size="lg" title="Frage wurde richtig Beantwortet" />
                            )}
                        </span>
                    </a>
                </td>
                {/*<td>*/}
                {/*    <span className="inhalts-element-inhalt">*/}
                {/*        <ReactMarkdown source={element.inhalt} className="markdown" />*/}
                {/*    </span>*/}
                {/*</td>*/}
                {/*<td>*/}
                {/*    <span className={`inhalts-element-inhalt ${element.ersteller.aktiv ? '' : 'text-muted'}`}>{element.ersteller.name}</span>*/}
                {/*</td>*/}
                <td>
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="btn-group">
                            {isModerator && (
                                <React.Fragment>
                                    <InhaltsElementAnpinnen
                                        typ={WISSEN_INHALTSTYP_FRAGE}
                                        inhaltsElement={element}
                                        pinInhaltselement={pinInhaltselement}
                                        istElementAngepinnt={istElementAngepinnt(element, angepinnteInhaltselemente)}
                                        unpinInhaltselement={unpinInhaltselement}
                                    />
                                    <InhaltsElementAlsFokusthema
                                        typ={WISSEN_INHALTSTYP_FRAGE}
                                        inhaltsElement={element}
                                        fokusthemen={fokusthemen}
                                        setFokusthemen={setFokusthemen}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                        <span className="ml-2">{moment(element.aktualisiertAm).format('DD.MM.YYYY, HH:mm')}</span>
                    </div>
                </td>
            </tr>
        ))}
    </React.Fragment>
);

export const Wiki = ({
    elemente,
    isFilterActive,
    pinInhaltselement,
    angepinnteInhaltselemente,
    isModerator,
    unpinInhaltselement,
    fokusthemen,
    setFokusthemen,
}) => (
    <React.Fragment>
        {elemente.length === 0 && <NoInhaltsElementFound isFilterActive={isFilterActive} />}
        {elemente.map(element => (
            <tr className="inhalts-element-link-li" key={element.id}>
                <td>
                    <a href={`/wissen/${element.id}/details`} className="inhalts-element-link">
                        <span className="inhalts-element-inhalt">{element.bezeichnung}</span>
                    </a>
                </td>
                {/*<td>*/}
                {/*    <span className="inhalts-element-inhalt">*/}
                {/*        <ReactMarkdown source={element.inhalt} className="markdown" />*/}
                {/*    </span>*/}
                {/*</td>*/}
                {/*<td>*/}
                {/*    <span className={`inhalts-element-inhalt ${element.ersteller.aktiv ? '' : 'text-muted'}`}>{element.ersteller.name}</span>*/}
                {/*</td>*/}
                <td>
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="btn-group">
                            {isModerator && (
                                <React.Fragment>
                                    <InhaltsElementAnpinnen
                                        typ={WISSEN_INHALTSTYP_WIKI}
                                        inhaltsElement={element}
                                        pinInhaltselement={pinInhaltselement}
                                        istElementAngepinnt={istElementAngepinnt(element, angepinnteInhaltselemente)}
                                        unpinInhaltselement={unpinInhaltselement}
                                    />
                                    <InhaltsElementAlsFokusthema
                                        typ={WISSEN_INHALTSTYP_WIKI}
                                        inhaltsElement={element}
                                        fokusthemen={fokusthemen}
                                        setFokusthemen={setFokusthemen}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                        <span className="ml-2">{moment(element.aktualisiertAm).format('DD.MM.YYYY, HH:mm')}</span>
                    </div>
                </td>
            </tr>
        ))}
    </React.Fragment>
);

const NoInhaltsElementFound = ({ isFilterActive }) => (
    <tr>
        <td className="border-top-0">
            {isFilterActive ? (
                <span>Kein Element entspricht den Filterkriterien!</span>
            ) : (
                <span className="text-muted">Hier gibt es noch keine Inhalte – Kannst Du uns helfen, unsere Wissensdatenbank zu füllen?</span>
            )}
        </td>
    </tr>
);

export const istElementAngepinnt = (element, angepinnteInhaltselemente) =>
    angepinnteInhaltselemente.find(angepinntesElement => angepinntesElement.id === element.id) || false;

const istFrageRichtigBeantwortet = antworten => antworten.find(antwort => antwort.alsRichtigGekennzeichnet) || false;
