import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleFormSubmitResult } from '../../formHelpers';
import { wissenKommentarLoeschen } from '../wissenApi';

export default ({ kommentar, btnColor = 'danger', deleteKommentarInInhaltselement }) => {
    const [modalOpen, toggleModal] = useState(false);

    const deleteKommentar = async () => {
        const result = wissenKommentarLoeschen(kommentar);
        if (handleFormSubmitResult(result)) {
            deleteKommentarInInhaltselement(kommentar);
        }
    };

    return (
        <React.Fragment>
            <button className={`btn btn-sm btn-${btnColor}`} title="Antwort löschen" onClick={() => toggleModal(!modalOpen)}>
                <FontAwesomeIcon icon="trash" />
            </button>
            {modalOpen && (
                <Modal
                    onClose={() => toggleModal(!modalOpen)}
                    size="md"
                    title="Kommentar löschen"
                    canClose={true}
                    footer={
                        <div className="btn-group">
                            <button
                                className="btn btn-danger"
                                onClick={() => {
                                    deleteKommentar();
                                    toggleModal(!modalOpen);
                                }}>
                                OK
                            </button>
                            <button className="btn btn-secondary" onClick={() => toggleModal(!modalOpen)}>
                                Abbrechen
                            </button>
                        </div>
                    }>
                    <h3>Kommentar wirklich löschen ?</h3>
                </Modal>
            )}
        </React.Fragment>
    );
};
