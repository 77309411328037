import React from 'react';
import * as api from '../api';
import UrlaubsantragZeitraum from './Widget/UrlaubsantragZeitraum';

/**
 * @typedef {Object} Urlaubsantrag
 * @property {string} id
 * @property {string} von - in YYYY-MM-DD format
 * @property {string} bis - in YYYY-MM-DD format
 * @property {string} mitarbeiter
 * @property {Projekt[]} projekte
 */

/**
 * @typedef {Object} Projekt
 * @property {int} id
 * @property {string} label
 */

export default class UrlaubsantragVertretungWidget extends React.Component {
    /**
     * @type {{fetching: boolean, urlaubsantrags: Urlaubsantrag[]}}
     */
    state = {
        fetching: true,
        urlaubsantrags: [],
    };

    componentDidMount() {
        this.loadUrlaubsantragList();
    }

    loadUrlaubsantragList = async () => {
        const json = await api.getJson(`/urlaub/urlaubsantrag/list-vertretung-data`);
        this.setState({
            fetching: false,
            urlaubsantrags: json.urlaubsantrags,
        });
    };

    render() {
        const { fetching } = this.state;

        if (fetching) {
            return (
                <div>
                    <span>Loading</span>
                </div>
            );
        }

        if (this.state.urlaubsantrags.length === 0) {
            return null;
        }

        return (
            <div className="alert alert-info">
                folgende Urlaubsvertretungen betreffen dich:
                <br />
                {this.state.urlaubsantrags.map(urlaubsantrag => (
                    <React.Fragment key={urlaubsantrag.id}>
                        <span>
                            <UrlaubsantragZeitraum urlaubsantrag={urlaubsantrag} /> für {urlaubsantrag.mitarbeiter} bei{' '}
                            {urlaubsantrag.projekte.map(projekt => projekt.label).join(', ')}
                        </span>
                        <br />
                    </React.Fragment>
                ))}
            </div>
        );
    }
}
