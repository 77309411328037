import React from 'react';

export default ({ data, fetching }) => (
    <div className="row">
        <div className="col-auto" style={{ width: 40 }}>
            <img src="/images/icons/Zeiterfassung_meine_Projektstunden.svg" className="img-fluid" />
        </div>
        <div className="col">
            <h2>Meine Projektstunden</h2>
        </div>
        <div className="col-auto text-right">
            <div className={`${!fetching && 'd-none'}`}>
                <i className="fas fa-2x fa-spinner fa-pulse" />
            </div>
        </div>
    </div>
);
