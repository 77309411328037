import React, { useState } from 'react';
import fixBase64 from './fixBase64';

export default ({ initialValues = null, setDatei, fileRequired = false }) => {
    const [fileData, setFileData] = useState(initialValues ? initialValues.datei : null);

    const handleFileChange = event => {
        const reader = new FileReader();
        const file = event.target.files[0];
        reader.readAsDataURL(file);
        setFileData(file);
        reader.onload = async () => {
            setDatei({
                id: initialValues ? (file.name !== initialValues.datei.name ? null : initialValues.datei.id) : null,
                inhalt: reader.result ? fixBase64(reader.result) : null,
                name: file ? file.name : initialValues ? initialValues.datei.name : '',
            });
        };
    };

    return (
        <div className="d-flex align-items-baseline">
            <div className="upload-button">
                <input type="file" onChange={e => handleFileChange(e)} />
            </div>
            {fileData && <span className="ml-2 text-muted">{fileData.name}</span>}
            {!fileData && fileRequired && <span className="font-weight-bold text-danger">Bitte eine Datei auswählen</span>}
        </div>
    );
};
