import React from 'react';
import Select from 'react-select';
import toast from './toastify';
import ReactMde from 'react-mde';

export const InputField = ({ label, className = '', children, note = '', tags = false }) => (
    <div className={`${className} col-12`}>
        <label>{label}</label>
        <span className="text-muted ml-2 small">{note}</span>
        <div className={`form-group input-field-children ${tags ? 'tags' : ''}`}>{children}</div>
    </div>
);

export const transformAlleKnotenForReactSelect = alleKnoten => alleKnoten.map(knoten => ({ label: knoten.name, value: knoten.id }));

export const findKnotenById = (alleKnoten, knotenId) => alleKnoten.find(knoten => knoten.id === knotenId);

export const ReactSelectAdapter = ({ input, ...rest }) => <Select {...input} {...rest} searchable />;

export const ReactMdeAdapter = ({ input, ...rest }) => <ReactMde {...input} {...rest} toolbarCommands={[['bold', 'italic', 'header']]} />;

export const transformItemsWithIdAndNameForReactSelect = items => items.map(item => ({ ...item, value: item.id, label: item.name }));

export const transformItemsWithIdAndNameForBackend = items => items.map(item => ({ id: item.value, name: item.label }));

export const required = value => (value ? undefined : 'Pflichtfeld!');

export const dispatchCustomEvent = (eventName = '', params = null) => {
    const event = new CustomEvent(eventName, { detail: params });
    window.dispatchEvent(event);
};

export const handleFormSubmitResult = (result, successMessage = '') => {
    if (result.error) {
        toast.error(result.error);
        return false;
    } else if (result.statusCode && result.statusCode !== 200) {
        toast.error('Es ist ein unerwarteter Fehler aufgetreten');
        return false;
    } else {
        if (successMessage.length !== 0) {
            toast.success(successMessage);
        }
        return true;
    }
};
