import React, { useState } from 'react';
import DokumentEdit from './DokumentEdit';
import { CardTemplate } from '../ViewTemplates';
import moment from 'moment';
import Kommentar from '../Kommentar/Kommentar';
import Avatar from '../../Aufgaben/Aufgabe/Avatar';
import PlaceholderPic from '../../../../../../application/public/images/dashboard/Profilbild_geschlechtsneutral.svg';
import KommentarAnlegen from '../Kommentar/KommentarAnlegen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InhaltsElementDelete from '../InhaltsElemente/InhaltsElementDelete';
import InhaltsElementAlsFokusthema from '../InhaltsElemente/InhaltsElementAlsFokusthema';
import InhaltsElementAnpinnen, { pinInhaltselementOnDetailPage, unPinInhaltselementOnDetailPage } from '../InhaltsElemente/InhaltsElementAnpinnen';
import { WISSEN_INHALTSTYP_DOKUMENT, WISSEN_INHALTSTYP_DOKUMENT_URL } from '../WissenConstants';
import { istElementAngepinnt } from '../InhaltsElemente/InhaltsTypenListItem';
import { deleteKommentarInInhaltselement, updateKommentarInInhaltselement } from '../Kommentar/updateKommentarInInhaltselement';
import { getInhaltsTypOfBackend } from '../getInhaltsTyp';
import InhaltsElementBewerten from '../InhaltsElemente/InhaltsElementBewerten';
import { deleteAllowed, editAllowed, pinAndFokusthemaSetzenAllowed } from '../InhaltselementActionPermissions';
import TagDetails from '../Tags/TagDetails';
import ReactMarkdown from 'react-markdown';

export default initialProps => {
    const [dokument, setDokument] = useState(initialProps.dokument);
    const [fokusthemen, setFokusthemen] = useState([initialProps.dokument]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body d-flex justify-content-end">
                            <div className="btn-group">
                                {editAllowed(initialProps.benutzerIstAdmin, initialProps.benutzerIstErstellerVonInhaltsAggregat) && (
                                    <DokumentEdit
                                        dokument={dokument}
                                        updateDokument={dokument => setDokument(dokument)}
                                        alleKnoten={initialProps.alleKnoten}
                                        alleTags={initialProps.alleTags}
                                    />
                                )}
                                <KommentarAnlegen
                                    inhaltselement={dokument}
                                    inhaltselementTyp={getInhaltsTypOfBackend(WISSEN_INHALTSTYP_DOKUMENT)}
                                    addKommentarToInhaltselement={kommentar => setDokument({ ...dokument, kommentare: [...dokument.kommentare, kommentar] })}
                                />
                                {pinAndFokusthemaSetzenAllowed(initialProps.benutzerIstAdmin, initialProps.benutzerIstModerator) && (
                                    <React.Fragment>
                                        <InhaltsElementAlsFokusthema
                                            inhaltsElement={dokument}
                                            fokusthemen={fokusthemen}
                                            setFokusthemen={setFokusthemen}
                                            typ={WISSEN_INHALTSTYP_DOKUMENT}
                                        />
                                        <InhaltsElementAnpinnen
                                            typ={WISSEN_INHALTSTYP_DOKUMENT}
                                            inhaltsElement={{ ...dokument, knotenId: dokument.knoten.id }}
                                            pinInhaltselement={element => pinInhaltselementOnDetailPage(element, setDokument)}
                                            unpinInhaltselement={element => unPinInhaltselementOnDetailPage(element, setDokument)}
                                            istElementAngepinnt={istElementAngepinnt(dokument, dokument.knoten.angepinnteInhaltselemente)}
                                        />
                                    </React.Fragment>
                                )}
                                <InhaltsElementBewerten
                                    inhaltselement={dokument}
                                    typ={WISSEN_INHALTSTYP_DOKUMENT}
                                    istBewertetDurchAktuelleBenutzer={dokument.istBewertetDurchAktuelleBenutzer}
                                />
                                {deleteAllowed(
                                    initialProps.benutzerIstAdmin,
                                    initialProps.benutzerIstModerator,
                                    initialProps.benutzerIstErstellerVonInhaltsAggregat
                                ) && <InhaltsElementDelete inhaltsElement={dokument} type={WISSEN_INHALTSTYP_DOKUMENT_URL} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-xl-5 col-lg-12">
                    <CardTemplate headingClass="bg-light border-bottom border-primary" heading={dokument.bezeichnung}>
                        <ul className="list-group list-unstyled list-group-flush">
                            <li className="list-group-item d-flex align-items-center">
                                <span className="knoten-detail-type font-weight-bold">Aktualisiert am:</span>
                                <span> {moment(dokument.aktualisiertAm).format('DD.MM.YYYY, hh:mm')}</span>
                            </li>
                            <li className="list-group-item d-flex align-items-center">
                                <span className="knoten-detail-type font-weight-bold">Ersteller:</span>
                                {dokument.ersteller.aktiv && (
                                    <Avatar name={dokument.ersteller.name} url={`/mitarbeiter/${dokument.ersteller.internId}/detail`} avatar={PlaceholderPic} />
                                )}
                                {!dokument.ersteller.aktiv && <span className="text-muted">{dokument.ersteller.name}</span>}
                            </li>
                            <li className="list-group-item d-flex align-items-center">
                                <span className="knoten-detail-type font-weight-bold">Knoten:</span>
                                <span> {dokument.knoten.name}</span>
                            </li>
                            <TagDetails tags={dokument.tags} />
                            <li className="list-group-item d-flex flex-column">
                                <span className="font-weight-bold">Beschreibung:</span>
                                <ReactMarkdown escapeHtml={false} source={dokument.beschreibung} />
                            </li>
                        </ul>
                    </CardTemplate>
                </div>
                <div className="col-xl-7 col-lg-12">
                    {dokument.kommentare.length !== 0 && (
                        <CardTemplate headingClass="bg-light border-bottom border-primary" heading="Kommentare" classNames="mb-3">
                            <ul className="list-group list-group-flush list-unstyled">
                                {dokument.kommentare.map(kommentar => (
                                    <Kommentar
                                        key={kommentar.id}
                                        kommentar={kommentar}
                                        updateKommentarInInhaltselement={updatedKommentar =>
                                            updateKommentarInInhaltselement(updatedKommentar, dokument, setDokument)
                                        }
                                        deleteKommentarInInhaltselement={deletedKommentar =>
                                            deleteKommentarInInhaltselement(deletedKommentar, dokument, setDokument)
                                        }
                                    />
                                ))}
                            </ul>
                        </CardTemplate>
                    )}
                    <CardTemplate headingClass="bg-light border-bottom border-primary mt-md-3" heading="Angefügte Datei">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="list-unstyled list-group list-group-flush">
                                        <li className="list-group-item d-flex align-items-center justify-content-between">
                                            <span>{dokument.datei.name}</span>
                                            <a className="btn btn-primary" href={`/wissen/datei/herunterladen/${dokument.datei.id}`}>
                                                Herunterladen
                                                <FontAwesomeIcon className="ml-2" icon="file" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </CardTemplate>
                </div>
            </div>
        </div>
    );
};
