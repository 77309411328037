import React, { Component } from 'react';
import moment from 'moment';
import numeral from 'numeral';

moment.locale('de');

export default class StudentstundenUebersichtSheet extends Component {
    render() {
        if (this.props.sheet === undefined) {
            return null;
        }

        const getHoursForProjektLphAndDay = (projekt, lphName, day) => {
            const dayProjekt = day.projekte.find(dayProjekt => dayProjekt.id === projekt.id);
            if (dayProjekt === undefined) {
                return null;
            }

            const lph = dayProjekt.lphstunden.find(lph => lph.lphName === lphName);
            if (lph === undefined) {
                return null;
            }

            return lph.hours;
        };

        const getTotalHoursForProjektAndDay = (projekt, day) => {
            const dayProjekt = day.projekte.find(dayProjekt => dayProjekt.id === projekt.id);
            if (dayProjekt === undefined) {
                return null;
            }

            return dayProjekt.totalstunden;
        };

        const monthStart = moment(`${this.props.sheet.year}-${this.props.sheet.month}-01`, 'YYYY-M-DD');
        const monthEnd = monthStart.clone().endOf('month');
        const currentDay = monthStart.clone();
        const days = [];
        while (currentDay.isSameOrBefore(monthEnd, 'day')) {
            days.push({ dayName: currentDay.format('dd'), dayOfMonth: currentDay.format('DD'), today: moment().isSame(currentDay, 'day') });
            currentDay.add(1, 'day');
        }

        return (
            <div className="monat-stunden-uebersicht--widget card table-responsive border-top-0">
                &nbsp;
                <table className="table table-striped table-bordered table-small table-small-uebersicht table-sm mb-0">
                    <thead>
                        <tr>
                            <th />
                            <th className="text-right pr-3">Σ</th>
                            {days.map((day, i) => (
                                <th key={`day${i}`} className="text-center">
                                    <div className="calendar--day">
                                        <span className="calendar--day--inner">
                                            <span className="day">{day.dayOfMonth}</span>
                                        </span>
                                        <div>{day.dayName}</div>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.sheet.studentTagStunden.map(studentDaten => (
                            <tr key={`student-${studentDaten.student.id}`}>
                                <td className="text-nowrap">{studentDaten.student.name}</td>
                                <td className="text-right">{numeral(studentDaten.sumStunden).format('0.00')}</td>
                                {studentDaten.tagStunden.map(tagStunden => (
                                    <td key={tagStunden.tag} className="text-right">
                                        {numeral(tagStunden.stunden).format('0.00')}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
