import React from 'react';
import getBadgeColor from '../../getBadgeColor';
import moment from 'moment';
import { STATUS_ENTWURF, STATUS_ERLEDIGT, STATUS_GELOESCHT, STATUS_HINFAELLIG, STATUS_IN_ARBEIT, STATUS_OFFEN } from '../AufgabenConstants';
import Avatar from '../Aufgabe/Avatar';

export const Dringlichkeit = ({ aufgabeRef, aufgabe }) => (
    <li ref={aufgabeRef} className="position-relative p-2 d-flex align-items-center justify-content-center aufgabe-row-item dringlichkeit-aufgabe-row">
        <div className="d-flex align-items-center justify-content-md-between justify-content-between w-100">
            <span className="font-weight-bold resp-aufgabe-label mr-2">Dringlichkeit:</span>
            <span className={`dringlichkeit dringlichkeit_${aufgabe.dringlichkeit}`} />
        </div>
    </li>
);

export const Projekt = ({ aufgabe }) => (
    <li className="p-2 d-flex align-items-center justify-content-center aufgabe-row-item projekt-aufgabe-row">
        <div className="d-flex align-items-center justify-content-md-between justify-content-between w-100">
            <span className="font-weight-bold resp-aufgabe-label mr-2">Projekt:</span>
            {aufgabe.projekt !== null && (
                <span className="titel projekt-aufgabe-liste text-right text-md-left resp-titel-and-projekt projekt-label" title={aufgabe.projekt.name}>
                    {aufgabe.projekt.isBau ? (
                        <a href={aufgabe.projekt.url}>{aufgabe.projekt.id && aufgabe.projekt.name === '' ? 'Kein Projektname' : aufgabe.projekt.name}</a>
                    ) : (
                        <>{aufgabe.projekt.id && aufgabe.projekt.name === '' ? 'Kein Projektname' : aufgabe.projekt.name}</>
                    )}
                </span>
            )}
        </div>
    </li>
);

export const Titel = ({ aufgabe, textSearch }) => {
    const titelChars = Array.from(aufgabe.titel);
    return (
        <li className="p-2 d-flex align-items-center justify-content-center aufgabe-row-item">
            <div className="d-flex align-items-center justify-content-md-between justify-content-between w-100">
                <span className="font-weight-bold resp-aufgabe-label mr-2">Titel:</span>
                <span className="titel text-right text-md-left resp-titel-and-projekt">
                    {titelChars.map(char => (
                        <span key={Math.random()} className={`${textSearch.includes(char.toLowerCase()) && 'text-underline'}`}>
                            {char}
                        </span>
                    ))}
                </span>
            </div>
        </li>
    );
};

export const Beschreibung = ({ aufgabe }) => (
    <li className="p-2 d-flex align-items-center justify-content-center aufgabe-row-item">
        <div className="d-flex align-items-center justify-content-md-between justify-content-between w-100">
            <span className="font-weight-bold resp-aufgabe-label mr-2">Beschreibung:</span>
            <span title={aufgabe.beschreibung} className="titel">
                {aufgabe.beschreibung}
            </span>
        </div>
    </li>
);

export const Ersteller = ({ aufgabe }) => (
    <li className="p-2 d-flex align-items-center justify-content-start aufgabe-row-item ersteller-row-resp">
        <div className="d-flex align-items-center justify-content-between justify-content-xl-start mitarbeiter--aufgabe ersteller-row-resp">
            <span className="font-weight-bold resp-aufgabe-label mr-2">Ersteller:</span>
            <div className="d-flex align-items-center">
                <Avatar name={aufgabe.ersteller.name} url={aufgabe.ersteller.url} avatar={aufgabe.ersteller.avatar} linkClassName="bearbeiter-name" />
            </div>
        </div>
    </li>
);

export const Bearbeiter = ({ aufgabe }) => (
    <li className="p-2 d-flex align-items-center aufgabe-row-item bearbeiter-row-resp">
        <div className="d-flex flex-column align-items-start justify-content-between bearbeiter-row-resp">
            <span className="font-weight-bold resp-aufgabe-label mr-2">Bearbeiter:</span>
            {aufgabe.bearbeiter.map(mitarbeiter => (
                <div key={mitarbeiter.id} className="d-flex align-items-center w-100 justify-content-end justify-content-xl-start mitarbeiter--aufgabe">
                    <Avatar name={mitarbeiter.name} url={mitarbeiter.url} avatar={mitarbeiter.avatar} linkClassName="bearbeiter-name" />
                </div>
            ))}
        </div>
    </li>
);

export const Start = ({ aufgabe }) => (
    <li className="p-2 d-flex align-items-center justify-content-md-start justify-content-between aufgabe-row-item start-aufgabe-row">
        <span className="font-weight-bold resp-aufgabe-label mr-2">Start:</span>
        <span>{aufgabe.start === null ? '' : aufgabe.start === '' ? aufgabe.start : moment(aufgabe.start).format('DD.MM.YYYY')}</span>
    </li>
);

export const Ende = ({ aufgabe }) => (
    <li className="p-2 d-flex align-items-center justify-content-md-start justify-content-between aufgabe-row-item ende-aufgabe-row">
        <span className="font-weight-bold resp-aufgabe-label mr-2">Ende:</span>
        <span className="ende">{aufgabe.ende === null ? '' : aufgabe.ende === '' ? aufgabe.ende : moment(aufgabe.ende).format('DD.MM.YYYY')}</span>
    </li>
);

export const Created = ({ aufgabe }) => (
    <li className="p-2 d-flex align-items-center justify-content-md-start justify-content-between aufgabe-row-item created-aufgabe-row">
        <span className="font-weight-bold resp-aufgabe-label mr-2">Erstellt:</span>
        <span className="erstellungsdatum">{aufgabe.erstellungsdatum.format('DD.MM.YYYY')}</span>
    </li>
);

export const Updated = ({ aufgabe }) => (
    <li className="p-2 d-flex align-items-center justify-content-md-start justify-content-between aufgabe-row-item updated-aufgabe-row">
        <span className="font-weight-bold resp-aufgabe-label mr-2">Geändert am:</span>
        <span className="aenderungsdatum">{aufgabe.aenderungsdatum.format('DD.MM.YYYY')}</span>
    </li>
);

export const Status = ({ openStatusSelector, isStatusSelectorOpen, setStatus, aufgabe: { status } }) => (
    <li className="p-2 d-flex align-items-center justify-content-md-center justify-content-end aufgabe-row-item status-aufgabe-row">
        <span className="font-weight-bold resp-aufgabe-label mr-2 mr-auto">Status:</span>
        <span className={`badge p-2 status status-label badge-${getBadgeColor(status)}`} onClick={() => openStatusSelector()}>
            {status}
        </span>
        {isStatusSelectorOpen && (
            <div className="d-flex align-items-center justify-content-around flex-column popup --status-wechsel">
                <h5>wechseln zu</h5>
                {getStatusOptions(status).map(status => (
                    <span key={status} className={`badge w-100 p-2 mt-2 status badge-${getBadgeColor(status)}`} onClick={() => setStatus(status)}>
                        {status}
                    </span>
                ))}
            </div>
        )}
    </li>
);

export const getStatusOptions = status => {
    switch (status) {
        case STATUS_ENTWURF:
            return [STATUS_OFFEN, STATUS_IN_ARBEIT, STATUS_ERLEDIGT, STATUS_HINFAELLIG, STATUS_GELOESCHT];
        case STATUS_OFFEN:
            return [STATUS_ENTWURF, STATUS_IN_ARBEIT, STATUS_ERLEDIGT, STATUS_HINFAELLIG, STATUS_GELOESCHT];
        case STATUS_IN_ARBEIT:
            return [STATUS_OFFEN, STATUS_ENTWURF, STATUS_ERLEDIGT, STATUS_HINFAELLIG, STATUS_GELOESCHT];
        case STATUS_ERLEDIGT:
            return [STATUS_OFFEN, STATUS_IN_ARBEIT, STATUS_ENTWURF, STATUS_HINFAELLIG, STATUS_GELOESCHT];
        case STATUS_HINFAELLIG:
            return [STATUS_OFFEN, STATUS_IN_ARBEIT, STATUS_ERLEDIGT, STATUS_ENTWURF, STATUS_GELOESCHT];
        case STATUS_GELOESCHT:
            return [STATUS_OFFEN, STATUS_IN_ARBEIT, STATUS_ERLEDIGT, STATUS_ENTWURF, STATUS_HINFAELLIG];
        default:
            return [STATUS_ENTWURF, STATUS_OFFEN, STATUS_IN_ARBEIT, STATUS_ERLEDIGT, STATUS_HINFAELLIG, STATUS_GELOESCHT];
    }
};
