import React from 'react';
import Layer from '../../../../../../application/public/images/icons/layers.svg';
import File from '../../../../../../application/public/images/icons/file.svg';

export const createBaumDataFromAllKnoten = (knoten = [], aktuellerKnoten, withCounter = false) => {
    const enrichKnotenForTree = knoten => ({
        title: withCounter && knoten.anzahlInhaltsaggregate !== 0 ? `${knoten.name} (${knoten.anzahlInhaltsaggregate})` : knoten.name,
        key: knoten.id,
        expanded: false,
        children: [],
        selectable: knoten.id !== aktuellerKnoten,
        icon: <img alt="layer-icon" src={Layer} className="tree-knoten" />,
        ...knoten,
    });

    const masterKnoten = knoten.filter(knoten => knoten.parent === null).map(knoten => enrichKnotenForTree(knoten))[0];

    const getChildKnotenFromParent = parent => {
        parent.children = knoten.filter(knoten => knoten.parent && knoten.parent.id === parent.id).map(knoten => enrichKnotenForTree(knoten));
        parent.children.forEach(child => getChildKnotenFromParent(child));
        if (parent.children.length === 0) {
            parent.icon = <img alt="layer-icon" src={File} className="tree-knoten" />;
        }
    };

    getChildKnotenFromParent(masterKnoten);

    return [{ ...masterKnoten, expanded: true }];
};
