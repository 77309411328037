import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paginate from '../Paginate';
import * as AufgabenField from './AufgabenListe/Aufgabe';
import AufgabenListeFilterItem from './AufgabenListe/AufgabenListeFilterItem';
import * as api from '../api';
// import Aufgabe from './AufgabenListe/Aufgabe';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import AufgabeDetailView from './Aufgabe/AufgabeDetailView';
import Modal from '../Modal/Modal';
import toast from '../toastify';
import AufgabeEdit from './Aufgabe/AufgabeEdit';
import ButtonImg from '../../../../../application/public/images/icons/Aufgaben.svg';
import AufgabeErstellen from './Aufgabe/AufgabeErstellen';
import { STATUS_ERLEDIGT, STATUS_GELOESCHT, STATUS_HINFAELLIG } from './AufgabenConstants';

export const FIELD_TITEL = 'titel';
export const FIELD_START = 'start';
export const FIELD_ENDE = 'ende';
export const FIELD_PROJEKT = 'projekt';
export const FIELD_BESCHREIBUNG = 'beschreibung';
export const FIELD_BEARBEITER = 'bearbeiter';
export const FIELD_ERSTELLER = 'ersteller';
export const FIELD_AENDERUNGSDATUM = 'aenderungsdatum';
export const FIELD_ERSTELLDATUM = 'erstellungsdatum';
export const FIELD_STATUS = 'status';
export const FIELD_DRINGLICHKEIT = 'dringlichkeit';
export const ASC = 'asc';
export const DESC = 'desc';

const aufgabenDashboardListConfig = {
    headline: 'Meine Aufgaben',
    url: '/aufgaben/meine/ich',
    fields: {
        [FIELD_DRINGLICHKEIT]: {
            label: '',
            sort: true,
            filter: true,
        },
        [FIELD_TITEL]: {
            sort: true,
            search: true,
        },
        [FIELD_PROJEKT]: {
            sort: true,
            search: true,
        },
        [FIELD_START]: {
            sort: true,
        },
        [FIELD_ENDE]: {
            sort: true,
        },
        [FIELD_STATUS]: {
            // can field be sorted
            sort: true,
            // will there be a auswahlfilter on the list
            filter: true,
            // will the values of this column be applied to search
            search: true,
        },
    },
    elementsPerPage: 10,
    toCompleteListBtn: true,
};

const SINGLE_FIELD_DEFAULTS = {
    label: '',
    sort: false,
    filter: false,
    search: false,
    className: '',
};

const FIELD_DEFAULTS = {
    [FIELD_TITEL]: {
        ...SINGLE_FIELD_DEFAULTS,
        label: 'Titel',
    },
    [FIELD_START]: {
        ...SINGLE_FIELD_DEFAULTS,
        label: 'Start',
        className: 'start-filter-item',
    },
    [FIELD_ENDE]: {
        ...SINGLE_FIELD_DEFAULTS,
        label: 'Ende',
        className: 'ende-filter-item',
    },
    [FIELD_PROJEKT]: {
        ...SINGLE_FIELD_DEFAULTS,
        label: 'Projekt',
        className: 'projekt-filter-item',
        search: true,
        filter: true,
    },
    [FIELD_BESCHREIBUNG]: {
        ...SINGLE_FIELD_DEFAULTS,
        label: 'Beschreibung',
    },
    [FIELD_BEARBEITER]: {
        ...SINGLE_FIELD_DEFAULTS,
        label: 'Bearbeiter',
        className: 'bearbeiter-filter-item',
    },
    [FIELD_ERSTELLER]: {
        ...SINGLE_FIELD_DEFAULTS,
        label: 'Ersteller',
        className: 'ersteller-filter-item',
    },
    [FIELD_AENDERUNGSDATUM]: {
        ...SINGLE_FIELD_DEFAULTS,
        label: 'Geändert am',
        className: 'updated-filter-item',
    },
    [FIELD_ERSTELLDATUM]: {
        ...SINGLE_FIELD_DEFAULTS,
        label: 'erstellt',
        className: 'created-filter-item',
    },
    [FIELD_STATUS]: {
        ...SINGLE_FIELD_DEFAULTS,
        label: 'Status',
        className: 'status-filter-item',
    },
    [FIELD_DRINGLICHKEIT]: {
        ...SINGLE_FIELD_DEFAULTS,
        label: 'Drl.',
        className: 'dringlichkeit-filter-item',
    },
};

export class List extends React.Component {
    state = {
        dirty: false,
        modalWillCloseDirty: false,
        // data
        aufgaben: [],
        dataLoaded: false,
        // paging
        pageCount: null,
        activePage: 0,
        // filter
        textSearchFilter: '',
        dateFilterStart: null,
        dateFilterEnd: null,
        dateFilteredAufgabenNotFound: null,
        openAuswahlFilter: null,
        openStatusSelector: null,
        activeFilter: {
            status: [STATUS_GELOESCHT, STATUS_HINFAELLIG, STATUS_ERLEDIGT],
        },
        // sorting
        sortField: this.props.sort ? this.props.sort[0] : FIELD_DRINGLICHKEIT,
        sortOrder: this.props.sort ? this.props.sort[1] : ASC,
        // styling
        datePickerOrientation: 'horizontal',
        modalOpen: false,
        detailsOpen: false,
        editOpen: false,
        aufgabeForViewAndEdit: {},
    };

    loadAufgaben = async () => {
        const json = await api.getJson(this.props.url);
        const newState = {
            aufgaben: json.aufgaben.map(aufgabe => ({
                ...aufgabe,
                start: aufgabe.start === null ? null : moment(aufgabe.start),
                ende: aufgabe.ende === null ? null : moment(aufgabe.ende),
                erstellungsdatum: moment(aufgabe.erstellungsdatum),
                aenderungsdatum: moment(aufgabe.aenderungsdatum),
            })),
            dataLoaded: true,
            pageCount: Math.ceil(json.aufgaben.length / this.props.elementsPerPage),
        };
        this.setState(newState);
    };

    componentDidMount() {
        this.loadAufgaben();
        window.addEventListener('resize', this.changeDatePickerOrientation);
        window.addEventListener('dispatchAufgabe', this.loadAufgaben.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.changeDatePickerOrientation);
        window.removeEventListener('dispatchAufgabe', this.loadAufgaben.bind(this));
    }

    // change the date picker orientation to vertical on small screens
    changeDatePickerOrientation = () => this.setState({ datePickerOrientation: window.innerWidth < 1600 ? 'vertical' : 'horizontal' });

    // handle page click from pagination
    setPage = activePage => this.setState({ activePage });

    // handle the sortation if user clicks on an sort item
    setSortField = sortField => {
        this.setState(state => ({
            sortField,
            sortOrder: state.sortField === sortField && state.sortOrder === ASC ? DESC : ASC,
        }));
    };

    // get the height of an dom element, we use it here to calculate the min-height for the aufgaben list container for better user exp in the frontend
    domElementHeight = null;
    getDomElementHeight = el => {
        if (el) {
            this.domElementHeight = el.getBoundingClientRect().height;
        }
    };

    // our function to sort fields asc or desc, in some cases we need to check if a specific field is given with special cases like projekt name or text fields and projekts without name
    dynamicSort = (field, sortOrder) => {
        return (a, b) => {
            let result;

            if (field === FIELD_PROJEKT) {
                if (a[field] === null && b[field] !== null) {
                    result = 1;
                } else if (a[field] !== null && b[field] === null) {
                    result = -1;
                } else if (a[field] === null && b[field] === null) {
                    result = 0;
                } else {
                    result = a[field].name.toLowerCase().localeCompare(b[field].name.toLowerCase());
                }
            } else if (field === FIELD_STATUS) {
                result = getStatusPrio(a[field]) - getStatusPrio(b[field]);
            } else {
                // standart sortation
                result = a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
            }

            return sortOrder === ASC ? result : result * -1;
        };
    };

    setActiveFilter = (field, options) => {
        this.setState({
            activeFilter: {
                ...this.state.activeFilter,
                [field]: options,
            },
            activePage: 0,
        });
    };

    // handling input from filter and transform it to lowercase
    setTextFilter = value => this.setState({ textSearchFilter: value, activePage: 0 });

    // handle date range picker selection
    setDateFilter = (startDate, endDate) => this.setState({ dateFilterStart: startDate, dateFilterEnd: endDate, activePage: 0 });

    // check wich aufgaben to display raw(paginated) or filtered
    getAufgaben = (withPaging = true) => {
        const aufgaben = this.state.aufgaben
            // filter by text
            .filter(aufgabe => {
                if (this.state.textSearchFilter.length === 0) {
                    return true;
                }
                if (aufgabe.key.includes(this.state.textSearchFilter)) {
                    return true;
                }
                return Object.keys(this.props.fields).find(field => {
                    if (aufgabe[field] !== null && typeof aufgabe[field] === 'object' && this.props.fields[field].search === true) {
                        return aufgabe[field].name.toLowerCase().includes(this.state.textSearchFilter);
                    }

                    if (aufgabe[field] !== null && this.props.fields[field].search === true) {
                        return aufgabe[field].toLowerCase().includes(this.state.textSearchFilter);
                    }
                });
            })
            // filter by date
            .filter(aufgabe => {
                const filterStart = this.state.dateFilterStart;
                const filterEnd = this.state.dateFilterEnd;
                const aufgabeStart = aufgabe.start;
                const aufgabeEnde = aufgabe.ende !== null ? aufgabe.ende : aufgabe.start;

                if (filterStart === null && filterEnd === null) {
                    return true;
                } else if (filterStart !== null && filterEnd === null) {
                    if (aufgabeStart !== null) {
                        return aufgabeStart.unix() >= filterStart.unix();
                    }
                    return false;
                } else if (filterStart !== null && filterEnd !== null) {
                    if (aufgabeStart !== null && aufgabeEnde !== null) {
                        return aufgabeStart.isBetween(filterStart, filterEnd, 'days', '[]') && aufgabeEnde.isBetween(filterStart, filterEnd, 'days', '[]');
                    }
                    return false;
                } else if (filterEnd !== null) {
                    if (aufgabeEnde !== null) {
                        return aufgabeEnde.unix() <= filterEnd.unix();
                    }
                }
                return false;
            })
            // filter by filterPopUp
            .filter(aufgabe => {
                const activeFilterFields = Object.keys(this.state.activeFilter);
                const activeFilter = this.state.activeFilter;

                if (
                    activeFilterFields.includes(FIELD_BEARBEITER) &&
                    activeFilterFields.find(field => {
                        let bearbeiter = {};
                        aufgabe[field].forEach(field => (bearbeiter = field.name));
                        return activeFilter[field].includes(bearbeiter);
                    })
                ) {
                    return false;
                }

                if (activeFilterFields.find(field => activeFilter[field].includes('Persönliche Aufgabe') && aufgabe.projekt === null)) {
                    return false;
                }

                if (activeFilterFields.find(field => activeFilter[field].includes(aufgabe[field] !== null && aufgabe[field].name))) {
                    return false;
                }

                if (activeFilterFields.find(field => activeFilter[field].includes(aufgabe[field] !== null && aufgabe[field]))) {
                    return false;
                }
                return true;
            })
            // sort and paginate
            .sort(this.dynamicSort(this.state.sortField, this.state.sortOrder));

        if (withPaging) {
            return aufgaben.slice(this.props.elementsPerPage * this.state.activePage, this.props.elementsPerPage * (this.state.activePage + 1));
        } else {
            return aufgaben;
        }
    };

    needsPaging = () => this.getAufgaben(false).length > this.props.elementsPerPage;

    getFields = () => {
        const { fields } = this.props;
        return Object.keys(fields).map(key => ({
            ...FIELD_DEFAULTS[key],
            key,
            ...fields[key],
        }));
    };

    setOpenAuswahlFilter = field => this.setState(state => ({ openAuswahlFilter: state.openAuswahlFilter === field ? null : field }));

    getAuswahlFilterOptions = field => arrayUnique(this.state.aufgaben.map(aufgabe => aufgabe[field]));

    // handle click on aufgabe status to display the popup and the popup options
    openStatusSelector = key => {
        this.setState({
            openStatusSelector: key,
        });
    };

    getAufgabeByKey = key => this.state.aufgaben.find(aufgabe => (aufgabe.key === key ? aufgabe : null));

    // set the updated status in aufgabe and add it to aufgaben after the filter above is done
    setAufgabeStatus = async (key, status) => {
        this.state.openStatusSelector = null;
        const aufgabe = this.getAufgabeByKey(key);
        try {
            const updatedAufgabe = await api.postJson(`/aufgaben/status-wechseln/${key}`, {
                ...aufgabe,
                status,
            });

            if (updatedAufgabe.error) {
                toast.error(updatedAufgabe.error);
            } else {
                this.state.aufgaben = this.state.aufgaben.map(aufgabe => {
                    if (aufgabe.key === key) {
                        aufgabe.status = status;
                        aufgabe.dringlichkeit = updatedAufgabe.aufgabe.dringlichkeit;
                    }
                    return aufgabe;
                });
                toast.success('Status erfolgreich gewechselt');
                this.setState(this.state);
            }
        } catch (e) {
            toast.error('Es ist ein unerwarteter Fehler aufgetreten');
        }
    };

    // toggle modal
    toggleModal = () => {
        if (this.state.dirty === false) {
            this.setState({ modalOpen: !this.state.modalOpen, detailsOpen: false, editOpen: false });
        }
    };

    // toggle aufgaben detail
    toggleAufgabeDetail = aufgabeForViewAndEdit => {
        this.toggleModal();
        this.setState({ aufgabeForViewAndEdit, detailsOpen: !this.state.detailsOpen });
    };

    // toggle aufgaben edit
    toggleAufgabeEdit = () => this.setState({ detailsOpen: false, editOpen: !this.state.editOpen });

    // finally update the aufgaben with the edited aufgabe
    updateEditedAufgabe = updatedAufgabe => {
        this.state.aufgaben = this.state.aufgaben.map(aufgabe => {
            if (aufgabe.key === updatedAufgabe.key) {
                aufgabe = {
                    ...updatedAufgabe,
                    start: moment(updatedAufgabe.start),
                    ende: moment(updatedAufgabe.ende),
                    aenderungsdatum: moment(updatedAufgabe.aenderungsdatum),
                    erstellungsdatum: moment(updatedAufgabe.erstellungsdatum),
                };
            }
            return aufgabe;
        });
        this.setState(this.state);
    };

    checkIfDirty = dirty => this.setState({ dirty });

    backToDetailsFromEdit = (editedAufgabe = {}) => {
        this.setState({
            editOpen: false,
            detailsOpen: true,
            aufgabeForViewAndEdit: Object.keys(editedAufgabe).length === 0 ? this.state.aufgabeForViewAndEdit : editedAufgabe,
        });
    };

    render() {
        const {
            dataLoaded,
            sortOrder,
            dateFilterStart,
            dateFilterEnd,
            datePickerOrientation,
            detailsOpen,
            editOpen,
            aufgabeForViewAndEdit,
            modalWillCloseDirty,
            activeFilter,
        } = this.state;
        const { className } = this.props;
        const aufgaben = this.getAufgaben();
        const fields = this.getFields();
        // check if data is loaded otherwise return loading "screen"
        if (dataLoaded === false) {
            return (
                <div>
                    <h3>Loading ...</h3>
                </div>
            );
        }
        return (
            <React.Fragment>
                {this.state.modalOpen && (
                    <Modal
                        onClose={() => this.toggleModal()}
                        size="md"
                        title={detailsOpen ? 'Aufgabe Details' : 'Aufgabe bearbeiten'}
                        modalWillCloseDirty={modalWillCloseDirty => this.setState({ modalWillCloseDirty })}
                        canClose={true}
                        checkIfDirty={this.state.dirty}>
                        {detailsOpen && (
                            <AufgabeDetailView
                                aufgabeForViewAndEdit={aufgabeForViewAndEdit}
                                aufgaben={this.getAufgaben(false)}
                                toggleAufgabenEdit={() => this.toggleAufgabeEdit()}
                                setStatus={status => this.setAufgabeStatus(aufgabeForViewAndEdit.key, status)}
                            />
                        )}
                        {editOpen && (
                            <AufgabeEdit
                                aufgabe={aufgabeForViewAndEdit}
                                aufgaben={this.getAufgaben(false)}
                                updateEditedAufgabe={updatedAufgabe => this.updateEditedAufgabe(updatedAufgabe)}
                                toggleModal={() => this.toggleModal()}
                                checkIfDirty={dirty => this.checkIfDirty(dirty)}
                                modalWillCloseDirty={modalWillCloseDirty}
                                cancel={() => this.setState({ modalWillCloseDirty: false })}
                                toggleDetailView={editedAufgabe => this.backToDetailsFromEdit(editedAufgabe)}
                                modalWillCloseClean={() =>
                                    this.setState({
                                        modalWillCloseDirty: false,
                                        dirty: false,
                                        modalOpen: !this.state.modalOpen,
                                        detailsOpen: false,
                                        editOpen: false,
                                    })
                                }
                            />
                        )}
                    </Modal>
                )}
                <div className={`card mb-5 ${className}`}>
                    {this.state.openAuswahlFilter && <div className="popup-overlay" onClick={() => this.setOpenAuswahlFilter(null)} />}
                    {this.state.openStatusSelector && <div className="popup-overlay" onClick={() => this.openStatusSelector(null)} />}
                    <div className="card-body bg-white">
                        <div className="container-fluid">
                            <div className="row justify-content-between">
                                {this.props.headline && (
                                    <div className="col d-flex align-items-center">
                                        <img src={ButtonImg} style={{ height: 20, width: 20 }} className="mr-2" />
                                        <h2>{this.props.headline}</h2>
                                        <div className="ml-auto d-flex">
                                            <AufgabeErstellen userid={this.props.userid} username={this.props.username} icon="plus" />
                                            {this.props.toCompleteListBtn && (
                                                <a className="text-white" href="/aufgaben/allgemeine-liste">
                                                    <button className="ml-2 btn btn-outline-secondary open-gesamtliste-btn" title="zur Gesamtliste">
                                                        <FontAwesomeIcon icon="tasks" />
                                                    </button>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-lg-4">
                                    <div className="form-group d-flex m-0">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Suche"
                                            onChange={e => this.setTextFilter(e.target.value.toLowerCase())}
                                        />
                                        <button className="btn btn-secondary disabled disabled-btn-events">
                                            <FontAwesomeIcon icon={'search'} />
                                        </button>
                                    </div>
                                </div>
                                <div className={`col-12 mt-3 mt-lg-0 ${this.needsPaging() ? 'col-lg-4 col-sm-6' : 'col-xl-6'}`}>
                                    <DateRangePicker
                                        startDate={dateFilterStart}
                                        startDateId="cursor-"
                                        endDate={dateFilterEnd}
                                        endDateId="your_unique_end_date_id"
                                        onDatesChange={({ startDate, endDate }) => this.setDateFilter(startDate, endDate)}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                        hideKeyboardShortcutsPanel={true}
                                        startDatePlaceholderText="Von"
                                        endDatePlaceholderText="Bis"
                                        minimumNights={0}
                                        small
                                        orientation={datePickerOrientation}
                                        showClearDates
                                        monthFormat="DD.MM.YYYY"
                                        isOutsideRange={() => {}}
                                    />
                                </div>
                                {this.needsPaging() && (
                                    <div className="col-12 col-sm-6 col-lg-4 d-flex justify-content-start justify-content-md-end mt-3 mt-lg-0">
                                        <Paginate
                                            pageRangeDisplayed={1}
                                            breakLabel=".."
                                            initialPage={0}
                                            forcePage={this.state.activePage}
                                            pageCount={this.state.pageCount}
                                            onPageChange={activePage => this.setPage(activePage.selected)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <div className="container-fluid p-0">
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <ul className={`list-unstyled list-group flex-md-row flex-column justify-content-between ${className}--filter-list`}>
                                        {fields.map(field => (
                                            <AufgabenListeFilterItem
                                                key={field.key}
                                                label={field.label}
                                                field={field.key}
                                                className={field.className}
                                                canBeFiltered={field.filter}
                                                canBeSorted={field.sort}
                                                setSortField={() => this.setSortField(field.key)}
                                                sortOrder={this.state.sortField === field.key && sortOrder}
                                                openAuswahlFilter={this.state.openAuswahlFilter === field.key}
                                                setOpenAuswahlFilter={() => this.setOpenAuswahlFilter(field.key)}
                                                auswahlFilterOptions={this.getAuswahlFilterOptions(field.key)}
                                                setActiveFilter={options => this.setActiveFilter(field.key, options)}
                                                activeElements={this.state.activeFilter[field.key]}
                                            />
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="row" style={{ minHeight: this.getAufgaben(true).length * this.domElementHeight }}>
                                <div className="col-12">
                                    <ul className="list-unstyled list-group">
                                        {aufgaben.length === 0 && (
                                            <li className="list-group-item p-2 w-100 d-flex align-items-baseline justify-content-center aufgabe mt-2 mb-2">
                                                {Object.keys(activeFilter).length !== 0 ? (
                                                    <>
                                                        <span>Keine Aufgabe zu den entsprechenden Filterkriterien gefunden</span>
                                                        <a
                                                            href="#"
                                                            className="ml-3 text-primary"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.setState({ activeFilter: {} });
                                                            }}>
                                                            Filter löschen
                                                        </a>
                                                    </>
                                                ) : (
                                                    <span>Aktuell sind keine Aufgaben vorhanden</span>
                                                )}
                                            </li>
                                        )}
                                        {aufgaben.map(aufgabe => (
                                            <ul
                                                key={aufgabe.key}
                                                className={`list-unstyled list-group flex-column flex-md-row justify-content-between alternate-color-li link-to-aufgabe position-relative ${className}--filter-list`}>
                                                <div className="toggle-modal-overlay" onClick={() => this.toggleAufgabeDetail(aufgabe)} />
                                                {fields.map(field => {
                                                    const Component = getFieldView(field.key);
                                                    return (
                                                        <Component
                                                            textSearch={this.state.textSearchFilter}
                                                            key={field.key}
                                                            aufgabeRef={this.getDomElementHeight}
                                                            aufgabe={aufgabe}
                                                            openStatusSelector={() => this.openStatusSelector(aufgabe.key)}
                                                            isStatusSelectorOpen={this.state.openStatusSelector === aufgabe.key}
                                                            setStatus={status => this.setAufgabeStatus(aufgabe.key, status)}>
                                                            />
                                                        </Component>
                                                    );
                                                })}
                                            </ul>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            {this.needsPaging() && (
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-center p-2">
                                        <Paginate
                                            containerClassName="mt-3 pagination"
                                            pageRangeDisplayed={1}
                                            breakLabel=".."
                                            initialPage={0}
                                            pageCount={this.state.pageCount}
                                            onPageChange={activePage => this.setPage(activePage.selected)}
                                            forcePage={this.state.activePage}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ({ userid, username }) => (
    <React.Fragment>
        <List userid={userid} username={username} className="dashboard-aufgabenliste" {...aufgabenDashboardListConfig} />
    </React.Fragment>
);

const getFieldView = field => {
    switch (field) {
        case FIELD_DRINGLICHKEIT:
            return AufgabenField.Dringlichkeit;
        case FIELD_PROJEKT:
            return AufgabenField.Projekt;
        case FIELD_TITEL:
            return AufgabenField.Titel;
        case FIELD_START:
            return AufgabenField.Start;
        case FIELD_ENDE:
            return AufgabenField.Ende;
        case FIELD_STATUS:
            return AufgabenField.Status;
        case FIELD_ERSTELLDATUM:
            return AufgabenField.Created;
        case FIELD_AENDERUNGSDATUM:
            return AufgabenField.Updated;
        case FIELD_BESCHREIBUNG:
            return AufgabenField.Beschreibung;
        case FIELD_BEARBEITER:
            return AufgabenField.Bearbeiter;
        case FIELD_ERSTELLER:
            return AufgabenField.Ersteller;
        default:
            return <li>field not defined!</li>;
    }
};

const getStatusPrio = status => {
    switch (status) {
        case 'Entwurf':
            return 3;
        case 'entwurf':
            return 3;
        case 'offen':
            return 0;
        case 'Offen':
            return 0;
        case 'in Arbeit':
            return 1;
        case 'in arbeit':
            return 1;
        case 'In Arbeit':
            return 1;
        case 'erledigt':
            return 4;
        case 'hinfällig':
            return 5;
        default:
            return 0;
    }
};

export const arrayUnique = array => array.reduce((last, current) => (last.includes(current) ? last : [...last, current]), []);
