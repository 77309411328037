import React from 'react';
import * as api from '../api';
import moment from 'moment';
import PortalButtonBar from '../PortalButtonBar';
import MonthPicker from './StundenUebersichtWidget/MonthPicker';
import SheetContainer from './StundenUebersichtWidget/StudentstundenUebersichtSheetContainer';

/**
 * Monthpicker date object
 * @typedef {Object} MonthpickerDate
 * @property {number} year
 * @property {number} month
 */

export default class StudentstundenUebersichtWidget extends React.Component {
    state = {
        dataLoaded: false,
        monthSheets: [],
        /**
         * @type {MonthpickerDate}
         */
        selectedStartDate: {
            year: parseInt(moment().format('YYYY'), 10),
            month: parseInt(moment().format('M'), 10),
        },
        /**
         * @type {MonthpickerDate}
         */
        selectedEndDate: {
            year: parseInt(moment().format('YYYY'), 10),
            month: parseInt(moment().format('M'), 10),
        },
    };

    async componentDidMount() {
        const start = moment().format('YYYY-MM-DD');
        const end = moment()
            .endOf('month')
            .format('YYYY-MM-DD');

        const json = await api.getJson(`/zeiterfassung/zeiterfassung/uebersicht/studentstundenuebersicht/data/${start}/${end}`);

        const newState = {
            ...json,
            dataLoaded: true,
        };

        this.setState(newState);
    }

    /**
     *
     * @param {MonthpickerDate} startDate
     * @param {MonthpickerDate} endDate
     * @return {Promise<void>}
     */
    updateData = async (startDate, endDate) => {
        const start = moment(`${startDate.year}-${startDate.month}-01`, 'YYYY-M-DD').format('YYYY-MM-DD');
        const end = moment(`${endDate.year}-${endDate.month}-01`, 'YYYY-M-DD')
            .endOf('month')
            .format('YYYY-MM-DD');

        const json = await api.getJson(`/zeiterfassung/zeiterfassung/uebersicht/studentstundenuebersicht/data/${start}/${end}`);

        const newState = {
            ...json,
            selectedStartDate: startDate,
            selectedEndDate: endDate,
        };

        this.setState(newState);
    };

    /**
     * @param {number} year
     * @param {number} month
     */
    onStartDateChange = (year, month) => {
        if (
            moment(`${year}-${month}-01`, 'YYYY-M-DD').isAfter(moment(`${this.state.selectedEndDate.year}-${this.state.selectedEndDate.month}-01`, 'YYYY-M-DD'))
        ) {
            return;
        }

        // we need to update state before requesting a data update otherwise the monthpicker will close only after the request resolves
        const selectedStartDate = { year, month };
        this.setState({ selectedStartDate });

        this.updateData(selectedStartDate, this.state.selectedEndDate);
    };

    /**
     * @param {number} year
     * @param {number} month
     */
    onEndDateChange = (year, month) => {
        if (
            moment(`${year}-${month}-01`, 'YYYY-M-DD').isBefore(
                moment(`${this.state.selectedStartDate.year}-${this.state.selectedStartDate.month}-01`, 'YYYY-M-DD')
            )
        ) {
            return;
        }

        // we need to update state before requesting a data update otherwise the monthpicker will close only after the request resolves
        const selectedEndDate = { year, month };
        this.setState({ selectedEndDate });

        this.updateData(this.state.selectedStartDate, selectedEndDate);
    };

    render() {
        console.log(this.state.dataLoaded, 'this.state.dataLoaded');
        if (!this.state.dataLoaded) {
            return <div>Loading</div>;
        }

        return (
            <React.Fragment>
                <PortalButtonBar>
                    <div className="row">
                        <div className="col-xl-2">
                            <div className="align-items-center row">
                                <label className="col-xl-3 col-form-label">Start</label>
                                <div className="col-xl-7">
                                    <MonthPicker value={this.state.selectedStartDate} onChange={this.onStartDateChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="align-items-center row">
                                <label className="col-xl-3 col-form-label">Ende</label>
                                <div className="col-xl-7">
                                    <MonthPicker value={this.state.selectedEndDate} onChange={this.onEndDateChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </PortalButtonBar>
                <SheetContainer sheets={this.state.monthSheets} />
            </React.Fragment>
        );
    }
}
