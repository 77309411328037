import React from 'react';
import Swiper from 'react-id-swiper';
import { ImportantCard } from './Knoten/KnotenDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class InhaltsElementeSlider extends React.Component {
    constructor(props) {
        super(props);
        this.swiper = null;
    }

    goNext = () => this.swiper && this.swiper.slideNext();

    goPrev = () => this.swiper && this.swiper.slidePrev();

    render() {
        const { inhaltselemente, breakpoints, slidesPerView } = this.props;
        const params = {
            slidesPerView: slidesPerView,
            loop: false,
            loopFillGroupWithBlank: false,
            centeredSlides: false,
            spaceBetween: 10,
            noSwiping: true,
            breakpoints: {
                1200: {
                    slidesPerView: slidesPerView - 1,
                },
                992: {
                    slidesPerView: slidesPerView - 2,
                },
                768: {
                    slidesPerView: 2,
                },
                ...breakpoints,
            },
        };
        return (
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center justify-content-center" onClick={() => this.goPrev()}>
                    <button className="btn btn-link text-dark">
                        <FontAwesomeIcon icon="chevron-left" title="zurück" size="lg" />
                    </button>
                </div>
                <Swiper ref={node => (node ? (this.swiper = node.swiper) : null)} {...params}>
                    {inhaltselemente.map(element => (
                        <div
                            className=""
                            style={{
                                width: 250,
                                height: 'auto',
                            }}
                            key={element.id}>
                            <ImportantCard element={element} />
                        </div>
                    ))}
                </Swiper>
                <div className="d-flex align-items-center justify-content-center" onClick={() => this.goNext()}>
                    <button className="btn btn-link text-dark">
                        <FontAwesomeIcon icon="chevron-right" title="vor" size="lg" />
                    </button>
                </div>
            </div>
        );
    }
}
