import { Mutation } from 'react-apollo';
import React from 'react';
import { gql } from 'apollo-boost';

export default ({ data, fetching }) => (
    <div className="row">
        <div className="col-auto" style={{ width: 40 }}>
            <img src="/images/icons/Zeiterfassung_meine_Projektstunden.svg" className="img-fluid" />
        </div>
        <div className="col-auto">
            <h2>Zeiterfassung</h2>
        </div>
        <div className="col text-center">
            <div className="h2 mb-0">
                <Mutation mutation={SET_WEEK}>
                    {setWeek => (
                        <button className="btn btn-link text-dark" onClick={() => setWeek({ variables: { week: data.last } })}>
                            <i className="fas fa-chevron-left" />
                        </button>
                    )}
                </Mutation>
                KW {data.week}/{data.year}
                <Mutation mutation={SET_WEEK}>
                    {setWeek => (
                        <button className="btn btn-link text-dark" onClick={() => setWeek({ variables: { week: data.next } })}>
                            <i className="fas fa-chevron-right" />
                        </button>
                    )}
                </Mutation>
            </div>
            {data.firstDay} - {data.lastDay}
        </div>
        <div className="col-auto text-right">
            <div className={`${!fetching && 'd-none'}`}>
                <i className="fas fa-2x fa-spinner fa-pulse" />
            </div>
        </div>
    </div>
);

const SET_WEEK = gql`
    mutation setWeek($week: String!) {
        setZeiterfassungWidgetWeek(week: $week) @client
    }
`;
