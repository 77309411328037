import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { withClientState } from 'apollo-link-state';
import { gql } from 'apollo-boost';
import ErrorBoundary from './ErrorBoundary';

export const defaults = {
    user: 'ich',
    updater: 0,
    zeiterfassungWidget: {
        week: 'now',
        __typename: 'ZeiterfassungWidgetState',
    },
    kalenderWidget: {
        month: 'now',
        __typename: 'KalenderWidgetState',
    },
    projektstundenWidget: {
        month: 'now',
        __typename: 'ProjektstundenWidgetState',
    },
    monatsuebersichtWidget: {
        month: 'now',
        __typename: 'MonatsuebersichtWidgetState',
    },
};

export const resolvers = {
    Mutation: {
        setUser: (_, { user }, { cache }) => {
            cache.writeData({ data: { user } });
            return true;
        },
        setZeiterfassungWidgetWeek: (_, { week }, { cache }) => {
            cache.writeData({
                data: {
                    zeiterfassungWidget: {
                        week,
                        __typename: 'ZeiterfassungWidgetState',
                    },
                },
            });
            return true;
        },
        updateZeiterfassungWidget: (_, test, { cache }) => {
            // first check if user is authenticated already
            const data = cache.readQuery({
                query: gql`
                    {
                        updater
                    }
                `,
            });
            cache.writeData({
                data: {
                    updater: data.updater + 1,
                },
            });

            return true;
        },
        setKalenderWidgetMonth: (_, { month }, { cache }) => {
            cache.writeData({
                data: {
                    kalenderWidget: {
                        month,
                        __typename: 'KalenderWidgetState',
                    },
                },
            });
            return true;
        },
        setProjektstundenWidgetMonth: (_, { month }, { cache }) => {
            cache.writeData({
                data: {
                    projektstundenWidget: {
                        month,
                        __typename: 'ProjektstundenWidgetState',
                    },
                },
            });
            return true;
        },
        setMonatsuebersichtWidgetMonth: (_, { month }, { cache }) => {
            cache.writeData({
                data: {
                    monatsuebersichtWidget: {
                        month,
                        __typename: 'MonatsuebersichtWidgetState',
                    },
                },
            });
            return true;
        },
    },
};

const typeDefs = `
  type ZeiterfassungWidgetState {
    week: String!
  }
  type KalenderWidgetState {
    month: String!
  }
  type ProjektstundenWidgetState {
    month: String!
  }
  type MonatsuebersichtWidgetState {
    month: String!
  }

  type Mutation {
    setUser(user: String!): String
    setKalenderWidgetMonth(month: String!): KalenderWidgetState
    setProjektstundenWidgetMonth(month: String!): ProjektstundenWidgetState
    setZeiterfassungWidgetMonth(month: String!): ZeiterfassungWidgetState
    updateZeiterfassungWidget: ZeiterfassungWidgetState
    setMonatsuebersichtWidgetMonth(month: String!): MonatsuebersichtWidgetState
  }

  type Query {
    user: String  
    updater: Int  
    kalenderWidget: KalenderWidgetState
    zeiterfassungWidget: ZeiterfassungWidgetState
    monatsuebersichtWidget: MonatsuebersichtWidgetState
  }
`;

const cache = new InMemoryCache();
const clientLink = withClientState({
    resolvers,
    defaults,
    cache,
    typeDefs,
});

const client = new ApolloClient({
    link: clientLink,
    cache,
});

const ApolloApp = (Widget, props = {}) => (
    <ErrorBoundary>
        <ApolloProvider client={client}>
            <Widget {...props} />
        </ApolloProvider>
    </ErrorBoundary>
);

export default ApolloApp;
