/**
 * Posts params to server under url and expects json result
 *
 * @param url
 * @param params
 * @returns {Promise<any>}
 */
export const postJson = async (url, params = {}) => {
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });
    return await rawResponse.json();
};

export const deleteAction = async (url, params = {}) => {
    const rawResponse = await fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });
    return await rawResponse.json();
};

/**
 * Fetches json from url
 *
 * @param url
 * @param queryParams
 * @returns {Promise<any>}
 */
export const getJson = async (url, queryParams = {}) => {
    const queryString = Object.keys(queryParams)
        .map(key => {
            const param = queryParams[key];
            return Array.isArray(param) ? param.map((value, index) => `${key}[${index}]=${value}`).join('&') : `${key}=${param}`;
        })
        .join('&');
    if (queryString.length > 0) {
        url += `?${queryString}`;
    }
    const result = await fetch(url);
    return await result.json();
};

export const Zeiterfassung = {};
