import React from 'react';
import KommetarForm from './KommetarForm';
import { handleFormSubmitResult } from '../../formHelpers';
import { wissenKommentarAktualisieren } from '../wissenApi';

export default ({ kommentar, toggleModal, updateKommentarInInhaltselement }) => {
    const onSubmit = async values => {
        const result = await wissenKommentarAktualisieren({
            ...kommentar,
            ...values,
        });
        if (handleFormSubmitResult(result)) {
            updateKommentarInInhaltselement(result.kommentar);
        }
    };
    return <KommetarForm initialValues={kommentar} toggleModal={toggleModal} onSubmit={values => onSubmit(values)} />;
};
