import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FrageEditAction from './FrageEditAction';

export default ({ frage, updateFrage, alleKnoten, alleTags }) => {
    const [modalOpen, toggleModal] = useState(false);

    return (
        <React.Fragment>
            <button className="btn btn-sm btn-secondary" title="Frage Bearbeiten" onClick={() => toggleModal(!modalOpen)}>
                <FontAwesomeIcon icon="edit" />
            </button>
            {modalOpen && (
                <Modal onClose={() => toggleModal(!modalOpen)} size="md" title={`Frage ${frage.bezeichnung} bearbeiten`} canClose={true}>
                    <FrageEditAction frage={frage} updateFrage={updateFrage} toggleModal={toggleModal} alleKnoten={alleKnoten} alleTags={alleTags} />
                </Modal>
            )}
        </React.Fragment>
    );
};
