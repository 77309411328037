import React from 'react';
import { tagLoeschen } from '../wissenApi';
import { handleFormSubmitResult } from '../../formHelpers';

export default ({ tag, updateTags, toggleModal }) => {
    const handleTagDelete = async () => {
        const result = await tagLoeschen(tag);
        if (handleFormSubmitResult(result, 'Tag erfolgreich gelöscht')) {
            updateTags(result.alleSortedByFirstLetter);
            toggleModal();
        }
    };
    return (
        <button className="btn btn-danger" onClick={() => handleTagDelete()}>
            Tag Löschen
        </button>
    );
};
