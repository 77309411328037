const getBadgeColor = badge => {
    switch (badge) {
        case 'Entwurf':
            return 'info';
        case 'entwurf':
            return 'info';
        case 'offen':
            return 'danger';
        case 'Offen':
            return 'danger';
        case 'in Arbeit':
            return 'primary';
        case 'in arbeit':
            return 'primary';
        case 'In Arbeit':
            return 'primary';
        case 'erledigt':
            return 'success';
        case 'Erledigt':
            return 'success';
        case 'hinfällig':
            return 'dark';
        case 'Hinfällig':
            return 'dark';
        case 'Gelöscht':
            return 'warning';
        default:
            return '';
    }
};

export default getBadgeColor;
