export const normalizeKnoten = knoten => {
    knoten.icon = null;

    if (knoten.kinder) {
        knoten.kinder.forEach(kind => normalizeKnoten(kind));
    }

    if (knoten.children) {
        knoten.children.forEach(kind => normalizeKnoten(kind));
    }

    return knoten;
};
