import React from 'react';

export default class EditableValue extends React.Component {
    state = {
        value: '',
        init: '',
        lastPropValue: '',
    };

    constructor(props) {
        super(props);
        this.state.value = this.state.init = this.state.lastPropValue = props.value;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.lastPropValue) {
            this.state.value = this.state.init = this.state.lastPropValue = nextProps.value;
            this.setState(this.state);
        }
    }

    save(value) {
        if (value === this.state.init) {
            return;
        }

        this.setState({ value, init: value });
        this.props.onChange(value);
    }

    render() {
        if (this.props.disabled) {
            return (
                <div>
                    <input className="form-control form-control-zeit-input" value={this.state.value} tabIndex={this.props.tabIndex} disabled="disabled" />
                </div>
            );
        }

        return (
            <div>
                <input
                    className="form-control form-control-zeit-input"
                    value={this.state.value}
                    onChange={e => this.setState({ value: e.target.value })}
                    onBlur={e => this.save(e.target.value)}
                    tabIndex={this.props.tabIndex}
                    onKeyUp={this.props.onKeyUp}
                />
            </div>
        );
    }
}
