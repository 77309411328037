import React from 'react';
import Modal from '../../Modal';

export default class UrlaubsantragKommentare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kommentar: props.kommentar,
        };
    }

    setKommentar = e => {
        this.setState({ kommentar: e.target.value });
    };

    render() {
        return (
            <div className="card mt-3">
                <div className="card-body">
                    <div className="form-group">
                        <label className="lead">Kommentare</label>
                        <textarea
                            className="form-control"
                            rows="3"
                            value={this.state.kommentar}
                            onChange={this.setKommentar}
                            onBlur={() => {
                                this.props.onChangeKommentar(this.state.kommentar);
                            }}
                        />
                    </div>
                    <div className="row">
                        <div className="col">
                            {this.props.showStornieren ? (
                                <Modal
                                    onConfirm={this.props.onStornieren}
                                    triggerBtnClasses={'btn btn-outline-danger'}
                                    triggerBtnDisabled={!this.props.enableStornieren}
                                    triggerBtnText={'Stornieren'}
                                    confirmBtnClasses={'btn btn-danger'}
                                    confirmBtnText={'Stornieren'}
                                    cancelBtnText={'Abbrechen'}
                                    contents={'Willst du wirklich den Urlaubsantrag stornieren? Der Vorgang kann nicht rückgängig gemacht werden.'}
                                />
                            ) : (
                                <button className="btn btn-outline-danger" onClick={this.props.onVerwerfen} disabled={!this.props.enableVerwerfen}>
                                    Verwerfen
                                </button>
                            )}
                        </div>
                        <div className="col text-right">
                            <button className="mr-2 btn btn-outline-primary" onClick={this.props.onSaveDraft} disabled={!this.props.enableSubmit}>
                                Als Entwurf speichern
                            </button>
                            <button className="btn btn-primary" onClick={this.props.onBeantragen} disabled={!this.props.enableBeantragen}>
                                Absenden
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
