import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DokumentEditAction from './DokumentEditAction';

export default ({ dokument, updateDokument, alleKnoten, alleTags }) => {
    const [modalOpen, toggleModal] = useState(false);

    return (
        <React.Fragment>
            <button className="btn btn-sm btn-secondary" title="Dokument Bearbeiten" onClick={() => toggleModal(!modalOpen)}>
                <FontAwesomeIcon icon="edit" />
            </button>
            {modalOpen && (
                <Modal onClose={() => toggleModal(!modalOpen)} size="md" title={`Dokument ${dokument.bezeichnung} bearbeiten`} canClose={true}>
                    <DokumentEditAction
                        dokument={dokument}
                        updateDokument={updateDokument}
                        toggleModal={toggleModal}
                        alleKnoten={alleKnoten}
                        alleTags={alleTags}
                    />
                </Modal>
            )}
        </React.Fragment>
    );
};
