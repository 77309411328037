import Avatar from '../../Aufgaben/Aufgabe/Avatar';
import PlaceholderPic from '../../../../../../application/public/images/dashboard/Profilbild_geschlechtsneutral.svg';
import React from 'react';
import KommentarEdit from './KommentarEdit';
import moment from 'moment';
import KommentarDelete from './KommentarDelete';
import ReactMarkdown from 'react-markdown';

export default ({ kommentar, updateKommentarInInhaltselement, deleteKommentarInInhaltselement }) => {
    return (
        <li className="list-group-item d-flex flex-column">
            <div className="w-100 text-right">
                <span>{moment(kommentar.erstelltAm).format('DD.MM.YY, hh:mm')}</span>
            </div>
            <div>
                {kommentar.ersteller.aktiv && (
                    <Avatar
                        name={kommentar.ersteller.name}
                        avatar={PlaceholderPic}
                        url={`/mitarbeiter/${kommentar.ersteller.internId}/detail`}
                        linkClassName="mb-2"
                    />
                )}
                {!kommentar.ersteller.aktiv && <span className="text-muted">{kommentar.ersteller.name}</span>}
                <ReactMarkdown source={kommentar.inhalt} className="text-muted" escapeHtml={false} />
            </div>
            <div className="d-flex justify-content-end align-items-end">
                {kommentar.istAktuellerBenutzerErstellerVonKommentar && (
                    <div>
                        <KommentarEdit kommentar={kommentar} updateKommentarInInhaltselement={updateKommentarInInhaltselement} />
                        <KommentarDelete kommentar={kommentar} deleteKommentarInInhaltselement={deleteKommentarInInhaltselement} />
                    </div>
                )}
            </div>
        </li>
    );
};
