import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KommentarAnlegenAction from './KommentarAnlegenAction';

export default ({ inhaltselement, inhaltselementTyp, addKommentarToInhaltselement, btnColor = 'secondary' }) => {
    const [modalOpen, toggleModal] = useState(false);

    return (
        <React.Fragment>
            <button className={`btn btn-sm btn-${btnColor}`} title="Inhaltselement Kommentieren" onClick={() => toggleModal(!modalOpen)}>
                <FontAwesomeIcon icon="quote-right" />
            </button>
            {modalOpen && (
                <Modal onClose={() => toggleModal(!modalOpen)} size="md" title={`Kommentar erstellen in ${inhaltselement.bezeichnung || ''}`} canClose={true}>
                    <KommentarAnlegenAction
                        toggleModal={toggleModal}
                        inhaltselementId={inhaltselement.id}
                        inhaltselementTyp={inhaltselementTyp}
                        addKommentarToInhaltselement={addKommentarToInhaltselement}
                    />
                </Modal>
            )}
        </React.Fragment>
    );
};
