import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import KnotenErzeugenForm from './KnotenErzeugenForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({ knotenParent, addNewKnotenToKnotenBaum, inhaltsTypen, benutzer, berechtigungsgruppen }) => {
    const [modalOpen, toggleModal] = useState(false);

    return (
        <React.Fragment>
            <button className="btn btn-sm btn-outline-secondary" onClick={() => toggleModal(!modalOpen)}>
                <FontAwesomeIcon icon="plus" />
            </button>
            {modalOpen && (
                <Modal onClose={() => toggleModal(!modalOpen)} size="md" title={`Knoten erstellen in ${knotenParent.name} `} canClose={true}>
                    <KnotenErzeugenForm
                        toggleModal={toggleModal}
                        knotenParent={knotenParent}
                        addNewKnotenToKnotenBaum={addNewKnotenToKnotenBaum}
                        inhaltsTypen={inhaltsTypen}
                        benutzer={benutzer}
                        berechtigungsgruppen={berechtigungsgruppen}
                    />
                </Modal>
            )}
        </React.Fragment>
    );
};
