import React from 'react';
import * as api from '../api';
import PortalButtonBar from '../PortalButtonBar';
import Select from 'react-select';
import moment from 'moment';
import MonthPicker from '../Zeiterfassung/StundenUebersichtWidget/MonthPicker';
import TooltipTrigger from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

/**
 * Monthpicker date object
 * @typedef {Object} MonthpickerDate
 * @property {number} year
 * @property {number} month
 */

/**
 * Mitarbeiter dropdown object
 * @typedef {Object} Mitarbeiter
 * @property {number} value
 * @property {string} label
 */
export default class BalkenUrlaubsuebersicht extends React.Component {
    state = {
        dataLoaded: false,
        /**
         * @type {MonthpickerDate}
         */
        selectedStartDate: {
            year: parseInt(moment().format('YYYY'), 10),
            month: parseInt(moment().format('M'), 10),
        },
        /**
         * @type {MonthpickerDate}
         */
        selectedEndDate: {
            year: parseInt(moment().format('YYYY'), 10),
            month: parseInt(moment().format('M'), 10),
        },
        /**
         * @type {Mitarbeiter[]}
         */
        selectedMitarbeiters: [],
        /**
         * @type {Mitarbeiter[]}
         */
        mitarbeiters: [],
        data: [],
        kwData: [],
    };

    async componentDidMount() {
        const json = await api.getJson(`/urlaub/uebersicht/balkenuebersicht/init`);

        const newState = {
            ...json,
            dataLoaded: true,
        };

        this.setState(newState);
    }

    /**
     * @param {number} year
     * @param {number} month
     */
    onStartDateChange = (year, month) => {
        if (
            moment(`${year}-${month}-01`, 'YYYY-M-DD').isAfter(moment(`${this.state.selectedEndDate.year}-${this.state.selectedEndDate.month}-01`, 'YYYY-M-DD'))
        ) {
            return;
        }

        // we need to update state before requesting a data update otherwise the monthpicker will close only after the request resolves
        const selectedStartDate = { year, month };
        this.setState({ selectedStartDate });

        this.updateData(selectedStartDate, this.state.selectedEndDate, this.state.selectedMitarbeiters);
    };

    /**
     * @param {number} year
     * @param {number} month
     */
    onEndDateChange = (year, month) => {
        if (
            moment(`${year}-${month}-01`, 'YYYY-M-DD').isBefore(
                moment(`${this.state.selectedStartDate.year}-${this.state.selectedStartDate.month}-01`, 'YYYY-M-DD')
            )
        ) {
            return;
        }

        // we need to update state before requesting a data update otherwise the monthpicker will close only after the request resolves
        const selectedEndDate = { year, month };
        this.setState({ selectedEndDate });

        this.updateData(this.state.selectedStartDate, selectedEndDate, this.state.selectedMitarbeiters);
    };

    onMitarbeiterChange = selectedMitarbeiters => {
        this.setState({ selectedMitarbeiters });
        this.updateData(this.state.selectedStartDate, this.state.selectedEndDate, selectedMitarbeiters);
    };

    /**
     *
     * @param {MonthpickerDate} startDate
     * @param {MonthpickerDate} endDate
     * @param {Mitarbeiter[]} selectedMitarbeiters
     * @return {Promise<void>}
     */
    updateData = async (startDate, endDate, selectedMitarbeiters) => {
        const startMoment = moment(`${startDate.year}-${startDate.month}-01`, 'YYYY-M-DD');
        const endMoment = moment(`${endDate.year}-${endDate.month}-01`, 'YYYY-M-DD').endOf('month');

        const json = await api.getJson(`/urlaub/uebersicht/balkenuebersicht/data/${startMoment.format('YYYY-MM-DD')}/${endMoment.format('YYYY-MM-DD')}`, {
            mId: selectedMitarbeiters.map(mitarbeiter => mitarbeiter.value),
        });

        let iteratedMoment = startMoment.clone().startOf('week');
        const kwData = [];
        while (iteratedMoment.isSameOrBefore(endMoment)) {
            if (iteratedMoment.isBetween(startMoment, endMoment, '[]')) {
                kwData.push({
                    moment: iteratedMoment.clone(),
                    daysSinceStart: iteratedMoment.diff(startMoment, 'days'),
                });
            }
            iteratedMoment.add(1, 'week');
        }

        const newState = {
            ...json,
            selectedStartDate: startDate,
            selectedEndDate: endDate,
            kwData,
        };
        this.setState(newState);
    };

    getSelectedMonthRangeInterlaces = () => {
        const startDate = this.state.selectedStartDate.month;
        const endDate = this.state.selectedEndDate.month;
        const interlaces = [];
        for (let i = startDate; i <= endDate; i++) {
            interlaces.push(i);
        }
        return interlaces.map(interlace =>
            moment()
                .month(interlace - 1)
                .format('MMMM')
        );
    };

    render() {
        const rangeStart = moment(`${this.state.selectedStartDate.year}-${this.state.selectedStartDate.month}-01`, 'YYYY-M-DD');
        const rangeEnd = moment(`${this.state.selectedEndDate.year}-${this.state.selectedEndDate.month}-01`, 'YYYY-M-DD').endOf('month');
        const daysInRange = rangeEnd.diff(rangeStart, 'days') + 1;

        const urlaubsToBalken = mitarbeiterUrlaubs => {
            let previousCumulativeDurationDays = 0;
            const urlaubsdatenMappedForRendering = mitarbeiterUrlaubs.map(urlaub => {
                const urlaubStart = moment(urlaub.start, 'YYYY-MM-DD');
                const urlaubEnd = moment(urlaub.end, 'YYYY-MM-DD');

                let daysSinceStart;
                let durationDays;
                if (urlaubStart.isAfter(rangeStart)) {
                    daysSinceStart = urlaubStart.diff(rangeStart, 'days');
                    durationDays = urlaubEnd.isBefore(rangeEnd) ? urlaubEnd.diff(urlaubStart, 'days') + 1 : rangeEnd.diff(urlaubStart, 'days') + 1;
                } else {
                    daysSinceStart = 0;
                    durationDays = urlaubEnd.isBefore(rangeEnd) ? urlaubEnd.diff(rangeStart, 'days') + 1 : daysInRange;
                }

                const result = {
                    previousCumulativeDurationDays,
                    daysSinceStart,
                    durationDays,
                    status: urlaub.status,
                    percentSinceStart: (daysSinceStart / daysInRange) * 100 - (previousCumulativeDurationDays / daysInRange) * 100,
                    percentDuration: (durationDays / daysInRange) * 100,
                    start: urlaubStart.format('DD. MMM YYYY'),
                    end: urlaubEnd.format('DD. MMM YYYY'),
                };

                previousCumulativeDurationDays += durationDays;
                return result;
            });
            return urlaubsdatenMappedForRendering.map((data, i) => (
                <TooltipTrigger
                    key={i}
                    placement="top"
                    trigger="hover"
                    tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
                        <div
                            {...getTooltipProps({
                                ref: tooltipRef,
                                className: 'tooltip-container',
                                /* your props here */
                            })}>
                            <div
                                {...getArrowProps({
                                    ref: arrowRef,
                                    className: 'tooltip-arrow',
                                    'data-placement': placement,
                                    /* your props here */
                                })}
                            />
                            {data.start === data.end ? data.start : `${data.start} - ${data.end}`}
                        </div>
                    )}>
                    {({ getTriggerProps, triggerRef }) => (
                        <div
                            {...getTriggerProps({
                                key: i,
                                ref: triggerRef,
                                className: `trigger balkenurlaub balkenurlaub-${data.status}`,
                                style: {
                                    left: `${data.percentSinceStart}%`,
                                    width: `${data.percentDuration}%`,
                                },
                            })}
                        />
                    )}
                </TooltipTrigger>
            ));
        };

        const interlaces = () =>
            this.state.kwData.map(kwDatum => ({
                marginLeft: (100 / daysInRange) * kwDatum.daysSinceStart,
                weekNumber: kwDatum.moment.week(),
                year: kwDatum.moment.year(),
            }));

        return (
            <React.Fragment>
                <div>
                    <PortalButtonBar>
                        <div className="row">
                            <div className="col-xl-2">
                                <div className="align-items-center row">
                                    <label className="col-xl-3 col-form-label">Start</label>
                                    <div className="col-xl-7">
                                        <MonthPicker value={this.state.selectedStartDate} onChange={this.onStartDateChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2">
                                <div className="align-items-center row">
                                    <label className="col-xl-3 col-form-label">Ende</label>
                                    <div className="col-xl-7">
                                        <MonthPicker
                                            value={this.state.selectedEndDate}
                                            onChange={this.onEndDateChange}
                                            maxValue={new Date().getFullYear() + 1}
                                        />
                                    </div>
                                </div>
                            </div>
                            {this.state.mitarbeiters.length > 1 && (
                                <div className="col-xl-6">
                                    <div className="form-group mb-0 row">
                                        <label className="col-xl-2 text-xl-center text-lg-left col-form-label">Mitarbeiter</label>
                                        <div className="col-xl-10">
                                            <Select
                                                options={this.state.mitarbeiters}
                                                value={this.state.selectedMitarbeiters}
                                                onChange={this.onMitarbeiterChange}
                                                isMulti={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </PortalButtonBar>
                </div>
                {this.state.data.length > 0 && (
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-2 pt-1 pb-1" />
                                <div className="col-10 pt-1 pb-1">
                                    <div className="row">
                                        <div className="col-12 p-0 mb-5">
                                            <div className="d-flex justify-content-between">
                                                {this.getSelectedMonthRangeInterlaces().map(month => (
                                                    <span className="w-100 month-interlace" key={month}>
                                                        {month}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2 pt-1 pb-1">
                                    <span className="text-muted kw-heading" />
                                </div>
                                <div className="col-10 pt-1 pb-1" style={{ borderRight: '1px black solid' }}>
                                    <div className="row">
                                        <div className="col-12 p-0 mb-5">
                                            <div>
                                                {interlaces().map(interlace => (
                                                    <React.Fragment key={`${interlace.year}${interlace.weekNumber}`}>
                                                        <span
                                                            style={{ marginLeft: `${interlace.marginLeft}%` }}
                                                            className={`kw-text ${interlace.weekNumber < 10 ? `kw-text-single-number` : ``}`}>
                                                            {interlace.weekNumber}
                                                        </span>
                                                        <div
                                                            style={{ marginLeft: `${interlace.marginLeft}%` }}
                                                            className="kw-interlace d-flex align-items-center justify-content-start">
                                                            <span />
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto mr-auto">{rangeStart.format('DD. MMM YYYY')}</div>
                                        <div className="col-auto">{rangeEnd.format('DD. MMM YYYY')}</div>
                                    </div>
                                </div>
                            </div>
                            {this.state.data.map((mitarbeiterData, mitarbeiterKey) => (
                                <div className="row" key={mitarbeiterKey + mitarbeiterData.mitarbeiter}>
                                    <div className="col-2 pt-1 pb-1">{mitarbeiterData.mitarbeiter}</div>
                                    <div className="col-10 p-0" style={{ borderRight: '1px black solid' }}>
                                        <div className="d-flex justify-content-between">
                                            {this.getSelectedMonthRangeInterlaces().map(month => (
                                                <span key={`month-interlace-mitarbeiter-${month}`} className="w-100 month-interlace-mitarbeiter" />
                                            ))}
                                            {interlaces().map(interlace => (
                                                <React.Fragment key={`mitarbeiter-${interlace.year}-${interlace.weekNumber}`}>
                                                    <div
                                                        style={{ marginLeft: `${interlace.marginLeft}%` }}
                                                        className="kw-interlace-mitarbeiter d-flex align-items-center justify-content-start">
                                                        <span />
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        {urlaubsToBalken(mitarbeiterData.urlaubs)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
