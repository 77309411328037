import $ from 'jquery';
import 'jquery-typeahead';

import 'jquery-typeahead/dist/jquery.typeahead.min.css';

$.typeahead({
    input: '.js-typeahead',
    minLength: 1,
    dynamic: true,
    maxItem: 15,
    template:
        '<div class="row align-items-center">' +
        '<div class="col-auto" style="width: 60px"><div class="card-img-top-cover square"><div style="background-image: url({{image}});"></div></div></div>' +
        '<div class="col text-truncate"><div class="d-flex align-items-center"><span>{{title}}</span><span class="ml-2 is-mitarbeiter-working {{isMitarbeiterWorkingNow}} krank-or-urlaub-{{isMitarbeiterKrankOrHaveUrlaub}}"></span></div>' +
        '<small class="text-muted d-block">{{description}}</small>' +
        '</div>' +
        '</div>',
    emptyTemplate: 'Kein Ergebnis für "{{query}}"',
    group: {
        key: 'category',
        template: item => {
            return {
                projekt: 'Projekte',
                mitarbeiter: 'Mitarbeiter',
                standort: 'Standorte',
            }[item.category];
        },
    },
    source: {
        group: {
            href: '{{href}}',
            display: ['title', 'description', 'rest', 'isMitarbeiterWorkingNow', 'isMitarbeiterKrankOrHaveUrlaub'],
            ajax: {
                url: '/search/{{query}}/',
                path: 'data',
            },
        },
    },
});
