import React, { Component } from 'react';
import * as api from '../api';
import moment from 'moment';
import Filter from './StundenUebersichtWidget/ProjektUebersichtFilter';
import Header from './StundenUebersichtWidget/ProjektUebersichtHeader';
import BodyGroupedByMitarbeiter from './StundenUebersichtWidget/ProjektUebersichtMitarbeiterGroup';
import BodyGroupedByMonth from './StundenUebersichtWidget/ProjektUebersichtMonthGroup';
import PortalButtonBar from '../PortalButtonBar';

moment.locale('de');

const SORT_BY = { NAME: Symbol('SORT_NAME'), FUNCTION: Symbol('SORT_FUNCTION') };
const GROUP_BY = { MITARBEITER: Symbol('GROUP_MA'), MONTH: Symbol('GROUP_MONTH') };

export default class ProjektStundenUebersichtWidget extends Component {
    state = {
        dataLoaded: false,
        loadingProjektUebersicht: false,
        uebersicht: {},
        projekts: {},
        mitarbeiters: {},
        earliestDate: {}, // { year: 2019, month: 1 }
        latestDate: {}, // { year: 2019, month: 1 }
        selectedStartDate: {}, // { year: 2019, month: 1 }
        selectedEndDate: {}, // { year: 2019, month: 2 }
        selectedDates: [], // [{ year: 2019, month: 1 }, ...]
        selectedProjektId: null,
        sortBy: SORT_BY.NAME,
        groupBy: GROUP_BY.MITARBEITER,
        singleMitarbeiter: false,
    };

    async componentDidMount() {
        const json = await api.getJson(`/zeiterfassung/stundenuebersicht/projekt/initial-data/`);

        const earliestDate = {
            year: parseInt(json.earliestDate.year, 10),
            month: parseInt(json.earliestDate.month, 10),
        };
        const latestDate = {
            year: parseInt(json.latestDate.year, 10),
            month: parseInt(json.latestDate.month, 10),
        };

        const newState = {
            ...json,
            dataLoaded: true,
            earliestDate,
            latestDate,
            selectedStartDate: earliestDate,
            selectedEndDate: latestDate,
            selectedDates: this.calculateSelectedDates(earliestDate, latestDate),
            selectedProjektId: null,
            singleMitarbeiter: Object.keys(json.mitarbeiters).length === 1,
        };
        if (Array.isArray(newState.uebersicht)) {
            newState.uebersicht = {};
        }

        this.setState(newState);
    }

    calculateSelectedDates = (startDate, endDate) => {
        const selectedDates = [];
        for (let year = startDate.year; year <= endDate.year; year++) {
            for (let month = 1; month <= 12; month++) {
                if ((year === startDate.year && month < startDate.month) || (year === endDate.year && month > endDate.month)) {
                    continue;
                }

                selectedDates.push({ year, month });
            }
        }
        return selectedDates;
    };

    onStartDateChange = (year, month) => {
        if (
            moment(`${year}-${month}-01`, 'YYYY-M-DD').isAfter(moment(`${this.state.selectedEndDate.year}-${this.state.selectedEndDate.month}-01`, 'YYYY-M-DD'))
        ) {
            return;
        }

        const selectedStartDate = { year, month };
        this.setState({
            selectedStartDate,
            selectedDates: this.calculateSelectedDates(selectedStartDate, this.state.selectedEndDate),
        });
    };

    onEndDateChange = (year, month) => {
        if (
            moment(`${year}-${month}-01`, 'YYYY-M-DD').isBefore(
                moment(`${this.state.selectedStartDate.year}-${this.state.selectedStartDate.month}-01`, 'YYYY-M-DD')
            )
        ) {
            return;
        }

        const selectedEndDate = { year, month };
        this.setState({
            selectedEndDate,
            selectedDates: this.calculateSelectedDates(this.state.selectedStartDate, selectedEndDate),
        });
    };

    onProjektChange = async selectedProjekt => {
        const uebersicht = this.state.uebersicht;
        const pId = selectedProjekt.value;

        if (uebersicht.hasOwnProperty(pId)) {
            this.setState({ selectedProjektId: selectedProjekt.value });
            return;
        }

        this.setState({ loadingProjektUebersicht: true });
        const json = await api.getJson(`/zeiterfassung/stundenuebersicht/projekt/projekt-data/${pId}`);
        uebersicht[pId] = json.uebersicht[pId];
        this.setState({ uebersicht, selectedProjektId: pId, loadingProjektUebersicht: false });
    };

    makePdf = () => {
        this.makeFile('pdf');
    };

    makeFile = format => {
        const pId = this.state.selectedProjektId;
        const startDate = moment(`${this.state.selectedStartDate.year}-${this.state.selectedStartDate.month}-01`, 'YYYY-M-DD').format('YYYY-MM-DD');
        const endDate = moment(`${this.state.selectedEndDate.year}-${this.state.selectedEndDate.month}-01`, 'YYYY-M-DD')
            .endOf('month')
            .format('YYYY-MM-DD');
        const group = this.state.groupBy === GROUP_BY.MITARBEITER ? 'mitarbeiter' : 'month';
        const sort = this.state.sortBy === SORT_BY.NAME ? 'name' : 'function';

        const url = `/zeiterfassung/stundenuebersicht/projekt/file/${pId}/${startDate}/${endDate}/${group}/${sort}.${format}`;
        if (format === 'pdf') {
            window.open(url, '_blank');
        } else {
            location = url;
        }
    };

    makeXlsx = () => {
        this.makeFile('xlsx');
    };

    onGroupByChange = groupBy => {
        this.setState({ groupBy });
    };

    onSortByChange = sortBy => {
        this.setState({ sortBy });
    };

    render() {
        if (!this.state.dataLoaded) {
            return <div>Loading</div>;
        }

        return (
            <div>
                <PortalButtonBar>
                    <Filter
                        selectedProjektId={this.state.selectedProjektId}
                        projekts={this.state.projekts}
                        onProjektChange={this.onProjektChange}
                        startDate={this.state.selectedStartDate}
                        endDate={this.state.selectedEndDate}
                        minDate={this.state.earliestDate}
                        maxDate={this.state.latestDate}
                        onStartDateChange={this.onStartDateChange}
                        onEndDateChange={this.onEndDateChange}
                        onGroupByChange={this.onGroupByChange}
                        onSortByChange={this.onSortByChange}
                        sortOptions={SORT_BY}
                        groupOptions={GROUP_BY}
                        sortBy={this.state.sortBy}
                        groupBy={this.state.groupBy}
                        singleMitarbeiter={this.state.singleMitarbeiter}
                        onPdfButtonClick={this.makePdf}
                        onXlsxButtonClick={this.makeXlsx}
                    />
                </PortalButtonBar>
                {this.state.loadingProjektUebersicht ? (
                    <div>Loading</div>
                ) : (
                    <div className="projekt-stunden-uebersicht--widget card table-responsive-xl">
                        <table className="table table-striped  table-small mb-0" id="reportTable">
                            <Header projekt={this.state.uebersicht[this.state.selectedProjektId]} selectedProjektId={this.state.selectedProjektId} />
                            {this.state.groupBy === GROUP_BY.MITARBEITER && (
                                <BodyGroupedByMitarbeiter
                                    projekt={this.state.uebersicht[this.state.selectedProjektId]}
                                    mitarbeiters={this.state.mitarbeiters}
                                    selectedDates={this.state.selectedDates}
                                    sortBy={this.state.sortBy}
                                    sortOptions={SORT_BY}
                                    singleMitarbeiter={this.state.singleMitarbeiter}
                                />
                            )}
                            {this.state.groupBy === GROUP_BY.MONTH && (
                                <BodyGroupedByMonth
                                    projekt={this.state.uebersicht[this.state.selectedProjektId]}
                                    mitarbeiters={this.state.mitarbeiters}
                                    selectedDates={this.state.selectedDates}
                                    sortBy={this.state.sortBy}
                                    sortOptions={SORT_BY}
                                />
                            )}
                        </table>
                    </div>
                )}
            </div>
        );
    }
}
