import React, { Component } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

export default class MitarbeiterUebersichtHeader extends Component {
    render() {
        const mitarbeiter = this.props.mitarbeiter;
        const standorte = this.props.standorte;

        const startMoment = moment(`${this.props.startDate.year}-${this.props.startDate.month}-01`, 'YYYY-M-DD');
        const endMoment = moment(`${this.props.endDate.year}-${this.props.endDate.month}-01`, 'YYYY-M-DD').endOf('month');
        const sortKonditionenAscending = (a, b) => {
            const momentA = moment(a.start, 'YYYY-MM-DD');
            const momentB = moment(b.start, 'YYYY-MM-DD');
            return momentA.isBefore(momentB) ? -1 : momentB.isBefore(momentA) ? 1 : 0;
        };

        // first step is to find all arbeitskonditionen which are applicable for the selected time period
        let allApplicableKonditionen = [];

        // for starters, all arbeitskonditionen that start within the selected time period are applicable
        const inPeriodKonditionen = mitarbeiter.arbeitskonditionen.filter(arbeitskondition => {
            const konditionStart = moment(arbeitskondition.start, 'YYYY-MM-DD');
            return konditionStart >= startMoment && konditionStart < endMoment;
        });
        inPeriodKonditionen.sort(sortKonditionenAscending);
        allApplicableKonditionen = allApplicableKonditionen.concat(inPeriodKonditionen);

        /*
         * if no arbeitskonditionen start during the selected time period, as well as if the first arbeitskondition
         * starting during the selected time period does not actually start on the start date of the period, we must
         * also fetch the last arbeitskondition which started before the selected time period, since it's the one which
         * is "active" until the first arbeitskondition starting during the period actually starts
         */
        if (inPeriodKonditionen.length === 0 || !moment(inPeriodKonditionen[0].start).isSame(startMoment)) {
            const beforePeriodKonditionen = mitarbeiter.arbeitskonditionen.filter(arbeitskondition => {
                return moment(arbeitskondition.start, 'YYYY-MM-DD') < startMoment;
            });
            beforePeriodKonditionen.sort(sortKonditionenAscending);
            if (beforePeriodKonditionen.length > 0) {
                allApplicableKonditionen.push(beforePeriodKonditionen[beforePeriodKonditionen.length - 1]);
            }
        }

        allApplicableKonditionen.sort(sortKonditionenAscending);

        /*
         * We have collected all applicable arbeitskonditionen; now it is time to pack them into a data structure which
         * can be easily used to render the data.
         *
         * Standort arbeitszeiten object
         * @typedef {Object} StandortData
         * @property {string} name
         * @property {ArbeitszeitenData} zeiten
         *
         * Standort arbeitszeiten ArbeitszeitenData
         * @typedef {Object} StandortData
         * @property {string} zeiten
         * @property {string} start
         */
        let standortData;

        if (allApplicableKonditionen.length === 0) {
            /*
             * If there are no applicable arbeitskonditionen, we just display the standorts from MitarbeiterStandort
             * with "unknown" arbeitszeiten
             */
            standortData = mitarbeiter.standorte
                // for every standort in MitarbeiterStandort, construct a StandortData object
                .map(standortId => {
                    return {
                        name: standorte[standortId] !== undefined ? standorte[standortId].name : '',
                        zeiten: [{ zeiten: 'Unbekannte Regelarbeitszeiten', start: null }],
                    };
                })
                // filter out standorte without a name
                .filter(standort => standort.name !== '');
            // throw out doubles
            standortData = _.uniqBy(standortData, standort => standort.name);
            if (standortData.length === 0) {
                standortData = [
                    {
                        name: 'Unbekannter Standort',
                        zeiten: [{ zeiten: 'Unbekannte Regelarbeitszeiten', start: null }],
                    },
                ];
            }
        } else {
            /*
             * If there are applicable arbeitskonditionen, iterate through all of them, group them by standord and
             * construct appropriate objects.
             */
            standortData = allApplicableKonditionen.reduce((carry, kondition) => {
                const standortName = standorte[kondition.standortId] === undefined ? 'Unbekannter Standort' : standorte[kondition.standortId].name;
                let standortKondition = carry.find(konditionInCarry => konditionInCarry.name === standortName);
                if (standortKondition === undefined) {
                    standortKondition = {
                        name: standortName,
                        zeiten: [],
                    };
                    carry.push(standortKondition);
                }

                standortKondition.zeiten.push({
                    zeiten: `MO: ${kondition.mo} / DI: ${kondition.di} / MI: ${kondition.mi} / DO: ${kondition.do} / FR: ${kondition.fr} / SA: ${
                        kondition.sa
                    } / SO: ${kondition.so}. Teilzeitfaktor: ${numeral(kondition.teilzeitfaktor).format('0.00')}`,
                    start: moment(kondition.start, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                });

                return carry;
            }, []);
        }

        const getArbeitszeitenString = zeiten => `${zeiten.start ? `Ab ${zeiten.start}: ` : ''}${zeiten.zeiten}`;
        const getMitarbeiterString = mitarbeiter => `${mitarbeiter.titel || ''} ${mitarbeiter.name} ${mitarbeiter.nachname}`;

        return (
            <tr>
                <td>{getMitarbeiterString(mitarbeiter)}</td>
                <td colSpan={this.props.colSpan - 1}>
                    <table className="table-borderless">
                        <thead>
                            {standortData.map((rowContent, i) => (
                                <tr key={`standort${i}`}>
                                    <td style={{ padding: 0 }}>{rowContent.name}</td>
                                    <td style={{ padding: '0 0 0 1.5rem' }}>
                                        {rowContent.zeiten.map((zeiten, j) => (
                                            <div key={`standort${i}zeit${j}`} className="text-muted">
                                                {getArbeitszeitenString(zeiten)}
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                            ))}
                        </thead>
                    </table>
                </td>
            </tr>
        );
    }
}
