import ReactDOM from 'react-dom';
import React from 'react';
import { KEY_ESC } from './KeyBindings';

export default class Modal extends React.Component {
    static defaultProps = {
        onClose: () => null,
        footer: null,
    };

    state = {
        animate: false,
    };

    open = () => {
        this.setState({ animate: true });
    };

    close = () => {
        if (this.props.checkIfDirty) {
            this.props.modalWillCloseDirty(true);
            return;
        }
        if (this.props.modalWillCloseDirty) {
            this.props.modalWillCloseDirty(false);
        }

        this.setState({ animate: false });
        setTimeout(() => this.props.onClose(), 1000);
    };

    handleKeyUp = e => {
        e.preventDefault();
        const keys = {
            [KEY_ESC]: () => this.close(),
        };
        if (keys[e.keyCode]) {
            keys[e.keyCode]();
        }
    };

    componentDidMount = () => {
        window.addEventListener('keyup', this.handleKeyUp, false);
        setTimeout(() => this.open(), 100);
    };

    componentWillUnmount = () => window.removeEventListener('keyup', this.handleKeyUp, false);

    render() {
        const { animate } = this.state;
        const { children, size, title, canClose, footer } = this.props;
        return ReactDOM.createPortal(
            <React.Fragment>
                <div className={`modal ${animate ? 'show' : 'hide'}`}>
                    <div className={`modal-dialog modal-dialog-centered modal-${size}`}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{title}</h5>
                                {canClose && (
                                    <button className="close" onClick={() => this.close()}>
                                        <span>&times;</span>
                                    </button>
                                )}
                            </div>
                            <div className="modal-body">{children}</div>
                            {footer && <div className="modal-footer">{footer}</div>}
                        </div>
                    </div>
                </div>
                <div className={`modal-backdrop ${animate ? 'show' : 'hide'}`} />
            </React.Fragment>,
            document.getElementById('modal-portal')
        );
    }
}
