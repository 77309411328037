import React, { useState } from 'react';
import PlaceholderPic from '../../../../../../application/public/images/dashboard/Profilbild_geschlechtsneutral.svg';
import Avatar from '../../Aufgaben/Aufgabe/Avatar';
import List from '../InhaltsElemente/List';
import DokumentAnlegen from '../Dokument/DokumentAnlegen';
import { CardTemplate } from '../ViewTemplates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WISSEN_INHALTSTYP_DOKUMENT, WISSEN_INHALTSTYP_FRAGE, WISSEN_INHALTSTYP_WIKI } from '../WissenConstants';
import { getInhaltsTypOfBackend } from '../getInhaltsTyp';
import WikiAnlegen from '../Wiki/WikiAnlegen';
import moment from 'moment';
import FrageAnlegen from '../FrageAntwort/FrageAnlegen';
import Tree from 'rc-tree';
import { createBaumDataFromAllKnoten } from './createBaumDataFromAllKnoten';
import InhaltsElementeSlider from '../InhaltsElementeSlider';
import { faChevronRight, faChevronDown } from '@fortawesome/fontawesome-free-solid';
import _ from 'lodash';

React.useLayoutEffect = React.useEffect;

const TAB_FOKUSTHEMEN = 'fokusthemen';
const TAB_NEUSTE_INHALTE = 'neuste_inhalte';

export default initialProps => {
    const [knoten, setKnoten] = useState(initialProps.knoten);
    const [expandedKeys, setExpandedKeys] = useState([initialProps.knoten.id, initialProps.knoten.parent ? initialProps.knoten.parent.id : '']);

    const [fokusthemen, setFokusthemen] = useState(initialProps.alleFokusthemen);

    const [filterOpen, setFilterOpen] = useState({
        [WISSEN_INHALTSTYP_DOKUMENT]: false,
        [WISSEN_INHALTSTYP_FRAGE]: false,
        [WISSEN_INHALTSTYP_WIKI]: false,
    });

    const [openTab, setOpenTab] = useState(TAB_FOKUSTHEMEN);

    const addNewDokumentToKnoten = dokument =>
        setKnoten({
            ...knoten,
            dokumente: [...knoten.dokumente, dokument],
        });

    const addNewWikiToKnoten = wiki =>
        setKnoten({
            ...knoten,
            wikis: [...knoten.wikis, wiki],
        });

    const addNewFrageAntwortToKnoten = frageAntwort =>
        setKnoten({
            ...knoten,
            fragen: [...knoten.fragen, frageAntwort],
        });

    const inhaltsElemente = [
        { typ: WISSEN_INHALTSTYP_DOKUMENT, elemente: knoten.dokumente },
        { typ: WISSEN_INHALTSTYP_FRAGE, elemente: knoten.fragen },
        { typ: WISSEN_INHALTSTYP_WIKI, elemente: knoten.wikis },
    ];

    const actionAllowed = buttonTyp => knoten.inhaltstypen.find(typ => typ.inhaltstyp === buttonTyp);

    const goToKnoten = knotenId => {
        if (!knotenId || knotenId.length <= 0) {
            return;
        }

        const fn = _.debounce(() => {
            window.location.href = `/wissen/${knotenId}/details/`;
        }, 200);

        fn();
    };

    const expandAll = () => {
        const ids = initialProps.alleKnoten.map(knoten => knoten.id);
        setExpandedKeys(expandedKeys.length > 0 ? [] : ids);
    };

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <ul className="nav nav-tabs nav-tabs-uebersicht">
                        <li className="nav-item">
                            <div
                                onClick={() => setOpenTab(TAB_FOKUSTHEMEN)}
                                className={`border-top border-left border-right urlaubsantrag-verwalten-tab ${
                                    openTab === TAB_FOKUSTHEMEN ? 'tab-active' : ''
                                }`}>
                                <p className="pl-4 pr-4 pt-2 pb-2 m-0">Fokusthemen</p>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div
                                onClick={() => setOpenTab(TAB_NEUSTE_INHALTE)}
                                className={`border-top border-left border-right urlaubsantrag-verwalten-tab ${
                                    openTab === TAB_NEUSTE_INHALTE ? 'tab-active' : ''
                                }`}>
                                <p className="pl-4 pr-4 pt-2 pb-2 m-0">Neuste Inhalte</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-12">
                    {fokusthemen.length <= 6 && fokusthemen.length !== 0 && openTab === TAB_FOKUSTHEMEN && (
                        <div className="container-fluid card border-top-0">
                            <div className="row p-3">
                                {fokusthemen.map(element => (
                                    <div className="col-12 col-sm-4 col-xl-2 mt-3 mt-xl-0" key={element.id}>
                                        <ImportantCard element={element} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {fokusthemen.length > 6 && openTab === TAB_FOKUSTHEMEN && <InhaltsElementeSlider inhaltselemente={fokusthemen} slidesPerView={6} />}
                    {initialProps.neusteInhaltselemente.length <= 6 && initialProps.neusteInhaltselemente.length !== 0 && openTab === TAB_NEUSTE_INHALTE && (
                        <div className="container-fluid card border-top-0">
                            <div className="row p-3">
                                {initialProps.neusteInhaltselemente.map(element => (
                                    <div className="col-12 col-sm-4 col-xl-2 mt-3 mt-xl-0" key={element.id}>
                                        <ImportantCard element={element} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {initialProps.neusteInhaltselemente.length > 6 && openTab === TAB_NEUSTE_INHALTE && (
                        <InhaltsElementeSlider inhaltselemente={initialProps.neusteInhaltselemente} slidesPerView={6} />
                    )}
                </div>
            </div>
            <div className="row mt-2 mb-5">
                <div className="col-md-12 col-lg-3 d-flex flex-column">
                    <CardTemplate
                        classNames="card"
                        heading={
                            <div className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon icon="graduation-cap" />
                                    <span className="text-dark ml-2">Wissensbaum</span>
                                </div>
                                <div className="pointer" onClick={() => expandAll()}>
                                    {expandedKeys.length > 0 ? <FontAwesomeIcon icon="minus-square" /> : <FontAwesomeIcon icon="plus-square" />}
                                </div>
                            </div>
                        }
                        bodyClass="p-1 overflow-scroll-x">
                        <Tree
                            className="knoten-details-tree"
                            switcherIcon={data => {
                                if (data.isLeaf) {
                                    return false;
                                }
                                return data.expanded ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />;
                            }}
                            onSelect={knotenID => goToKnoten(knotenID)}
                            treeData={createBaumDataFromAllKnoten(initialProps.alleKnoten, knoten.id, true)}
                            defaultSelectedKeys={[initialProps.knoten.id]}
                            expandedKeys={expandedKeys}
                        />
                    </CardTemplate>
                </div>
                <div className="col-md-12 col-lg-9 card">
                    <div className="card-header d-flex border-bottom-0 mb-3 bg-transparent">
                        <h2>{knoten.name}</h2>
                        {knoten.moderatoren.length !== 0 && (
                            <div className="ml-auto">
                                <ul className="list-group list-unstyled list-group-flush">
                                    {knoten.moderatoren.map(moderator => (
                                        <li className="p-1 align-items-center d-flex justify-content-between" key={moderator.internId}>
                                            <span className="mr-2">Moderator :</span>
                                            <Avatar url={`/mitarbeiter/${moderator.internId}/details`} name={moderator.name} avatar={PlaceholderPic} />
                                        </li>
                                    ))}
                                    <li className="p-1">
                                        <span>Erstellt am: {moment(knoten.erzeugtAm).format('DD.MM.YYYY')}</span>
                                    </li>
                                    <li className="p-1">
                                        <span>Geändert am: {moment(knoten.aktualisiertAm).format('DD.MM.YYYY')}</span>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    {knoten.angepinnteInhaltselemente.length > 4 && (
                        <CardTemplate
                            heading={
                                <div>
                                    <FontAwesomeIcon icon="thumbtack" />
                                    <span className="ml-2">Angepinnte Inhaltselemente</span>
                                </div>
                            }
                            classNames="mb-3"
                            bodyClass="p-2">
                            <InhaltsElementeSlider
                                inhaltselemente={knoten.angepinnteInhaltselemente}
                                slidesPerView={5}
                                breakpoints={{
                                    992: {
                                        slidesPerView: 3,
                                    },
                                }}
                            />
                        </CardTemplate>
                    )}
                    {knoten.angepinnteInhaltselemente.length <= 4 && knoten.angepinnteInhaltselemente.length !== 0 && (
                        <CardTemplate
                            heading={
                                <div>
                                    <FontAwesomeIcon icon="thumbtack" />
                                    <span className="ml-2 text-dark">Angepinnte Inhaltselemente</span>
                                </div>
                            }
                            classNames="mb-3"
                            bodyClass="pt-0 pl-2 pr-2 pb-3">
                            <div className="container-fluid">
                                <div className="row">
                                    {knoten.angepinnteInhaltselemente.map(element => (
                                        <div className="col-12 col-sm-4 col-xl-3 mt-3" key={element.id}>
                                            <ImportantCard element={element} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </CardTemplate>
                    )}
                    {inhaltsElemente.map((element, index) => {
                        const inhaltsTyp = element.typ;
                        return (
                            <CardTemplate
                                bodyClass="p-0"
                                key={element.typ}
                                heading={
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            {inhaltsTyp === WISSEN_INHALTSTYP_DOKUMENT && <FontAwesomeIcon icon="file-alt" />}
                                            {inhaltsTyp === WISSEN_INHALTSTYP_WIKI && <FontAwesomeIcon icon="book" />}
                                            {inhaltsTyp === WISSEN_INHALTSTYP_FRAGE && <FontAwesomeIcon icon="question-circle" />}
                                            <span className="ml-2 text-dark">{getInhaltsTypHeadline(inhaltsTyp)}</span>
                                        </div>
                                        <div className="btn-group">
                                            {element.elemente.length !== 0 && (
                                                <button
                                                    className="btn btn-sm btn-outline-secondary"
                                                    onClick={() =>
                                                        setFilterOpen({
                                                            ...filterOpen,
                                                            [inhaltsTyp]: !filterOpen[inhaltsTyp],
                                                        })
                                                    }>
                                                    <FontAwesomeIcon icon="filter" />
                                                    <span className="ml-2">Filter</span>
                                                </button>
                                            )}
                                            {actionAllowed(getInhaltsTypOfBackend(WISSEN_INHALTSTYP_FRAGE)) && inhaltsTyp === WISSEN_INHALTSTYP_FRAGE && (
                                                <FrageAnlegen
                                                    addNewFrageToKnoten={frageAntwort => addNewFrageAntwortToKnoten(frageAntwort)}
                                                    knoten={knoten}
                                                    alleTags={initialProps.alleTags}
                                                />
                                            )}
                                            {actionAllowed(getInhaltsTypOfBackend(WISSEN_INHALTSTYP_WIKI)) && inhaltsTyp === WISSEN_INHALTSTYP_WIKI && (
                                                <WikiAnlegen
                                                    addNewWikiToKnoten={wiki => addNewWikiToKnoten(wiki)}
                                                    knoten={knoten}
                                                    alleTags={initialProps.alleTags}
                                                />
                                            )}
                                            {actionAllowed(getInhaltsTypOfBackend(WISSEN_INHALTSTYP_DOKUMENT)) && inhaltsTyp === WISSEN_INHALTSTYP_DOKUMENT && (
                                                <DokumentAnlegen
                                                    addNewDokumentToKnoten={dokument => addNewDokumentToKnoten(dokument)}
                                                    knoten={knoten}
                                                    alleTags={initialProps.alleTags}
                                                />
                                            )}
                                        </div>
                                    </div>
                                }
                                classNames={`${!actionAllowed(getInhaltsTypOfBackend(inhaltsTyp)) ? 'd-none' : ''} mb-3`}
                                headingClass={`${inhaltsTyp === WISSEN_INHALTSTYP_DOKUMENT ? '' : 'border-bottom-0'} text-dark`}>
                                <List
                                    showFilter={filterOpen[inhaltsTyp]}
                                    inhaltsTyp={inhaltsTyp}
                                    inhaltsElemente={element.elemente.map(element => ({
                                        ...element,
                                        knotenId: knoten.id,
                                    }))}
                                    angepinnteInhaltselemente={knoten.angepinnteInhaltselemente}
                                    pinInhaltselement={element =>
                                        setKnoten({
                                            ...knoten,
                                            angepinnteInhaltselemente: [...knoten.angepinnteInhaltselemente, element],
                                        })
                                    }
                                    setFokusthemen={setFokusthemen}
                                    fokusthemen={fokusthemen}
                                    unpinInhaltselement={element =>
                                        setKnoten({
                                            ...knoten,
                                            angepinnteInhaltselemente: knoten.angepinnteInhaltselemente.filter(
                                                angepinntesInhaltselement => angepinntesInhaltselement.id !== element.id
                                            ),
                                        })
                                    }
                                    isModerator={initialProps.benutzerIstModerator}
                                />
                            </CardTemplate>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export const ImportantCard = ({ element }) => (
    <a href={`/wissen/${element.id}/details`} className="important-card">
        <div className="card shadow-sm h-100">
            <div className="card-header bg-primary important-card-heading">
                <small>{element.knoten.name}</small>
            </div>
            <div className="card-body pb-2 d-flex flex-column justify-content-between">
                <p className="text-dark font-weight-bold">{element.bezeichnung}</p>
                <div>
                    <hr className="mb-0 w-100" />
                    <small className="text-muted">
                        <span>{element.ersteller.name}</span>
                        <span className="ml-3">{moment(element.erstelltAm).format('DD.MM.YYYY')}</span>
                    </small>
                </div>
            </div>
        </div>
    </a>
);

const getInhaltsTypHeadline = typ => {
    switch (typ) {
        case WISSEN_INHALTSTYP_DOKUMENT:
            return 'Dokumente';
        case WISSEN_INHALTSTYP_FRAGE:
            return 'Fragen';
        case WISSEN_INHALTSTYP_WIKI:
            return 'Wiki - Einträge';
        default:
            return '';
    }
};
