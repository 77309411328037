import React, { Component } from 'react';
import { formatMinsAsHrs } from '../StundenUebersichtWidget/utils';
import Collapser from './Collapser';

export default class MitarbeiterUebersichtProject extends Component {
    render() {
        const project = this.props.project;
        const projectKey = this.props.projectKey;
        const isOnlyProject = this.props.isOnlyProject;

        return (
            <tr>
                <td>
                    <div className="text-truncate" title={project.name} style={{ 'max-width': '300px' }}>
                        {!isOnlyProject && project.bauprojekt && (
                            <React.Fragment>
                                <Collapser target={`.collapse-${projectKey}`} classNames="pr-2" />
                            </React.Fragment>
                        )}
                        {project.nr} {project.name}
                    </div>
                    {project.lphNames.map((lph, i) => (
                        <div key={i} className={`text-muted pl-3 ${!isOnlyProject ? `collapse collapse-${projectKey}` : ''}`}>
                            {lph}
                        </div>
                    ))}
                </td>
                {project.dateColumns.map((dateColumn, i) => (
                    <td key={i} className="text-right">
                        {formatMinsAsHrs(dateColumn.allLphMinutes)}
                        {dateColumn.individualLphs.map((lph, j) => (
                            <div key={j} className={`text-muted ${!isOnlyProject ? `collapse collapse-${projectKey}` : ''}`}>
                                {formatMinsAsHrs(lph.totalMinutes)}
                            </div>
                        ))}
                    </td>
                ))}
                <td className="text-right">
                    {formatMinsAsHrs(project.sumColumn.projektMinutes)}
                    {project.sumColumn.lphSums.map((lph, i) => (
                        <div key={i} className={`text-muted ${!isOnlyProject ? `collapse collapse-${projectKey}` : ''}`}>
                            {formatMinsAsHrs(lph.totalMinutes)}
                        </div>
                    ))}
                </td>
            </tr>
        );
    }
}
