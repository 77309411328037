import React, { Component } from 'react';
import { formatMinsAsHrs } from '../StundenUebersichtWidget/utils';

export default class MitarbeiterUebersichtFooter extends Component {
    render() {
        return (
            <tr>
                <td key="label" className="text-right font-weight-bold">
                    {this.props.label}
                </td>
                {this.props.monthValues.map((datum, i) => (
                    <td key={i} className="text-right font-weight-bold">
                        {formatMinsAsHrs(datum)}
                    </td>
                ))}
                <td key="sum" className="text-right font-weight-bold">
                    {formatMinsAsHrs(this.props.sumValue)}
                </td>
            </tr>
        );
    }
}
