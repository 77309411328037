import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KommentarEditAction from './KommentarEditAction';

export default ({ kommentar, updateKommentarInInhaltselement }) => {
    const [modalOpen, toggleModal] = useState(false);

    return (
        <React.Fragment>
            <button className="btn btn-sm btn-outline-secondary" title="Bearbeiten" onClick={() => toggleModal(!modalOpen)}>
                <FontAwesomeIcon icon="edit" />
            </button>
            {modalOpen && (
                <Modal onClose={() => toggleModal(!modalOpen)} size="md" title="Kommentar bearbeiten" canClose={true}>
                    <KommentarEditAction kommentar={kommentar} toggleModal={toggleModal} updateKommentarInInhaltselement={updateKommentarInInhaltselement} />
                </Modal>
            )}
        </React.Fragment>
    );
};
