import React, { useState } from 'react';
import MultiFileUpload from '../Datei/MultiFileUpload';
import { WysiwygEditor } from '../../WysiwygSSREditor/WysiwygEditor';

export default ({ antwort = { inhalt: '' }, submitAntwort, toggleEditor }) => {
    const [dateien, setDateien] = useState([]);
    const [inhalt, setInhalt] = useState(antwort ? antwort.inhalt : '');

    return (
        <div>
            <div className="d-flex flex-column">
                <div className="form-group">
                    <WysiwygEditor value={inhalt} setValue={setInhalt} />
                    <MultiFileUpload dateien={dateien} setDateien={dateien => setDateien(dateien)} />
                    <div className="btn-group">
                        <button
                            className={`${inhalt.length === 0 ? 'element-disabled' : ''} btn btn-primary btn-sm ml-auto`}
                            onClick={() => submitAntwort({ ...antwort, inhalt, dateien })}>
                            OK
                        </button>
                        <button className="btn btn-secondary btn-sm" onClick={() => toggleEditor(false)}>
                            Abbrechen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
