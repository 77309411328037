import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { findKnotenById, InputField, required, transformItemsWithIdAndNameForBackend, transformItemsWithIdAndNameForReactSelect } from '../../formHelpers';
import InhaltsElementVerschieben from '../InhaltsElemente/InhaltsElementVerschieben';
import MultiFileUpload from '../Datei/MultiFileUpload';
import TagDropdown from '../Tags/TagDropdown';
import { WysiwygEditor } from '../../WysiwygSSREditor/WysiwygEditor';

export default ({ onSubmit, initialValues = null, toggleModal, alleKnoten, knoten, alleTags }) => {
    const [dateien, setDateien] = useState(initialValues ? initialValues.dateien : []);
    const [tags, setTags] = useState(initialValues ? transformItemsWithIdAndNameForReactSelect(initialValues.tags) : []);
    const [value, setValue] = useState(initialValues ? initialValues.inhalt : '');

    return (
        <Form
            onSubmit={values =>
                onSubmit({
                    ...values,
                    dateien,
                    knoten: initialValues ? findKnotenById(alleKnoten, values.neuerKnoten.value) : knoten,
                    tags: transformItemsWithIdAndNameForBackend(tags),
                    inhalt: value,
                })
            }
            initialValues={initialValues}
            render={({ handleSubmit, submitting, invalid }) => (
                <form onSubmit={handleSubmit}>
                    <div className="container-fluid">
                        <div className="row">
                            <InputField label="Bezeichnung">
                                <Field name="bezeichnung" component="input" className="form-control" validate={required} autoFocus>
                                    {props => (
                                        <input
                                            autoFocus
                                            placeholder={props.meta.error && props.meta.touched ? props.meta.error : ''}
                                            {...props.input}
                                            className={`form-control ${props.meta.error && props.meta.touched && 'font-weight-bold border-danger text-danger'}`}
                                        />
                                    )}
                                </Field>
                            </InputField>
                        </div>
                        <div className="row">
                            {initialValues && <InhaltsElementVerschieben alleKnoten={alleKnoten} aktuellerKnoten={initialValues.knoten} />}
                        </div>
                        <div className="row">
                            <InputField label="Inhalt">
                                <WysiwygEditor value={value} setValue={setValue} />
                            </InputField>
                        </div>
                        <div className="row">
                            <InputField label="Tags verwalten">
                                <TagDropdown alleTags={alleTags} tags={tags} setTags={tags => setTags(tags)} />
                            </InputField>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex align-items-start flex-column">
                                <MultiFileUpload dateien={dateien} setDateien={dateien => setDateien(dateien)} />
                            </div>
                        </div>
                    </div>
                    <hr className="w-100" />
                    <div className="modal-footer p-0 w-100 border-0">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={submitting || invalid || value.length === 0}
                            onClick={e => handleSubmit(e).then(toggleModal())}>
                            Speichern und Schliessen
                        </button>
                    </div>
                </form>
            )}
        />
    );
};
