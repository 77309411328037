import React from 'react';
import UrlaubToUeberStundenConverter from './UrlaubToUeberStundenConverter';

export default ({ date, sollStd, setValue, value, onBlur = undefined }) => (
    <tr className={sollStd <= 0 ? 'element-disabled' : ''}>
        <td className="urlaubsantrag-datum-tag">{date.format('dd D.M.YY')}</td>
        <td className="text-center urlaubsantrag-sollstd">{sollStd}</td>
        <td>
            <UrlaubToUeberStundenConverter setValue={setValue} sollStd={sollStd} value={value} onBlur={onBlur} />
        </td>
    </tr>
);
