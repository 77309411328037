import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleFormSubmitResult } from '../../formHelpers';
import { wissenInhaltselementAlsFokusthema } from '../wissenApi';

export default ({ inhaltsElement, setFokusthemen, fokusthemen = [], typ = '' }) => {
    const thema = fokusthemen.find(thema => thema.id === inhaltsElement.id);
    const fokusthema = thema && thema.istFokusthema;

    const handleFokusthemaSetzen = async elementId => {
        const result = await wissenInhaltselementAlsFokusthema(elementId, typ, fokusthema ? 0 : 1);

        if (handleFormSubmitResult(result)) {
            setFokusthemen(result.alleFokusthemen);
        }
    };

    return (
        <button
            title="Als Fokusthema setzten"
            className={`btn btn-sm ${fokusthema ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => handleFokusthemaSetzen(inhaltsElement.id)}>
            <FontAwesomeIcon icon="exclamation-triangle" />
        </button>
    );
};
