import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { InputField } from '../../formHelpers';
import { WysiwygEditor } from '../../WysiwygSSREditor/WysiwygEditor';

export default ({ onSubmit, initialValues = null, toggleModal }) => {
    const [value, setValue] = useState(initialValues ? initialValues.inhalt : '');
    return (
        <Form
            onSubmit={values =>
                onSubmit({
                    ...values,
                    inhalt: value,
                })
            }
            initialValues={initialValues}
            render={({ handleSubmit, pristine, submitting, invalid }) => (
                <form onSubmit={handleSubmit}>
                    <div className="container-fluid">
                        <div className="row">
                            <InputField label="Inhalt">
                                <WysiwygEditor value={value} setValue={setValue} />
                            </InputField>
                        </div>
                    </div>
                    <div className="modal-footer p-0 w-100 border-0">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={submitting || invalid || value.length === 0}
                            onClick={e => handleSubmit(e).then(toggleModal())}>
                            Speichern und Schliessen
                        </button>
                    </div>
                </form>
            )}
        />
    );
};
