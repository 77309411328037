import React, { Component } from 'react';

export class PdfButton extends Component {
    render() {
        const disabled = this.props.disabled || false;

        return (
            <button title="PDF herunterladen" className="btn btn-secondary" type="button" disabled={disabled} onClick={this.props.onClick}>
                <span className="fa fa-lg fa-file-pdf" />
            </button>
        );
    }
}

export class XlsxButton extends Component {
    render() {
        const disabled = this.props.disabled || false;

        return (
            <button title="XLSX herunterladen" className="btn btn-secondary" type="button" disabled={disabled} onClick={this.props.onClick}>
                <span className="fa fa-lg fa-file-excel" />
            </button>
        );
    }
}
