import React from 'react';
import KommetarForm from './KommetarForm';
import { handleFormSubmitResult } from '../../formHelpers';
import { wissenKommentarErzeugen } from '../wissenApi';

export default ({ toggleModal, inhaltselementId, inhaltselementTyp, addKommentarToInhaltselement }) => {
    const onSubmit = async values => {
        const result = await wissenKommentarErzeugen({ ...values, inhaltselementId, inhaltselementTyp });
        if (handleFormSubmitResult(result, 'Kommentar erfolgreich angelegt.')) {
            addKommentarToInhaltselement(result.kommentar);
        }
    };
    return <KommetarForm toggleModal={toggleModal} onSubmit={values => onSubmit(values)} />;
};
