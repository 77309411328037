import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class extends Component {
    state = {
        collapsed: false,
    };

    toggle = () => {
        this.setState({ collapsed: !this.state.collapsed });
    };

    render() {
        const icon = this.state.collapsed ? 'minus' : 'plus';

        return (
            <React.Fragment>
                <span data-toggle="collapse" data-target={this.props.target} onClick={this.toggle} className={this.props.classNames}>
                    <FontAwesomeIcon icon={icon} size="sm" />
                </span>
            </React.Fragment>
        );
    }
}
