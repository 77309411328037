export const WISSEN_INHALTSTYP_WIKI = 'Wiki';
export const WISSEN_INHALTSTYP_FRAGE = 'Frage';
export const WISSEN_INHALTSTYP_DOKUMENT = 'Dokument';
export const WISSEN_INHALTSTYP_WIKI_URL = 'wiki';
export const WISSEN_INHALTSTYP_FRAGE_URL = 'frage';
export const WISSEN_INHALTSTYP_DOKUMENT_URL = 'dokument';
export const WISSEN_INHALTSTYP_ANTWORT = 'Antwort';

export const WISSEN_INHALTSELEMENTTYP_FRAGE = 'FRAGE';

export const REACT_SELECT_INHALTSTYPEN = [
    {
        value: WISSEN_INHALTSTYP_DOKUMENT,
        label: WISSEN_INHALTSTYP_DOKUMENT,
    },
    {
        value: WISSEN_INHALTSTYP_FRAGE,
        label: WISSEN_INHALTSTYP_FRAGE,
    },
    {
        value: WISSEN_INHALTSTYP_WIKI,
        label: WISSEN_INHALTSTYP_WIKI,
    },
];
