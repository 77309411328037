import React from 'react';

export default ({ date, sollStd, urlaubSumme, ueberstundenSumme, sollStdSumme, children }) => (
    <table className="table table-striped">
        <thead>
            <tr>
                <td className="small">Datum</td>
                <td className="small text-center">Sollstd</td>
                <td className="small text-center">Urlaub / Überstunden</td>
            </tr>
        </thead>
        <tbody className="urlaubsantrag-table">{children}</tbody>
    </table>
);
