import React from 'react';
import { postJson } from '../../api';
import WikiForm from './WikiForm';
import { handleFormSubmitResult } from '../../formHelpers';

export default ({ knoten, toggleModal, addNewWikiToKnoten, alleTags }) => {
    const onSubmit = async values => {
        const result = await postJson('/wissen/wiki/erzeugen', {
            ...values,
            knoten: knoten,
        });
        if (handleFormSubmitResult(result, 'Wiki erfolgreich angelegt!')) {
            addNewWikiToKnoten(result.wiki);
        }
    };

    return <WikiForm onSubmit={values => onSubmit(values)} toggleModal={toggleModal} knoten={knoten} alleTags={alleTags} />;
};
