import React from 'react';

export default class ReststundenAlert extends React.Component {
    render() {
        if (!this.props.enableAlert) {
            return null;
        }

        const restStundenWarning =
            this.props.restUrlaubStd < 0 && this.props.restUeberStd < 0 && this.props.aktuellerAntragUeberStd > 0
                ? 'Urlaub- und Überstunden'
                : this.props.restUrlaubStd < 0
                ? 'Urlaubstunden'
                : 'Überstunden';

        return (
            <React.Fragment>
                {(this.props.restUrlaubStd < 0 || (this.props.restUeberStd < 0 && this.props.aktuellerAntragUeberStd > 0)) && (
                    <div className="alert alert-danger mt-3">{`Dein Urlaubsantrag beansprucht mehr ${restStundenWarning} als du verfügbar hast!`}</div>
                )}
            </React.Fragment>
        );
    }
}
