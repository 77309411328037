import React from 'react';
import moment from 'moment';

export default ({ start, ende }) => (
    <div>
        <span className="lead">von:</span>
        <span className="ml-2">{start !== null ? moment(start).format('DD.MM.YYYY') : '-'}</span>
        <span className="lead ml-3">bis:</span>
        <span className="ml-2">{ende !== null ? moment(ende).format('DD.MM.YYYY') : '-'}</span>
    </div>
);
