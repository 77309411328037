import React from 'react';
import moment from 'moment';

export default class GeschaeftsfuehrungInvolviertAlert extends React.Component {
    istGeschaeftsfuehrungInvolviert = () => {
        return this.istUrlaubsantragLaengerAls21Tage() || this.beinhaltetUrlaubsantragMehrAls2TageUeberstunden();
    };

    istUrlaubsantragLaengerAls21Tage = () => {
        if (this.props.urlaubsantrag.von === null || this.props.urlaubsantrag.bis === null) {
            return false;
        }
        return (
            moment.duration(new moment(`${this.props.urlaubsantrag.bis} 00:00:00`).diff(new moment(`${this.props.urlaubsantrag.von} 00:00:00`))).asWeeks() > 3
        );
    };

    beinhaltetUrlaubsantragMehrAls2TageUeberstunden = () => {
        return (
            this.props.urlaubsantrag.konditionen.reduce((carry, kondition) => {
                return carry + kondition.ueberstunden;
            }, 0) >
            this.props.dailyWorkHours * 2
        );
    };

    render() {
        if (this.props.urlaubsantrag === null) {
            return null;
        }

        const geschaeftsfuehrungInvolviertWarnings = [];
        if (this.istUrlaubsantragLaengerAls21Tage()) {
            geschaeftsfuehrungInvolviertWarnings.push(
                'Hinweis: Die Dauer Deines Urlaubs überschreitet die Standardurlaubslänge (3 Wochen); es wird daher eine Information an die Geschäftsführung weitergeleitet. Dies hat keinen Einfluss auf den weiteren Genehmigungsprozess.'
            );
        }
        if (this.beinhaltetUrlaubsantragMehrAls2TageUeberstunden()) {
            geschaeftsfuehrungInvolviertWarnings.push(
                'Hinweis: längere Abwesenheiten (>2 Tage) sollen im Regelfall nicht über Überstunden abgedeckt werden; es wird daher eine Information an die Geschäftsführung weitergeleitet. Dies hat keinen Einfluss auf den weiteren Genehmigungsprozess.'
            );
        }

        return (
            <React.Fragment>
                {this.istGeschaeftsfuehrungInvolviert() &&
                    geschaeftsfuehrungInvolviertWarnings.map((warning, key) => (
                        <div key={key} className="alert alert-info mt-3">
                            {warning}
                        </div>
                    ))}
            </React.Fragment>
        );
    }
}
