import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleFormSubmitResult } from '../../formHelpers';
import { wissenInhaltselementAnpinnen, wissenInhaltselementEntpinnen } from '../wissenApi';

export default ({ inhaltsElement, pinInhaltselement, istElementAngepinnt, typ, unpinInhaltselement }) => {
    const handleElementAnpinnen = async () => {
        if (handleFormSubmitResult(await wissenInhaltselementAnpinnen(inhaltsElement, typ))) {
            pinInhaltselement(inhaltsElement);
        }
    };

    const handleElementEntpinnen = async () => {
        if (handleFormSubmitResult(await wissenInhaltselementEntpinnen(inhaltsElement, typ))) {
            unpinInhaltselement(inhaltsElement);
        }
    };

    return (
        <button
            title="Anpinnen"
            className={`btn btn-sm ${istElementAngepinnt ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => {
                istElementAngepinnt ? handleElementEntpinnen() : handleElementAnpinnen();
            }}>
            <FontAwesomeIcon icon="thumbtack" />
        </button>
    );
};

export const pinInhaltselementOnDetailPage = (element, action) =>
    action({
        ...element,
        knoten: {
            ...element.knoten,
            angepinnteInhaltselemente: [...element.knoten.angepinnteInhaltselemente, element],
        },
    });

export const unPinInhaltselementOnDetailPage = (element, action) =>
    action({
        ...element,
        knoten: {
            ...element.knoten,
            angepinnteInhaltselemente: element.knoten.angepinnteInhaltselemente.filter(
                angepinntesInhaltselement => angepinntesInhaltselement.id !== element.id
            ),
        },
    });
