import React, { Component } from 'react';
import Collapser from './Collapser';

export default class MonatUebersichtSheet extends Component {
    render() {
        if (this.props.sheet === undefined) {
            return null;
        }

        const getHoursForProjektLphAndDay = (projekt, lphName, day) => {
            const dayProjekt = day.projekte.find(dayProjekt => dayProjekt.id === projekt.id);
            if (dayProjekt === undefined) {
                return null;
            }

            const lph = dayProjekt.lphstunden.find(lph => lph.lphName === lphName);
            if (lph === undefined) {
                return null;
            }

            return lph.hours;
        };

        const getTotalHoursForProjektAndDay = (projekt, day) => {
            const dayProjekt = day.projekte.find(dayProjekt => dayProjekt.id === projekt.id);
            if (dayProjekt === undefined) {
                return null;
            }

            return dayProjekt.totalstunden;
        };

        return (
            <div className="monat-stunden-uebersicht--widget card table-responsive border-top-0">
                &nbsp;
                <table className="table table-striped table-bordered table-small table-small-uebersicht table-sm mb-0">
                    <thead>
                        <tr>
                            <th />
                            {this.props.sheet.tage.map((tag, i) => (
                                <th key={`tag${i}`} className="text-center">
                                    <div className={`calendar--day`}>
                                        <span
                                            className={`calendar--day--inner ${tag.calendarOptions.color} ${
                                                tag.calendarOptions.fill ? `fill-${tag.calendarOptions.fill}` : ''
                                            } ${tag.today ? 'today' : ''}`}>
                                            <span className="day">{tag.dayOfMonth}</span>
                                            {tag.calendarOptions.arbeitszeit && tag.calendarOptions.vollstaendig ? (
                                                <span className="calendar--day--inner--underline" />
                                            ) : (
                                                !tag.calendarOptions.vollstaendig && <span className="calendar--day--inner--underline incomplete-day" />
                                            )}
                                        </span>
                                        <div>{tag.dayName}</div>
                                    </div>
                                </th>
                            ))}
                            <th className="text-right pr-3">Σ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Von</td>
                            {this.props.sheet.tage.map((tag, i) => (
                                <td key={`von${i}`} className="text-right">
                                    {tag.von}
                                </td>
                            ))}
                            <td />
                        </tr>
                        <tr>
                            <td>Bis</td>
                            {this.props.sheet.tage.map((tag, i) => (
                                <td key={`bis${i}`} className="text-right">
                                    {tag.bis}
                                </td>
                            ))}
                            <td />
                        </tr>
                        <tr>
                            <td>Pause</td>
                            {this.props.sheet.tage.map((tag, i) => (
                                <td key={`pause${i}`} className="text-right">
                                    {tag.pausestunden}
                                </td>
                            ))}
                            <td />
                        </tr>
                        <tr>
                            <td>Soll Tag</td>
                            {this.props.sheet.tage.map((tag, i) => (
                                <td key={`sollstunden${i}`} className="text-right">
                                    {tag.sollstunden}
                                </td>
                            ))}
                            <td />
                        </tr>
                        <tr>
                            <td>Σ Tag</td>
                            {this.props.sheet.tage.map((tag, i) => (
                                <td key={`projektstunden${i}`} className="text-right">
                                    {tag.projektstunden}
                                </td>
                            ))}
                            <td className="text-right">{this.props.sheet.summen.projektstunden}</td>
                        </tr>
                        {this.props.sheet.projekte.map((projekt, i) => (
                            <React.Fragment key={`p${i}`}>
                                <tr>
                                    <td>
                                        <div className="text-truncate" style={{ 'max-width': '200px' }} title={projekt.name}>
                                            {projekt.bauprojekt && <Collapser target={`.collapse-p${i}`} classNames="pr-2" />}
                                            {projekt.name}
                                        </div>
                                        {projekt.lphstunden.map((lph, j) => (
                                            <div key={`p${i}lname${j}`} className={`text-muted pl-3 collapse collapse-p${i}`} style={{ 'max-width': '200px' }}>
                                                {lph.lphName}
                                            </div>
                                        ))}
                                    </td>
                                    {this.props.sheet.tage.map((tag, j) => (
                                        <td key={`p${i}t${j}`} className="text-right">
                                            {getTotalHoursForProjektAndDay(projekt, tag) || '\u00A0'}
                                            {projekt.lphstunden.map((lph, k) => (
                                                <div key={`p${i}t${j}l${k}`} className={`text-muted collapse collapse-p${i}`}>
                                                    {getHoursForProjektLphAndDay(projekt, lph.lphName, tag) || '\u00A0'}
                                                </div>
                                            ))}
                                        </td>
                                    ))}
                                    <td className="text-right">
                                        {projekt.totalstunden || '\u00A0'}
                                        {projekt.lphstunden.map((lph, j) => (
                                            <div key={`p${i}lsum${j}`} className={`text-muted collapse collapse-p${i}`}>
                                                {lph.hours || '\u00A0'}
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                        <tr>
                            <td>Urlaub</td>
                            {this.props.sheet.tage.map((tag, i) => (
                                <td key={`urlaub${i}`} className="text-right">
                                    {tag.urlaubstunden}
                                </td>
                            ))}
                            <td className="text-right">{this.props.sheet.summen.urlaubstunden}</td>
                        </tr>
                        <tr>
                            <td>Abgebaute Überstunden</td>
                            {this.props.sheet.tage.map((tag, i) => (
                                <td key={`urlaub${i}`} className="text-right">
                                    {tag.abgebauteUeberstunden}
                                </td>
                            ))}
                            <td className="text-right">{this.props.sheet.summen.abgebauteUeberstunden}</td>
                        </tr>
                        <tr>
                            <td>Krank</td>
                            {this.props.sheet.tage.map((tag, i) => (
                                <td key={`krank${i}`} className="text-right">
                                    {tag.krankstunden}
                                </td>
                            ))}
                            <td className="text-right">{this.props.sheet.summen.krankstunden}</td>
                        </tr>
                        <tr>
                            <td>Kommentar</td>
                            {this.props.sheet.tage.map((tag, i) => (
                                <td key={`kommentar${i}`}>
                                    <div className="text-truncate" style={{ 'max-width': '42px' }} title={tag.kommentar}>
                                        {tag.kommentar}
                                    </div>
                                </td>
                            ))}
                            <td />
                        </tr>
                    </tbody>
                </table>
                <table className="table table-striped table-small table-small-uebersicht mb-0">
                    <thead>
                        <tr>
                            <td>
                                <div className="row">
                                    <div className="col-xl-2">Sollstunden</div>
                                    <div className="col-xl-1 text-right">{this.props.sheet.stundenzusammenfassung.sollstunden}</div>
                                    <div className="col-xl-1" />
                                    <div className="col-xl-2">Urlaubsstunden im lfd. Monat</div>
                                    <div className="col-xl-1 text-right">{this.props.sheet.urlaubzusammenfassung.urlaubstageImLfdMonat}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="row">
                                    <div className="col-xl-2">Überstunden aus Vormonat</div>
                                    <div className="col-xl-1 text-right">{this.props.sheet.stundenzusammenfassung.ueberstundenVormonat}</div>
                                    <div className="col-xl-1" />
                                    <div className="col-xl-2">Urlaubsstunden bislang erhalten</div>
                                    <div className="col-xl-1 text-right">{this.props.sheet.urlaubzusammenfassung.urlaubstageBislangErhalten}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="row">
                                    <div className="col-xl-2">Arbeitszeitanpassungen</div>
                                    <div className="col-xl-1 text-right">{this.props.sheet.stundenzusammenfassung.arbeitszeiterfassunganpassungstunden}</div>
                                    <div className="col-xl-1" />
                                    <div className="col-xl-2">Resturlaubsstunden {this.props.sheet.year}</div>
                                    <div className="col-xl-1 text-right">{this.props.sheet.urlaubzusammenfassung.resturlaubstage}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="row">
                                    <div className="col-xl-2">Gesamtsumme</div>
                                    <div className="col-xl-1 text-right">{this.props.sheet.stundenzusammenfassung.gesamtsumme}</div>
                                    <div className="col-xl-1" />
                                    <div className="col-xl-2">Gesamturlaubsanspruch {this.props.sheet.year}</div>
                                    <div className="col-xl-1 text-right">{this.props.sheet.urlaubzusammenfassung.gesamturlaubstage}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="row">
                                    <div className="col-xl-2">Übertrag in Folgemonat</div>
                                    <div className="col-xl-1 text-right">{this.props.sheet.stundenzusammenfassung.uebertragungFolgemonat}</div>
                                    <div className="col-xl-1" />
                                    <div className="col-xl-2" />
                                    <div className="col-xl-1 text-right" />
                                </div>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        );
    }
}
