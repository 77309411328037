import React from 'react';
import moment from 'moment';
import * as api from '../api';
import PortalButtonBar from '../PortalButtonBar';
import Select from 'react-select';
import numeral from '../numeral';
import { PdfButton, XlsxButton } from './StundenUebersichtWidget/Buttons';
import Paginate from '../Paginate';

moment.locale('de');

const yearForSelect = year => ({ value: year, label: year });
const yearsForSelect = Array.from({ length: parseInt(moment().format('YYYY'), 10) - 2019 + 2 }, (v, i) => i + 2019).map(year => yearForSelect(year));

const initialState = {
    fetching: true,
    data: {
        jahr: null,
        mitarbeiterDaten: [],
        currentPage: 1,
        totalPages: 0,
    },
};

export default class UrlaubJahresuebersicht extends React.Component {
    state = initialState;

    componentDidMount() {
        this.changeYear(parseInt(moment().format('YYYY'), 10));
    }

    changeYear = jahr => {
        this.setState(initialState);
        this.loadData(jahr, 1);
    };

    loadData = async (year, page) => {
        this.setState({ fetching: true });
        const json = await api.getJson(`/zeiterfassung/urlaub/uebersicht/jahresuebersicht/data/${year}/${page}`);
        this.setState({
            data: {
                ...json,
            },
            fetching: false,
        });
    };

    makePdf = () => {
        this.makeFile('pdf');
    };

    makeFile = format => {
        const url = `/zeiterfassung/urlaub/uebersicht/jahresuebersicht/file/${this.state.data.jahr}.${format}`;
        if (format === 'pdf') {
            window.open(url, '_blank');
        } else {
            location = url;
        }
    };

    makeXlsx = () => {
        this.makeFile('xlsx');
    };

    handlePageChange = async page => {
        await this.loadData(this.state.data.jahr, page.selected + 1);
    };

    render() {
        const number = number => numeral(parseFloat(parseFloat(number).toFixed(1))).format('0.0');

        return (
            <React.Fragment>
                <div>
                    <PortalButtonBar>
                        <div className="form-group row align-items-center mb-3">
                            <span className="col-sm-12 col-md-1">Jahr</span>
                            <div className="col-md-1 col-sm-12">
                                <Select
                                    isDisabled={this.state.fetching}
                                    options={yearsForSelect}
                                    value={yearForSelect(this.state.data.jahr)}
                                    onChange={value => this.changeYear(value.value)}
                                    isMulti={false}
                                />
                            </div>
                            <div className="col-auto mt-3">
                                {this.state.data.totalPages > 0 && (
                                    <Paginate pageCount={this.state.data.totalPages} onPageChange={page => this.handlePageChange(page)} />
                                )}
                            </div>
                            <div className="col-auto ml-auto mt-3 mt-lg-3 mt-xl-0">
                                <PdfButton onClick={this.makePdf} disabled={this.state.fetching} />
                                <XlsxButton onClick={this.makeXlsx} disabled={this.state.fetching} />
                            </div>
                        </div>
                    </PortalButtonBar>
                </div>
                {this.state.data.mitarbeiterDaten.length > 0 && (
                    <div className="card border-top-0 mb-0">
                        <div>
                            <table className="table table-striped table-bordered table-small table-small-uebersicht table-sm">
                                <thead>
                                    <tr>
                                        <th className="text-center" colSpan="3">
                                            Vertragliche Rahmenbedingungen
                                        </th>
                                        <th className="text-center" colSpan="4">
                                            Urlaubsanspruch {this.state.data.jahr}
                                        </th>
                                        <th className="text-center" colSpan="13">
                                            Urlaub {this.state.data.jahr}
                                        </th>
                                        <th colSpan="3" />
                                    </tr>
                                    <tr>
                                        <th>Mitarbeiter</th>
                                        <th className="text-center">
                                            Teilzeit- <br />
                                            faktor
                                        </th>
                                        <th className="text-center">
                                            Vertraglicher
                                            <br />
                                            Urlaubsanspruch
                                            <br />
                                            Tage
                                        </th>
                                        <th className="text-center">
                                            Berechneter
                                            <br />
                                            Urlaubsanspruch
                                            <br />
                                            Stunden
                                        </th>
                                        <th className="text-center">
                                            Urlaubsanspruch
                                            <br />
                                            aus Vorjahr
                                            <br />
                                            Stunden
                                        </th>
                                        <th className="text-center">
                                            Anpassung
                                            <br />
                                            Manuell
                                            <br />
                                            Stunden
                                        </th>
                                        <th className="text-center">
                                            Summe
                                            <br />
                                            Stunden
                                        </th>
                                        {this.state.data.mitarbeiterDaten[0].monatStunden.map((monatDaten, i) => (
                                            <th className="text-center" key={i}>
                                                {moment(monatDaten.monat).format('MMM')}
                                            </th>
                                        ))}
                                        <th className="text-center">Summe Jan. - Dez.</th>
                                        <th className="text-center">
                                            Offener
                                            <br />
                                            Urlaubsanspruch
                                            <br />
                                            {this.state.data.jahr}
                                        </th>
                                        <th className="text-center">Resturlaub</th>
                                        <th className="text-center">
                                            Bereits
                                            <br />
                                            gebuchter
                                            <br />
                                            Urlaub {this.state.data.jahr + 1}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.mitarbeiterDaten.map((daten, i) => (
                                        <tr key={i}>
                                            <td>{daten.mitarbeiter}</td>
                                            <td className="text-center">{number(daten.teilzeitFaktor)}</td>
                                            <td className="text-center">{number(daten.vertraglicherUrlaubsanspruchTage)}</td>
                                            <td className="text-center">{number(daten.berechneterUrlaubsanspruchStunden)}</td>
                                            <td className="text-center">{number(daten.vorjahrUrlaubsanspruchStunden)}</td>
                                            <td className="text-center">{number(daten.manuelleUrlaubsanpassungStunden)}</td>
                                            <td className="text-center">{number(daten.summeUrlaubsanspruchStunden)}</td>
                                            {daten.monatStunden.map((monatDaten, j) => (
                                                <td className="text-center" key={j}>
                                                    {number(monatDaten.urlaubstunden)}
                                                </td>
                                            ))}
                                            <td className="text-center">{number(daten.summeMonatStunden)}</td>
                                            <td className="text-center">{number(daten.offenerAnspruchStunden)}</td>
                                            <td className="text-center">{number(daten.resturlaubStunden)}</td>
                                            <td className="text-center">{number(daten.bereitsGebuchtNaechstesJahrStunden)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
