import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTimes, faCheck, faHourglassHalf } from '@fortawesome/fontawesome-free-solid';
import * as api from '../api';
import moment from 'moment';
import UrlaubsantragStatus from './Widget/UrlaubsantragStatus';
import UrlaubsantragZeitraum from './Widget/UrlaubsantragZeitraum';
import { URLAUBSANTRAG_GENEHMIGUNG_STATUS } from './constants';
import toast from '../toastify';

/**
 * @typedef {Object} Urlaubsantrag
 * @property {string} id
 * @property {string} status URLAUBSANTRAG_STATUS
 * @property {string} antragsteller
 * @property {string} von - in YYYY-MM-DD format
 * @property {string} bis - in YYYY-MM-DD format
 * @property {int} dauerTage
 * @property {string} eingang - in YYYY-MM-DD format
 * @property {Verantwortlicher[]} genehmigungsVerantwortliche
 */

/**
 * @typedef {Object} Verantwortlicher
 * @property {string} name
 * @property {string} status URLAUBSANTRAG_GENEHMIGUNG_STATUS
 */

export default class UrlaubsantraegeVerwaltenWidget extends React.Component {
    /**
     * @type {{fetching: boolean, aktuellViewActive: boolean, historyViewActive: boolean, urlaubsantrags: {aktuell: Urlaubsantrag[], archiv: Urlaubsantrag[]}}}
     */
    state = {
        fetching: true,
        aktuellViewActive: true,
        historyViewActive: false,
        urlaubsantrags: { aktuell: [], archiv: [] },
    };

    componentDidMount() {
        this.loadUrlaubsantragList();
    }

    loadUrlaubsantragList = async () => {
        const json = await api.getJson('/urlaub/urlaubsantrag/list-verantwortlicher-data');
        this.setState({
            fetching: false,
            urlaubsantrags: {
                aktuell: json.urlaubsantrags.aktuell,
                archiv: json.urlaubsantrags.archiv,
            },
        });
    };

    toggleAktuellView = () => (this.state.aktuellViewActive ? null : this.setState({ aktuellViewActive: true, historyViewActive: false }));
    toggleHistoryView = () => (this.state.historyViewActive ? null : this.setState({ historyViewActive: true, aktuellViewActive: false }));

    urlaubsantragGenehmigen = urlaubsantragId => {
        api.getJson(`/urlaub/urlaubsantrag/genehmigen/${urlaubsantragId}`)
            .then(json => {
                if (json.success === undefined || json.success === false) {
                    let errorMessage = 'Ein Fehler ist beim Genehmigen des Urlaubsantrags aufgetreten';
                    if (json.error !== undefined) {
                        errorMessage = json.error;
                    }
                    toast.error(errorMessage);
                } else {
                    this.loadUrlaubsantragList();
                }
            })
            .catch(json => {
                toast.error('Ein Fehler ist beim Genehmigen des Urlaubsantrags aufgetreten');
            });
    };

    urlaubsantragAblehnen = urlaubsantragId => {
        api.getJson(`/urlaub/urlaubsantrag/ablehnen/${urlaubsantragId}`)
            .then(json => {
                if (json.success === undefined || json.success === false) {
                    let errorMessage = 'Ein Fehler ist beim Ablehnen des Urlaubsantrags aufgetreten';
                    if (json.error !== undefined) {
                        errorMessage = json.error;
                    }
                    toast.error(errorMessage);
                } else {
                    this.loadUrlaubsantragList();
                }
            })
            .catch(json => {
                toast.error('Ein Fehler ist beim Ablehnen des Urlaubsantrags aufgetreten');
            });
    };

    urlaubsantragAnzeigen = urlaubsantragId => {
        location = `/urlaub/urlaubsantrag/${urlaubsantragId}`;
    };

    render() {
        if (this.state.fetching) {
            return (
                <div>
                    <span>Loading</span>
                </div>
            );
        }

        if (this.state.urlaubsantrags.aktuell.length === 0 && this.state.urlaubsantrags.archiv.length === 0) {
            return null;
        }

        const aktuellViewActive = this.state.aktuellViewActive;
        const urlaubsantrags = aktuellViewActive ? this.state.urlaubsantrags.aktuell : this.state.urlaubsantrags.archiv;

        return (
            <div className="card">
                <div className="card-body">
                    <div className="row mr-0">
                        <div className="col-auto pr-0 d-flex align-items-center tab-empty-space">
                            <h2>Urlaubsanträge verwalten</h2>
                        </div>
                        <div className="col tab-empty-space" />
                        <div className="col-auto pl-0 pr-0">
                            <ul className="nav nav-tabs nav-tabs-uebersicht">
                                <li className="nav-item">
                                    <div
                                        onClick={this.toggleAktuellView}
                                        className={`border-top border-left border-right urlaubsantrag-verwalten-tab ${aktuellViewActive ? `tab-active` : ``}`}>
                                        <p className="pl-4 pr-4 pt-2 pb-2 m-0">Aktuell</p>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div
                                        onClick={this.toggleHistoryView}
                                        className={`border-top border-sm-left border-right urlaubsantrag-verwalten-tab ${
                                            aktuellViewActive ? `` : `tab-active`
                                        }`}>
                                        <p className="pl-4 pr-4 pt-2 pb-2 m-0">Historie</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <table className="table table-responsive table-striped mb-0 urlaubsantrag-verwalten-table-widget">
                        <thead>
                            <tr>
                                <th className="border-top-0">Status</th>
                                <th className="border-top-0">Antragssteller</th>
                                <th className="border-top-0">Zeitraum</th>
                                <th className="border-top-0">Abwesenheit</th>
                                <th className="border-top-0">Eingang</th>
                                <th className="verantwortlicher-mitarbeiter border-top-0">Verantwortliche MA</th>
                                <th className="border-top-0" />
                            </tr>
                        </thead>
                        <tbody>
                            {urlaubsantrags.map(urlaubsantragDaten => (
                                <tr key={urlaubsantragDaten.id}>
                                    <td>
                                        <UrlaubsantragStatus urlaubsantragStatus={urlaubsantragDaten.status} />
                                    </td>
                                    <td>
                                        <a className="classic-table-href" href={urlaubsantragDaten.antragstellerProfileUrl}>
                                            {urlaubsantragDaten.antragsteller}
                                        </a>
                                    </td>
                                    <td>
                                        <UrlaubsantragZeitraum urlaubsantrag={urlaubsantragDaten} />
                                    </td>
                                    <td>{`${urlaubsantragDaten.dauerTage} Tage`}</td>
                                    <td>{urlaubsantragDaten.eingang ? moment(urlaubsantragDaten.eingang).format('DD.MM.YY') : ''}</td>

                                    <td>
                                        {urlaubsantragDaten.genehmigungsVerantwortliche.map(mitarbeiter => (
                                            <div key={mitarbeiter.name} className="d-flex justify-content-start align-items-center ">
                                                <span>{mitarbeiter.name}</span>
                                                <FontAwesomeIcon
                                                    className="ml-2"
                                                    color={`${
                                                        mitarbeiter.status === URLAUBSANTRAG_GENEHMIGUNG_STATUS.GENEHMIGT
                                                            ? `#00cb1d`
                                                            : mitarbeiter.status === URLAUBSANTRAG_GENEHMIGUNG_STATUS.ABGELEHNT
                                                            ? `red`
                                                            : `gray`
                                                    }`}
                                                    icon={
                                                        mitarbeiter.status === URLAUBSANTRAG_GENEHMIGUNG_STATUS.GENEHMIGT
                                                            ? faCheck
                                                            : mitarbeiter.status === URLAUBSANTRAG_GENEHMIGUNG_STATUS.ABGELEHNT
                                                            ? faTimes
                                                            : faHourglassHalf
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </td>
                                    <td className="text-right">
                                        {aktuellViewActive ? (
                                            <div className="btn-group">
                                                <button
                                                    title="Antrag genehmigen"
                                                    className="btn btn-sm p-1 btn-success"
                                                    onClick={() => {
                                                        this.urlaubsantragGenehmigen(urlaubsantragDaten.id);
                                                    }}>
                                                    <FontAwesomeIcon fixedWidth={true} icon={faCheck} />
                                                </button>
                                                <button
                                                    title="Antrag ablehnen"
                                                    className="btn btn-sm p-1 btn-danger"
                                                    onClick={() => {
                                                        this.urlaubsantragAblehnen(urlaubsantragDaten.id);
                                                    }}>
                                                    <FontAwesomeIcon fixedWidth={true} icon={faTimes} />
                                                </button>
                                            </div>
                                        ) : null}
                                        <button
                                            title="zum Urlaubsantrag"
                                            className="ml-3 btn btn-sm btn-secondary"
                                            onClick={() => {
                                                this.urlaubsantragAnzeigen(urlaubsantragDaten.id);
                                            }}>
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
