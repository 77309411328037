import React from 'react';
import { postJson } from '../../api';
import FrageForm from './FrageForm';
import { handleFormSubmitResult } from '../../formHelpers';

export default ({ addNewFrageToKnoten, toggleModal, knoten, alleTags }) => {
    const onSubmit = async values => {
        const result = await postJson('/wissen/frage/erzeugen', { ...values, knoten });
        if (handleFormSubmitResult(result, 'Frage erfolgreich angelegt.')) {
            addNewFrageToKnoten(result.frage);
        }
    };
    return <FrageForm onSubmit={values => onSubmit(values)} toggleModal={toggleModal} knoten={knoten} alleTags={alleTags} />;
};
