import React from 'react';

export default ({ isSelection, activeStandort }) => (
    <div className="card">
        <div className="card-body">
            {isSelection && activeStandort !== null && (
                <>
                    <strong>Standort</strong>
                    <br />
                    <p>{activeStandort.name}</p>

                    <strong>Adresse</strong>
                    <p>
                        {activeStandort.strasse}
                        <br />
                        {activeStandort.stadt}
                    </p>

                    <strong>Kontakt</strong>
                    <p>
                        {activeStandort.tel}
                        <br />
                        {activeStandort.fax}
                        <br />
                        {activeStandort.mail}
                    </p>
                </>
            )}
            {!isSelection && (
                <>
                    Bitte wählen Sie einen Standort aus oder nutzen Sie die Suchfunktion um ein
                    Projektbüro zu finden
                </>
            )}
        </div>
    </div>
);
