import React from 'react';
import { BtnBold, BtnBulletList, BtnItalic, BtnNumberedList, createButton, Editor, EditorProvider, Separator, Toolbar } from 'react-simple-wysiwyg';

export const WysiwygEditor = ({ value, setValue }) => {
    const BtnH1 = createButton('H1', <div>H1</div>, () => document.execCommand('formatBlock', true, '<H1>'));
    const BtnH2 = createButton('H2', <div>H2</div>, () => document.execCommand('formatBlock', true, '<H2>'));
    const BtnH3 = createButton('H3', <div>H3</div>, () => document.execCommand('formatBlock', true, '<H3>'));

    return (
        <EditorProvider>
            <Editor value={value} onChange={e => setValue(e.target.value)} tabIndex={2}>
                <Toolbar>
                    <BtnBold />
                    <BtnItalic />
                    <Separator />
                    <BtnNumberedList />
                    <BtnBulletList />
                    <Separator />
                    <BtnH1 />
                    <BtnH2 />
                    <BtnH3 />
                </Toolbar>
            </Editor>
        </EditorProvider>
    );
};
