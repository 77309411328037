import React, { Component } from 'react';

export default class ProjektUebersichtHeader extends Component {
    render() {
        const projekt = this.props.projekt;
        const selectedProjektId = this.props.selectedProjektId;

        if (selectedProjektId === null || projekt === undefined) {
            return (
                <thead>
                    <tr>
                        <td>{null === selectedProjektId ? 'Bitte wählen Sie ein Projekt' : 'Keine Daten vorhanden'}</td>
                    </tr>
                </thead>
            );
        }

        return (
            <thead>
                <tr>
                    <td colSpan="6">
                        <table className="table-borderless">
                            <thead>
                                <tr>
                                    <td style={{ padding: '0 0 0.75rem 0' }}>
                                        {projekt.name}
                                        <div className="text-muted">{projekt.ort}</div>
                                    </td>
                                    <td style={{ padding: '0 0 0.75rem 3rem' }}>
                                        <div>{projekt.auftraggeber}</div>
                                        <div className="small text-muted">Auftraggeber</div>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </td>
                </tr>
                <tr>
                    <th />
                    <th className="text-right">LP 1-4</th>
                    <th className="text-right">LP 5-7</th>
                    <th className="text-right">LP 8-9</th>
                    <th className="text-right">PS</th>
                    <th className="text-right">Σ</th>
                </tr>
            </thead>
        );
    }
}
