import React from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import * as api from '../../api';
import toast from '../../toastify';
import getBadgeColor from '../../getBadgeColor';
import { convertBearbeiter, convertProjekt, convertProjekte } from './getAufgabeView';
import { STATUS_ENTWURF, STATUS_ERLEDIGT, STATUS_HINFAELLIG, STATUS_IN_ARBEIT, STATUS_OFFEN } from '../AufgabenConstants';
import { InputField, ReactSelectAdapter } from '../../formHelpers';

export default class AufgabeErstellenForm extends React.Component {
    state = {
        projekte: null,
        bearbeiter: null,
        startDate: moment(),
        endDate: null,
        status: STATUS_ENTWURF,
        dataLoaded: false,
        initialAufgabe: {},
        showCreatedAufgaben: false,
        angelegteAufgaben: [],
        aufgabeToReEdit: {},
        emptyValuesWithLastProjektAndBearbeiter: {
            projekt: null,
            bearbeiter: [],
        },
    };

    async componentDidMount() {
        let initialAufgabe = {};

        if (this.props.projekt !== undefined) {
            initialAufgabe = {
                projekt: {
                    value: this.props.projekt.id,
                    label: this.props.projekt.label,
                },
            };
        }

        const json = await api.getJson('/aufgaben/bearbeiten-daten');

        const newState = {
            projekte: convertProjekte(json.projekts),
            bearbeiter: convertBearbeiter(json.bearbeiter),
            dataLoaded: true,
            initialAufgabe: {
                ...initialAufgabe,
                bearbeiter: this.props.defaultBearbeiter,
            },
        };
        this.setState(newState);
    }

    getAngelegteAufgaben = (newAufgabe, projekt) => {
        const angelegteAufgaben = this.state.angelegteAufgaben;

        const aufgabenProjekt = {
            projekt:
                projekt === null
                    ? null
                    : {
                          label: projekt.name,
                          value: projekt.id,
                      },
        };

        const editExistingAufgabe = this.state.angelegteAufgaben.map(aufgabe => {
            if (aufgabe.key === newAufgabe.key) {
                aufgabe = {
                    ...newAufgabe,
                    ...aufgabenProjekt,
                };
            }
            return aufgabe;
        });

        return angelegteAufgaben.length === 0
            ? [{ ...newAufgabe, ...aufgabenProjekt }]
            : !angelegteAufgaben.includes(angelegteAufgaben.find(aufgabe => aufgabe.id === newAufgabe.id))
            ? [{ ...newAufgabe, ...aufgabenProjekt }, ...this.state.angelegteAufgaben]
            : angelegteAufgaben.includes(angelegteAufgaben.find(aufgabe => aufgabe.id === newAufgabe.id))
            ? editExistingAufgabe
            : null;
    };

    onSubmit = async values => {
        const params = {
            ...values,
            projekt:
                values.projekt === undefined || values.projekt === null
                    ? null
                    : {
                          id: values.projekt.value,
                          name: values.projekt.label,
                          url: `projekt/${values.projekt.value}/detail`,
                      },
            bearbeiter:
                values.bearbeiter === undefined
                    ? []
                    : values.bearbeiter.map(bearbeiter => ({
                          id: bearbeiter.value,
                          name: bearbeiter.label,
                          url: `mitarbeiter/${bearbeiter.value}/detail`,
                          avatar: `${bearbeiter.label}_.JPG`,
                      })),
            titel: values.titel,
            beschreibung: values.beschreibung === undefined ? '' : values.beschreibung,
            start: this.state.startDate !== null ? this.state.startDate.format('DD.MM.YYYY') : null,
            ende: this.state.endDate !== null ? this.state.endDate.format('DD.MM.YYYY') : null,
            status: this.state.status,
        };
        try {
            const value = await api.postJson(`/aufgaben/speichern/ich/${values.key ? values.key : 'null'}`, params);
            if (value.error) {
                toast.error(value.error);
                this.setState({ error: true });
            } else if (value.statusCode && value.statusCode !== 200) {
                toast.error('Eine interner Fehler ist aufgetreten');
                this.setState({ error: true });
            } else {
                this.setState({
                    error: false,
                    showCreatedAufgaben: true,
                    angelegteAufgaben: this.getAngelegteAufgaben(value.aufgabe, params.projekt),
                    emptyValuesWithLastProjektAndBearbeiter: {
                        projekt: convertProjekt(params),
                        bearbeiter: convertBearbeiter(params.bearbeiter),
                    },
                });
                this.props.expand();
                this.dispatchAufgabeToList();
                values.key ? toast.success('Aufgabe erfolgreich gespeichert') : toast.success('Aufgabe erfolgreich erstellt');
            }
        } catch (e) {
            toast.error('Es ist ein unerwarteter Fehler aufgetreten');
        }
    };

    dispatchAufgabeToList = () => {
        const event = new CustomEvent('dispatchAufgabe', { detail: 'new Aufgabe created!' });
        window.dispatchEvent(event);
    };

    setStatus = (status, e) => {
        e.preventDefault();
        this.setState({ status: status });
    };

    setAufgabeToReEdit = aufgabe => {
        this.setState({
            initialAufgabe: {},
            aufgabeToReEdit: {
                ...aufgabe,
                bearbeiter: convertBearbeiter(aufgabe.bearbeiter),
            },
            status: aufgabe.status,
        });
    };

    required = value => (value ? undefined : 'Pflichtfeld!');

    render() {
        const {
            dataLoaded,
            projekte,
            bearbeiter,
            startDate,
            endDate,
            status,
            showCreatedAufgaben,
            angelegteAufgaben,
            aufgabeToReEdit,
            emptyValuesWithLastProjektAndBearbeiter,
            initialAufgabe,
        } = this.state;
        return (
            <div className="d-flex flex-column align-items-start justify-content-center flex-lg-row">
                <Form
                    initialValues={
                        Object.keys(initialAufgabe).length !== 0
                            ? initialAufgabe
                            : Object.keys(aufgabeToReEdit).length !== 0
                            ? aufgabeToReEdit
                            : emptyValuesWithLastProjektAndBearbeiter
                    }
                    onSubmit={this.onSubmit}
                    render={({ handleSubmit, form, values, submitting, pristine }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="container-fluid">
                                <div className="row">
                                    <InputField label="Titel">
                                        <Field name="titel" className="form-control" validate={this.required} autoFocus>
                                            {props => (
                                                <input
                                                    autoFocus
                                                    placeholder={props.meta.error && props.meta.touched ? props.meta.error : 'Titel'}
                                                    {...props.input}
                                                    className={`form-control ${props.meta.error &&
                                                        props.meta.touched &&
                                                        'font-weight-bold border-danger text-danger'}`}
                                                />
                                            )}
                                        </Field>
                                    </InputField>
                                    <InputField label="Beschreibung">
                                        <Field name="beschreibung" rows={4} component="textarea" placeholder="Beschreibung" className="form-control" />
                                    </InputField>
                                    <InputField label="Projekt" note="(kann nachträglich nicht mehr geändert werden!)">
                                        {dataLoaded ? (
                                            <Field
                                                isDisabled={Object.keys(aufgabeToReEdit).length !== 0 && aufgabeToReEdit.projekt !== null ? true : false}
                                                name="projekt"
                                                component={ReactSelectAdapter}
                                                options={projekte}
                                                isClearable={true}
                                                placeholder="Persönliche Aufgabe"
                                            />
                                        ) : (
                                            <Select isDisabled={true} placeholder="Loading .." />
                                        )}
                                    </InputField>
                                    <InputField label="Bearbeiter">
                                        {dataLoaded ? (
                                            <Field
                                                name="bearbeiter"
                                                component={ReactSelectAdapter}
                                                options={bearbeiter}
                                                placeholder="Bitte wählen"
                                                isMulti={true}
                                                isClearable={true}
                                            />
                                        ) : (
                                            <Select isDisabled={true} placeholder="Loading .." />
                                        )}
                                    </InputField>
                                    <InputField label="Start / Ende">
                                        <DateRangePicker
                                            startDate={startDate}
                                            startDateId="cursor-"
                                            endDate={endDate}
                                            endDateId="your_unique_end_date_id"
                                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            hideKeyboardShortcutsPanel={true}
                                            startDatePlaceholderText="Start"
                                            endDatePlaceholderText="Ende"
                                            minimumNights={0}
                                            small
                                            showClearDates
                                            monthFormat="DD.MM.YYYY"
                                            isOutsideRange={() => {}}
                                        />
                                    </InputField>
                                    <InputField label="Status">
                                        <div className="btn-group-toggle">
                                            <button
                                                className={`btn btn-outline-info ${status === STATUS_ENTWURF && 'active'}`}
                                                onClick={e => this.setStatus(STATUS_ENTWURF, e)}>
                                                Entwurf
                                            </button>
                                            <button
                                                className={`btn btn-outline-danger ${this.state.endDate ? '' : 'element-disabled'} ${
                                                    this.state.startDate ? '' : 'element-disabled'
                                                } ${status === STATUS_OFFEN && 'active'}`}
                                                onClick={e => this.setStatus(STATUS_OFFEN, e)}>
                                                Offen
                                            </button>
                                            <button
                                                className={`btn btn-outline-primary ${this.state.startDate ? '' : 'element-disabled'} ${
                                                    this.state.endDate ? '' : 'element-disabled'
                                                } ${status === STATUS_IN_ARBEIT && 'active'}`}
                                                onClick={e => this.setStatus(STATUS_IN_ARBEIT, e)}>
                                                in Arbeit
                                            </button>
                                            <button
                                                className={`btn btn-outline-success  ${this.state.endDate ? '' : 'element-disabled'} ${
                                                    this.state.startDate ? '' : 'element-disabled'
                                                } ${status === STATUS_ERLEDIGT && 'active'}`}
                                                onClick={e => this.setStatus(STATUS_ERLEDIGT, e)}>
                                                Erledigt
                                            </button>
                                            <button
                                                className={`btn btn-outline-dark ${status === STATUS_HINFAELLIG && 'active'}`}
                                                onClick={e => this.setStatus(STATUS_HINFAELLIG, e)}>
                                                Hinfällig
                                            </button>
                                        </div>
                                    </InputField>
                                    <div className="col-12 d-flex justify-content-end">
                                        <Field
                                            name="created"
                                            component="input"
                                            disabled
                                            placeholder={`Erstellt am:  ${moment().format('DD.MM.YYYY')}`}
                                            className="text-muted border-0 text-right"
                                        />
                                    </div>
                                    <hr className="w-100" />
                                    <div className="modal-footer p-0 w-100 border-0">
                                        {showCreatedAufgaben && (
                                            <button className="btn btn-primary" onClick={e => handleSubmit(e)} disabled={submitting || pristine}>
                                                Speichern
                                            </button>
                                        )}
                                        <button
                                            className="btn btn-primary"
                                            disabled={submitting || pristine}
                                            onClick={e =>
                                                handleSubmit(e).then(() => {
                                                    this.setState({ aufgabeToReEdit: {} });
                                                    if (this.state.error === false) {
                                                        form.reset();
                                                    }
                                                })
                                            }>
                                            Speichern und Neu
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={submitting || pristine}
                                            onClick={e => handleSubmit(e).then(this.props.toggleModal())}>
                                            Speichern und Schliessen
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                />
                {showCreatedAufgaben && (
                    <AngelegteAufgaben angelegteAufgaben={angelegteAufgaben} setAufgabeToReEdit={aufgabe => this.setAufgabeToReEdit(aufgabe)} />
                )}
            </div>
        );
    }
}

const AngelegteAufgaben = ({ angelegteAufgaben, setAufgabeToReEdit }) => (
    <div className="container-fluid created-aufgaben-list">
        <div className="row">
            <div className="col-12 p-0 p-lg-1">
                <div className="card">
                    <div className="card-header rounded">
                        <h4 className="m-0">Angelegte Aufgaben</h4>
                    </div>
                    <div className="card-body rounded">
                        {angelegteAufgaben.map(aufgabe => (
                            <div
                                key={aufgabe.id}
                                className="d-flex align-items-center justify-content-between table-striped pointer mt-2 angelegte-aufgabe p-2"
                                onClick={() => setAufgabeToReEdit(aufgabe)}>
                                <div>
                                    <span>{aufgabe.titel}</span>
                                </div>
                                <div>
                                    <span className={`p-2 badge badge-${getBadgeColor(aufgabe.status)} --status`}>{aufgabe.status}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
);
