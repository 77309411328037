import React from 'react';
import ReactDOM from 'react-dom';

export default class UrlaubsantragStornoModal extends React.Component {
    static defaultProps = {
        onConfirm: () => console.log('Sollst mal onConfirm definieren :P'),
        triggerBtnClasses: 'btn btn-primary',
        triggerBtnDisabled: false,
        triggerBtnText: 'Modal öffnen',
        confirmBtnClasses: 'btn btn-primary',
        confirmBtnText: 'Ja',
        showCancelBtn: true,
        cancelBtnClasses: 'btn btn-outline-primary',
        cancelBtnText: 'Nein',
        showCloseX: true,
        title: 'Achtung!',
        contents: 'Bist du sicher?',
    };

    state = {
        isOpen: false,
    };

    openModal = () => this.setState({ isOpen: true });

    closeModal = () => this.setState({ isOpen: false });

    confirmModal = () => {
        this.closeModal();
        this.props.onConfirm();
    };

    render() {
        const triggerProps = {};
        ({
            triggerBtnClasses: triggerProps.triggerBtnClasses,
            triggerBtnDisabled: triggerProps.triggerBtnDisabled,
            triggerBtnText: triggerProps.triggerBtnText,
        } = this.props);

        const modalProps = {};
        ({
            confirmBtnClasses: modalProps.confirmBtnClasses,
            confirmBtnText: modalProps.confirmBtnText,
            showCancelBtn: modalProps.showCancelBtn,
            cancelBtnClasses: modalProps.cancelBtnClasses,
            cancelBtnText: modalProps.cancelBtnText,
            showCloseX: modalProps.showCloseX,
            title: modalProps.title,
            contents: modalProps.contents,
        } = this.props);

        return (
            <React.Fragment>
                <ModalTrigger openModal={this.openModal} {...triggerProps} />
                {this.state.isOpen && <ModalContent confirmModal={this.confirmModal} closeModal={this.closeModal} {...modalProps} />}
            </React.Fragment>
        );
    }
}

const ModalTrigger = ({ triggerBtnClasses, triggerBtnDisabled, triggerBtnText, openModal }) => (
    <button className={triggerBtnClasses} onClick={openModal} disabled={triggerBtnDisabled}>
        {triggerBtnText}
    </button>
);

const ModalContent = ({
    confirmModal,
    closeModal,
    confirmBtnClasses,
    confirmBtnText,
    showCancelBtn,
    cancelBtnClasses,
    cancelBtnText,
    showCloseX,
    title,
    contents,
}) =>
    ReactDOM.createPortal(
        <div className="modal-201c modal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        {showCloseX && (
                            <span onClick={closeModal} className="close">
                                &times;
                            </span>
                        )}
                    </div>
                    <div className="modal-body">
                        <p>{contents}</p>
                    </div>
                    <div className="modal-footer">
                        {showCancelBtn && (
                            <button className={cancelBtnClasses} onClick={closeModal}>
                                {cancelBtnText}
                            </button>
                        )}
                        <button className={confirmBtnClasses} onClick={confirmModal}>
                            {confirmBtnText}
                        </button>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
