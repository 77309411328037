import React from 'react';
import moment from 'moment';
import * as api from '../api';
import UrlaubsantragStatus from './Widget/UrlaubsantragStatus';
import UrlaubsantragZeitraum from './Widget/UrlaubsantragZeitraum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URLAUBSANTRAG_GENEHMIGUNG_STATUS } from './constants';
import { faArrowRight, faCheck, faHourglassHalf, faTimes } from '@fortawesome/fontawesome-free-solid';
import PortalButtonBar from '../PortalButtonBar';
import MonthPicker from '../Zeiterfassung/StundenUebersichtWidget/MonthPicker';
import Select from 'react-select';
import Paginate from '../Paginate';

/**
 * Monthpicker date object
 * @typedef {Object} MonthpickerDate
 * @property {number} year
 * @property {number} month
 */

/**
 * Mitarbeiter dropdown object
 * @typedef {Object} Mitarbeiter
 * @property {number} value
 * @property {string} label
 */

/**
 * Projekt dropdown object
 * @typedef {Object} Projekt
 * @property {number} value
 * @property {string} label
 */

export default class UrlaubsantragUebersicht extends React.Component {
    rowsPerPage = 50;

    state = {
        fetching: true,
        urlaubsantrags: {
            eigene: [],
            verantwortlich: [],
            alle: [],
        },
        eigeneViewActive: true,
        verantwortlichViewActive: false,
        alleViewActive: false,
        totalPages: 1,
        activePage: 0,
        activeUrlaubsantrags: [],
        filteredUrlaubsantrags: [],
        paginatedUrlaubsantrags: [],
        /**
         * @type {MonthpickerDate}
         */
        selectedStartDate: {
            year: 2019,
            month: 1,
        },
        /**
         * @type {MonthpickerDate}
         */
        selectedEndDate: {
            year: parseInt(moment().format('YYYY'), 10) + 1,
            month: 12,
        },
        /**
         * @type {Mitarbeiter[]}
         */
        selectedMitarbeiters: [],
        /**
         * @type {Mitarbeiter[]}
         */
        mitarbeiters: [],
        /**
         * @type {Projekt[]}
         */
        selectedProjekts: [],
        /**
         * @type {Projekt[]}
         */
        projekts: [],
    };

    async componentDidMount() {
        await this.getEigeneUrlaubsantragData();

        this.activateEigeneView();
    }

    async getEigeneUrlaubsantragData() {
        const json = await api.getJson(`/urlaub/urlaubsantrag/list-eigene-data`);

        this.setState(prevState => ({
            urlaubsantrags: {
                ...prevState.urlaubsantrags,
                eigene: json.urlaubsantrags['eigene'],
            },
            mitarbeiters: json.mitarbeiters,
            projekts: json.projekts,
            fetching: false,
        }));
    }

    async getAlleUrlaubsantragData(page, selectedMitarbeiters, selectedProjekts, fromDate, toDate) {
        const mitarbeiterIds = selectedMitarbeiters?.map(mitarbeiter => mitarbeiter.value);
        const projektIds = selectedProjekts?.map(projekt => projekt.value);

        const json = await api.postJson(`/urlaub/urlaubsantrag/list-alle-data`, {
            page,
            mitarbeiterIds: mitarbeiterIds ?? [],
            projektIds: projektIds ?? [],
            fromDate: fromDate ?? null,
            toDate: toDate ?? null,
        });

        this.setState(prevState => ({
            urlaubsantrags: {
                ...prevState.urlaubsantrags,
                alle: json.urlaubsantrags['alle'],
            },
            paginatedUrlaubsantrags: json.urlaubsantrags['alle'],
            totalPages: json.totalPages,
            activePage: page - 1,
            fetching: false,
            selectedMitarbeiters: selectedMitarbeiters ?? [],
            selectedProjekts: selectedProjekts ?? [],
            selectedStartDate: fromDate ?? prevState.selectedStartDate,
            selectedEndDate: toDate ?? prevState.selectedEndDate,
        }));
    }

    async getVerantwortlicheUrlaubsantragData() {
        if (this.state.urlaubsantrags.verantwortlich.length > 0) {
            return;
        }

        const json = await api.getJson(`/urlaub/urlaubsantrag/list-verantwortlich-data`);

        this.setState(prevState => ({
            urlaubsantrags: {
                ...prevState.urlaubsantrags,
                verantwortlich: json.urlaubsantrags['verantwortlich'],
            },
            fetching: false,
        }));
    }

    toggleEigeneView = () => {
        return this.state.eigeneViewActive ? null : this.activateEigeneView();
    };

    toggleVerantwortlichView = async () => {
        if (!this.state.verantwortlichViewActive) {
            await this.getVerantwortlicheUrlaubsantragData();
            return this.activateVerantwortlichView();
        }

        return null;
    };

    toggleAllView = async page => {
        if (!this.state.alleViewActive) {
            await this.getAlleUrlaubsantragData(page);
            return this.activateAllView();
        }

        return null;
    };

    activateEigeneView = () => {
        const activeUrlaubsantrags = this.state.urlaubsantrags.eigene;
        const selectedMitarbeiters = [];
        const selectedProjekts = [];
        const filteredUrlaubsantrags = this.filterUrlaubsantrags(
            activeUrlaubsantrags,
            this.state.selectedStartDate,
            this.state.selectedEndDate,
            selectedMitarbeiters,
            selectedProjekts
        );
        const totalPages = Math.ceil(filteredUrlaubsantrags.length / this.rowsPerPage);
        const activePage = 0;
        const paginatedUrlaubsantrags = this.paginateUrlaubsantrags(filteredUrlaubsantrags, this.rowsPerPage, activePage);
        this.setState({
            eigeneViewActive: true,
            verantwortlichViewActive: false,
            alleViewActive: false,
            activeUrlaubsantrags,
            filteredUrlaubsantrags,
            totalPages,
            activePage,
            paginatedUrlaubsantrags,
            selectedMitarbeiters,
            selectedProjekts,
        });
    };

    activateVerantwortlichView = () => {
        const activeUrlaubsantrags = this.state.urlaubsantrags.verantwortlich;
        const filteredUrlaubsantrags = this.filterUrlaubsantrags(
            activeUrlaubsantrags,
            this.state.selectedStartDate,
            this.state.selectedEndDate,
            this.state.selectedMitarbeiters,
            this.state.selectedProjekts
        );
        const totalPages = Math.ceil(filteredUrlaubsantrags.length / this.rowsPerPage);
        const activePage = 0;
        const paginatedUrlaubsantrags = this.paginateUrlaubsantrags(filteredUrlaubsantrags, this.rowsPerPage, activePage);
        this.setState({
            eigeneViewActive: false,
            verantwortlichViewActive: true,
            alleViewActive: false,
            activeUrlaubsantrags,
            filteredUrlaubsantrags,
            totalPages,
            activePage,
            paginatedUrlaubsantrags,
        });
    };

    activateAllView = () => {
        const activeUrlaubsantrags = this.state.urlaubsantrags.alle;
        const filteredUrlaubsantrags = this.filterUrlaubsantrags(
            activeUrlaubsantrags,
            this.state.selectedStartDate,
            this.state.selectedEndDate,
            this.state.selectedMitarbeiters,
            this.state.selectedProjekts
        );

        this.setState({
            eigeneViewActive: false,
            verantwortlichViewActive: false,
            alleViewActive: true,
            activeUrlaubsantrags,
            filteredUrlaubsantrags,
            activePage: 0,
        });
    };

    filterUrlaubsantrags = (urlaubsantrags, selectedStartDate, selectedEndDate, selectedMitarbeiters, selectedProjekts) => {
        const startMoment = moment(`${selectedStartDate.year}-${selectedStartDate.month}-01`, 'YYYY-M-DD');
        const endMoment = moment(`${selectedEndDate.year}-${selectedEndDate.month}-01`, 'YYYY-M-DD').endOf('month');
        return urlaubsantrags.filter(ua => {
            const date =
                moment(ua.von, 'YYYY-MM-DD').isBetween(startMoment, endMoment, 'day', '[]') ||
                moment(ua.bis, 'YYYY-MM-DD').isBetween(startMoment, endMoment, 'day', '[]');
            let mitarbeiter = true;
            if (selectedMitarbeiters.length > 0) {
                mitarbeiter = selectedMitarbeiters.find(mitarbeiterOption => mitarbeiterOption.value === ua.antragstellerId) !== undefined;
            }
            let projekt = true;
            if (selectedProjekts.length > 0) {
                projekt = selectedProjekts.find(projektOption => ua.projekte.map(projekt => projekt.projektId).includes(projektOption.value)) !== undefined;
            }
            return date && mitarbeiter && projekt;
        });
    };

    // selectedPage begins at 0
    paginateUrlaubsantrags = (urlaubsantragsToPaginate, rowsPerPage, selectedPage) =>
        urlaubsantragsToPaginate.slice(rowsPerPage * selectedPage, rowsPerPage * (selectedPage + 1));

    urlaubsantragAnzeigen = urlaubsantragId => {
        location = `/urlaub/urlaubsantrag/${urlaubsantragId}`;
    };

    handlePageClick = async data => {
        if (this.state.alleViewActive) {
            await this.getAlleUrlaubsantragData(
                data.selected + 1,
                this.state.selectedMitarbeiters,
                this.state.selectedProjekts,
                this.state.selectedStartDate,
                this.state.selectedEndDate
            );
            return;
        }

        const activePage = data.selected;
        const paginatedUrlaubsantrags = this.paginateUrlaubsantrags(this.state.filteredUrlaubsantrags, this.rowsPerPage, activePage);
        this.setState({ activePage, paginatedUrlaubsantrags });
    };

    /**
     * @param {number} year
     * @param {number} month
     */
    onStartDateChange = (year, month) => {
        if (
            moment(`${year}-${month}-01`, 'YYYY-M-DD').isAfter(moment(`${this.state.selectedEndDate.year}-${this.state.selectedEndDate.month}-01`, 'YYYY-M-DD'))
        ) {
            return;
        }

        const selectedStartDate = { year, month };

        const filteredUrlaubsantrags = this.filterUrlaubsantrags(
            this.state.activeUrlaubsantrags,
            selectedStartDate,
            this.state.selectedEndDate,
            this.state.selectedMitarbeiters,
            this.state.selectedProjekts
        );
        const totalPages = Math.ceil(filteredUrlaubsantrags.length / this.rowsPerPage);
        const activePage = 0;
        const paginatedUrlaubsantrags = this.paginateUrlaubsantrags(filteredUrlaubsantrags, this.rowsPerPage, activePage);

        this.setState({
            selectedStartDate,
            totalPages,
            activePage,
            filteredUrlaubsantrags,
            paginatedUrlaubsantrags,
        });
    };

    /**
     * @param {number} year
     * @param {number} month
     */
    onEndDateChange = (year, month) => {
        if (
            moment(`${year}-${month}-01`, 'YYYY-M-DD').isBefore(
                moment(`${this.state.selectedStartDate.year}-${this.state.selectedStartDate.month}-01`, 'YYYY-M-DD')
            )
        ) {
            return;
        }

        const selectedEndDate = { year, month };

        const filteredUrlaubsantrags = this.filterUrlaubsantrags(
            this.state.activeUrlaubsantrags,
            this.state.selectedStartDate,
            selectedEndDate,
            this.state.selectedMitarbeiters,
            this.state.selectedProjekts
        );
        const totalPages = Math.ceil(filteredUrlaubsantrags.length / this.rowsPerPage);
        const activePage = 0;
        const paginatedUrlaubsantrags = this.paginateUrlaubsantrags(filteredUrlaubsantrags, this.rowsPerPage, activePage);

        this.setState({
            selectedEndDate,
            totalPages,
            activePage,
            filteredUrlaubsantrags,
            paginatedUrlaubsantrags,
        });
    };

    handleAlleFilter = async (selectedMitarbeiters, projekte, fromDate, toDate) => {
        await this.getAlleUrlaubsantragData(1, selectedMitarbeiters, projekte, fromDate, toDate);
    };

    onMitarbeiterChange = async selectedMitarbeiters => {
        const filteredUrlaubsantrags = this.filterUrlaubsantrags(
            this.state.activeUrlaubsantrags,
            this.state.selectedStartDate,
            this.state.selectedEndDate,
            selectedMitarbeiters,
            this.state.selectedProjekts
        );
        const totalPages = Math.ceil(filteredUrlaubsantrags.length / this.rowsPerPage);
        const activePage = 0;
        const paginatedUrlaubsantrags = this.paginateUrlaubsantrags(filteredUrlaubsantrags, this.rowsPerPage, activePage);

        this.setState({
            totalPages,
            activePage,
            filteredUrlaubsantrags,
            paginatedUrlaubsantrags,
            selectedMitarbeiters,
        });
    };

    onProjektChange = selectedProjekts => {
        const filteredUrlaubsantrags = this.filterUrlaubsantrags(
            this.state.activeUrlaubsantrags,
            this.state.selectedStartDate,
            this.state.selectedEndDate,
            this.state.selectedMitarbeiters,
            selectedProjekts
        );
        const totalPages = Math.ceil(filteredUrlaubsantrags.length / this.rowsPerPage);
        const activePage = 0;
        const paginatedUrlaubsantrags = this.paginateUrlaubsantrags(filteredUrlaubsantrags, this.rowsPerPage, activePage);

        this.setState({
            totalPages,
            activePage,
            filteredUrlaubsantrags,
            paginatedUrlaubsantrags,
            selectedProjekts,
        });
    };

    render() {
        if (this.state.fetching) {
            return (
                <div>
                    <span>Loading ...</span>
                </div>
            );
        }

        const uaVerwaltenTableClassName = this.state.eigeneViewActive ? 'urlaubsantrag-verwalten-table-eigene' : 'urlaubsantrag-verwalten-table-andere';

        return (
            <React.Fragment>
                <div>
                    <PortalButtonBar>
                        <div className="row">
                            <div className="col-xl-2">
                                <div className="align-items-center row">
                                    <label className="col-xl-3 col-form-label">Start</label>
                                    <div className="col-xl-7">
                                        <MonthPicker
                                            value={this.state.selectedStartDate}
                                            onChange={
                                                this.state.alleViewActive
                                                    ? (year, month) =>
                                                          this.handleAlleFilter(
                                                              this.state.selectedMitarbeiters,
                                                              this.state.selectedProjekts,
                                                              { year, month },
                                                              this.state.selectedEndDate
                                                          )
                                                    : this.onStartDateChange
                                            }
                                            minValue={new Date().getFullYear() - 100}
                                            maxValue={new Date().getFullYear() + 100}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2">
                                <div className="align-items-center row">
                                    <label className="col-xl-3 col-form-label">Ende</label>
                                    <div className="col-xl-7">
                                        <MonthPicker
                                            value={this.state.selectedEndDate}
                                            onChange={
                                                this.state.alleViewActive
                                                    ? (year, month) =>
                                                          this.handleAlleFilter(
                                                              this.state.selectedMitarbeiters,
                                                              this.state.selectedProjekts,
                                                              this.state.selectedStartDate,
                                                              { year, month }
                                                          )
                                                    : this.onEndDateChange
                                            }
                                            minValue={new Date().getFullYear() - 100}
                                            maxValue={new Date().getFullYear() + 100}
                                        />
                                    </div>
                                </div>
                            </div>
                            {!this.state.eigeneViewActive && (
                                <div className="col-xl-4">
                                    <div className="form-group mb-0 row">
                                        <label className="col-xl-3 text-xl-center text-lg-left col-form-label">Mitarbeiter</label>
                                        <div className="col-xl-9">
                                            <Select
                                                options={this.state.mitarbeiters}
                                                value={this.state.selectedMitarbeiters}
                                                onChange={
                                                    this.state.alleViewActive
                                                        ? value =>
                                                              this.handleAlleFilter(
                                                                  value,
                                                                  this.state.selectedProjekts,
                                                                  this.state.selectedStartDate,
                                                                  this.state.selectedEndDate
                                                              )
                                                        : this.onMitarbeiterChange
                                                }
                                                isMulti={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!this.state.eigeneViewActive && (
                                <div className="col-xl-4">
                                    <div className="form-group mb-0 row">
                                        <label className="col-xl-3 text-xl-center text-lg-left col-form-label">Projekte</label>
                                        <div className="col-xl-9">
                                            <Select
                                                options={this.state.projekts}
                                                value={this.state.selectedProjekts}
                                                onChange={
                                                    this.state.alleViewActive
                                                        ? value =>
                                                              this.handleAlleFilter(
                                                                  this.state.selectedMitarbeiters,
                                                                  value,
                                                                  this.state.selectedStartDate,
                                                                  this.state.selectedEndDate
                                                              )
                                                        : this.onProjektChange
                                                }
                                                isMulti={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </PortalButtonBar>
                </div>
                <div className="row mt-2 mr-0">
                    <div className="col-auto pr-0">
                        <ul className="nav nav-tabs nav-tabs-uebersicht">
                            <li className="nav-item">
                                <div
                                    onClick={this.toggleEigeneView}
                                    className={`border-top border-left border-right urlaubsantrag-verwalten-tab ${
                                        this.state.eigeneViewActive ? `tab-active` : ``
                                    }`}>
                                    <p className="pl-4 pr-4 pt-2 pb-2 m-0">Eigene</p>
                                </div>
                            </li>
                            <li className="nav-item">
                                <div
                                    onClick={this.toggleVerantwortlichView}
                                    className={`border-top border-sm-left border-right urlaubsantrag-verwalten-tab ${
                                        this.state.verantwortlichViewActive ? `tab-active` : ``
                                    }`}>
                                    <p className="pl-4 pr-4 pt-2 pb-2 m-0">Verantwortlich für</p>
                                </div>
                            </li>
                            <li className="nav-item">
                                <div
                                    onClick={() => this.toggleAllView(1)}
                                    className={`border-top border-sm-left border-right urlaubsantrag-verwalten-tab ${
                                        this.state.alleViewActive ? `tab-active` : ``
                                    }`}>
                                    <p className="pl-4 pr-4 pt-2 pb-2 m-0">Alle</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col" style={{ borderBottom: '1px solid #dee2e6' }} />
                </div>
                <div className="card border-top-0">
                    <div className="card-body">
                        {this.state.totalPages > 1 && (
                            <div className="row">
                                <div className="col-12">
                                    <Paginate forcePage={this.state.activePage} pageCount={this.state.totalPages} onPageChange={this.handlePageClick} />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <table className={`table table-responsive-md table-striped mb-0 mt-2 ${uaVerwaltenTableClassName}`}>
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        {this.state.eigeneViewActive ? null : <th>Antragssteller</th>}
                                        <th>Urlaubszeitraum</th>
                                        <th>Abwesenheit</th>
                                        <th>Beantragt am</th>
                                        <th>Projektvertretungen</th>
                                        <th className="verantwortlicher-mitarbeiter">Verantwortliche Mitarbeiter</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.paginatedUrlaubsantrags
                                        .sort((a, b) => (a.von > b.von ? -1 : 1))
                                        .map(urlaubsantragDaten => (
                                            <tr key={urlaubsantragDaten.id}>
                                                <td>
                                                    <UrlaubsantragStatus urlaubsantragStatus={urlaubsantragDaten.status} />
                                                </td>
                                                {this.state.eigeneViewActive ? null : (
                                                    <td>
                                                        <a href={urlaubsantragDaten.antragstellerProfileUrl}>{urlaubsantragDaten.antragsteller}</a>
                                                    </td>
                                                )}
                                                <td>
                                                    <UrlaubsantragZeitraum urlaubsantrag={urlaubsantragDaten} />
                                                </td>
                                                <td>{`${urlaubsantragDaten.dauerTage} Tage`}</td>
                                                <td>{urlaubsantragDaten.eingang ? moment(urlaubsantragDaten.eingang).format('DD.MM.YY') : ''}</td>
                                                <td>
                                                    {urlaubsantragDaten.projekte.map(projekt => (
                                                        <div key={projekt.projektId}>
                                                            <div>
                                                                <a className="d-block text-truncate" style={{ maxWidth: '350px' }} href={projekt.projektUrl}>
                                                                    {projekt.projektName}
                                                                </a>
                                                            </div>
                                                            {projekt.vertreter.map(vertretung => (
                                                                <div key={vertretung.vertretungId} className="pl-4">
                                                                    <a href={vertretung.vertretungProfileUrl}>{vertretung.vertretungName}</a>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </td>
                                                <td>
                                                    {urlaubsantragDaten.genehmigungsVerantwortliche.map(mitarbeiter => (
                                                        <div key={mitarbeiter.name} className="d-flex justify-content-start align-items-center ">
                                                            <a href={mitarbeiter.profileUrl}>{mitarbeiter.name}</a>
                                                            <FontAwesomeIcon
                                                                className="ml-2"
                                                                color={`${
                                                                    mitarbeiter.status === URLAUBSANTRAG_GENEHMIGUNG_STATUS.GENEHMIGT
                                                                        ? `#00cb1d`
                                                                        : mitarbeiter.status === URLAUBSANTRAG_GENEHMIGUNG_STATUS.ABGELEHNT
                                                                        ? `red`
                                                                        : `gray`
                                                                }`}
                                                                icon={
                                                                    mitarbeiter.status === URLAUBSANTRAG_GENEHMIGUNG_STATUS.GENEHMIGT
                                                                        ? faCheck
                                                                        : mitarbeiter.status === URLAUBSANTRAG_GENEHMIGUNG_STATUS.ABGELEHNT
                                                                        ? faTimes
                                                                        : faHourglassHalf
                                                                }
                                                            />
                                                        </div>
                                                    ))}
                                                </td>
                                                <td className="text-right">
                                                    <button
                                                        title="zum Urlaubsantrag"
                                                        className="ml-3 p-1 btn btn-secondary"
                                                        onClick={() => {
                                                            this.urlaubsantragAnzeigen(urlaubsantragDaten.id);
                                                        }}>
                                                        <FontAwesomeIcon fixedWidth={true} icon={faArrowRight} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <hr />
                        {this.state.totalPages > 1 && (
                            <div className="row">
                                <div className="col-12">
                                    <Paginate forcePage={this.state.activePage} pageCount={this.state.totalPages} onPageChange={this.handlePageClick} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
