import React from 'react';
import moment from 'moment';
import numeral from 'numeral';

const roundMinutes = m => (m <= 7 ? 0 : m <= 22 ? 15 : m <= 37 ? 30 : m <= 52 ? 45 : 60);

export const MODE__COMPLETE_TO_HOURS = 'hours';
export const MODE__COMPLETE_TO_MINUTES = 'minutes';
export const MODE__COMPLETE_TO_EDUCATED_GUESS = 'guess';

export default class EditableTimeValue extends React.Component {
    static defaultProps = {
        mode: MODE__COMPLETE_TO_HOURS,
        roundMinutes: false,
        lowNumbersResultInHours: false,
        disabled: false,
    };

    state = {
        value: '',
        init: '',
        timeString: '',
    };

    constructor(props) {
        super(props);
        this.state.timeString = props.timeString;
        this.state.value = this.state.init = props.timeString ? moment(props.timeString).format('H:mm') : '';
    }

    // there will ne new props from outside, and then we will change the timestrings
    componentWillReceiveProps(nextProps) {
        if (nextProps.timeString !== this.state.timeString) {
            this.state.timeString = nextProps.timeString;
            this.state.value = this.state.init = nextProps.timeString ? moment(nextProps.timeString).format('H:mm') : '';
            this.setState(this.state);
        }
    }

    save(value) {
        if (value === this.state.init) {
            return;
        }

        const { onChange } = this.props;
        let match;

        let hours = 0,
            minutes = 0,
            separator = '';

        if ((match = value.match(/^(\d{1,4})$/))) {
            // match single digit
            const digit = parseInt(match[1]);
            switch (this.props.mode) {
                case MODE__COMPLETE_TO_HOURS:
                    hours = digit;
                    break;
                case MODE__COMPLETE_TO_MINUTES:
                    minutes = digit;
                    break;
                case MODE__COMPLETE_TO_EDUCATED_GUESS:
                    if (digit < 10) {
                        hours = digit;
                    } else {
                        minutes = digit;
                    }
                    break;
                default:
                    throw new Error(`Invalid Mode "${this.props.mode}".`);
            }
        } else if ((match = value.match(/^(\d{1,2})\s*(\D+)\s*(\d{1,2})$/))) {
            // match dual digit with separator
            [hours, separator, minutes] = [parseInt(match[1]), match[2], parseInt(match[3])];

            // treat minutes as fractal
            if (',' === separator) {
                // special treatment for numnbers less than 10
                // 4,5 -> 4,50, so that ,50 will be transfered to :30
                if (1 === match[3].length) {
                    minutes *= 10;
                }
                minutes = (minutes / 100) * 60;
            }
        }

        if (0 === hours && 0 === minutes) {
            this.setState({ value: '', init: '' });
            onChange(null);
        } else {
            hours = Math.min(hours, 23);
            minutes = Math.min(minutes, 60);

            if (this.props.roundMinutes) {
                minutes = roundMinutes(minutes);
            }

            // if minutes round to full
            if (60 === minutes) {
                hours++;
                minutes = 0;
            }

            const display = `${numeral(hours).format('0')}:${numeral(minutes).format('00')}`;
            const result = `${numeral(hours).format('00')}:${numeral(minutes).format('00')}`;

            this.setState({ value: display, init: display });
            onChange(moment(`2019-01-28T${result}:00+01:00`));
        }
    }

    render() {
        if (this.props.disabled) {
            return <input className="form-control form-control-zeit-input" value={this.state.value} tabIndex={this.props.tabIndex} disabled="disabled" />;
        }

        return (
            <div>
                <input
                    className="form-control form-control-zeit-input"
                    value={this.state.value}
                    onChange={e => this.setState({ value: e.target.value })}
                    onBlur={e => this.save(e.target.value)}
                    tabIndex={this.props.tabIndex}
                    onKeyUp={this.props.onKeyUp}
                />
            </div>
        );
    }
}
