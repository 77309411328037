import React, { useState } from 'react';
import { CardTemplate } from '../ViewTemplates';
import Avatar from '../../Aufgaben/Aufgabe/Avatar';
import PlaceholderPic from '../../../../../../application/public/images/dashboard/Profilbild_geschlechtsneutral.svg';
import InhaltsElementBewerten from '../InhaltsElemente/InhaltsElementBewerten';
import Kommentar from '../Kommentar/Kommentar';
import ReactMarkdown from 'react-markdown';
import KommentarAnlegen from '../Kommentar/KommentarAnlegen';
import FrageEdit from './FrageEdit';
import Antwort from './Antwort';
import AntwortEditor from './AntwortEditor';
import moment from 'moment';
import { handleFormSubmitResult } from '../../formHelpers';
import InhaltsElementAlsFokusthema from '../InhaltsElemente/InhaltsElementAlsFokusthema';
import InhaltsElementDelete from '../InhaltsElemente/InhaltsElementDelete';
import { WISSEN_INHALTSTYP_FRAGE_URL, WISSEN_INHALTSTYP_FRAGE, WISSEN_INHALTSELEMENTTYP_FRAGE } from '../WissenConstants';
import { deleteKommentarInInhaltselement, updateKommentarInInhaltselement } from '../Kommentar/updateKommentarInInhaltselement';
import { wissenFrageAktualisieren, wissenFrageBeantworten } from '../wissenApi';
import InhaltsElementAnpinnen, { pinInhaltselementOnDetailPage, unPinInhaltselementOnDetailPage } from '../InhaltsElemente/InhaltsElementAnpinnen';
import { istElementAngepinnt } from '../InhaltsElemente/InhaltsTypenListItem';
import InhaltselementDetailFiles from '../InhaltsElemente/InhaltselementDetailPageFiles';
import { deleteAllowed, editAllowed, pinAndFokusthemaSetzenAllowed } from '../InhaltselementActionPermissions';

export default initialProps => {
    const [frage, setFrage] = useState(initialProps.frage);
    const [richtigeAntwort, antwortAlsRichtigMarkierenOderEntziehen] = useState(frage.antworten.find(antwort => antwort.alsRichtigGekennzeichnet) || false);
    const [editorOpen, toggleEditor] = useState(false);
    const [fokusthemen, setFokusthemen] = useState([initialProps.frage]);

    const updateAntwort = async updatedAntwort => {
        const result = await wissenFrageAktualisieren(updatedAntwort);
        if (handleFormSubmitResult(result)) {
            setFrage(result.frage);
        }
    };

    const submitAntwort = async antwort => {
        const result = await wissenFrageBeantworten({
            ...antwort,
            frage,
        });
        if (handleFormSubmitResult(result)) {
            setFrage(result.frage);
            toggleEditor(false);
        }
    };

    return (
        <div className="container-fluid mb-5">
            <div className="row">
                <div className={`${frage.kommentare.length !== 0 ? 'col-lg-6' : 'col-lg-12'} col-lg-6 col-md-12`}>
                    <CardTemplate headingClass="d-none">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-end">
                                    <div className="btn-group">
                                        {editAllowed(initialProps.benutzerIstAdmin, initialProps.benutzerIstErstellerVonInhaltsAggregat) && (
                                            <FrageEdit
                                                frage={frage}
                                                updateFrage={frage => setFrage(frage)}
                                                alleKnoten={initialProps.alleKnoten}
                                                alleTags={initialProps.alleTags}
                                            />
                                        )}
                                        <KommentarAnlegen
                                            inhaltselement={frage}
                                            inhaltselementTyp={WISSEN_INHALTSELEMENTTYP_FRAGE}
                                            addKommentarToInhaltselement={kommentar => setFrage({ ...frage, kommentare: [...frage.kommentare, kommentar] })}
                                        />
                                        {pinAndFokusthemaSetzenAllowed(initialProps.benutzerIstAdmin, initialProps.benutzerIstModerator) && (
                                            <React.Fragment>
                                                <InhaltsElementAlsFokusthema
                                                    inhaltsElement={frage}
                                                    fokusthemen={fokusthemen}
                                                    setFokusthemen={setFokusthemen}
                                                    typ={WISSEN_INHALTSTYP_FRAGE}
                                                />
                                                <InhaltsElementAnpinnen
                                                    typ={WISSEN_INHALTSTYP_FRAGE}
                                                    inhaltsElement={{ ...frage, knotenId: frage.knoten.id }}
                                                    pinInhaltselement={element => pinInhaltselementOnDetailPage(element, setFrage)}
                                                    unpinInhaltselement={element => unPinInhaltselementOnDetailPage(element, setFrage)}
                                                    istElementAngepinnt={istElementAngepinnt(frage, frage.knoten.angepinnteInhaltselemente)}
                                                />
                                            </React.Fragment>
                                        )}
                                        <InhaltsElementBewerten
                                            inhaltselement={frage}
                                            typ={WISSEN_INHALTSTYP_FRAGE}
                                            istBewertetDurchAktuelleBenutzer={frage.istBewertetDurchAktuelleBenutzer}
                                        />
                                        {deleteAllowed(
                                            initialProps.benutzerIstAdmin,
                                            initialProps.benutzerIstModerator,
                                            initialProps.benutzerIstErstellerVonInhaltsAggregat
                                        ) && <InhaltsElementDelete inhaltsElement={frage} type={WISSEN_INHALTSTYP_FRAGE_URL} />}
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-between mt-2 mt-xl-0">
                                    <div className="text-muted">
                                        <ReactMarkdown escapeHtml={false} source={frage.inhalt} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        {frage.tags.map(tag => (
                                            <div className="col-auto" key={tag.id}>
                                                <span className="badge badge-info font-weight-normal w-100">{tag.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                {editorOpen && <AntwortEditor toggleEditor={toggleEditor} submitAntwort={antwort => submitAntwort(antwort)} />}
                            </div>
                            {frage.antworten.length !== 0 && (
                                <div className="row">
                                    <div className="col-12">
                                        <h2>Antworten</h2>
                                    </div>
                                    <hr className="w-100" />
                                </div>
                            )}
                            {frage.antworten.map(antwort => (
                                <div className="row" key={antwort.id}>
                                    <div className="col-12">
                                        <Antwort
                                            updateFrageAfterAntwortDelete={deletedAntwort =>
                                                setFrage({ ...frage, antworten: frage.antworten.filter(antwort => antwort.id !== deletedAntwort.id) })
                                            }
                                            submitAntwort={antwort => updateAntwort(antwort)}
                                            initialValues={antwort}
                                            richtigeAntwort={richtigeAntwort}
                                            antwortAlsRichtigMarkierenOderEntziehen={antwortAlsRichtigMarkierenOderEntziehen}
                                            benutzerIstModerator={initialProps.benutzerIstModerator}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </CardTemplate>
                </div>
                {frage.kommentare.length !== 0 && (
                    <div className="col-lg-6 col-md-12">
                        <CardTemplate headingClass="bg-light border-bottom border-primary" classNames="mb-3 mt-lg-0 mt-3" heading="Kommentare">
                            <ul className="list-group list-group-flush list-unstyled">
                                {frage.kommentare.map(kommentar => (
                                    <Kommentar
                                        key={kommentar.id}
                                        kommentar={kommentar}
                                        updateKommentarInInhaltselement={updatedKommentar => updateKommentarInInhaltselement(updatedKommentar, frage, setFrage)}
                                        deleteKommentarInInhaltselement={deletedKommentar => deleteKommentarInInhaltselement(deletedKommentar, frage, setFrage)}
                                    />
                                ))}
                            </ul>
                        </CardTemplate>
                        <CardTemplate headingClass="bg-light border-bottom border-primary" heading="Angefügte Dateien">
                            <InhaltselementDetailFiles inhaltselement={frage} />
                        </CardTemplate>
                    </div>
                )}
                <div className="col-6 d-flex justify-content-between mt-4">
                    <div className="d-flex align-items-center">
                        {frage.ersteller.aktiv && (
                            <Avatar name={frage.ersteller.name} url={`/mitarbeiter/${frage.ersteller.internId}/detail`} avatar={PlaceholderPic} />
                        )}
                        {!frage.ersteller.aktiv && <span className="text-muted">{frage.ersteller.name}</span>}
                        <span className="ml-1 text-muted">zuletzt aktualisiert am {moment(frage.aktualisiertAm).format('DD.MM.YY, hh:mm')}</span>
                    </div>
                    <div className="btn-group">
                        <button className="btn btn-sm btn-primary" onClick={() => toggleEditor(!editorOpen)}>
                            Antworten
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
