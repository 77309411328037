import React, { Component } from 'react';

import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';

const months = ['Jan.', 'Feb.', 'März', 'Apr.', 'Mai', 'Juni', 'Juli', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dez.'];

export default class StundenUebersichtMonthPicker extends Component {
    render() {
        return (
            <React.Fragment>
                <input
                    className="form-control form-control-zeit-input mb-0"
                    onClick={() => {
                        this.refs.picker.show();
                    }}
                    value={this.props.value.month + '/' + this.props.value.year}
                    readOnly={true}
                />
                <Picker
                    ref="picker"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    years={{
                        min: this.props.minValue,
                        max: this.props.maxValue,
                    }}
                    lang={months}
                />
            </React.Fragment>
        );
    }
}
