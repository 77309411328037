import $ from 'jquery';

import 'select2/src/js/select2/i18n/de';
import 'select2';

$(document).on('e2-dom-init', (e, $context) => {
    $context.find('select:not(.custom-select)').select2({
        theme: 'bootstrap4',
    });
});
