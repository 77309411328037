import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlus } from '@fortawesome/fontawesome-free-solid';
import * as api from '../api';
import UrlaubsantragStatus from './Widget/UrlaubsantragStatus';
import UrlaubsantragZeitraum from './Widget/UrlaubsantragZeitraum';
import { URLAUBSANTRAG_STATUS } from './constants';

/**
 * @typedef {Object} Urlaubsantrag
 * @property {string} id
 * @property {string} status URLAUBSANTRAG_STATUS
 * @property {string} von - in YYYY-MM-DD format
 * @property {string} bis - in YYYY-MM-DD format
 * @property {int} tageBis
 */

export default class MeineUrlaubsantraegeWidget extends React.Component {
    /**
     * @type {{fetching: boolean, urlaubsantrags: Urlaubsantrag[]}}
     */
    state = {
        fetching: true,
        urlaubsantrags: [],
        vertretungUrlaubsantrags: [],
        eigeneViewActive: true,
        vertretungViewActive: false,
    };

    componentDidMount() {
        this.loadUrlaubsantragList();
    }

    loadUrlaubsantragList = async () => {
        const json = await api.getJson(`/urlaub/urlaubsantrag/list-own-data-pending`);
        this.setState({
            fetching: false,
            urlaubsantrags: json.urlaubsantrags,
            vertretungUrlaubsantrags: json.vertretungUrlaubsantrags,
        });
    };

    toggleEigeneView = () => (this.state.eigeneViewActive ? null : this.setState({ eigeneViewActive: true, vertretungViewActive: false }));
    toggleVertretungView = () => (this.state.vertretungViewActive ? null : this.setState({ eigeneViewActive: false, vertretungViewActive: true }));

    render() {
        const { fetching } = this.state;

        if (fetching) {
            return (
                <div>
                    <span>Loading</span>
                </div>
            );
        }
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="row mr-0">
                                <div className="col-auto pr-0 d-flex align-items-center tab-empty-space">
                                    <h2>Urlaub</h2>
                                </div>
                                <div className="col tab-empty-space" />
                                <div className="col-auto pl-0 pr-0">
                                    <ul className="nav nav-tabs nav-tabs-uebersicht">
                                        <li className="nav-item">
                                            <div
                                                onClick={this.toggleEigeneView}
                                                className={`border-top border-left border-right urlaubsantrag-verwalten-tab ${
                                                    this.state.eigeneViewActive ? `tab-active` : ``
                                                }`}>
                                                <p className="pl-4 pr-4 pt-2 pb-2 m-0">Mein Urlaub</p>
                                            </div>
                                        </li>
                                        {this.state.vertretungUrlaubsantrags.length > 0 && (
                                            <li className="nav-item">
                                                <div
                                                    onClick={this.toggleVertretungView}
                                                    className={`border-top border-sm-left border-right urlaubsantrag-verwalten-tab ${
                                                        this.state.vertretungViewActive ? `tab-active` : ``
                                                    }`}>
                                                    <p className="pl-4 pr-4 pt-2 pb-2 m-0">Urlaubsvertretung</p>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className="col-auto d-flex align-items-center tab-empty-space">
                                    <button
                                        title="neuer Urlaubsantrag"
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                            location = '/urlaub/urlaubsantrag/';
                                        }}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
                            </div>
                            {this.state.eigeneViewActive && (
                                <React.Fragment>
                                    {this.state.urlaubsantrags.length === 0 ? (
                                        <div className="row mt-3">
                                            <p>Keine kommenden Urlaube!</p>
                                        </div>
                                    ) : (
                                        <table className="table meinurlaub-widged-table table-striped mb-0">
                                            <thead>
                                                <tr className="meinurlaub-widged-head text-center">
                                                    <th className="border-top-0">Status</th>
                                                    <th className="border-top-0">von / bis</th>
                                                    <th className="border-top-0">Tage bis zum Urlaub</th>
                                                    <th className="border-top-0" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.urlaubsantrags.map(urlaubsantrag => (
                                                    <tr key={urlaubsantrag.id}>
                                                        <td>
                                                            <UrlaubsantragStatus urlaubsantragStatus={urlaubsantrag.status} />
                                                        </td>
                                                        <td>
                                                            <UrlaubsantragZeitraum urlaubsantrag={urlaubsantrag} />
                                                        </td>
                                                        <td>
                                                            {[
                                                                URLAUBSANTRAG_STATUS.ABGELEHNT,
                                                                URLAUBSANTRAG_STATUS.VERWORFEN,
                                                                URLAUBSANTRAG_STATUS.STORNIERT,
                                                            ].includes(urlaubsantrag.status)
                                                                ? '-'
                                                                : urlaubsantrag.tageBis}
                                                        </td>
                                                        <td className="text-right">
                                                            <div className="btn-group">
                                                                <button
                                                                    title="zum Urlaubsantrag"
                                                                    className="btn btn-sm btn-secondary"
                                                                    onClick={() => {
                                                                        location = `/urlaub/urlaubsantrag/${urlaubsantrag.id}`;
                                                                    }}>
                                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </React.Fragment>
                            )}
                            {this.state.vertretungViewActive && (
                                <table className="table meinurlaub-widged-table table-striped mb-0">
                                    <thead>
                                        <tr className="meinurlaub-widged-head text-center">
                                            <th className="border-top-0">von / bis</th>
                                            <th className="border-top-0">Projekt</th>
                                            <th className="border-top-0">Vertretung für</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.vertretungUrlaubsantrags.map(urlaubsantrag =>
                                            urlaubsantrag.projekte.map(projekt => (
                                                <tr key={urlaubsantrag.id + '' + projekt.id}>
                                                    <td className="border-top-0">
                                                        <UrlaubsantragZeitraum urlaubsantrag={urlaubsantrag} />
                                                    </td>
                                                    <td className="border-top-0">
                                                        <span style={{ 'white-space': 'normal' }}>{projekt.label}</span>
                                                    </td>
                                                    <td className="border-top-0">{urlaubsantrag.mitarbeiter}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
