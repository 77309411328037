import React from 'react';
import NumberInput from './NumberInput';

export default class UrlaubToUeberStundenConverter extends React.Component {
    /**
     * @type {{this.props.value: UrlaubsantragTagKonditionen}}
     */
    state = {
        urlaubStd: this.props.value.urlaubstunden,
        ueberStd: this.props.value.ueberstunden,
    };

    handleUeberStundenChange = value => {
        value = Math.min(this.props.sollStd, value);

        this.state.ueberStd = value;
        if (value + this.state.urlaubStd > this.props.sollStd) {
            this.state.urlaubStd = Math.max(0, this.props.sollStd - value);
        }

        this.setState(this.state);
        this.props.setValue(this.state);
    };

    handleUrlaubsStundenChange = value => {
        value = Math.min(this.props.sollStd, value);

        this.state.urlaubStd = value;
        if (value + this.state.ueberStd > this.props.sollStd) {
            this.state.ueberStd = Math.max(0, this.props.sollStd - value);
        }

        this.setState(this.state);
        this.props.setValue(this.state);
    };

    render() {
        return (
            <div className="d-flex ml-auto mr-auto urlaubsantrag-stunden-editor ">
                <div className="input-group no-index">
                    <NumberInput value={this.state.urlaubStd} setValue={this.handleUrlaubsStundenChange} onBlur={this.props.onBlur} />
                </div>
                <div className="input-group no-index">
                    <NumberInput value={this.state.ueberStd} setValue={this.handleUeberStundenChange} onBlur={this.props.onBlur} />
                </div>
            </div>
        );
    }
}
