import React, { useState } from 'react';
import * as api from '../../api';
import SortableTree from 'react-sortable-tree';
import KnotenErzeugen from '../Knoten/KnotenErzeugen';
import KnotenEdit from '../Knoten/KnotenEdit';
import KnotenDelete from '../Knoten/KnotenDelete';
import { toast } from 'react-toastify';
import { createBaumDataFromAllKnoten } from '../Knoten/createBaumDataFromAllKnoten';
import { normalizeKnoten } from '../Knoten/normalizeKnoten';

export default initialProps => {
    const [data, setData] = useState(createBaumDataFromAllKnoten(initialProps.knoten));

    const handleKnotenSortieren = async newParentKnoten => {
        const result = await api.postJson(`/wissen/knoten/sortieren`, {
            sortierung: newParentKnoten.children.map((childKnoten, index) => ({
                id: childKnoten.id,
                position: index,
            })),
        });

        if (result.error) {
            toast.error(result.error);
        } else if (result.statusCode && result.statusCode !== 200) {
            toast.error('Es ist ein unerwarteter Fehler aufgetreten');
        }
    };

    const handleKnotenVerschieben = async (movedKnoten, newParentKnoten) => {
        const normalizedKnoten = normalizeKnoten(movedKnoten);
        const normalizedParent = normalizeKnoten(newParentKnoten);

        const result = await api.postJson(`/wissen/knoten/verschieben`, {
            ...normalizedKnoten,
            position: normalizedParent.children.indexOf(normalizedKnoten),
            parent: {
                ...normalizedParent,
                kinder: normalizedParent.children,
            },
        });

        if (result.error) {
            toast.error(result.error);
        } else if (result.statusCode && result.statusCode !== 200) {
            toast.error('Es ist ein unerwarteter Fehler aufgetreten');
        }

        await handleKnotenSortieren(newParentKnoten);
    };

    const addNewKnotenToKnotenBaum = newKnoten => {
        initialProps.knoten.push(newKnoten);
        setData(createBaumDataFromAllKnoten(initialProps.knoten));
    };

    const updateKnoten = (alleKnoten, aktualisierterKnoten) => {
        aktualisierterKnoten.expanded = true;

        const expandAllParents = parent => {
            parent.expanded = true;
            if (parent.parent !== null) {
                expandAllParents(alleKnoten.find(knoten => knoten.id === parent.parent.id));
            }
        };

        if (aktualisierterKnoten.parent !== null) {
            expandAllParents(alleKnoten.find(knoten => knoten.id === aktualisierterKnoten.parent.id));
        }

        setData(createBaumDataFromAllKnoten(alleKnoten));
    };

    const deleteKnoten = deletedKnoten => setData(createBaumDataFromAllKnoten(initialProps.knoten.filter(knoten => knoten.id !== deletedKnoten.id)));

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body" style={{ height: 800 }}>
                            {data.length === 0 && <h3>Lade daten ...</h3>}
                            <SortableTree
                                treeData={data}
                                onChange={treeData => setData(treeData)}
                                canDrag={data => data.parentNode !== null}
                                canDrop={data => data.nextParent !== null}
                                onMoveNode={data => {
                                    if (data.node.parent.id === data.nextParentNode.id) {
                                        handleKnotenSortieren(data.nextParentNode);
                                    } else {
                                        handleKnotenVerschieben(data.node, data.nextParentNode);
                                    }
                                }}
                                generateNodeProps={data => ({
                                    listIndex: 0,
                                    lowerSiblingCounts: [],
                                    buttons: [
                                        <div className="h-100 w-100 d-flex justify-content-center align-items-center mr-3">
                                            <span>Inhaltselemente ({data.node.wikis.length + data.node.fragen.length + data.node.dokumente.length})</span>
                                        </div>,
                                        data.node.kinderErlaubt && (
                                            <KnotenErzeugen
                                                knotenParent={data.node}
                                                addNewKnotenToKnotenBaum={newKnoten => addNewKnotenToKnotenBaum(newKnoten)}
                                                inhaltsTypen={initialProps.inhaltsTypen}
                                                benutzer={initialProps.benutzer}
                                                berechtigungsgruppen={initialProps.berechtigungsgruppen}
                                            />
                                        ),
                                        <KnotenEdit
                                            updateKnoten={(alleKnoten, aktualisierterKnoten) => updateKnoten(alleKnoten, aktualisierterKnoten)}
                                            knoten={data.node}
                                            inhaltsTypen={initialProps.inhaltsTypen}
                                            benutzer={initialProps.benutzer}
                                            berechtigungsgruppen={initialProps.berechtigungsgruppen}
                                        />,
                                        data.treeIndex !== 0 && data.node.children.length === 0 && (
                                            <KnotenDelete deleteKnoten={() => deleteKnoten(data.node)} knoten={data.node} />
                                        ),
                                    ],
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
