import React, { Component } from 'react';
import * as api from '../api';
import moment from 'moment';
import Filter from './StundenUebersichtWidget/MitarbeiterUebersichtFilter';
import MitarbeiterHeader from './StundenUebersichtWidget/MitarbeiterUebersichtHeader';
import MitarbeiterUebersichtProject from './StundenUebersichtWidget/MitarbeiterUebersichtProject';
import MitarbeiterUebersichtFooter from './StundenUebersichtWidget/MitarbeiterUebersichtFooter';
import { isMonthInSelectedPeriod } from './StundenUebersichtWidget/utils';
import PortalButtonBar from '../PortalButtonBar';

moment.locale('de');

const momentFromDateObj = date => {
    return moment(`${date.year}-${date.month}-01`, 'YYYY-M-DD');
};

export default class MitarbeiterStundenUebersichtWidget extends Component {
    state = {
        dataLoaded: false,
        loadingMitarbeiterUebersicht: false,
        uebersicht: {},
        projekts: {},
        mitarbeiters: {},
        mitarbeitersForSelect: [],
        standorts: {},
        earliestDate: {}, // { year: 2019, month: 1 }
        latestDate: {}, // { year: 2019, month: 1 }
        availableDates: [], // [{ year: 2019, month: 1 }, ...]
        selectedStartDate: {}, // { year: 2019, month: 1 }
        selectedEndDate: {}, // { year: 2019, month: 2 }
        selectedMitarbeiterId: null,
    };

    calculateSelectedDates = (startDate, endDate) => {
        const selectedDates = [];
        for (let year = startDate.year; year <= endDate.year; year++) {
            for (let month = 1; month <= 12; month++) {
                if ((year === startDate.year && month < startDate.month) || (year === endDate.year && month > endDate.month)) {
                    continue;
                }

                selectedDates.push({ year, month });
            }
        }
        return selectedDates;
    };

    async componentDidMount() {
        const json = await api.getJson(`/zeiterfassung/stundenuebersicht/mitarbeiter/initial-data/`);

        const earliestDate = {
            year: parseInt(json.earliestDate.year, 10),
            month: parseInt(json.earliestDate.month, 10),
        };
        const latestDate = {
            year: parseInt(json.latestDate.year, 10),
            month: parseInt(json.latestDate.month, 10),
        };
        const availableDates = this.calculateSelectedDates(earliestDate, latestDate);

        const mitarbeitersForSelect = Object.keys(json.mitarbeiters)
            .map(mId => json.mitarbeiters[mId])
            .sort((a, b) => {
                const nachnameComparison = a.nachname.localeCompare(b.nachname, 'de');
                if (nachnameComparison !== 0) {
                    return nachnameComparison;
                }
                return a.name.localeCompare(b.name, 'de');
            })
            .map(mitarbeiter => {
                return {
                    value: mitarbeiter.id,
                    label: `${mitarbeiter.name} ${mitarbeiter.nachname}`,
                };
            });

        const newState = {
            ...json,
            mitarbeitersForSelect,
            dataLoaded: true,
            availableDates,
            earliestDate,
            latestDate,
            selectedStartDate: earliestDate,
            selectedEndDate: latestDate,
            selectedMitarbeiterId: json.ich,
        };

        this.setState(newState);
    }

    onStartDateChange = (year, month) => {
        if (momentFromDateObj({ year, month }).isAfter(momentFromDateObj(this.state.selectedEndDate))) {
            return;
        }

        const selectedStartDate = { year, month };
        const availableDates = this.calculateSelectedDates(selectedStartDate, this.state.selectedEndDate);
        this.setState({ selectedStartDate, availableDates });
    };

    onEndDateChange = (year, month) => {
        if (momentFromDateObj({ year, month }).isBefore(momentFromDateObj(this.state.selectedStartDate))) {
            return;
        }

        const selectedEndDate = { year, month };
        const availableDates = this.calculateSelectedDates(this.state.selectedStartDate, selectedEndDate);
        this.setState({ selectedEndDate, availableDates });
    };

    onMitarbeiterChange = async selectedMitarbeiter => {
        const uebersicht = this.state.uebersicht;
        const mId = selectedMitarbeiter.value;

        if (typeof uebersicht[mId] !== 'undefined') {
            this.setState({ selectedMitarbeiterId: mId });
            return;
        }

        this.setState({ loadingMitarbeiterUebersicht: true });
        const json = await api.getJson(`/zeiterfassung/stundenuebersicht/mitarbeiter/mitarbeiter-data/${mId}`);
        uebersicht[mId] = json.uebersicht[mId];
        this.setState({ uebersicht, selectedMitarbeiterId: mId, loadingMitarbeiterUebersicht: false });
    };

    makePdf = () => {
        this.makeFile('pdf');
    };

    makeFile = format => {
        const mId = this.state.selectedMitarbeiterId;
        const startDate = momentFromDateObj(this.state.selectedStartDate).format('YYYY-MM-DD');
        const endDate = momentFromDateObj(this.state.selectedEndDate)
            .endOf('month')
            .format('YYYY-MM-DD');

        const url = `/zeiterfassung/stundenuebersicht/mitarbeiter/file/${mId}/${startDate}/${endDate}.${format}`;
        if (format === 'pdf') {
            window.open(url, '_blank');
        } else {
            location = url;
        }
    };

    makeXlsx = () => {
        this.makeFile('xlsx');
    };

    render() {
        if (!this.state.dataLoaded) {
            return <div>Loading</div>;
        }

        const yearHeaders = this.state.availableDates.reduce((yearHeaders, date) => {
            let yearHeader = yearHeaders.find(headerYear => headerYear.year === date.year);
            if (yearHeader === undefined) {
                yearHeader = { year: date.year, monthsCount: 0 };
                yearHeaders.push(yearHeader);
            }
            yearHeader.monthsCount++;
            return yearHeaders;
        }, []);

        const state = this.state;
        const mId = state.selectedMitarbeiterId;
        const uebersicht = state.uebersicht;

        const fullRowColSpan = yearHeaders.reduce(
            (result, yearHeader) => result + yearHeader.monthsCount,
            2 // additional columns for projekt/lph name and sum
        );

        let bodyRows = [];
        if (uebersicht[mId] === undefined) {
            bodyRows = [
                <tr key="ratheruniquerow">
                    <td colSpan={fullRowColSpan}>Keine Daten vorhanden</td>
                </tr>,
            ];
        } else {
            /*
             * @typedef {Object} ProjektDatum
             * @property {string} name
             * @property {string} nr
             * @property {string[]} lphNames
             * @property {DateColumn[]} dateColumns
             * @property {SumColumn} sumColumn
             *
             * @typedef {Object} DateColumn
             * @property {int} year
             * @property {int} month
             * @property {int} totalMinutes
             * @property {LPH} individualLphs
             *
             * @typedef {Object} LPH
             * @property {string} lph
             * @property {int} totalMinutes
             *
             * @typedef {Object} SumColumn
             * @property {int} projektMinutes
             * @property {LPH[]} lphSums
             */
            const projectData = [];
            const sumData = [];
            let grandTotal = 0;
            const singleProject = uebersicht[mId].individualProjects.length === 1;
            uebersicht[mId].individualProjects
                .sort((a, b) => state.projekts[a.pId].name.localeCompare(state.projekts[b.pId].name, 'de'))
                .forEach(project => {
                    const projectDatum = {
                        name: state.projekts[project.pId].name,
                        nr: state.projekts[project.pId].nr,
                        bauprojekt: state.projekts[project.pId].bauprojekt,
                        lphNames: [],
                        dateColumns: [],
                        sumColumn: { projektMinutes: 0, lphSums: [] },
                    };

                    project.allLphs.years.forEach(year => {
                        year.months
                            .filter(month => isMonthInSelectedPeriod(month.month, year.year, state.availableDates))
                            .forEach(month => {
                                projectDatum.dateColumns.push({
                                    year: year.year,
                                    month: month.month,
                                    allLphMinutes: month.totalMinutes,
                                    individualLphs: [],
                                });
                                projectDatum.sumColumn.projektMinutes += month.totalMinutes;

                                let sumDatum = sumData.find(sumObj => sumObj.year === year.year && sumObj.month === month.month);
                                if (sumDatum === undefined) {
                                    sumDatum = { year: year.year, month: month.month, minutes: 0 };
                                    sumData.push(sumDatum);
                                }
                                sumDatum.minutes += month.totalMinutes;
                                grandTotal += month.totalMinutes;
                            });
                    });

                    project.individualLphs.forEach(lph => {
                        projectDatum.lphNames.push(lph.lph);
                        const lphSum = { lph: lph.lph, totalMinutes: 0 };

                        lph.years.forEach(year => {
                            year.months
                                .filter(month => isMonthInSelectedPeriod(month.month, year.year, state.availableDates))
                                .forEach(month => {
                                    const dateColumn = projectDatum.dateColumns.find(column => column.year === year.year && column.month === month.month);
                                    dateColumn.individualLphs.push({
                                        lph: lph.lph,
                                        totalMinutes: month.totalMinutes,
                                    });
                                    lphSum.totalMinutes += month.totalMinutes;
                                });
                        });

                        projectDatum.sumColumn.lphSums.push(lphSum);
                    });

                    if (projectDatum.sumColumn.projektMinutes > 0) {
                        projectData.push(projectDatum);
                    }
                });

            const zeiterfassungSums = { sollstunden: 0, krankminuten: 0, urlaubminuten: 0 };
            const zeiterfassungData = uebersicht[mId].zeiterfassungYears.reduce((carry, zeiterfassungYear) => {
                zeiterfassungYear.months.forEach(month => {
                    if (isMonthInSelectedPeriod(month.month, zeiterfassungYear.year, state.availableDates)) {
                        carry.push({
                            year: zeiterfassungYear.year,
                            month: month.month,
                            sollstunden: month.sollstunden,
                            krankminuten: month.krankminuten,
                            urlaubminuten: month.urlaubminuten,
                        });
                        zeiterfassungSums.sollstunden += month.sollstunden;
                        zeiterfassungSums.krankminuten += month.krankminuten;
                        zeiterfassungSums.urlaubminuten += month.urlaubminuten;
                    }
                });
                return carry;
            }, []);

            bodyRows = [
                ...projectData.map((project, i) => <MitarbeiterUebersichtProject key={i} project={project} projectKey={i} isOnlyProject={singleProject} />),
                <MitarbeiterUebersichtFooter key="sum" label="Σ" monthValues={sumData.map(sumDatum => sumDatum.minutes)} sumValue={grandTotal} />,
                <MitarbeiterUebersichtFooter
                    key="soll"
                    label="Sollstunden"
                    monthValues={zeiterfassungData.map(zeiterfassungDatum => zeiterfassungDatum.sollstunden * 60)}
                    sumValue={zeiterfassungSums.sollstunden * 60}
                />,
                <MitarbeiterUebersichtFooter
                    key="krank"
                    label="Krank"
                    monthValues={zeiterfassungData.map(zeiterfassungDatum => zeiterfassungDatum.krankminuten)}
                    sumValue={zeiterfassungSums.krankminuten}
                />,
                <MitarbeiterUebersichtFooter
                    key="urlaub"
                    label="Urlaub"
                    monthValues={zeiterfassungData.map(zeiterfassungDatum => zeiterfassungDatum.urlaubminuten)}
                    sumValue={zeiterfassungSums.urlaubminuten}
                />,
            ];
        }

        return (
            <div>
                <div>
                    <PortalButtonBar>
                        <Filter
                            selectedMitarbeiterId={this.state.selectedMitarbeiterId}
                            mitarbeiters={this.state.mitarbeitersForSelect}
                            onMitarbeiterChange={this.onMitarbeiterChange}
                            startDate={this.state.selectedStartDate}
                            endDate={this.state.selectedEndDate}
                            minDate={this.state.earliestDate}
                            maxDate={this.state.latestDate}
                            onStartDateChange={this.onStartDateChange}
                            onEndDateChange={this.onEndDateChange}
                            onPdfButtonClick={this.makePdf}
                            onXlsxButtonClick={this.makeXlsx}
                        />
                    </PortalButtonBar>
                </div>
                {state.loadingMitarbeiterUebersicht ? (
                    <div>Loading</div>
                ) : (
                    <div className="container-fluid">
                        <div className="mitarbeiter-stunden-uebersicht--widget card table-responsive-xl">
                            <table className="table table-responsive table-striped table-small mb-0" id="reportTable">
                                <thead>
                                    <MitarbeiterHeader
                                        mitarbeiter={this.state.mitarbeiters[this.state.selectedMitarbeiterId]}
                                        standorte={this.state.standorts}
                                        startDate={this.state.selectedStartDate}
                                        endDate={this.state.selectedEndDate}
                                        colSpan={fullRowColSpan}
                                    />
                                    <tr>
                                        <th />
                                        {this.state.availableDates.map(date => (
                                            <th className="text-right" key={`${date.year}${date.month}`}>
                                                {momentFromDateObj(date).format('MMM YY')}
                                            </th>
                                        ))}
                                        <th className="text-right">Σ</th>
                                    </tr>
                                </thead>
                                <tbody>{bodyRows}</tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
