import React from 'react';

export default ({ standort, projekte, mitarbeiters }) => (
    <div className="row">
        <div className="col-md-3">
            <h4>Adresse</h4>
            {standort.strasse}
            <br />
            {standort.stadt}
            <br />
            <br />
            Tel: {standort.tel}
            <br />
            Fax: {standort.fax}
            <br />
            Mail: {standort.mail}
            <br />
        </div>
        {projekte.length > 0 && (
            <div className="col-md-6">
                <h4>Aktuelle Projekte</h4>
                <ul className="list-unstyled">
                    {projekte.map(projekt => (
                        <li key={projekt.id}>
                            <a href={projekt.link}>{projekt.name}</a>
                        </li>
                    ))}
                </ul>
            </div>
        )}
        {mitarbeiters.length > 0 && (
            <div className="col-md-3">
                <h4>Mitarbeiter am Baubüro</h4>
                <ul className="list-unstyled">
                    {mitarbeiters.map(mitarbeiter => (
                        <li key={mitarbeiter.id}>
                            <a href={mitarbeiter.link}>{mitarbeiter.name}</a>
                        </li>
                    ))}
                </ul>
            </div>
        )}
    </div>
);
