import $ from 'jquery';

$(() => {
    // handles start_time and end_time on time forms
    $('.e2-form-prototype .duplicate-fields').on('click', e => {
        const $row = $(e.target).closest('.row');
        const startValue = $row.find('.start_time').val();
        const endValue = $row.find('.end_time').val();

        $row.closest('.e2-form-prototype')
            .find('.start_time')
            .val(startValue);
        $row.closest('.e2-form-prototype')
            .find('.end_time')
            .val(endValue);
    });
});
