import React from 'react';
import { postJson } from '../../api';
import FrageForm from './FrageForm';
import { handleFormSubmitResult } from '../../formHelpers';

export default ({ frage, updateFrage, toggleModal, alleKnoten, alleTags }) => {
    const onSubmit = async values => {
        const result = await postJson('/wissen/frage/aktualisieren', values);
        if (handleFormSubmitResult(result, 'Frage erfolgreich bearbeitet')) {
            updateFrage(result.frage);
        }
    };

    return <FrageForm initialValues={frage} onSubmit={values => onSubmit(values)} toggleModal={toggleModal} alleKnoten={alleKnoten} alleTags={alleTags} />;
};
