import React from 'react';
import ReactPaginate from 'react-paginate';

export default class Paginate extends React.Component {
    render() {
        const p = {
            previousLabel: '\u00ab',
            nextLabel: '\u00bb',
            breakLabel: '...',
            marginPagesDisplayed: 2,
            pageRangeDisplayed: 5,
            containerClassName: 'pagination',
            pageClassName: 'page-item',
            breakClassName: 'page-item',
            previousClassName: 'page-item',
            nextClassName: 'page-item',
            pageLinkClassName: 'page-link',
            breakLinkClassName: 'page-link',
            previousLinkClassName: 'page-link',
            nextLinkClassName: 'page-link',
            activeClassName: 'active',
            disabledClassName: 'disabled',
            ...this.props,
        };

        return <ReactPaginate {...p} />;
    }
}
