import React from 'react';
import getBadgeColor from '../../getBadgeColor';
import moment from 'moment';
import { getNextView, getPrevView } from './getAufgabeView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getStatusOptions } from '../AufgabenListe/Aufgabe';
import { KEY_LEFT, KEY_RIGHT } from '../../Modal/KeyBindings';
import AufgabeStartEnde from './AufgabeStartEnde';
import Avatar from './Avatar';

export default class AufgabeDetailView extends React.Component {
    state = {
        aufgabe: this.props.aufgabeForViewAndEdit,
        isStatusSelectorOpen: false,
    };

    async componentDidMount() {
        window.addEventListener('keyup', this.handleArrowKeys, false);
    }

    componentWillUnmount = () => window.removeEventListener('keyup', this.handleArrowKeys, false);

    handleArrowKeys = e => {
        e.preventDefault();

        const keys = {
            [KEY_RIGHT]: () => this.setState({ aufgabe: getNextView(this.props.aufgaben, this.state.aufgabe) }),
            [KEY_LEFT]: () => this.setState({ aufgabe: getPrevView(this.props.aufgaben, this.state.aufgabe) }),
        };
        if (keys[e.keyCode]) {
            keys[e.keyCode]();
        }
    };

    openStatusSelector = () => this.setState({ isStatusSelectorOpen: !this.state.isStatusSelectorOpen });

    render() {
        const { aufgabe, isStatusSelectorOpen } = this.state;
        const { setStatus } = this.props;
        return (
            <React.Fragment>
                {isStatusSelectorOpen && <div className="popup-overlay --detail" onClick={() => this.openStatusSelector()} />}
                <div className="container-fluid">
                    <div className="row">
                        <div className="d-flex col-12 align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <span className={`d-block dringlichkeit-aufgabe-detail-view dringlichkeit-aufgabe-detail-view_${aufgabe.dringlichkeit}`} />
                                <h2 className="mb-2 ml-3 mb-lg-0">{aufgabe.titel}</h2>
                            </div>
                            <div
                                onClick={() => this.openStatusSelector()}
                                className={`d-flex pointer justify-content-center p-2 badge-${getBadgeColor(aufgabe.status)}`}>
                                <span>{aufgabe.status}</span>
                                {isStatusSelectorOpen && (
                                    <div className="d-flex align-items-center justify-content-around flex-column popup --status-wechsel">
                                        <h5 className="text-dark">wechseln zu</h5>
                                        {getStatusOptions(aufgabe.status).map(status => (
                                            <span
                                                key={status}
                                                className={`badge w-100 p-2 mt-2 status badge-${getBadgeColor(status)}`}
                                                onClick={() => setStatus(status)}>
                                                {status}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-baseline justify-content-between">
                                <AufgabeStartEnde start={aufgabe.start} ende={aufgabe.ende} />
                            </div>
                        </div>
                        <hr className="w-100 mt-3" />
                        <div className="col-12">
                            <h4>Projekt</h4>
                            {aufgabe.projekt ? (
                                <a href={aufgabe.projekt.url}>{aufgabe.projekt.name}</a>
                            ) : (
                                <span className="text-muted">Persönliche Aufgabe</span>
                            )}
                        </div>
                        <hr className="w-100 mt-3" />
                        <div className="col-12 d-flex flex-column">
                            {aufgabe.beschreibung}
                            {!aufgabe.beschreibung && <em>Keine Beschreibung vorhanden</em>}
                        </div>
                        <hr className="w-100 mt-3" />
                        <div className="col-12 d-flex flex-column">
                            <div className="d-flex mt-2">
                                <span style={{ minWidth: 100 }}>Schlüssel:</span>
                                <span>{aufgabe.key}</span>
                            </div>
                            <div className="d-flex mt-2">
                                <span style={{ minWidth: 100 }}>Ersteller:</span>
                                <Avatar url={aufgabe.ersteller.url} name={aufgabe.ersteller.name} avatar={aufgabe.ersteller.avatar} />
                            </div>
                            {aufgabe.bearbeiter.length !== 0 && (
                                <div className="d-flex mt-2">
                                    <span style={{ minWidth: 100 }}>Bearbeiter:</span>
                                    {aufgabe.bearbeiter.map(bearbeiter => (
                                        <Avatar
                                            name={bearbeiter.name}
                                            key={bearbeiter.id}
                                            avatar={bearbeiter.avatar}
                                            url={bearbeiter.url}
                                            linkClassName="bearbeiter"
                                        />
                                    ))}
                                </div>
                            )}
                            <div className="d-flex mt-2">
                                <span style={{ minWidth: 100 }}>Erstellt am:</span>
                                <span>{moment(aufgabe.erstellungsdatum).format('DD.MM.YYYY')}</span>
                            </div>
                            <div className="d-flex mt-2">
                                <span style={{ minWidth: 100 }}>Geändert am:</span>
                                <span>{moment(aufgabe.aenderungsdatum).format('DD.MM.YYYY')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row" />
                </div>
                <hr className="w-100" />
                <div className="modal-footer justify-content-between p-0 w-100 border-0">
                    <div className="btn-group">
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => this.setState({ aufgabe: getPrevView(this.props.aufgaben, this.state.aufgabe) })}>
                            <FontAwesomeIcon className="mr-2" icon="chevron-left" />
                            Vorherige Aufgabe
                        </button>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => this.setState({ aufgabe: getNextView(this.props.aufgaben, this.state.aufgabe) })}>
                            Nächste Aufgabe
                            <FontAwesomeIcon className="ml-2" icon="chevron-right" />
                        </button>
                    </div>
                    <div className="d-flex">
                        <button className="btn btn-primary mr-2" onClick={() => this.props.toggleAufgabenEdit()}>
                            <FontAwesomeIcon icon="edit" />
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
