import React from 'react';
import Swiper from 'react-id-swiper';
import PlaceholderPic from '../../../../../application/public/images/dashboard/Profilbild_geschlechtsneutral.svg';

export default class GeburstageSlider extends React.Component {
    constructor(props) {
        super(props);
        this.swiper = null;
    }
    goNext = () => this.swiper && this.swiper.slideNext();
    goPrev = () => this.swiper && this.swiper.slidePrev();

    render() {
        const getGebIndex = geb => geb.before === false;
        const params = {
            slidesPerView: 5,
            loop: true,
            loopFillGroupWithBlank: false,
            centeredSlides: false,
            spaceBetween: 20,
            noSwiping: true,
            lazy: {
                loadPrevNext: true,
            },
            initialSlide: this.props.geburtstage.findIndex(getGebIndex),
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
            },
        };
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-1 d-flex align-items-center justify-content-center">
                        <button className="btn btn-link text-dark" onClick={this.goPrev}>
                            <i className="fas fa-chevron-left" />
                        </button>
                    </div>
                    <div className="col-10">
                        <Swiper {...params} ref={node => (node ? (this.swiper = node.swiper) : null)}>
                            {this.getGeburtstage().map(mitarbeiter => (
                                <a key={mitarbeiter.id} href={`/mitarbeiter/${mitarbeiter.id}/detail/`}>
                                    <div
                                        title={mitarbeiter.name}
                                        className={`d-flex align-items-center flex-column ${mitarbeiter.today ? 'birthday bg-light' : ''}`}>
                                        <span>{mitarbeiter.date}</span>
                                        <div className="mitarbeiter d-flex align-items-center justify-content-center">
                                            {mitarbeiter.img ? (
                                                <>
                                                    <img alt="img" data-src={mitarbeiter.img} className="swiper-lazy rounded-circle" />
                                                    <div className="swiper-lazy-preloader" />
                                                </>
                                            ) : (
                                                <img alt="placeholder_pic" src={PlaceholderPic} className="swiper-lazy rounded-circle" />
                                            )}
                                        </div>
                                        <span className="text-center">{mitarbeiter.name}</span>
                                    </div>
                                </a>
                            ))}
                        </Swiper>
                    </div>
                    <div className="col-1 d-flex align-items-center justify-content-center">
                        <button className="btn btn-link text-dark" onClick={this.goNext}>
                            <i className="fas fa-chevron-right" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    getGeburtstage = () => this.props.geburtstage;
}
